import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import CodeFlask from 'codeflask';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';



@Component({
  selector: 'app-administration-theme-settings',
  templateUrl: './administration-theme-settings.component.html',
  styleUrls: ['./administration-theme-settings.component.css']
})
export class AdministrationThemeSettingsComponent implements OnInit {
  @ViewChild('codejs',{static:true}) codejs:ElementRef;
  @ViewChild('codecss',{static:true}) codecss:ElementRef;

  theamForm:FormGroup;

  js_editor;
  css_editor;
  javascript:any='';
  css_script:any='';
  response:any=[];

  constructor(
    private api:ApiService,
    private toastr:ToastrService,
    private formBuilder:FormBuilder
  ) { 
    this.theamForm = this.formBuilder.group({
      id:[''],
      theme_application_name:['']
    });
  }

  ngOnInit(): void {
    this.getThemeSetting();
  }
 
  cssEditor(){
    this.css_editor = new CodeFlask(this.codecss.nativeElement, { language: 'css',  lineNumbers: true });

    this.css_editor.updateCode(this.css_script)
    this.css_editor.onUpdate((css) => {
      this.css_script = css;
    });
  }

  jsEditor(){
    this.js_editor = new CodeFlask(this.codejs.nativeElement, { language: 'js',  lineNumbers: true });

    this.js_editor.updateCode(this.javascript)
      this.js_editor.onUpdate((js) => {
        this.javascript = js;
      });
  }

  async getThemeSetting(){
    this.api.get('admin/applicationsetting/list').subscribe((result)=>{
      let data = result.data;
      this.javascript = (data.theme_header_scripts)?data.theme_header_scripts:'';
      this.css_script = (data.theme_custom_css)?data.theme_custom_css:'';
      this.theamForm.patchValue(data);
      this.cssEditor();
      this.jsEditor();
    },(err)=>{

    });
  }

  submitForm(data):void{
    data.theme_header_scripts = this.javascript;
    data.theme_custom_css = this.css_script;

    let endPoint = (data.id)?'update':'create';

    this.api.post('admin/theme_setting/'+endPoint,data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.getThemeSetting();
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    }); 


  }
  

}
