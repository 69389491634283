import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-administration-bulk-availability',
  templateUrl: './administration-bulk-availability.component.html',
  styleUrls: ['./administration-bulk-availability.component.css']
})
export class AdministrationBulkAvailabilityComponent implements OnInit {

  response:any=[];
  users:any=[];
  isSelected:boolean;
  userListIds:any=[];
  userDetails:any=[];

  constructor(
    private api:ApiService,
    private toastr:ToastrService,
  ) {
    this.isSelected=false;
   }

  ngOnInit(): void {
    this.getAvailableUser();
  }

  getAvailableUser(){
    this.userListIds=[];
    this.api.get('admin/availability/lists').subscribe((result)=>{
      this.response = result;
      this.users = result.data;
      this.users.forEach((element,index) => {
        
        if(element.availability_date){
          this.users[index].availability_date = formatDate(element.availability_date, 'yyyy-MM-dd', 'en');
        }else{
          this.users[index].availability_date='';
        }
        this.users[index].last_availability_date = element.availability_date_to
        this.users[index].isSelected=this.isSelected;
      });
    },(err)=>{
    });
  }

  toggle(){
    this.users.forEach((element,index) => {
      if(!element.isSelected){
        this.users[index].isSelected=true;
      }
    });
    this.selectedData();
  }

  selectedUser(index){
    if(this.users[index].isSelected){
      this.users[index].isSelected=true;
      this.userDetails[index]={id:this.users[index].id,availability_date:this.users[index].availability_date,availability_date_to:this.users[index].availability_date_to};
    }
    this.selectedData();
  }

  selectedData(){
    this.userListIds=[];
    this.users.forEach((element,index)=>{
      if(element.isSelected){
        this.userListIds.push({id:this.users[index].id,availability_date:this.users[index].availability_date,availability_date_to:this.users[index].availability_date_to});
      }
    });
    console.log(this.userListIds);
  }

  getStartDate(value,index,field){
    if(field){
      this.users[index].availability_date=value.target.value;
    }else{
      this.users[index].availability_date_to=value.target.value;
    }
    this.selectedData();
  }

  setDates(){
    let fromDate = document.getElementById('fromDate') as HTMLInputElement;
    let fromdate = fromDate.value;
    let toDate = document.getElementById('toDate') as HTMLInputElement;
    let todate = toDate.value;
    this.users.forEach((element,index) => {
      if(fromdate) this.users[index].availability_date = fromdate;
      if(todate) this.users[index].availability_date_to = todate;
    });
    this.selectedData();
  }

  sendRequest(){
    let isTrue=true;
    if(this.userListIds.length==0){
      this.toastr.error('Please select one or more employees before proceeding.','Error');
      isTrue=false;
    }
    if(isTrue){
      this.userListIds.forEach((element,index) => {
        if(!element.availability_date || !element.availability_date_to){
          isTrue=false;
        }
      });
      if(!isTrue){
        this.toastr.error('Please verify that all selected employees have dates selected.','Error');
      }
    }

    if(isTrue){
      let data = {'users':this.userListIds};
      this.api.post('admin/availability/update',data).subscribe((result)=>{
        this.response = result;
        this.toastr.success(this.response.message,"Success");
        this.getAvailableUser();
      },(err)=>{
        this.toastr.error(err.error.message,"Error");
      });
    }
  }


  

}
