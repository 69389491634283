<div class="row">
    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Flags</h6>
                <div class="btn-group">
  
                    <button class="action-btn bg-dark-green mr-2" (click)="openAddModal(addFlagModal)"><img src="assets/img/svg-icon/add-white.svg" width="20" /></button>
                    <!-- <button class="action-btn bg-dark-blue" data-toggle="modal" data-target="#exampleModalRight"><img src="assets/img/svg-icon/filter-white.svg" width="20" /></button> -->
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content list_table" id="ticketList">
                    <div class="table-responsive list_table">
                        <table class="table  table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th class="pt-2">Model</th>
                                    <th class="pt-2">Name</th>
                                    <th class="pt-2">Icon</th>
                                    <th class="pt-2">Message</th>
                                    <th class="pt-2">Create At</th>
                                    <th class="pt-2">Updated At</th>
                                    <th class="pt-2 text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="flags.length > 0">
                                    <tr *ngFor="let flag of flags | paginate: { id: 'call_script_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalRecords }; let i=index">
                                        <td class="text-black">{{flag.model_name}}</td>
                                        <td class="text-black">{{flag.name}}</td>
                                        <td class="" [ngStyle]="{ 'color': flag.icon_color }"><i class="fa {{flag.icon}}"></i></td>
                                        <td class=""> <div class="alert alert-{{flag.message_style}}"  role="alert"> {{flag.message}} </div></td>
                                        
                                        <td class="text-black">{{flag.created_at | date : 'MM/dd/yyyy' }}</td>
                                        <td class="text-black">{{flag.updated_at | date : 'MM/dd/yyyy' }}</td>
                                        <td class="text-right">
                                          <a href="javascript:void(0)" class="mr-2 action-btn bg-edit" title="Update Call Script" (click)="editFlagScript(addFlagModal,i)"><img src="assets/img/svg-icon/edit.svg" width="20" /></a>
                                          <a href="javascript:void(0)" class="action-btn bg-red" title="Delete" (click)="deleteModal(flagDeleteModal,flag)"><img src="assets/img/svg-icon/delete.svg" width="20" /></a>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                        <pagination-controls class="pagination-controls-theme" id="call_script_pagination" (pageChange)="getFlags(page = $event)" maxSize="9" directionLinks="true"
                        autoHide="true" responsive="true">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  
  <!-- Flag Modal  -->
  <ng-template #addFlagModal let-modal>
  
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Flag Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross  click')"
        aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
  
    <div class="modal-body md-b-body">
        <app-modal-flag [details]="flagDetails" [flagModelRef]="flagModelRef" [modelName]="modelName"></app-modal-flag>
    </div>
  
  </ng-template>
  
  <!-- Delete  -->
  <ng-template #flagDeleteModal let-modal>
  
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>
  
    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteFlag()" class="btn btn-success  border-r-10">Yes, delete it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>
  
  </ng-template>
  
  <!-- Right Alight Modal -->
  <div class="modal fade drawer right-align" id="exampleModalRight" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Right Align Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
  