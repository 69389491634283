import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-administration-manage-calls-dashboard',
  templateUrl: './administration-manage-calls-dashboard.component.html',
  styleUrls: ['./administration-manage-calls-dashboard.component.css']
})
export class AdministrationManageCallsDashboardComponent implements OnInit {

  callsForm: FormGroup;
  callScheduleForm: FormGroup;
  page: any = 1;
  force_schedule: any;
  totalRecords: any;
  response: any;
  calls: any = [];
  CompanySettingData:any=[];
  callaData: any;
  modalReference: any = '';
  isNo:boolean = false;
  isForceSchedule:boolean=false;
  isCallLogs: boolean = false;
  isCallSchedule: boolean = false;
  allClientDetails: any = [];
  date1: any = [];
  date2: any = [];
  date3: any = [];
  date4: any = [];
  date5: any = [];
  date6: any = [];
  date7: any = [];
  date8: any = [];
  date9: any = [];
  date10: any = [];
  date11: any = [];
  date12: any = [];
  date13: any = [];
  date14: any = [];
  date15: any = [];
  date16: any = [];
  date17: any = [];
  date18: any = [];
  date19: any = [];
  date20: any = [];
  date21: any = [];
  date22: any = [];
  date23: any = [];
  date24: any = [];
  date25: any = [];
  date26: any = [];
  date27: any = [];
  date28: any = [];

  constructor(
    private api: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toast: ToastrService,
    public toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.init();
    const date = new Date('2022-06-20');
    this.date1 = formatDate(new Date(date.setDate(date.getDate() + 0)), 'yyyy-MM-dd', 'en-US');
    this.date2 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date3 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date4 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date5 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date6 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date7 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date8 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date9 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date10 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date11 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date12 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date13 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date14 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date15 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date16 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date17 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date18 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date19 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date20 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date21 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date22 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date23 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date24 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date25 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date26 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date27 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');
    this.date28 = formatDate(new Date(date.setDate(date.getDate() + 1)), 'yyyy-MM-dd', 'en-US');

  }
  init(): void {
    this.callsForm = this.formBuilder.group({
      name: [''],
    });

    this.callScheduleForm = this.formBuilder.group({
      id: [],
      force_schedule: [''],
      enable_caller: [],
      option_1: [''],
      option_2: [''],
      option_3: [''],
      scheduler_1: [''],
      scheduler_2: [''],
      scheduler_3: [''],
      scheduler_4: [''],
      recruiter_1: [''],
      recruiter_2: [''],
      recruiter_3: [''],
      ext_811: [''],
      ext_822: [''],
      ext_833: [''],
      ext_844: [''],
      ext_855: [''],
      ext_866: [''],
      ext_877: [''],
      user1: [],
      user2: [],
      user3: [],
      user4: [],
      user5: [],
      user6: [],
      user7: [],
      user8: [],
      user9: [],
      user10: [],
      user11: [],
      user12: [],
      user13: [],
      user14: [],
      user15: [],
      user16: [],
      user17: [],
      user18: [],
      user19: [],
      user20: [],
      user21: [],
      user22: [],
      user23: [],
      user24: [],
      user25: [],
      user26: [],
      user27: [],
      user28: [],

    });
  }

  async getCalls(page) {
    this.page = page;
    this.api.get('admin/calls/lists?page=' + page).subscribe((result) => {
      this.totalRecords = result.data.total;
      this.calls = result.data.data;
    }, (err) => {

    });
  }
  addCalls(Content) {
    // this.callsForm.reset();
    this.modalService.open(Content, { size: 'xl', centered: true });
  }
  submitCallsData(data) {
    console.log(data);
  }
  deleteCallsData() {
    let data = { id: this.callaData.id };
    this.modalReference.close();
    this.api.post('admin/calls/delete', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getCalls(this.page);
    }, (err) => {
      console.log(err);
      this.toastr.error(err.error.message, 'Error');
    });
  }
  openDeletePopUp(deleteMarketing, current_row) {
    this.callaData = current_row;
    this.modalReference = this.modalService.open(deleteMarketing, { size: 'sm', centered: true });
  }
  openDashboard() {
    this.isCallLogs = this.isCallSchedule = false;
  }
  openCallLogs() {
    this.isCallLogs = true;
    this.isCallSchedule = false;
    this.getCalls(this.page)
  }
  openCallSchedule() {
    this.getCompanySetting();
    this.getClientDetails();
    this.isCallLogs = false;
    this.isCallSchedule = true;
  }

  submitCallScheduleData(data) {
    (this.isNo==true)?data.force_schedule=1:data.force_schedule=0;
    data.date1 = this.date1;
    data.date2 = this.date2;
    data.date3 = this.date3;
    data.date4 = this.date4;
    data.date5 = this.date5;
    data.date6 = this.date6;
    data.date7 = this.date7;
    data.date8 = this.date8;
    data.date9 = this.date9;
    data.date10 = this.date10;
    data.date11 = this.date11;
    data.date12 = this.date12;
    data.date13 = this.date13;
    data.date14 = this.date14;
    data.date15 = this.date15;
    data.date16 = this.date16;
    data.date17 = this.date17;
    data.date18 = this.date18;
    data.date19 = this.date19;
    data.date20 = this.date20;
    data.date21 = this.date21;
    data.date22 = this.date22;
    data.date23 = this.date23;
    data.date24 = this.date24;
    data.date25 = this.date25;
    data.date26 = this.date26;
    data.date27 = this.date27;
    data.date28 = this.date28;
    this.api.post('admin/company_setting/call_schedule_update', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getCompanySetting();
    }, (err) => {
      console.log(err);
      this.toastr.error(err.error.message, 'Error');
    });
  }
  timesheetsChange(key) {

    (key == 0) ? this.isNo = true : this.isNo = false;
    (key == 1) ? this.isNo = false : this.isNo = true;

  }
  async getClientDetails() {
    this.api.get('admin/course_registration/get_user').subscribe((result) => {
      this.response = result;
      this.allClientDetails = this.response.data;

    }, (err) => {

    });
  }
  async getCompanySetting() {
    this.api.get('admin/applicationsetting/list').subscribe((result) => {
      let data = result.data;
      (data.force_schedule == 1) ? this.isNo = true : this.isNo = false;
      (data.force_schedule == 1) ? this.isForceSchedule = true : this.isForceSchedule = false;
      console.log(this.isNo);
      this.callScheduleForm.patchValue({
        'id': data.id,
        'enable_caller': data.enable_caller,
        'force_schedule': data.force_schedule,
        'option_1': JSON.parse(data.call_schedule).daylight.option_1,
        'option_2': JSON.parse(data.call_schedule).daylight.option_2,
        'option_3': JSON.parse(data.call_schedule).daylight.option_3,
        'scheduler_1': JSON.parse(data.call_schedule).daylight.scheduler_1,
        'scheduler_2': JSON.parse(data.call_schedule).daylight.scheduler_2,
        'scheduler_3': JSON.parse(data.call_schedule).daylight.scheduler_3,
        'scheduler_4': JSON.parse(data.call_schedule).daylight.scheduler_4,
        'recruiter_1': JSON.parse(data.call_schedule).daylight.recruiter_1,
        'recruiter_2': JSON.parse(data.call_schedule).daylight.recruiter_2,
        'recruiter_3': JSON.parse(data.call_schedule).daylight.recruiter_3,
        'ext_811': JSON.parse(data.call_schedule).daylight.ext_811,
        'ext_822': JSON.parse(data.call_schedule).daylight.ext_822,
        'ext_833': JSON.parse(data.call_schedule).daylight.ext_833,
        'ext_844': JSON.parse(data.call_schedule).daylight.ext_844,
        'ext_855': JSON.parse(data.call_schedule).daylight.ext_855,
        'ext_866': JSON.parse(data.call_schedule).daylight.ext_866,
        'ext_877': JSON.parse(data.call_schedule).daylight.ext_877,
        'user1': JSON.parse(data.call_schedule).evening[this.date1],
        'user2': JSON.parse(data.call_schedule).evening[this.date2],
        'user3': JSON.parse(data.call_schedule).evening[this.date3],
        'user4': JSON.parse(data.call_schedule).evening[this.date4],
        'user5': JSON.parse(data.call_schedule).evening[this.date5],
        'user6': JSON.parse(data.call_schedule).evening[this.date6],
        'user7': JSON.parse(data.call_schedule).evening[this.date7],
        'user8': JSON.parse(data.call_schedule).evening[this.date8],
        'user9': JSON.parse(data.call_schedule).evening[this.date9],
        'user10': JSON.parse(data.call_schedule).evening[this.date10],
        'user11': JSON.parse(data.call_schedule).evening[this.date11],
        'user12': JSON.parse(data.call_schedule).evening[this.date12],
        'user13': JSON.parse(data.call_schedule).evening[this.date13],
        'user14': JSON.parse(data.call_schedule).evening[this.date14],
        'user15': JSON.parse(data.call_schedule).evening[this.date5],
        'user16': JSON.parse(data.call_schedule).evening[this.date16],
        'user17': JSON.parse(data.call_schedule).evening[this.date17],
        'user18': JSON.parse(data.call_schedule).evening[this.date18],
        'user19': JSON.parse(data.call_schedule).evening[this.date19],
        'user20': JSON.parse(data.call_schedule).evening[this.date20],
        'user21': JSON.parse(data.call_schedule).evening[this.date21],
        'user22': JSON.parse(data.call_schedule).evening[this.date22],
        'user23': JSON.parse(data.call_schedule).evening[this.date23],
        'user24': JSON.parse(data.call_schedule).evening[this.date24],
        'user25': JSON.parse(data.call_schedule).evening[this.date25],
        'user26': JSON.parse(data.call_schedule).evening[this.date26],
        'user27': JSON.parse(data.call_schedule).evening[this.date27],
        'user28': JSON.parse(data.call_schedule).evening[this.date28],

      });
    }, (err) => {
    });
    return true;
  }

}
