<form [formGroup]="flagForm" (ngSubmit)="submitFlagForm(flagForm.value)">
    <input type="hidden" formControlName="id">
    <div class="row">
        <div class="col-sm-6">
            <label for="">Name</label>
            <input type="text" formControlName="name" class="form-control">
            <span class="text-danger"*ngIf="flagForm.get('name').errors?.required || flagForm.get('name').errors?.minlength && flagForm.get('name').errors">
                {{formErrors.name}}</span>
        </div>
        <div class="col-sm-6">
            <label for="">Message</label>
            <input type="text" formControlName="message" class="form-control">
        </div>
        <div class="col-sm-4">
            <label for="">Style (Message)</label>
            <ng-select formControlName="message_style" clearAllText="Clear" class="form-control-ng rounded-0">
                <ng-option value="danger">Danger</ng-option>
                <ng-option value="Info">Info</ng-option>
                <ng-option value="success">Success</ng-option>
                <ng-option value="warning">Warning</ng-option>
            </ng-select>
        </div>
        <div class="col-sm-4">
            <label for=""> Icon</label>
            <ng-select formControlName="icon" clearAllText="Clear" class="form-control-ng rounded-0">
                <ng-option *ngFor="let icon of icons;" value="{{icon.c}}">{{icon.l}}</ng-option>
            </ng-select>
        </div>
        <div class="col-sm-4">
            <label for="">Color (Icon)</label>
            <input formControlName="icon_color" type="color" class="form-control color-input">
        </div>

        <div class="col-sm-4" [ngClass]="(modelName!='' && modelName!=undefined)?'hidden':''">
            <label for="">Model</label>
            <ng-select formControlName="model_name" [(ngModel)]="modelNameTemp">
                <ng-option *ngFor="let model of modelNames;" value="{{ model.value }}">{{ model.value }}</ng-option>
            </ng-select>
        </div>
    </div>
    
    <div class="row">
        <div class="col-sm-12">
            <button type="submit" [disabled]="!flagForm.valid" class="action-btn bg-dark-blue mt-2">Submit</button>
        </div>
    </div>
</form>
