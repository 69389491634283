import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-administration-support-desk',
  templateUrl: './administration-support-desk.component.html',
  styleUrls: ['./administration-support-desk.component.css']
})
export class AdministrationSupportDeskComponent implements OnInit {

  supportDeskForm:FormGroup;
  attachments:any=[];
  user:any=[];
  priorityData:any=[{label: "1 - Major", value: "Major"},{label: "2 - Important", value: "Important"},{label: "3 - Normal", value: "Normal"},{label: "4 - Minor ", value: "Minor"}];
  typeOfRequest:any=[{label: "Bug", value: "Bug"},{label: "Customization", value: "Customization"},{label: "Feature Request", value: "Feature Request"},{label: "Performance Problem", value: "Performance Problem"},{label:"Usability Problem",value:"Usability Problem"}];
  constructor(
    private api: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toast: ToastrService,
    public toastr: ToastrService,
  ) { 
    this.user =  JSON.parse(localStorage.getItem('reliant_user'));
  }

  ngOnInit(): void {
    this.supportDeskForm = this.formBuilder.group({
      priority: [''],
      type:[''],
      email:[''],
      summary:[''],
      description:[''],
      attachments:[''],
     
    });
  }
  openIssuesForm(Content){
    //console.log(this.user);
    this.supportDeskForm.reset();
    this.modalService.open(Content, { size: 'xl', centered: true });
  }
  onFileChange(event)  {
    this.attachments = event.target.files;
    // for  (var i =  0; i <  event.target.files.length; i++)  { 
    //     this.files.push(event.target.files[i]);
    // }
    console.log(this.attachments);
  }
  submitSupportDeskData(data){
    console.log(data);
  }
}
