<div class="col-xl-12 box-margin">
    <div class="card md-0">
        <div class="main-content">
            <div class="container-fluid">
                <div class="row">
                    <div class="form-group col-md-2">
                        <!-- Side Nav -->
                        <div class="slimScrollDiv"
                            style="position: relative; overflow: hidden; width: auto; height: 100%;">
                            <div class="ecaps-sidenav" id="ecapsSideNav"
                                style="overflow: hidden; width: auto; height: 100%;">
                                <!-- Side Menu Area -->
                                <div class="side-menu-area">
                                    <!-- Sidebar Menu -->
                                    <nav>
                                        <ul class="sidebar-menu" data-widget="tree">
                                            <li routerLinkActive="active">
                                                <a href="javascript:void(0);" class="active" (click)="openDashboard()">
                                                    <i class='fa fa-home'></i>
                                                    <span>Dashboard</span>
                                                </a>
                                            </li>
                                            <li routerLinkActive="active">
                                                <a href="javascript:void(0);" (click)="openCallLogs()">
                                                    <i class='fa fa-phone'></i>
                                                    <span>Call Logs </span>
                                                </a>
                                            </li>
                                            <li routerLinkActive="active">
                                                <a href="javascript:void(0);" (click)="openCallSchedule()">
                                                    <i class='fa fa-calendar'></i>
                                                    <span>Schedule</span>
                                                </a>
                                            </li>

                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-10 box-margin" *ngIf="isCallLogs">
                        <div class="card">
                            <div
                                class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                                <h6 class="card-title mb-0"> Calls</h6>
                                <div class="btn-group">
                                    <button (click)="addCalls(callsFormModal)"
                                        class="action-btn bg-dark-green mr-2"><img
                                            src="assets/img/svg-icon/add-white.svg" width="20" /></button>
                                    <!-- <button class="action-btn bg-dark-blue" data-toggle="modal" data-target="#exampleModalRight"><img src="assets/img/svg-icon/filter-white.svg" width="20" /></button> -->
                                </div>

                            </div>
                            <div class="card-body">
                                <div class="tab-content list_table" id="ticketList">
                                    <div class="tab-pane active show fade" id="active-01" role="tabpanel"
                                        aria-labelledby="active-01-tab">
                                        <div class="table-responsive list_table">
                                            <table class="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th class="pt-2"> Date/Time </th>
                                                        <th class="pt-2"> Direction </th>
                                                        <th class="pt-2"> Phone (From) </th>
                                                        <th class="pt-2"> Phone (To) </th>
                                                        <th class="pt-2"> Duration (s) </th>
                                                        <th class="pt-2"> Status </th>
                                                        <th class="pt-2"> Employee </th>
                                                        <th class="pt-2"> Client </th>
                                                        <th class="pt-2">Action </th>
                                                        <th class="pt-2">SID </th>
                                                        <th class="pt-2">Image </th>
                                                        <th class="pt-2">Active </th>
                                                        <th class="pt-2">Updated At </th>
                                                        <th class="pt-2">Actions </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container *ngIf="calls.length > 0">
                                                        <tr
                                                            *ngFor="let call of calls | paginate: { id: 'calls_pagination', itemsPerPage: 25, currentPage: page, totalItems: totalRecords }; let i=index">
                                                            <td class="text-black"> {{call.created_at |
                                                                date:'MM/dd/yyyy, h:mm a'}}</td>
                                                            <td> {{call.direction }} </td>
                                                            <td><a href="tel:{{call.phone_from}}"> {{call.phone_from
                                                                    }}</a></td>
                                                            <td><a href="tel:{{call.phone_to}}"> {{call.phone_to }}</a>
                                                            </td>
                                                            <td> {{call.duration}}</td>
                                                            <td> {{call.status}}</td>
                                                            <td> <span
                                                                    *ngIf="call.employee">{{call.employee.name}}</span>
                                                            </td>
                                                            <td> <a *ngIf="call.client"
                                                                    [routerLink]="['/crm/customer-profile/'+call.client.id]">
                                                                    {{call.client.company_name}} <i
                                                                        class='fa fa-share'></i></a></td>
                                                            <td> {{call.action}}</td>
                                                            <td> {{call.sid}}</td>
                                                            <td> image</td>
                                                            <td> <span *ngIf="call.active==1"> Yes</span>
                                                                <span *ngIf="call.active!=1">NO </span>
                                                            </td>
                                                            <td> {{call.updated_at | date:'MM/dd/yyyy, h:mm:ss'}}</td>
                                                            <td class="text-right">
                                                                <a href="javascript:void(0)"
                                                                    (click)="openDeletePopUp(deleteCalls,call)"
                                                                    class="action-btn bg-red" title="Delete"><img
                                                                        src="assets/img/svg-icon/delete.svg"
                                                                        width="20" /></a>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                    <tr *ngIf="calls.length == 0">
                                                        <td class="text-gray text-center" colspan="5">No Calls Found
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <pagination-controls class="pagination-controls-theme" id="calls_pagination"
                                                (pageChange)="getCalls(page = $event)" maxSize="9" directionLinks="true"
                                                autoHide="true" responsive="true">
                                            </pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-10 box-margin" *ngIf="isCallSchedule">
                        <div class="card">
                            <form [formGroup]="callScheduleForm"
                                (ngSubmit)="submitCallScheduleData(callScheduleForm.value)">
                                <div class="card-body">
                                    <div class="tab-content list_table" id="ticketList">
                                        <div class="tab-pane active show fade" id="active-01" role="tabpanel"
                                            aria-labelledby="active-01-tab">

                                            <div class="row">
                                                <div class="form-group col-md-12">
                                                    <label>Force Call Schedule? </label>
                                                    <div class="new-checkbox" *ngIf="isNo">
                                                        <span> No </span>
                                                        <label class="switch md-trigger md-setperspective" data-modal="modal-19" (change)="timesheetsChange(1)">
                                                            <input type="checkbox" value="1" checked />
                                                            <span class="slider round"></span>
                                                        </label>
                                                        <span style="color:rgb(13, 182, 22);"> Yes </span>
                                                    </div>
                                                    <div class="new-checkbox" *ngIf="!isNo">
                                                        <span style="color:rgb(13, 182, 22);"> No </span>
                                                        <label class="switch md-trigger md-setperspective" data-modal="modal-19" (change)="timesheetsChange(0)">
                                                            <input type="checkbox" value="0" />
                                                            <span class="slider round"></span>
                                                        </label>
                                                        <span> Yes </span>
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-12">
                                                    <label> Choose Call Schedule </label>

                                                    <div class="new-checkbox">
                                                        <input type="radio" [checked]="true" value="0"
                                                            formControlName="enable_caller"> Daylight
                                                        <input type="radio" value="1" formControlName="enable_caller">
                                                        Evening
                                                    </div>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                                    <h6 class="card-title mb-0"> Call Routing (Daylight)</h6>
                                </div>
                                <div class="form-group col-md-12">
                                    <div class="card card-shadow-hide">
                                        <div class="card-body">
                                            <span>Use the form below to configure assignees for each of the following
                                                menu options.
                                            </span>
                                            <div class="form-row">
                                                <div class="form-group  col-md-12">
                                                    <label class="col-form-label text-light-black font-16"> If you are a
                                                        healthcare facility and need to cancel... </label>
                                                    <ng-select placeholder="Select" clearAllText="Clear"
                                                        formControlName="option_1">
                                                        <ng-option *ngFor="let client of allClientDetails;let i = index"
                                                            value="{{client.id}}"> {{client.name}}</ng-option>
                                                    </ng-select>
                                                </div>
                                                <div class="form-group  col-md-12">
                                                    <label class="col-form-label text-light-black font-16"> If you are
                                                        an employee and running late or need to call off...</label>
                                                    <ng-select placeholder="Select" clearAllText="Clear"
                                                        formControlName="option_2">
                                                        <ng-option *ngFor="let client of allClientDetails;let i = index"
                                                            value="{{client.id}}"> {{client.name}}</ng-option>
                                                    </ng-select>
                                                </div>
                                                <div class="form-group  col-md-12">
                                                    <label class="col-form-label text-light-black font-16"> For
                                                        assistance with benefits, payroll, viewing paystubs, password
                                                        access or any technology related issue...</label>
                                                    <ng-select placeholder="Select" clearAllText="Clear"
                                                        formControlName="option_3">
                                                        <ng-option *ngFor="let client of allClientDetails;let i = index"
                                                            value="{{client.id}}"> {{client.name}}</ng-option>
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-md-6">
                                                    <div class="tab-content list_table" id="ticketList">
                                                        <div class="responsive list_table">
                                                            <table class="table table-responsive table-nowrap mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <td style="width:40%;">To speak with a
                                                                            scheduler, press three.</td>
                                                                        <th class="pt-2"> </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="text-black"> For Dana, press ONE</td>
                                                                        <td class="text-gray">
                                                                            <ng-select placeholder="Select"
                                                                                clearAllText="Clear"
                                                                                formControlName="scheduler_1">
                                                                                <ng-option
                                                                                    *ngFor="let client of allClientDetails;let i = index"
                                                                                    value="{{client.id}}">
                                                                                    {{client.name}}</ng-option>
                                                                            </ng-select>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="text-black"> For Stephanie, press TWO
                                                                        </td>
                                                                        <td class="text-gray">
                                                                            <ng-select placeholder="Select"
                                                                                clearAllText="Clear"
                                                                                formControlName="scheduler_2">
                                                                                <ng-option
                                                                                    *ngFor="let client of allClientDetails;let i = index"
                                                                                    value="{{client.id}}">
                                                                                    {{client.name}}</ng-option>
                                                                            </ng-select>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="text-black"> For Tanya, press THREE
                                                                        </td>
                                                                        <td class="text-gray">
                                                                            <ng-select placeholder="Select"
                                                                                clearAllText="Clear"
                                                                                formControlName="scheduler_3">
                                                                                <ng-option
                                                                                    *ngFor="let client of allClientDetails;let i = index"
                                                                                    value="{{client.id}}">
                                                                                    {{client.name}}</ng-option>
                                                                            </ng-select>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="text-black"> For Terry, press FOUR
                                                                        </td>
                                                                        <td class="text-gray">
                                                                            <ng-select placeholder="Select"
                                                                                clearAllText="Clear"
                                                                                formControlName="scheduler_4">
                                                                                <ng-option
                                                                                    *ngFor="let client of allClientDetails;let i = index"
                                                                                    value="{{client.id}}">
                                                                                    {{client.name}}</ng-option>
                                                                            </ng-select>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div>
                                                                <p>To speak with a recruiter regarding employment or the
                                                                    hiring process, press four</p>
                                                            </div>

                                                        </div>

                                                        <div class="responsive list_table">
                                                            <table class="table table-responsive table-nowrap mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th style="width:40%;"> </th>
                                                                        <th class="pt-2"> </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="text-black"> For Dana, press ONE</td>
                                                                        <td class="text-gray">
                                                                            <ng-select placeholder="Select"
                                                                                clearAllText="Clear"
                                                                                formControlName="recruiter_1">
                                                                                <ng-option
                                                                                    *ngFor="let client of allClientDetails;let i = index"
                                                                                    value="{{client.id}}">
                                                                                    {{client.name}}</ng-option>
                                                                            </ng-select>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="text-black"> For Stephanie, press TWO
                                                                        </td>
                                                                        <td class="text-gray">
                                                                            <ng-select placeholder="Select"
                                                                                clearAllText="Clear"
                                                                                formControlName="recruiter_2">
                                                                                <ng-option
                                                                                    *ngFor="let client of allClientDetails;let i = index"
                                                                                    value="{{client.id}}">
                                                                                    {{client.name}}</ng-option>
                                                                            </ng-select>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="text-black"> For Tanya, press THREE
                                                                        </td>
                                                                        <td class="text-gray">
                                                                            <ng-select placeholder="Select"
                                                                                clearAllText="Clear"
                                                                                formControlName="recruiter_3">
                                                                                <ng-option
                                                                                    *ngFor="let client of allClientDetails;let i = index"
                                                                                    value="{{client.id}}">
                                                                                    {{client.name}}</ng-option>
                                                                            </ng-select>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group col-md-6">
                                                    <div class="tab-content list_table" id="ticketList">
                                                        <div class="table-responsive list_table">
                                                            <table class="table responsive table-nowrap mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th style="width: 15px;" class="pt-2"> Ext</th>
                                                                        <th class="pt-2"> Assigned User</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="text-black"> 811</td>
                                                                        <td class="text-gray">
                                                                            <ng-select placeholder="Select"
                                                                                clearAllText="Clear"
                                                                                formControlName="ext_811">
                                                                                <ng-option
                                                                                    *ngFor="let client of allClientDetails;let i = index"
                                                                                    value="{{client.id}}">
                                                                                    {{client.name}}</ng-option>
                                                                            </ng-select>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="text-black"> 822</td>
                                                                        <td class="text-gray">
                                                                            <ng-select placeholder="Select"
                                                                                clearAllText="Clear"
                                                                                formControlName="ext_822">
                                                                                <ng-option
                                                                                    *ngFor="let client of allClientDetails;let i = index"
                                                                                    value="{{client.id}}">
                                                                                    {{client.name}}</ng-option>
                                                                            </ng-select>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="text-black"> 833</td>
                                                                        <td class="text-gray">
                                                                            <ng-select placeholder="Select"
                                                                                clearAllText="Clear"
                                                                                formControlName="ext_833">
                                                                                <ng-option
                                                                                    *ngFor="let client of allClientDetails;let i = index"
                                                                                    value="{{client.id}}">
                                                                                    {{client.name}}</ng-option>
                                                                            </ng-select>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="text-black"> 844</td>
                                                                        <td class="text-gray">
                                                                            <ng-select placeholder="Select"
                                                                                clearAllText="Clear"
                                                                                formControlName="ext_844">
                                                                                <ng-option
                                                                                    *ngFor="let client of allClientDetails;let i = index"
                                                                                    value="{{client.id}}">
                                                                                    {{client.name}}</ng-option>
                                                                            </ng-select>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="text-black"> 855</td>
                                                                        <td class="text-gray">
                                                                            <ng-select placeholder="Select"
                                                                                clearAllText="Clear"
                                                                                formControlName="ext_855">
                                                                                <ng-option
                                                                                    *ngFor="let client of allClientDetails;let i = index"
                                                                                    value="{{client.id}}">
                                                                                    {{client.name}}</ng-option>
                                                                            </ng-select>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="text-black"> 866</td>
                                                                        <td class="text-gray">
                                                                            <ng-select placeholder="Select"
                                                                                clearAllText="Clear"
                                                                                formControlName="ext_866">
                                                                                <ng-option
                                                                                    *ngFor="let client of allClientDetails;let i = index"
                                                                                    value="{{client.id}}">
                                                                                    {{client.name}}</ng-option>
                                                                            </ng-select>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="text-black"> 877</td>
                                                                        <td class="text-gray">
                                                                            <ng-select placeholder="Select"
                                                                                clearAllText="Clear"
                                                                                formControlName="ext_877">
                                                                                <ng-option
                                                                                    *ngFor="let client of allClientDetails;let i = index"
                                                                                    value="{{client.id}}">
                                                                                    {{client.name}}</ng-option>
                                                                            </ng-select>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>


                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div
                                    class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                                    <h6 class="card-title mb-0"> Call Routing (Evening)</h6>
                                </div>
                                <div class="form-group col-md-12">
                                    <div class="card card-shadow-hide">
                                        <div class="card-body">
                                            <span>For evening calls, use the schedule below to adjust call recipients
                                                for menu options that reach a staff member.
                                            </span>
                                            <div class="tab-content list_table" id="ticketList">
                                                <div class="table-responsive list_table">
                                                    <table class="table responsive table-bordered table-nowrap mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th class="pt-2"> </th>
                                                                <th class="pt-2"> Monday</th>
                                                                <th class="pt-2"> Tuesday</th>
                                                                <th class="pt-2"> Wednesday</th>
                                                                <th class="pt-2"> Thursday</th>
                                                                <th class="pt-2"> Friday</th>
                                                                <th class="pt-2"> Saturday</th>
                                                                <th class="pt-2"> Sunday</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-black"> 06/20 to 06/26</td>
                                                                <td class="text-gray">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear"  formControlName="user1">
                                                                      
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-gray">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user2">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-black">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user3">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-black">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user4">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-right">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user5">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-black">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user6">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-right">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user7">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-black"> 06/27 to 07/03</td>
                                                                <td class="text-gray">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user8">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-gray">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user9">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-black">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user10">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-black">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user11">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-right">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user12">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-black">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user13">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-right">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user14">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-black"> 07/04 to 07/10</td>
                                                                <td class="text-gray">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user15">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-gray">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user16">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-black">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user17">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-black">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user18">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-right">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user19">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-black">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user20">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-right">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user21">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-black"> 07/11 to 07/17</td>
                                                                <td class="text-gray">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user22">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-gray">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user23">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-black">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user24">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-black">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user25">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-right">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear " formControlName="user26">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-black">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user27">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                                <td class="text-right">
                                                                    <ng-select placeholder="Select"
                                                                        clearAllText="Clear" formControlName="user28">
                                                                        <ng-option
                                                                            *ngFor="let client of allClientDetails;let i = index"
                                                                            value="{{client.id}}"> {{client.name}}
                                                                        </ng-option>
                                                                    </ng-select>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-12">
                                    <div class="card-body">
                                        <div class="btn-modal-group">
                                            <button type="submit" [disabled]="!callScheduleForm.valid" id="submit-btn"
                                                class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Add Calls  -->

<ng-template #callsFormModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <form [formGroup]="callsForm" (ngSubmit)="submitCallsData(callsForm.value)">
            <div class="form-row">

                <div class="form-group col-md-12">

                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Calls Details</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <!-- <div class="form-group col-md-4">
                                    <label  class="col-form-label text-light-black font-16">Name<span
                                            class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="name">
                                </div> -->
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div class="btn-modal-group">
                <button type="submit" [disabled]="!callsForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
            </div>
        </form>
    </div>

</ng-template>


<!-- Delete Calls -->
<ng-template #deleteCalls let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>
    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteCallsData()" class="btn btn-success  border-r-10">Yes, delete
                it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>
</ng-template>

<!-- Right Alight Modal -->
<div class="modal fade drawer right-align" id="exampleModalRight" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Right Align Modal title</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</div>