<form [formGroup]="weekMonthlyAvailabilityForm"
        (ngSubmit)="submitWeekMonthlyAvailabilityForm(weekMonthlyAvailabilityForm.value)">
        <div class="form-row">
                <div class="form-group col-md-12">
                        <div class="">
                                <div class="user-area d-flex align-items-center justify-content-between">
                                        <h6 class="card-title mb-0"> Week #{{isCount}} {{updateDate(1) | date:'MM/dd'}}
                                                - {{updateDate(7) |
                                                date:'MM/dd'}} <br /></h6>

                                </div>
                                <div>
                                        <div class="form-row">
                                                <div class="form-group col-md-12">
                                                        <label for="inputState"
                                                                class="col-form-label text-light-black font-16">
                                                                Number of hours requested for this week?</label>
                                                        <ng-select formControlName="week_number_of_hours"
                                                                placeholder="Select" clearAllText="Clear"
                                                                class="form-control-ng rounded-0">
                                                                <ng-option *ngFor="let hours of number_of_hours"
                                                                        [value]="hours"> {{hours}} </ng-option>

                                                        </ng-select>
                                                </div>
                                        </div>
                                        <div class="">
                                                <div class="tab-content list_table">
                                                        <div class="tab-pane active show fade" id="active-01"
                                                                role="tabpanel" aria-labelledby="active-01-tab">
                                                                <div class="table-responsive list_table">
                                                                        <table class="table  table-nowrap mb-0"
                                                                                style=" width: 100%;">
                                                                                <thead>
                                                                                        <tr>
                                                                                                <th class="pt-2 "> </th>
                                                                                                <th
                                                                                                        class="pt-2 bg-green">
                                                                                                        Morning (8)
                                                                                                </th>
                                                                                                <th
                                                                                                        class="pt-2 bg-green">
                                                                                                        Afternoon (8)
                                                                                                </th>
                                                                                                <th
                                                                                                        class="pt-2 bg-green">
                                                                                                        Night (8)
                                                                                                </th>
                                                                                                <th
                                                                                                        class="pt-2 bg-green">
                                                                                                        Double (16)
                                                                                                </th>
                                                                                                <th
                                                                                                        class="pt-2 bg-green">
                                                                                                        Not Available
                                                                                                </th>
                                                                                        </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                        <tr
                                                                                                *ngFor="let week of newData.days;let i=index">
                                                                                                <th
                                                                                                        class="bg-green pl-3">
                                                                                                        {{week.dayName}}
                                                                                                        {{week.displayDate}}

                                                                                                </th>
                                                                                                <td class="text-center">
                                                                                                        <input (change)="onChange(i, $event.target.checked,0)"
                                                                                                                type="checkbox" />

                                                                                                </td>
                                                                                                <td class="text-center">
                                                                                                        <input (change)="onChange(i, $event.target.checked,1)"
                                                                                                                type="checkbox" />
                                                                                                </td>
                                                                                                <td class="text-center">
                                                                                                        <input (change)="onChange(i, $event.target.checked,2)"
                                                                                                                type="checkbox" />
                                                                                                </td>
                                                                                                <td class="text-center">
                                                                                                        <input [attr.disabled]="checkUncheckCheckArr.days[i].values[3].isdisabled ? true : null"
                                                                                                                [checked]="checkUncheckCheckArr.days[i].values[3].isdisabled"
                                                                                                                (change)="onChange(i, $event.target.checked,3)"
                                                                                                                type="checkbox" />
                                                                                                        {{checkUncheckCheckArr.days[i].values[3].isdisabled}}
                                                                                                </td>
                                                                                                <td class="text-center">
                                                                                                        <input [attr.disabled]="checkUncheckCheckArr.days[i].values[4].isdisabled ? true : null"
                                                                                                                [checked]="checkUncheckCheckArr.days[i].values[4].isdisabled"
                                                                                                                (change)="onChange(i, $event.target.checked,4)"
                                                                                                                type="checkbox" />
                                                                                                        {{checkUncheckCheckArr.days[i].values[4].isdisabled}}
                                                                                                </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                                <th
                                                                                                        class="bg-green pl-3">
                                                                                                        Comment(s)
                                                                                                </th>
                                                                                                <td class="text-center"
                                                                                                        colspan="6">
                                                                                                        <textarea
                                                                                                                formControlName="comments"
                                                                                                                class="form-control rounded-0 form-control-md"
                                                                                                                id="exampleTextarea"
                                                                                                                rows="4">
                                </textarea>
                                                                                                </td>
                                                                                        </tr>
                                                                                </tbody>
                                                                        </table>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>

                                </div>
                        </div>
                </div>

        </div>
        <div class="btn-modal-group text-center">
                <label for="">{{isCount}}</label>
        </div>
        <div class="btn-modal-group text-left">
                <a href="javascript:void(0)" *ngIf="isCount>1" class="btn btn-success border-r-10 mr-3 md-close"
                        (click)="monthlyAvailabilityPrevious()"> PREVIOUS </a>

                <button *ngIf="isCount<6" type="submit" [disabled]="!weekMonthlyAvailabilityForm.valid" id="submit-btn"
                        class="btn btn-success border-r-10 mr-3 md-close">NEXT</button>
                <button *ngIf="isCount>5" type="submit" [disabled]="!weekMonthlyAvailabilityForm.valid" id="submit-btn"
                        class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>

        </div>
</form>