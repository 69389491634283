import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../helper/helper.service';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  @Input() details:any;
  triggerForm:FormGroup;
  isForm:boolean=false;
  icons:any=[];
  models:any=[];
  response:any=[];
  columns:any=[];
  constructor(
    private formBuilder:FormBuilder,
    private helperIcon:HelperService,
    private api:ApiService,
    private toastr:ToastrService,
    private modalService:NgbModal,
  ) { 
    this.icons=this.helperIcon.getIocn();
    this.getModels();
  }

  ngOnInit(): void {
    if(this.details.id){
      this.getTableColumn(this.details.model_name);
      this.getData(this.details.trigger_type);
      setTimeout(()=>{
        this.triggerForm.patchValue(this.details);
      },100);
    }
    this.init();
  }

  init(){
    this.triggerForm = this.formBuilder.group({
      id:[''],
      name:[''],
      trigger_type:[''],
      model_name:[''],
      related_field:[''],
      button_label:[''],
      button_icon:[''],
      button_location:[''],
      email_subject:[''],
      email_body:[''],
    });
  }

  getModels(){
    this.api.get('admin/libraries/all_models').subscribe((result)=>{
      this.response = result;
      this.models = this.response.data;
    },(err)=>{      
    });
    return true;
  }

  getTableColumn(event){

    let index = this.models.findIndex((m)=> m.tableName === event);
    var field =''; 
    if(index !== -1){
      field = this.models[index].table_name;
    }
    let data = {'table_name':field};
    this.api.post('admin/libraries/get_table_column',data).subscribe((result)=>{
      this.response = result;
      this.columns = this.response.data;
    },(err)=>{

    });
  }

  getData(event){
    if(event=='Email (Button)'){
      this.isForm=true;
    }else{
      this.isForm=false;
    } 
  }

  submitTriggerForm(data){
    let endPoint = (data.id)?'update':'create';
    this.api.post('admin/libraries/'+endPoint,data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

}
