import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-flag-management-statuses',
  templateUrl: './flag-management-statuses.component.html',
  styleUrls: ['./flag-management-statuses.component.css']
})
export class FlagManagementStatusesComponent implements OnInit {

  statusDetails:any=[];
  statusModelRef:any;
  page:any=1;
  response:any=[];
  statuses:any=[];
  totalRecords:any=0;
  deleteId:any;
  modelName:any;

  constructor(
    private api:ApiService,
    private modalService:NgbModal,
    private toastr:ToastrService
  ) { }

  ngOnInit(): void {
    this.getStatus(this.page);
  }

  getStatus(page){
    this.api.get('admin/core_statuses/lists').subscribe((result)=>{
      this.response = result;
      this.statuses = this.response.data.data;
      this.totalRecords = this.response.total;
    },(err)=>{
    });
  }

  openAddModal(content){
    this.modalService.open(content,{ size:'xl',centered:true });
  }

  editStatus(content,index){
    this.statusDetails = this.statuses[index];
    this.statusModelRef=[];
    let statusModel=this.modalService.open(content,{ size:'xl',centered:true });
    this.statusModelRef.push(statusModel);
    this.closeModal(statusModel);
  }

  closeModal(modalRef){
    modalRef.result.then((data) => {
    }, (reason) => {
      if(reason!=0 && reason!='Cross  click'){
        this.getStatus(this.page);
      }
      this.statusDetails=[];
    });
  }

  deleteModal(content,current_row){
    this.deleteId = current_row.id;
    this.modalService.open(content, { size:'sm',centered:true });
  }

  deleteStatus(){
    let data = {id:this.deleteId};
    this.api.post('admin/core_statuses/delete',data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.getStatus(this.page);
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }
}
