import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { HelperService } from '../helper/helper.service';

@Component({
  selector: 'app-modal-call-logs',
  templateUrl: './modal-call-logs.component.html',
  styleUrls: ['./modal-call-logs.component.css']
})
export class ModalCallLogsComponent implements OnInit {
  @Input() modelName: any;
  callLogsForm: FormGroup;
  ContactForm: FormGroup;
  response: any;
  totalRecords: any;
  allCollLogs: any = [];
  page: any = 1;
  receivedDate: any;
  isSelectedStatus: boolean = false;
  isCustomerProfile: boolean = false;
  isSelectedFlag: boolean = false;
  isFollowUps: boolean = false;
  call_logstid: any;
  editCollLogs: any;
  editStatus: any;
  statuses: any;
  allContacts: any = [];
  times: any = [];
  flags: any = [];
  editFlag: any = [];
  flagModelRef: any = [];
  flagDetails: any = [];
  allTypes: any = [];
  statusDetails: any = [];
  statusModelRef: any = [];
  customerid: any = '';

  constructor(private route: ActivatedRoute,
    private api: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private helper: HelperService,
    private router: Router,
  ) {
    this.customerid = this.route.snapshot.params['id'];
    this.callLogsForm = this.formBuilder.group({
      received_date: ['', [Validators.required]],
      time: ['', [Validators.required]],
      contact_id: [''],
      notes: ['', [Validators.required]],
      id: [''],
    });
    this.ContactForm = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      contact_id: [''],
      notes: [''],
      title: [''],
      phone: [''],
      phone_mobile: [''],
      phone_home: [''],
      flag_id: [''],
      status_id: [''],
      type_id: [''],
    });
  }

  ngOnInit(): void {
    this.init();
    this.times = this.helper.getTime();
    this.getCallLogs(this.page);

    this.isCustomerProfile = (this.modelName == "customer-profile") ? true : false;
    this.isFollowUps = (this.modelName == "follow-ups") ? true : false;

    console.log("modelName==>", this.modelName);
  }
  init(): void {
  }
  async getCallLogs(page) {
    let customerId = (this.modelName == "customer-profile")?"&customer_id="+this.customerid:'';
    this.api.get('admin/call_logs/lists?page=' + page + "&modelName="+this.modelName+customerId).subscribe((result) => {
      this.response = result;
      this.totalRecords = result.data.total;
      this.allCollLogs = result.data.data;
    }, (err) => {
    });
    return true;
  }
  async getContacts() {
    this.api.get('admin/follow_ups/get_contacts').subscribe((result) => {
      this.response = result;
      this.allContacts = this.response.data;
    }, (err) => {

    });
  }
  openContactModal(content) {
    this.ContactForm.reset();
    this.modelName = "contacts";
    this.getFlag();
    this.getStatus();
    this.getType();
    this.modalService.open(content, { size: 'xl', centered: true });
  }
  editCollLogsModal(content, index) {
    this.getContacts();
    this.callLogsForm.reset();
    this.editCollLogs = this.allCollLogs[index];
    this.receivedDate = this.editCollLogs.received_date.slice(0, 10)
    this.callLogsForm.patchValue(this.editCollLogs);
    this.modalService.open(content, { size: 'xl', centered: true, scrollable: true });
    console.log(this.receivedDate);
  }
  deleteModal(content, current_row) {
    this.call_logstid = current_row.id;
    this.modalService.open(content, { size: 'sm', centered: true });
  }
  selectStatus(event) {
    (event != null) ? this.isSelectedStatus = true : this.isSelectedStatus = false;
    this.editStatus = this.statuses.filter(function (arr) { return arr.id == event })[0];// this.statuses[event];
  }
  addCallLogsReg(content) {
    this.callLogsForm.reset();
    this.receivedDate = '';
    this.getContacts();
    this.modalService.open(content, { size: 'xl', centered: true });
  }
  submitCallLogsData(data) {
    let endPoint = (data.id) ? 'update' : 'create';
    data.customer_id = this.customerid;
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/call_logs/' + endPoint, data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getCallLogs(this.page);
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.modalService.dismissAll();

    }, (err) => {
      this.toastr.error(err.error.message, "Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });

  }
  selectFlag(event) {
    (event != null) ? this.isSelectedFlag = true : this.isSelectedFlag = false;
    this.editFlag = this.flags.filter(function (arr) { return arr.id == event })[0];
  }
  openStatusModal(content) {
    this.statusModelRef = [];
    this.modelName = 'follow-ups';
    const modalRef = this.modalService.open(content, { size: 'xl', centered: true });
    this.statusDetails = this.editStatus;
    this.statusModelRef.push(modalRef);
    this.closeModal(modalRef);
  }
  deleteCallLogs() {
    let data = { id: this.call_logstid }
    this.api.post('admin/call_logs/delete', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getCallLogs(this.page);
      this.modalService.dismissAll();
    }, (err) => {
      console.log(err);
      this.toastr.error(err.error.message, 'Error');
      this.modalService.dismissAll();
    });
  }

  submitContactData(data) {
    let endPoint = (data.id) ? 'update' : 'create';
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/contacts/' + endPoint, data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getContacts();
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      //this.modalService.dismissAll();
      //this.closeModal(modalRef);
    }, (err) => {
      this.toastr.error(err.error.message, "Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });
  }
  flagManagementType(link) {
    this.modalService.dismissAll();
    this.router.navigateByUrl(link);
  }
  openFlagModal(content) {
    this.flagModelRef = [];
    //this.modelName='customers';
    const modalRef = this.modalService.open(content, { size: 'xl', centered: true });
    this.flagDetails = this.editFlag;
    this.flagModelRef.push(modalRef);
    this.closeModal(modalRef);
    //console.log("open flag model =====>",this.flagDetails);
  }
  closeModal(modalRef) {
    modalRef.result.then((data) => {
    }, (reason) => {
      if (reason != 0 && reason != 'Cross  click') {
        this.getFlag();
        this.getStatus();
      }

    });
  }
  getFlag() {
    let data = { model_name: this.modelName };
    this.api.post('admin/core_flags/all_flags', data).subscribe((result) => {
      this.response = result;
      this.flags = this.response.data;
    }, (err) => {
    });
    return true;
  }
  getStatus() {
    let data = { model_name: this.modelName };
    this.api.post('admin/core_statuses/all_statuses', data).subscribe((result) => {
      this.response = result;
      this.statuses = this.response.data;
    }, (err) => {
    });
    return true;
  }
  getType() {
    let data = { model_name: this.modelName };
    this.api.post('admin/core_types/lists', data).subscribe((result) => {
      this.response = result;
      this.allTypes = this.response.data;
    }, (err) => {
    });
    return true;
  }
}
