<form [formGroup]="statusForm" (ngSubmit)="submitStatusForm(statusForm.value)">
    <input type="hidden" formControlName="id">
    <div class="row">
        <div class="col-sm-12" [ngClass]="(modelName!='' && modelName!=undefined)?'hidden':''">
            <label for="">Model</label>
            <ng-select formControlName="model_name" [(ngModel)]="modelNameTemp">
                <ng-option *ngFor="let model of modelNames;" value="{{model.value}}">{{ model.value }}</ng-option>
            </ng-select>
        </div>

        <div class="col-sm-6">
            <label for="">Name</label>
            <input type="text" formControlName="name" class="form-control">
        </div>

        <div class="col-sm-3">
            <div class="checkbox d-inline">
                <label for="is_default" class="col-form-label text-light-black font-16">Use as the default status?</label>
                <div class="new-checkbox toggle-check-box-t mt-15">
                    <label>No</label>
                    <label class="switch ml-15 mr-15">
                        <input type="checkbox" formControlName="is_default" id="is_default" value="1">
                        <span class="slider round"></span>
                    </label>
                    <label>Yes</label>
                </div>
            </div>
        </div>

        <div class="col-sm-3">
            <div class="checkbox d-inline">
                <label for="should_deactivate" class="col-form-label text-light-black font-16">Should deactivate the record?</label>
                <div class="new-checkbox toggle-check-box-t mt-15">
                    <label>No</label>
                    <label class="switch ml-15 mr-15">
                        <input type="checkbox" formControlName="should_deactivate" id="should_deactivate">
                        <span class="slider round"></span>
                    </label>
                    <label>Yes</label>
                </div>
            </div>
        </div>
        
        <div class="col-sm-12">
            <label for="">Status Message</label>
            <textarea formControlName="message" id="" cols="30" rows="6" class="form-control"></textarea>
        </div>

        <div class="col-sm-12">
            <button type="submit" class="action-btn bg-dark-blue mt-2">Submit</button>
        </div>

    </div>
</form>