import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule  } from "@ng-select/ng-select";
import {NgxPaginationModule} from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './services/auth.guard';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './layout/layout.component';
import { EmployeeComponent } from './employee/employee.component';
import { RolesComponent } from './roles/roles.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SpecialtiesComponent } from './specialties/specialties.component';
import { UserComponent } from './user/user.component';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule, SPINNER } from 'ngx-ui-loader';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { ToastComponent } from './toast/toast.component';
import { CrmLayoutComponent } from './crm-layout/crm-layout.component';
import { AdministrationLayoutComponent } from './administration-layout/administration-layout.component';
import { HeadernavbarComponent } from './headernavbar/headernavbar.component';
import { RecruitingComponent } from './recruiting/recruiting.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { FormsComponent } from './forms/forms.component';
import { TrainingLayoutComponent } from './training-layout/training-layout.component';
import { TrainingDashboardComponent } from './training-dashboard/training-dashboard.component';
import { CrmDashboardComponent } from './crm-dashboard/crm-dashboard.component';
import { AdministrationDashboardComponent } from './administration-dashboard/administration-dashboard.component';
import { TrainingCoursesComponent } from './training-courses/training-courses.component';
import { CrmClientComponent } from './crm-client/crm-client.component';
import { CrmMarketingComponent } from './crm-marketing/crm-marketing.component';
import { CrmCallScriptComponent } from './crm-call-script/crm-call-script.component';
import { CrmFollowUpCommentComponent } from './crm-follow-up-comment/crm-follow-up-comment.component';
import { AdministrationRatesComponent } from './administration-rates/administration-rates.component';
import { AdministrationMessageTemplatesComponent } from './administration-message-templates/administration-message-templates.component';
import { AdministrationCustomFilesComponent } from './administration-custom-files/administration-custom-files.component';
import { CrmCustomersProfileComponent } from './crm-customers-profile/crm-customers-profile.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { EmployeeProfileComponent } from './employee-profile/employee-profile.component';
import { TrainingTopicComponent } from './training-topic/training-topic.component';
import { TrainingCertificationComponent } from './training-certification/training-certification.component';
import { EmployeeBadgeComponent } from './employee-badge/employee-badge.component';
import { AdministrationApplicationSettingsComponent } from './administration-application-settings/administration-application-settings.component';
import { AdministrationCompanySettingComponent } from './administration-company-setting/administration-company-setting.component';
import { AdministrationThemeSettingsComponent } from './administration-theme-settings/administration-theme-settings.component';
import { TrainingCourseRegistrationsComponent } from './training-course-registrations/training-course-registrations.component';
import { TrainingCourseBuilderComponent } from './training-course-builder/training-course-builder.component';
import { TrainingCourseShowComponent } from './training-course-show/training-course-show.component';
import { TrainingTestsBuilderComponent } from './training-tests-builder/training-tests-builder.component';
import { TrainingTestsComponent } from './training-tests/training-tests.component';
import { TrainingSectionViewComponent } from './training-section-view/training-section-view.component';
import { TrainingMyCoursesComponent } from './training-my-courses/training-my-courses.component';
import { AdministrationLibrariesComponent } from './administration-libraries/administration-libraries.component';
import { AdministrationTriggersComponent } from './administration-triggers/administration-triggers.component';
import { ModalComponent } from './modal/modal.component';
import { AdministrationBulkAvailabilityComponent } from './administration-bulk-availability/administration-bulk-availability.component';
import { CrmOpportunitiesComponent } from './crm-opportunities/crm-opportunities.component';
import { ModalFlagComponent } from './modal-flag/modal-flag.component';
import { ModalStatusComponent } from './modal-status/modal-status.component';
import { FlagManagementDashboardComponent } from './flag-management-dashboard/flag-management-dashboard.component';
import { FlagManagementLayoutComponent } from './flag-management-layout/flag-management-layout.component';
import { FlagManagementTypesComponent } from './flag-management-types/flag-management-types.component';
import { FlagManagementFlagsComponent } from './flag-management-flags/flag-management-flags.component';
import { FlagManagementStatusesComponent } from './flag-management-statuses/flag-management-statuses.component';
import { CrmFollowUpsComponent } from './crm-follow-ups/crm-follow-ups.component';
import { ModalCrmFollowUpsComponent } from './modal-crm-follow-ups/modal-crm-follow-ups.component';
import { CrmViewFollowUpsComponent } from './crm-view-follow-ups/crm-view-follow-ups.component';
import { CrmCallLogsShowComponent } from './crm-call-logs-show/crm-call-logs-show.component';
import { AdministrationViewReportsComponent } from './administration-view-reports/administration-view-reports.component';
import { AdministrationManageCallsDashboardComponent } from './administration-manage-calls-dashboard/administration-manage-calls-dashboard.component';
import { AdministrationCallsComponent } from './administration-calls/administration-calls.component';
import { AdministrationCallScheduleComponent } from './administration-call-schedule/administration-call-schedule.component';
import { AdministrationSupportDeskComponent } from './administration-support-desk/administration-support-desk.component';
import { CrmOpportunitiesShowComponent } from './crm-opportunities-show/crm-opportunities-show.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ModalMonthlyAvailabilityComponent } from './modal-monthly-availability/modal-monthly-availability.component';
import { ModalUploadFilesComponent } from './modal-upload-files/modal-upload-files.component';
import { ModalCallLogsComponent } from './modal-call-logs/modal-call-logs.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    LayoutComponent,
    EmployeeComponent,
    RolesComponent,
    SpecialtiesComponent,
    UserComponent,
    ToastComponent,
    CrmLayoutComponent,
    AdministrationLayoutComponent,
    HeadernavbarComponent,
    RecruitingComponent,
    ScheduleComponent,
    FormsComponent,
    TrainingLayoutComponent,
    TrainingDashboardComponent,
    CrmDashboardComponent,
    AdministrationDashboardComponent,
    TrainingCoursesComponent,
    CrmClientComponent,
    CrmMarketingComponent,
    CrmCallScriptComponent,
    CrmFollowUpCommentComponent,
    AdministrationRatesComponent,
    AdministrationMessageTemplatesComponent,
    AdministrationCustomFilesComponent,
    CrmCustomersProfileComponent,
    DateAgoPipe,
    EmployeeProfileComponent,
    TrainingTopicComponent,
    TrainingCertificationComponent,
    EmployeeBadgeComponent,
    AdministrationApplicationSettingsComponent,
    AdministrationCompanySettingComponent,
    AdministrationThemeSettingsComponent,
    TrainingCourseRegistrationsComponent,
    TrainingCourseBuilderComponent,
    TrainingCourseShowComponent,
    TrainingTestsBuilderComponent,
    TrainingTestsComponent,
    TrainingSectionViewComponent,
    TrainingMyCoursesComponent,
    AdministrationLibrariesComponent,
    AdministrationTriggersComponent,
    ModalComponent,
    AdministrationBulkAvailabilityComponent,
    CrmOpportunitiesComponent,
    ModalFlagComponent,
    ModalStatusComponent,
    FlagManagementDashboardComponent,
    FlagManagementLayoutComponent,
    FlagManagementTypesComponent,
    FlagManagementFlagsComponent,
    FlagManagementStatusesComponent,
    CrmFollowUpsComponent,
    ModalCrmFollowUpsComponent,
    CrmViewFollowUpsComponent,
    CrmCallLogsShowComponent,
    AdministrationViewReportsComponent,
    AdministrationManageCallsDashboardComponent,
    AdministrationCallsComponent,
    AdministrationCallScheduleComponent,
    AdministrationSupportDeskComponent,
    CrmOpportunitiesShowComponent,
    ModalMonthlyAvailabilityComponent,
    ModalUploadFilesComponent,
    ModalCallLogsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    NgxUiLoaderModule.forRoot({ fgsType: SPINNER.rectangleBounce, fgsSize: 40 }),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    NgxPaginationModule,
    EditorModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  providers: [
    AuthGuard,
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
