<div class="main-content">
  <div class="container-fluid">

      <div class="row">
        <div class="col-md-4">
          <div class="row">
              <div class="col-xl-12 height-card box-margin">
                  <div class="card mb-30">
                      <div class="card-header bg-green">
                          <h6 class="card-title mb-0">Choose a Form</h6>
                      </div>
                      <div class="card-body">

                              <div class="form-row">

                                  <div class="form-group col-md-12">

                                    <ng-select placeholder="Select" (change)="changeForm($event)" clearAllText="Clear" class="form-control-ng rounded-0">
                                        <ng-option *ngFor="let document of documents  let i=index" [value]="i">
                                            {{document.name}}
                                        </ng-option>
                                    </ng-select>
                                  </div>

                                  <div class="form-group text-center col-md-12">
                                      <!-- <div class="btn-group"> -->
                                        <button class="action-btn-2 bg-orange font-16 mr-2 mt-2" (click)="addForm(addFormModal)">Add new Form</button>
                                        <button class="action-btn-2 bg-dark-blue font-16 mr-2 mt-2" (click)="deleteForm()" *ngIf="formButton">Delete Form</button>
                                      <!-- </div> -->
                                  </div>

                              </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-12 height-card box-margin">
                  <div class="card mb-30">
                      <div class="card-header bg-green">
                          <h6 class="card-title mb-0">Choose a Link</h6>
                      </div>
                      <div class="card-body">

                            <div class="form-row">
                                <div class="form-group col-md-12">
                                  <ng-select placeholder="Select" id="link" clearAllText="Clear" (change)="changeLink($event)" class="form-control-ng rounded-0">
                                      <ng-option *ngFor="let link of links  let i=index" [value]="i">
                                          {{link.name}}
                                      </ng-option>
                                  </ng-select>
                                </div>

                                <div class="form-group text-center col-md-12">
                                    <button class="action-btn-2 mr-2 bg-orange font-16 mt-2" (click)="addLink(addLinkModal)">Add new Link</button>
                                    <button (click)="openUrl()" class="action-btn-2 mr-2 bg-orange font-16 mt-2" *ngIf="linkButton">Visit Link</button>
                                    <button class="action-btn-2 bg-dark-blue font-16 mt-2" (click)="deleteLink()"  *ngIf="linkButton">Delete Link</button>

                                </div>

                            </div>

                      </div>
                  </div>
              </div>
          </div>
        </div>
          <div class="col-md-8 box-margin">

             <div class="card" *ngIf="!formButton">
                <div class="card-body">
                  <p>Your document preview will be displayed here.</p>
                </div>
             </div>

              <div class="card" *ngIf="formButton">

                  <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                      <h6 class="card-title mb-0"><!-- Activations Report --></h6>
                      <div class="btn-group">
                          <button class="action-btn bg-dark-blue font-16 mr-2" (click)="Print()">Print Form</button>
                          <button class="action-btn bg-orange mr-2" (click)="mailModal(openEmailModal)">Email Form</button>
                      </div>
                  </div>
                  <div class="card-body">
                      <div id="printSection">
                        <iframe  [src]="getPath(currentform)" width="100%" height="900px;" frameborder="0" *ngIf="currentform"></iframe>
                      </div>
                  </div>
              </div>
          </div>

      </div>

        <!-- Modal popup end -->

  </div>
</div>

<ng-template #addLinkModal let-modal>
  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
      <h6 class="card-title mb-0">Link Details</h6>
      <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross click')"
          aria-label="Close">
          <span aria-hidden="true">×</span>
      </button>
  </div>
  <div class="modal-body md-b-body">
      <form [formGroup]="linkForm" (ngSubmit)="submitLink(linkForm.value)">
          <div class="form-row">
              <div class="form-group col-md-4">
                  <label class="col-form-label text-light-black font-16">Name <span class="text-danger">*</span> </label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="name" placeholder="The name of the link">
                    <span class="text-danger" *ngIf="linkForm.get('name').errors?.required || linkForm.get('name').errors?.minlength && linkForm.get('name').errors"> {{formErrors.name}}</span>
              </div>

              <div class="form-group col-md-4">
                <label class="col-form-label text-light-black font-16">URL <span class="text-danger">*</span> </label>
                <input class="form-control rounded-0 form-control-md" type="text" formControlName="url" placeholder="ex.https://google.com">
                  <span class="text-danger" *ngIf="linkForm.get('url').errors?.required || linkForm.get('url').errors?.pattern || url_error"> {{formErrors.url}}</span>
              </div>

          </div>

          <div class="form-row">
            <div class="form-group col-md-12">
               <button type="submit" [disabled]="!linkForm.valid" class="action-btn bg-dark-green mr-2">Submit</button>
            </div>
          </div>
      </form>
  </div>
</ng-template>



<ng-template #addFormModal let-modal>
  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
      <h6 class="card-title mb-0">Create New Form</h6>
      <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross click')"
          aria-label="Close">
          <span aria-hidden="true">×</span>
      </button>
  </div>
  <div class="modal-body md-b-body">
      <form [formGroup]="documentForm" (ngSubmit)="submitForm(documentForm.value)">
          <div class="form-row">
              <div class="form-group col-md-12">
                  <label class="col-form-label text-light-black font-16">Title <span class="text-danger">*</span> </label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="title" placeholder="Title">
                    <span class="text-danger" *ngIf="documentForm.get('title').errors?.required || documentForm.get('title').errors?.minlength && documentForm.get('title').errors"> {{formErrorsDoc.title}}</span>
              </div>

              <div class="form-group col-md-12">
                <input class="form-control rounded-0 form-control-md" accept="image/*,application/pdf" (change)="convertImageInBase64($event)" type="file" formControlName="file" >

              </div>

          </div>

          <div class="form-row">
            <div class="form-group col-md-12">
               <button type="submit" [disabled]="!documentForm.valid" class="action-btn bg-dark-green mr-2">Submit</button>
            </div>
          </div>
      </form>
  </div>
</ng-template>

<ng-template #openEmailModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0">Email Form</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross click')"
            aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        
        <form [formGroup]="emailForm" (ngSubmit)="sendEmail(emailForm.value)">
            <div class="row">

                <div class="col-md-6">
                    <label>Status</label>
                    <div class="form-group  d-flex mb-20">
                        <div class="form-check pl-0 mb-2">
                            <label class="form-check-label button-radio-btn">
                                <input type="radio" class="form-check-input radio-btn-button" (click)="getType($event)" formControlName="type" value="active">
                                <div class="layer"></div>
                                <div class="button"><span>Active</span></div>
                            </label>
                        </div>
                        <div class="form-check pl-0 mb-2">
                            <label class="form-check-label button-radio-btn">
                                <input type="radio" class="form-check-input radio-btn-button" (click)="getType($event)" formControlName="type" value="inactive">
                                <div class="layer"></div>
                                <div class="button"><span>Inactive</span></div>
                            </label>
                        </div>
                        <div class="form-check pl-0 mb-2">
                            <label class="form-check-label button-radio-btn">
                                <input type="radio" class="form-check-input radio-btn-button" (click)="getType($event)" formControlName="type" value="in-process">
                                <div class="layer"></div>
                                <div class="button"><span>In Process</span></div>
                            </label>
                        </div>
                        <div class="form-check pl-0 mb-2">
                            <label class="form-check-label button-radio-btn">
                                <input type="radio" class="form-check-input radio-btn-button" (click)="getType($event)" formControlName="type" value="all" active>
                                <div class="layer"></div>
                                <div class="button"><span>All</span></div>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-6" *ngIf="isType">
                    <label>Custom Recipient</label>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <input type="text" class="form-control" formControlName="custom_recipient" placeholder="Please enter an email address">
                        </div>
                    </div>
                </div>

                <div class="col-md-6" *ngIf="!isType">
                    <label>Recipient Type</label>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <select class="form-control" formControlName="recipient_type" (change)="getRecipient($event)">
                                <option value="">Select</option>
                                <option value="clients">Client</option>
                                <option value="employees">Employee</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-md-6" *ngIf="isSpeciality">
                    <label>Speciality</label>
                    <div class="form-group mb-20">
                        <ng-select formControlName="specialty" (change)="changeSpeciality($event)" placeholder="Select"clearAllText="Clear">
                            <ng-option *ngFor="let specialty of specialties let i=index" [value]="i">{{specialty.name}}</ng-option>
                        </ng-select>
                    </div>

                </div>

                

                <div class="col-md-6" *ngIf="isSelection">
                    <label>Selection (Leave blank to select all...)</label>
                    <div class="form-group mb-20">
                        <ng-select [multiple]="true" formControlName="recipients" placeholder="Select"clearAllText="Clear">
                            <ng-option *ngFor="let recipient of recipients" [value]="recipient.id"> <span *ngIf="recipient.company_name"> {{recipient.company_name}} </span> <span *ngIf="recipient.name">{{recipient.name +' - ' + recipient.specialty.name}} </span></ng-option>
                        </ng-select>
                    </div>

                </div>


                <div class="col-md-6" *ngIf="isClient">
                    <label>Contact Type</label>
                    <div class="form-group  d-flex mb-20">
                        <div class="form-check pl-0 mb-2">
                            <label class="form-check-label button-radio-btn">
                                <input type="radio" class="form-check-input radio-btn-button" formControlName="contact_type" value="administrator">
                                <div class="layer"></div>
                                <div class="button"><span>Administrator</span></div>
                            </label>
                        </div>
                        <div class="form-check pl-0 mb-2">
                            <label class="form-check-label button-radio-btn">
                                <input type="radio" class="form-check-input radio-btn-button" formControlName="contact_type" value="scheduler">
                                <div class="layer"></div>
                                <div class="button"><span>Scheduler</span></div>
                            </label>
                        </div>
                        <div class="form-check pl-0 mb-2">
                            <label class="form-check-label button-radio-btn">
                                <input type="radio" class="form-check-input radio-btn-button" formControlName="contact_type" value="all" active>
                                <div class="layer"></div>
                                <div class="button"><span>All</span></div>
                            </label>
                        </div>
                    </div>
                </div>

            </div>


            

            <div class="row align-items-center">
                <div class="col-md-10 col-sm-10 col-xl-10">
                    <label *ngIf="isSubject">Subject</label>
                    <label *ngIf="!isSubject">Select a template...</label>
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <input type="hidden" [value]="subject" formControlName="title" id="title">
                            <input type="text" class="form-control" (blur)="getSubject($event)" *ngIf="isSubject">
                            <ng-select placeholder="Select" clearAllText="Clear" *ngIf="!isSubject" (change)="getTemplate($event)">
                                <ng-option *ngFor="let template of message_templates let i=index" [value]="i">{{template.title}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 col-sm-2 col-xl-2">
                    <button type="button" class="btn-default mt-3" (click)="subjectToggle()">Template</button>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <label>Message</label>
                    <div class="form-group">
                        <editor id="message" formControlName="message" [init]="{
                            base_url: '/tinymce',
                            suffix: '.min',
                            height: 350,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                        }"></editor>
                    </div>
                </div>
                <div class="col-md-4">
                    <label>Message Type</label>
                    <div class="form-group  d-flex mb-20">
                        <div class="form-check pl-0 mb-2">
                            <label class="form-check-label button-radio-btn">
                                <input type="radio" checked class="form-check-input radio-btn-button " formControlName="message_type" value="email">
                                <div class="layer"></div>
                                <div class="button"><span>Email</span></div>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <label>File(s)</label>
                    <div class="form-group mb-20">
                        <ng-select [multiple]="true" formControlName="documents" placeholder="Select"clearAllText="Clear">
                            <ng-option *ngFor="let document of documents" [value]="document.id">{{document.name}}</ng-option>
                        </ng-select>
                    </div>

                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label>Attachment (s)</label>
                        <div class="form-group mb-20">
                            <i class="fa fa-paperclip font-16 mb-"></i>
                            <input type="file" fomrControlName="files" multiple name="attachment" (change)="onFileChange($event)">
                        </div>
                        <!-- <p class="text-dark">Max. 40MB</p> -->
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="box-footer">
                        <div class="pull-right">
                            <button type="submit" class="btn bg-dark-blue btn-sm mb-2"><i class="fa fa-envelope-o"></i> Send</button>
                            <!-- <button type="button" class="btn bg-dark-blue btn-sm mb-2"><i class="fa fa-pencil"></i> Draft</button>
                            <button type="reset" class="btn bg-dark-blue btn-sm mb-2"><i class="fa fa-times"></i> Discard</button> -->
                        </div>
                    </div>
                </div>


            </div>
        </form>

    </div>

</ng-template>
