import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';
import { environment } from '../../environments/environment';
import tinymce from 'tinymce';

const filePath = environment.filePath;
@Component({
  selector: 'app-training-course-builder',
  templateUrl: './training-course-builder.component.html',
  styleUrls: ['./training-course-builder.component.css']
})
export class TrainingCourseBuilderComponent implements OnInit {
  courseForm:FormGroup;
  certificationForm:FormGroup;
  topicForm:FormGroup;
  testForm:FormGroup;
  testDetailForm:FormGroup;
  sectionForm:FormGroup;
  resourseForm:FormGroup;
  currentCourseId:any;
  min:any=0;
  max:any=0;
  instructors:any=[];
  response:any=[];
  certifications:any=[];
  topics:any=[];
  sections:any=[];
  featuredImage:string = '';
  headerImage:string = '';
  tinyMceConfig:any;
  deleteId:any;
  filePath:any=filePath;
  currentCourse:any=[];
  scheduleDate:any;
  passingPercentage:any=0;
  temp:any=0;
  questions:any=[];
  tests:any=[];
  currentSectionId:any='';
  types:any=['Link','File','Video'];
  isLink:boolean=false;
  isFile:boolean=false;
  isVideo:boolean=false;
  file:string='';
  instructorModel:any =[];
  topicModel:any=[];
  certificateModel:any=[];
  currentUser:any;



  formErrors = {
    'name':'',
    'featured_image':'',
    'header_image':'',
    'description':'',
    'brief':'',
    'featured_video_embed':'',
    'webinar_chat_embed':'',
    'schedule_date':'',
    'registration_end_date':'',
    'course_date':'',
    'status':'',
    'visibility':'',
    'location_address_line_1':'',
    'location_address_line_2':'',
    'location_city':'',
    'location_state':'',
    'location_zip':'',
    'instructor':'',
    'cost':'',
    'topic':'',
    'certification':''
  }

  validationMessages = {
    'name':{
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    }
  }

  formErrorsCertificate = {
    'name':'',
    'description':''
  }

  validationMessagesCertificate = {
    'name':{
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    }
  }

  formErrorsTopic = {
    'name':'',
    'description':''
  }

  validationMessagesTopic = {
    'name':{
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    }
  }

  formErrorsTest = {
    'name':'',
    'description':''
  }

  validationMessagesTest = {
    'name':{
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    }
  }

  formErrorsTestDetail = {
    'name':'',
    'question':'',
    'passing_percentage':'',
    'description':'',
  }

  validationMessagesTestDetail = {
    'name':{
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    }
  }


  formErrorsSection = {
    'name':'',
    'description':'',
    'content':'',
    'is_hidden':'',
    'video_embed':'',
  }

  validationMessagesSection = {
  }

  constructor(
    private route:ActivatedRoute,
    private formBuilder:FormBuilder,
    private api:ApiService,
    private toastr:ToastrService,
    private modalService:NgbModal,
    private router:Router
  ) { 
    this.currentUser = JSON.parse(localStorage.getItem('reliant_user'));
    this.currentCourseId = this.route.snapshot.params['id'];

    this.getSection(this.currentCourseId);
    this.getInstructors();
    this.getTopics();
    this.getCertification();

    this.courseForm = this.formBuilder.group({
      id:[''],
      featured_image:[''],
      header_image:[''],
      description:[''],
      brief:[''],
      featured_video_embed:[''],
      webinar_chat_embed:[''],
      schedule_date:[''],
      registration_end_date:[''],
      course_date:[''],
      status:[''],
      visibility:[''],
      name:['',[Validators.required]],
      location_address_line_1:[''],
      location_address_line_2:[''],
      location_city:[''],
      location_state:[''],
      location_zip:[''],
      instructor:[''],
      cost:[''],
      topic:[''],
      certification:['']
    });

    this.courseForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.courseForm);
    });

    let date = formatDate(new Date(), 'MM/dd/yyyy', 'en');
    let name = 'New Course - '+date;
    if(!this.currentCourseId){
      this.courseForm.patchValue({'name':name});
      this.courseForm.patchValue({'status':'Draft'});
      this.submitData(this.courseForm.value);
    }else{
      this.getSingleCourse(this.currentCourseId);
    }
    
  }

  getInstructors(){
    this.api.get('admin/course_registration/get_user').subscribe((result)=>{
      this.instructors = result.data;
    },(err)=>{
    });
    return true;
  }

  ngOnInit(): void {
    this.init();
  }

  init() :void{
    this.certificationForm = this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required, Validators.minLength(3)]],
      description:[''],
    });
    this.certificationForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairsCertificate(this.certificationForm);
    });
    this.topicForm = this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required, Validators.minLength(3)]],
      description:['']
    });
    this.topicForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairsTopic(this.topicForm);
    });
    this.testForm = this.formBuilder.group({
      test_id: [''],
    });
    this.testForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairsTest(this.testForm);
    });
    this.testDetailForm = this.formBuilder.group({
      name:['', [Validators.required, Validators.minLength(3)]],
      question:[''],
      passing_percentage:[''],
      description:['']
    });
    this.testDetailForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairsTestDetail(this.testDetailForm);
    });
    this.sectionForm = this.formBuilder.group({
      name:[''],
      description:[''],
      content:[''],
      is_hidden:[''],
      video_embed:[''],
    });
    this.sectionForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairsSection(this.sectionForm);
    });

    this.resourseForm = this.formBuilder.group({
      name:[''],
      type:[''],
      link:[''],
      file:[''],
      embed:['']
    });
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  validateKeyValuePairsCertificate(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairsCertificate(abstractControl);
      } else {
        this.formErrorsCertificate[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessagesCertificate[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrorsCertificate[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  validateKeyValuePairsTopic(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairsCertificate(abstractControl);
      } else {
        this.formErrorsTopic[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessagesTopic[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrorsTopic[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  validateKeyValuePairsTest(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairsTest(abstractControl);
      } else {
        this.formErrorsTest[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessagesTest[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrorsTest[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  validateKeyValuePairsSection(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairsSection(abstractControl);
      } else {
        this.formErrorsSection[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessagesSection[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrorsSection[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  validateKeyValuePairsTestDetail(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairsTestDetail(abstractControl);
      } else {
        this.formErrorsTestDetail[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessagesTestDetail[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrorsTestDetail[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  getSingleCourse(id){
    let data = {id:id,user_id:this.currentUser.id};
    this.api.post('admin/courses/single_course',data).subscribe((result)=>{

      this.instructorModel=[];
      this.topicModel=[];
      this.certificateModel=[];

      this.response = result;
      this.currentCourse=this.response.data;
      let res=this.response.data;
      this.min = Number(res.min_registrations);
      this.max = Number(res.max_registrations);
      this.courseForm.patchValue(res);
      this.response.data.course_topics.forEach((element)=>{
        this.topicModel.push(Number(element?.topic_id));
      });
      this.response.data.course_instructor.forEach((element)=>{
        this.instructorModel.push(element?.user_id);
      });
      this.response.data.certification_courses.forEach((element)=>{
        this.certificateModel.push(Number(element?.certification_id));
      });
    },(err)=>{
    });
  }

  onFeaturedImageChange(event)  {
    this.featuredImage = event.target.files;
  }

  onHeaderImageChange(event)  {
    this.headerImage = event.target.files;
  }

  updateData(data){
    const formData =  new  FormData();
    formData.append('featured_image',this.featuredImage[0]);
    formData.append('header_image',this.headerImage[0]);
    formData.append('id',data.id);
    formData.append('description',data.description);
    formData.append('brief',data.brief);
    formData.append('featured_video_embed',data.featured_video_embed);
    formData.append('webinar_chat_embed',data.webinar_chat_embed);
    formData.append('schedule_date',data.schedule_date);
    formData.append('registration_end_date',data.registration_end_date);
    formData.append('course_date',data.course_date);
    formData.append('status',data.status);
    formData.append('visibility',data.visibility);
    formData.append('name',data.name);
    formData.append('location_address_line_1',data.location_address_line_1);
    formData.append('location_address_line_2',data.location_address_line_2);
    formData.append('location_city',data.location_city);
    formData.append('location_state',data.location_state);
    formData.append('location_zip',data.location_zip);
    formData.append('instructor',data.instructor);
    formData.append('cost',data.cost);
    formData.append('topic',data.topic);
    formData.append('certification',data.certification);
    formData.append('max_registrations',this.max);
    formData.append('min_registrations',this.min);
    formData.append('published',(data.published)?data.published:this.currentCourse.published)
    let endPoint = (data.id || this.currentCourseId)?'update':'create';
    this.api.uploadFile('admin/courses/'+endPoint,formData).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.getSingleCourse(this.currentCourseId);
    },(err)=>{
      this.toastr.error(err.error.message,"Error")
    });
  }

  submitData(data){
    let endPoint = (data.id || this.currentCourseId)?'update':'create';
    this.api.post('admin/courses/'+endPoint,data).subscribe((result)=>{
      this.response = result;
      this.router.navigateByUrl('/training/course-builder/'+this.response.data.id);
    },(err)=>{
      this.toastr.error(err.error.message,"Error")
    });
  }

  minReg(value){
    if(this.min!=0 || value==1) this.min = this.min+value;
  }

  maxReg(value){
    if(this.max!=0 || value==1) this.max = this.max+value;
  }

  passPer(){
    if(this.passingPercentage>=0 && this.passingPercentage<=.9){
      this.temp = this.temp+1;
      this.passingPercentage = this.temp/10;
    }
  }

  passPerMinus(){
    if(this.passingPercentage>=.1 && this.passingPercentage<=1){
      this.temp = this.temp-1;
      this.passingPercentage = this.temp/10;
    }
  }


  getCertification(){
    this.api.get('admin/certifications/all_lists').subscribe((result)=>{
      this.certifications = result.data;
    },(err)=>{
    });
    return true;
  }

  addCertification(content){
    this.certificationForm.reset();
    this.modalService.open(content,{ size:'lg' , centered:true });
  }

  submitCertificationData(data):void{
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    let endPoint = (data.id) ? 'update' : 'create';
    this.api.uploadFile('admin/certifications/' + endPoint, data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.getCertification();
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    }, (err) => {
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.modalService.dismissAll();
      this.toastr.error(err.error.message,"Error");
    });
  }

  getTopics(){
    this.api.get('admin/topics/all_lists').subscribe((result)=>{
      this.topics = result.data;
    },(err)=>{
    });
    return true;
  }

  addTopic(content){
    this.topicForm.reset();
    this.modalService.open(content,{ size:'lg' , centered:true });
  }

  submitTopicData(data):void{
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    let endPoint = (data.id) ? 'update' : 'create';
    this.api.post('admin/topics/' + endPoint, data).subscribe((result) => {
      this.response=result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.getTopics();
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    }, (err) => {
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.modalService.dismissAll();
      this.toastr.error(err.error.message,"Error");
    });
  }
 // Test Area Start
  addTest(content){
    this.testForm.reset();
    this.testDetailForm.reset();
    this.modalService.open(content, { size:'xl',centered:true });
    this.testDetail();
  }

  testDetail():void{
    this.api.get('admin/questions/question_lists').subscribe((result)=>{
      this.questions = result.data;
      this.passingPercentage=0;
    },(err)=>{
    });

    this.api.get('admin/tests/tests_lists').subscribe((result)=>{
      this.tests = result.data;
    },(err)=>{
    });
  }
// Test Area End

  submitTestDetailData(data){
    data.passingPercentage=this.passingPercentage;
    this.api.post('admin/tests/create',data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.testDetail();
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

  submitTestData(data){
    data.id=this.currentSectionId;
    this.api.post('admin/sections/section_update',data).subscribe((result)=>{
      this.modalService.dismissAll();
      this.getSection(this.currentCourseId);
    },(err)=>{
      this.modalService.dismissAll();
    });
  }

  removeTest(data){
    this.submitTestData({test_id:''})
  }

  addSection(content){
    this.sectionForm.reset();
    this.modalService.open(content,{ size:'xl', centered:true });
  }

  async getSection(course_id){
    let data = {course_id:course_id};
    this.api.post('admin/sections/lists',data).subscribe((result)=>{
      this.response = result;
      this.sections = this.response.data;
    },(err)=>{
    });
    return true;
  }

  submitSectionData(data:any){
    data.course_id = Number(this.currentCourseId);
    data.resources_selection = [];
    let endPoint = (data.id)?'update':'create';
    this.api.post('admin/sections/'+endPoint,data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.getSection(this.currentCourseId);
      this.modalService.dismissAll();
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
    return true;
  }

  getCurrentSection(index){
    let data=this.sections[index];

    const isHidden = document.getElementById(`is_hidden${index}`) as HTMLInputElement;
    
    if(isHidden.value && Number(data.is_hidden) ===1){
      isHidden.click();
    }else{
      isHidden.value='0';
    }
      const updateButtons = [...Array.from(document.querySelectorAll('.updateButton'))]
      updateButtons.forEach(btn => {
        btn.classList.add('hidden');
      });
      const collapse = document.getElementById(`collapse${index}`) as HTMLElement;
      const editElementRef = document.getElementById(`edit${index}`) as HTMLElement;

      collapse.classList.contains('collapsed') ? editElementRef.classList.remove('hidden') : editElementRef.classList.add('hidden');
      collapse.classList.contains('collapsed') ? this.currentSectionId = data.id : this.currentSectionId='';
    if(data.content){
      tinymce.get("content"+index).setContent(data.content);
    }
  }

  updateCurrentSection(index){  
    let id=document.getElementById(`id${index}`) as HTMLInputElement;
    let name=document.getElementById(`name${index}`) as HTMLInputElement;
    let description=document.getElementById(`description${index}`) as HTMLInputElement;
    let video_embed=document.getElementById(`video_embed${index}`) as HTMLInputElement;
    let is_hidden=document.getElementById(`is_hidden${index}`) as HTMLInputElement;
    let data = {id:id.value,description:description.value,name:name.value,video_embed:video_embed.value,is_hidden:is_hidden.value,content:tinymce.get("content"+index).getContent(),test_id:this.sections[index].test_id};
    this.submitSectionData(data);
  }

  deleteSection(content,current_row){
    this.deleteId = current_row.id;
    this.modalService.open(content,{ size:'sm',centered:true });
  }
  
  deleteCurrentSection(){
    let data =  {id:this.deleteId};
    this.api.post('admin/sections/delete',data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.getSection(this.currentCourseId);
      this.modalService.dismissAll();
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

  removeImage(current_course,field):void{
    let data = {id:current_course.id,field:field};
    this.api.post('admin/courses/remove_image',data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.getSingleCourse(this.currentCourseId);
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

  addResourse(content){
    this.resourseForm.reset();
    this.modalService.open(content,{ size:'lg',centered:true });
  }

  getType(event){
    this.isFile=false;
    this.isLink=false;
    this.isVideo=false;
    if(event=='File'){
      this.isFile=true;
    }
    if(event=='Link'){
      this.isLink=true;
    }
    if(event=='Video'){
      this.isVideo=true;
    }
  }

  getFile(event){
    this.file = event.target.files;
  }

  submitResourseData(data):void{

    const formData =  new  FormData();
    formData.append('section_id',this.currentSectionId);
    formData.append('file',this.file[0]);
    formData.append('name',data.name);
    formData.append('link',data.link);
    formData.append('embed',data.embed);
    formData.append('type',data.type);

    this.api.uploadFile('admin/reseourses/create',formData).subscribe((result)=>{
      console.log(result);
      this.modalService.dismissAll();
    },(err)=>{
      this.modalService.dismissAll();
    });
  }

  switchChange(event){
    var id = event.target.id;
    var value=(Number(event.target.value))?0:1;
    let newId = document.getElementById(`${id}`) as HTMLInputElement;
    newId.value = ''+value;
  }

}
