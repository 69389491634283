<div class="main-content- h-100vh">
    <div class="login_page">
        <div class="login_left">
            <div class="logo_box">
                <div class="lgn-logo-box">
                    <img src="assets/img/core-img/login-logo.svg" width="250" />
                    <h3 class="text-center lgn-logo-subtitle font-18 text-green">
                        Where Quality Staff, Superior service
                        <br>
                        & Affordability Intersect
                    </h3>
                </div>
            </div>
        </div>
        <div class="login-right">
            <div class="middle-box-login">
                <div class="card card-login">
                    <div class="lgn-mobile-loggo text-center">
                        <img src="assets/img/core-img/logo.png" width="150" />
                    </div>
                    <div class="card-body p-4">
                        <img class="sap-1" src="assets/img/svg-icon/sap-1.svg">
                        <!-- Logo -->
                        <h4 class="font-30 font-weight-600 login-title text-green">Sign in</h4>
                        <div class="alert alert-danger alert-dismissible fade show login-error-padding login-error-background" *ngIf="isError" role="alert">
                            {{ errorMessage }}
                            <button type="button" (click)="closeAlert()" class="close login-error-padding" data-dismiss="alert" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form [formGroup]="loginForm" (submit)="doLogin()">
                            <ngx-ui-loader [hasProgressBar]="false" overlayColor="rgba(255,255,255,.4)"></ngx-ui-loader>
                            <div class="form-group">
                                <label class="float-left text-blue font-16" for="emailaddress">Email address</label>
                                <input type="text" formControlName="email" class="form-control" id="emailaddress" placeholder="Enter your email">
                            </div>

                            <div class="form-group">
                                <label class="float-left text-blue font-16" for="password">Password</label>
                                <input class="form-control" type="password" formControlName="password" id="password" placeholder="Enter your password">
                            </div>

                            <div class="form-group text-right mb-3">
                                <a href="#">Forgot your password?</a>
                            </div>

                            <div class="form-group text-right mb-0">
                               <button class="btn login_bnt bg-dark-blue" type="submit"> SIGN IN</button>
                            </div>
                        </form>
                        <img class="sap-2" src="assets/img/svg-icon/sap-2.svg">
                        <!-- end card -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>