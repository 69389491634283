<div class="main-content">
    <div class="container-fluid">
        <form [formGroup]="courseForm" (ngSubmit)="updateData(courseForm.value)">
            <input type="hidden" formControlName="id">
            <div class="row">

                <div class="col-sm-8 box-margin">
                    <div class="card">

                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Workspace</h6>
                            <ul class="nav nav-tabs mb-0" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link show active" id="active-01-tab" data-toggle="tab"
                                        href="#workspace" role="tab" aria-controls="workspace"
                                        aria-selected="true">Workspace</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link mr-0" id="inactive-02-tab" data-toggle="tab" href="#setting"
                                        role="tab" aria-controls="setting" aria-selected="false">Setting</a>
                                </li>
                            </ul>
                        </div>

                        <div class="card-body">

                            <div class="tab-content list_table" id="ticketList">

                                <div class="tab-pane active show fade"
                                    id="workspace" role="tabpanel" aria-labelledby="active-0-tab">
                                    <!-- Show All Data -->
                                    <div id="accordion-">
                                        <div class="card boxshadow-hide mt-3" *ngFor="let section of sections let i=index">
                                            <div class="card-header bg-green collapsed text-white justify-content-between d-flex align-items-center">
                                                <a class="flex-1 card-title mb-0 font-18 text-dark collapsed" id="collapse{{i}}" (click)="getCurrentSection(i)" data-toggle="collapse" href="#accordion-{{i}}" aria-expanded="false">
                                                   <i class="fa fa-list" *ngIf="section.test_id==0"></i><i class="fa fa-check-square-o" *ngIf="section.test_id==1"></i> {{section.name}}
                                                </a>
                                                <button type="button" id="edit{{i}}" class="action-outline-c hidden btn btn-outline-success bordered mr-2 updateButton" (click)="updateCurrentSection(i)"> <i class="fa fa-floppy-o"></i> </button>
                                                <button type="button" class="action-outline-c btn btn-outline-danger bordered mr-2" (click)="deleteSection(deleteSectionModal,section)"> <i class="fa fa-times"></i> </button>
                                            </div>
                                            
                                            <div id="accordion-{{i}}" class="collapse" data-parent="#accordion-">
                                                <div class="card-body">
                                                    <input type="hidden" id="id{{i}}" [value]="section.id">
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <label for="">Name</label>
                                                            <input type="text" [value]="section.name" id="name{{i}}" class="form-control">
                                                        </div>
                                        
                                                        <div class="col-sm-6">
                                                            <label for="">Is Hidden</label>
                                                            <div class="new-checkbox toggle-check-box-t mt-15">
                                                                <label>No</label>
                                                                <label class="switch ml-15 mr-15">
                                                                    <input type="checkbox" (change)="switchChange($event)" id="is_hidden{{i}}">
                                                                    <span class="slider round"></span>
                                                                </label>
                                                                <label>Yes</label>
                                                            </div>
                                                        </div>
                                        
                                        
                                        
                                                        <div class="form-group col-md-6">
                                                            <label for="inputState" class="col-form-label text-light-black font-16">Description</label>
                                                            <textarea class="form-control" rows="5" id="description{{i}}">{{ section.description }}</textarea>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label class="col-form-label text-light-black font-16">Video Embed</label>
                                                            <textarea class="form-control" id="video_embed{{i}}" rows="5"> {{ section.video_embed }}</textarea>
                                                        </div>
                                        
                                                        <div class="form-group col-md-12">
                                                            <label for="">Content</label>
                                                            <!-- <editor  id="content{{i}}" [init]="tinyMceConfig"></editor> -->

                                                            <editor id="content{{i}}" [init]="{
                                                                    base_url: '/tinymce',
                                                                    suffix: '.min',
                                                                    height: 300,
                                                                    menubar: false,
                                                                    plugins: [
                                                                        'advlist autolink lists link image charmap print preview anchor',
                                                                        'searchreplace visualblocks code fullscreen',
                                                                        'insertdatetime media table paste code help wordcount'
                                                                    ],
                                                                    toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                                                }"></editor>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="card" *ngIf="section.test">
                                                    <span class="mt-2 mb-2 ml-2">Assigned Test</span>
                                                    <div class="card-header bg-green collapsed text-white justify-content-between d-flex align-items-center">
                                                        <a class="flex-1 card-title mb-0 font-18 text-dark" href="javascript:void(0)">
                                                            {{section.test.name}}
                                                        </a>
                                                        <a type="button" class="action-btn btn-outline-success bordered mr-2" [routerLink]="['/training/test-builder/'+section.test.id]"> <i class="fa fa-pencil"></i> </a>
                                                        <button type="button" class="action-btn btn-outline-danger bordered mr-2" (click)="removeTest(section)"> <i class="fa fa-times"></i> </button>
                                                    </div>
                                                </div>

                                            </div>

                                            

                                        </div>
                                    </div>
                                </div>


                                <div class="tab-pane fade" id="setting" role="tabpanel"
                                    aria-labelledby="inactive-02-tab">

                                    <div class="form-row">
                                        <div class="form-group col-md-6" *ngIf="currentCourse.image==''">
                                            <label for="inputState"
                                                class="col-form-label text-light-black font-16">Featured Image</label>
                                            <input type="file" formControlName="featured_image" accept="image/*" (change)="onFeaturedImageChange($event)" id="featured_image"
                                                class="custom-input-file" />
                                            <label for="featured_image"> <i class="fa fa-upload"></i> <span>Choose a file…</span> </label>

                                        </div>
                                        <div class="col-sm-6" *ngIf="currentCourse.image!=''">
                                            <div class="cou-bid-item">
                                              <img class="rounded thumb-2-xl border"  src="{{filePath+currentCourse.image}}" alt="">
                                              <span class="badge badge-light close_badge" (click)="removeImage(currentCourse,'image')"><i class="fa fa-times fa-2x"></i></span>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-6" *ngIf="currentCourse.header_image==''">
                                            <label for="inputState"
                                                class="col-form-label text-light-black font-16">Header Image</label>
                                            <input type="file" name="header_image" accept="image/*" (change)="onHeaderImageChange($event)" id="header_image"
                                                class="custom-input-file" />
                                            <label for="header_image"> <i class="fa fa-upload"></i> <span>Choose a file…</span> </label>

                                        </div>
                                        <div class="col-sm-6" *ngIf="currentCourse.header_image!=''">
                                            <div class="cou-bid-item">
                                                <img class="rounded thumb-2-xl border"  src="{{filePath+currentCourse.header_image}}" alt="">
                                                <span class="badge badge-light close_badge" (click)="removeImage(currentCourse,'header_image')"><i class="fa fa-times fa-2x"></i></span>
                                            </div>
                                          
                                        </div>

                                        <div class="form-group col-md-12">
                                            <label class="col-form-label text-light-black font-16">Exerpt</label>
                                            <input class="form-control" type="text" formControlName="brief">
                                        </div>

                                        <div class="form-group col-md-12">
                                            <label for="">Description</label>
                                            <editor formControlName="description" [init]="{
                                            base_url: '/tinymce',
                                            suffix: '.min',
                                            height: 350,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                        }"></editor>
                                        </div>

                                        <div class="form-group col-md-6">
                                            <label for="inputState"
                                                class="col-form-label text-light-black font-16">Featured video</label>
                                            <textarea formControlName="featured_video_embed" class="form-control"
                                                rows="5"></textarea>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label class="col-form-label text-light-black font-16">Chat Embed</label>
                                            <textarea formControlName="webinar_chat_embed" class="form-control"
                                                rows="5"></textarea>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="card mt-20">
                                                <div
                                                    class="card-header bg-green d-flex align-items-center justify-content-between">
                                                    <h6 class="card-title mb-0">Course Information</h6>
                                                </div>
                                                <div class="card-body">
                                                    <div class="form-row">
                                                        <div class="col-md-12">
                                                            <p class=" text-light-black font-16">
                                                                Use this section to configure how registrations should
                                                                be handled for this course, including optional limits as
                                                                well as specifying when they should take place.
                                                            </p>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label
                                                                class="col-form-label text-light-black font-16">Registration
                                                                Start Date</label>
                                                            <input formControlName="schedule_date" id="schedule_date" [value]="scheduleDate" type="date" class="form-control" data-provide="datepicker">
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label
                                                                class="col-form-label text-light-black font-16">Registration
                                                                End Date</label>
                                                            <input formControlName="registration_end_date" type="date"
                                                                class="form-control" data-provide="datepicker">
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label class="col-form-label text-light-black font-16">Min
                                                                Registrations</label>


                                                            <div class="input-group inc_dec mb-3">
                                                                <div class="input-group-prepend">
                                                                    <button type="button" class="input-group-text"
                                                                        (click)="minReg(-1)">-</button>
                                                                </div>
                                                                <div class="custom-file">
                                                                    <input type="number"
                                                                        class="form-control text-center" [value]="min"
                                                                        min="0" max="9999999999999"
                                                                        id="inputGroupFile01">
                                                                </div>
                                                                <div class="input-group-append">
                                                                    <button type="button" class="input-group-text"
                                                                        (click)="minReg(1)">+</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label class="col-form-label text-light-black font-16">Max
                                                                Registrations</label>

                                                            <div class="input-group inc_dec mb-3">
                                                                <div class="input-group-prepend">
                                                                    <button type="button" class="input-group-text"
                                                                        (click)="maxReg(-1)">-</button>
                                                                </div>
                                                                <div class="custom-file">
                                                                    <input type="number"
                                                                        class="form-control  text-center" [value]="max"
                                                                        min="0" max="9999999999999"
                                                                        id="inputGroupFile01">
                                                                </div>

                                                                <div class="input-group-append">
                                                                    <button type="button" class="input-group-text"
                                                                        (click)="maxReg(+1)">+</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-sm-6">
                                                            <label>Address Line 1</label>
                                                            <div class="form-row">
                                                                <div class="form-group col-md-12">
                                                                    <input type="text" class="form-control"
                                                                        formControlName="location_address_line_1"
                                                                        placeholder="Please enter address line 1">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-sm-6">
                                                            <label>Address Line 2</label>
                                                            <div class="form-row">
                                                                <div class="form-group col-md-12">
                                                                    <input type="text" class="form-control"
                                                                        formControlName="location_address_line_2"
                                                                        placeholder="Please enter address line 2">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-sm-4">
                                                            <label>City</label>
                                                            <div class="form-row">
                                                                <div class="form-group col-md-12">
                                                                    <input type="text" class="form-control"
                                                                        formControlName="location_city"
                                                                        placeholder="Please enter city">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-sm-4">
                                                            <label>State</label>
                                                            <div class="form-row">
                                                                <div class="form-group col-md-12">
                                                                    <select class="form-control"
                                                                        formControlName="location_state">
                                                                        <option value="">Select</option>
                                                                        <option value="AL">Alabama</option>
                                                                        <option value="AK">Alaska</option>

                                                                        <option value="AZ">Arizona</option>
                                                                        <option value="AR">Arkansas</option>
                                                                        <option value="CA">California</option>
                                                                        <option value="CO">Colorado</option>
                                                                        <option value="CT">Connecticut</option>
                                                                        <option value="DE">Delaware</option>
                                                                        <option value="FL">Florida</option>
                                                                        <option value="GA">Georgia</option>
                                                                        <option value="HI">Hawaii</option>
                                                                        <option value="ID">Idaho</option>
                                                                        <option value="IL">Illinois</option>
                                                                        <option value="IN">Indiana</option>
                                                                        <option value="IA">Iowa</option>

                                                                        <option value="KS">Kansas</option>
                                                                        <option value="KY">Kentucky</option>
                                                                        <option value="LA">Louisiana</option>
                                                                        <option value="ME">Maine</option>
                                                                        <option value="MD">Maryland</option>
                                                                        <option value="MA">Massachusetts</option>
                                                                        <option value="MI">Michigan</option>
                                                                        <option value="MN">Minnesota</option>
                                                                        <option value="MS">Mississippi</option>
                                                                        <option value="MO">Missouri</option>
                                                                        <option value="MT">Montana</option>
                                                                        <option value="NE">Nebraska</option>
                                                                        <option value="NV">Nevada</option>
                                                                        <option value="NH">New Hampshire</option>

                                                                        <option value="NJ">New Jersey</option>
                                                                        <option value="NM">New Mexico</option>
                                                                        <option value="NY">New York</option>
                                                                        <option value="NC">North Carolina</option>
                                                                        <option value="ND">North Dakota</option>
                                                                        <option value="OH">Ohio</option>
                                                                        <option value="OK">Oklahoma</option>
                                                                        <option value="OR">Oregon</option>
                                                                        <option value="PA">Pennsylvania</option>
                                                                        <option value="RI">Rhode Island</option>
                                                                        <option value="SC">South Carolina</option>
                                                                        <option value="SD">South Dakota</option>
                                                                        <option value="TN">Tennessee</option>
                                                                        <option value="TX">Texas</option>
                                                                        <option value="UT">Utah</option>
                                                                        <option value="VT">Vermont</option>
                                                                        <option value="VA">Virginia</option>
                                                                        <option value="WA">Washington</option>

                                                                        <option value="DC">Washington D.C.</option>
                                                                        <option value="WV">West Virginia</option>
                                                                        <option value="WI">Wisconsin</option>
                                                                        <option value="WY">Wyoming</option>

                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-sm-4">
                                                            <label>Zip</label>
                                                            <div class="form-row">
                                                                <div class="form-group col-md-12">
                                                                    <input type="text" class="form-control"
                                                                        formControlName="location_zip"
                                                                        placeholder="Please enter zip">
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="card mt-20">
                                                <div
                                                    class="card-header bg-green d-flex align-items-center justify-content-between">
                                                    <h6 class="card-title mb-0">Additional Information</h6>
                                                </div>
                                                <div class="card-body">
                                                    <div class="form-row">

                                                        <div class="form-group col-md-6">
                                                            <label
                                                                class="col-form-label text-light-black font-16">Certification(s)</label>
                                                            <div
                                                                class="d-flex align-items-center justify-content-between">
                                                                <ng-select [(ngModel)]="certificateModel" [multiple]="true"
                                                                    class="custom-mutiple-select"
                                                                    formControlName="certification" placeholder="Select"
                                                                    clearAllText="Clear">
                                                                    <ng-option
                                                                        *ngFor="let certification of certifications"
                                                                        [value]="certification.id"> <span
                                                                            *ngIf="certification.name">
                                                                            {{certification.name}} </span></ng-option>
                                                                </ng-select>
                                                                <button type="button" class="btn btn-default" (click)="addCertification(certificationFormModal)"><i
                                                                        class="fa fa-plus"></i> Add New</button>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label
                                                                class="col-form-label text-light-black font-16">Topic(s)</label>
                                                            <div
                                                                class="d-flex align-items-center justify-content-between">
                                                                <ng-select [(ngModel)]="topicModel" [multiple]="true"
                                                                    class="custom-mutiple-select"
                                                                    formControlName="topic" placeholder="Select"
                                                                    clearAllText="Clear">
                                                                    <ng-option *ngFor="let topic of topics"
                                                                        [value]="topic.id"> {{ topic.name }}</ng-option>
                                                                </ng-select>
                                                                <button type="button" class="btn btn-default"
                                                                    (click)="addTopic(topicFormModal)"><i
                                                                        class="fa fa-plus"></i> Add New</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- </form> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card mb-30">
                                <div class="card-header bg-green">
                                    <span class="card-title mb-0 h6">Course Details</span>
                                    <i class="fa fa-floppy-o pull-right"></i>
                                </div>
                                <div class="card-body">
                                    <!-- <form> -->

                                    <div class="form-row">

                                        <div class="form-group col-md-12">
                                            <label for="" class="">Name</label>
                                            <input type="text" class="form-control" formControlName="name">
                                            <span class="text-danger" *ngIf="courseForm.get('name').errors?.required">
                                                {{formErrors.name}}</span>

                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="input" class="">Date</label>
                                            <input type="date" formControlName="course_date" class="form-control">

                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="example-range">Cost ($)</label>
                                            <input type="text" formControlName="cost" class="form-control">
                                        </div>
                                        <div class="form-group col-md-12">
                                            <label for="example-range">Instructor(s) {{instructorModel}} </label>
                                            <ng-select [(ngModel)]="instructorModel" [multiple]="true" formControlName="instructor"
                                                placeholder="Select" clearAllText="Clear">
                                                <ng-option *ngFor="let instructor of instructors"
                                                    [value]="instructor.id"> <span *ngIf="instructor.name">
                                                        {{instructor.name}} </span></ng-option>
                                            </ng-select>
                                        </div>

                                        <div class="col-md-12">
                                            <label>Type</label>
                                            <div class="form-group  d-flex mb-20">
                                                <div class="form-check pl-0 mb-2">
                                                    <label class="form-check-label button-radio-btn">
                                                        <input type="radio" class="form-check-input radio-btn-button"
                                                            formControlName="visibility" value="In Person">
                                                        <div class="layer"></div>
                                                        <div class="button"><span>In Person</span></div>
                                                    </label>
                                                </div>
                                                <div class="form-check pl-0 mb-2">
                                                    <label class="form-check-label button-radio-btn">
                                                        <input type="radio" class="form-check-input radio-btn-button"
                                                            formControlName="visibility" value="Online">
                                                        <div class="layer"></div>
                                                        <div class="button"><span>Online</span></div>
                                                    </label>
                                                </div>
                                                <div class="form-check pl-0 mb-2">
                                                    <label class="form-check-label button-radio-btn">
                                                        <input type="radio" class="form-check-input radio-btn-button"
                                                            formControlName="visibility" value="Webinar" active>
                                                        <div class="layer"></div>
                                                        <div class="button"><span>Webinar</span></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12">
                                            <label for="example-range">Status</label>
                                            <ng-select formControlName="status" placeholder="Select">
                                                <ng-option value="Active"> Active</ng-option>
                                                <ng-option value="Draft"> Draft</ng-option>
                                                <ng-option value="Inactive"> Inactive</ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="form-group col-md-12 bg-white mt-15">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <button [routerLink]="['/training/course-show/'+currentCourse.id]" class="action-btn bg-dark-green text-white mr-2">Preview</button>
                                                <button [disabled]="!courseForm.valid"  class="action-btn bg-dark-blue ml-2">Save</button>
                                            </div>

                                          
                                        </div>
                                    </div>
                                    <!-- </form> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="card mb-30">
                                <div class="card-header bg-green">
                                    <h6 class="card-title mb-0">Tools</h6>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4" (click)="addTest(addTestModal)">
                                            <div class="action-card">
                                                <div class="action-card-header">
                                                    <i class="fa fa-plus" aria-hidden="true"></i>

                                                    <span class="font-12">Add test</span>
                                                </div>
                                                <div class="action-card-body">
                                                    <i class="fa fa-check-square-o" aria-hidden="true"></i>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4" (click)="addResourse(addResourseModal)">
                                            <div class="action-card">
                                                <div class="action-card-header">
                                                    <i class="fa fa-plus" aria-hidden="true"></i>

                                                    <span class="font-12">Add Resource</span>
                                                </div>
                                                <div class="action-card-body">
                                                    <i class="fa fa-file-image-o" aria-hidden="true"></i>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4" (click)="addSection(addSectionModal)">
                                            <div class="action-card">
                                                <div class="action-card-header">
                                                    <i class="fa fa-plus" aria-hidden="true"></i>

                                                    <span class="font-12">Add Section</span>
                                                </div>
                                                <div class="action-card-body">
                                                    <i class="fa fa-list" aria-hidden="true"></i>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </form>
    </div>
</div>

<!-- Certification -->


<ng-template #certificationFormModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0">Certification Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <form [formGroup]="certificationForm" (ngSubmit)="submitCertificationData(certificationForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="name" class="col-form-label text-light-black font-16">Name<span
                            class="text-danger">*</span></label>
                    <input class="form-control rounded-0 form-control-md" type="text" formControlName="name">
                    <span class="text-danger"
                        *ngIf="certificationForm.get('name').errors?.required || certificationForm.get('name').errors?.minlength && certificationForm.get('name').errors">
                        {{formErrorsCertificate.name}}</span>
                    <input type="hidden" formControlName="id" value="">
                </div>

                <div class="form-group col-md-12">
                    <label for="description" class="col-form-label text-light-black font-16">Description</label>
                    <textarea class="form-control rounded-0 form-control-md" type="text" formControlName="description"
                        rows="5"></textarea>

                </div>
            </div>
            <div class="btn-modal-group">
                <button type="submit" [disabled]="!certificationForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
            </div>
        </form>
    </div>

</ng-template>


<!-- Topic -->

<ng-template #topicFormModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0">Topic Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <form [formGroup]="topicForm" (ngSubmit)="submitTopicData(topicForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="name" class="col-form-label text-light-black font-16">Name<span
                            class="text-danger">*</span></label>
                    <input class="form-control rounded-0 form-control-md" type="text" formControlName="name">
                    <span class="text-danger"
                        *ngIf="topicForm.get('name').errors?.required || topicForm.get('name').errors?.minlength && topicForm.get('name').errors">
                        {{formErrors.name}}</span>
                    <input type="hidden" formControlName="id" value="">
                </div>

                <div class="form-group col-md-12">
                    <label for="description" class="col-form-label text-light-black font-16">Description</label>
                    <textarea class="form-control rounded-0 form-control-md" type="text" formControlName="description"
                        rows="5"></textarea>

                </div>
            </div>
            <div class="btn-modal-group">
                <button type="submit" [disabled]="!topicForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
            </div>
        </form>
    </div>

</ng-template>


<!-- Test -->
<ng-template #addTestModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0"> Test Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <form [formGroup]="testForm" (ngSubmit)="submitTestData(testForm.value)">
            <div class="row">
                <div class="form-group col-sm-7">
                    <label class="col-form-label text-light-black font-16">Test</label>
                    <div class="d-flex align-items-center justify-content-between">
                        <ng-select class="custom-mutiple-select" formControlName="test_id" placeholder="Select" clearAllText="Clear">
                            <ng-option *ngFor="let test of tests" [value]="test.id"> {{ test.name }}</ng-option>
                        </ng-select>
                        <button type="button" class="btn btn-default" (click)="addTest(addTestDetailModal)"><i class="fa fa-plus"></i> Add New</button>
                    </div>
                </div>
                <div class="col-sm-12">
                    <button class="action-btn bg-dark-blue">Submit</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>
<!-- Test Details -->
<ng-template #addTestDetailModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0"> Test Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <form [formGroup]="testDetailForm" (ngSubmit)="submitTestDetailData(testDetailForm.value)">
            <div class="row">
                <div class="col-sm-4">
                    <label for="">Name *</label>
                    <input type="text" formControlName="name" class="form-control">
                    <span class="text-danger"
                    *ngIf="testDetailForm.get('name').errors?.required || testDetailForm.get('name').errors?.minlength && testDetailForm.get('name').errors">
                    {{formErrorsTestDetail.name}}</span>
                </div>
                <div class="col-sm-4">
                    <label for="">Question(s)</label>
                    <ng-select [multiple]="true" class="custom-mutiple-select" formControlName="question" placeholder="Select" clearAllText="Clear">
                        <ng-option *ngFor="let question of questions" [value]="question.id"> {{ question.content }}</ng-option>
                    </ng-select>
                </div>
                <div class="form-group col-md-4">
                    <label class="text-light-black">Passing Percentage</label>
                    <div class="input-group inc_dec">
                        <div class="input-group-prepend">
                            <button type="button" class="input-group-text" (click)="passPerMinus()">-</button>
                        </div>
                        <div class="custom-file">
                            <input type="text"  class="form-control text-center" [value]="passingPercentage"  formControlName="passing_percentage">
                        </div>
                        <div class="input-group-append">
                            <button type="button" class="input-group-text" (click)="passPer()">+</button>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12">
                    <label for="">Description</label>
                    <textarea formControlName="description" class="form-control" id="" cols="30" rows="5"></textarea>
                </div>

                <div class="col-sm-12 mt-2">
                    <div class="btn-modal-group">
                        <button type="submit" [disabled]="!testDetailForm.valid" id="submit-btn"
                            class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
                    </div>
                </div>

            </div>

            

        </form>
    </div>

</ng-template>

<!-- Add Section  -->

<ng-template #addSectionModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0"> Section Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <form [formGroup]="sectionForm" (ngSubmit)="submitSectionData(sectionForm.value)">
            <div class="row">
                <div class="col-sm-6">
                    <label for="">Name</label>
                    <input type="text" formControlName="name" class="form-control">
                </div>
                <div class="col-sm-6">
                    <label for="">Is Hidden</label>
                    <div class="new-checkbox toggle-check-box-t mt-15">
                        <label>No</label>
                        <label class="switch ml-15 mr-15">
                            <input type="checkbox" formControlName="is_hidden" id="checkbox-recurit">
                            <span class="slider round"></span>
                        </label>
                        <label>Yes</label>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label for="inputState" class="col-form-label text-light-black font-16">Description</label>
                    <textarea class="form-control" formControlName="description" rows="5"></textarea>
                </div>
                <div class="form-group col-md-6">
                    <label class="col-form-label text-light-black font-16">Video Embed</label>
                    <textarea class="form-control" formControlName="video_embed" rows="5"></textarea>
                </div>
                <div class="form-group col-md-12">
                    <label for="">Content</label>
                    <editor formControlName="content" [init]="{
                            base_url: '/tinymce',
                            suffix: '.min',
                            height: 400,
                            menubar: true,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                        }"></editor>
                </div>
                <div class="col-sm-12">
                    <button class="action-btn bg-dark-blue">Submit</button>
                </div>

            </div>
        </form>
    </div>
</ng-template>

<!-- Delete Section -->

<ng-template #deleteSectionModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
      <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>
  
    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete this section?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteCurrentSection()" class="btn btn-success  border-r-10">Yes, delete it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>
  </ng-template>

<!-- Add Resourse -->

<ng-template #addResourseModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
      <h6 class="card-title mb-0 text-center">Resource Details</h6>
      <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
  
    <div class="modal-body md-b-body">
        <form [formGroup]="resourseForm" (ngSubmit)="submitResourseData(resourseForm.value)">
            <div class="row">
                <div class="col-sm-4">
                    <label for="name">Name</label>
                    <input type="text" formControlName="name" class="form-control">
                </div>

                <div class="col-sm-4">
                    <label>Type</label>
                    <div class="form-group  d-flex mb-20">
                        <div class="form-check pl-0 mb-2" *ngFor="let type of types">
                            <label class="form-check-label button-radio-btn">
                                <input type="radio" (click)="getType(type)" class="form-check-input radio-btn-button"
                                    formControlName="type" [value]="type">
                                <div class="layer"></div>
                                <div class="button"><span>{{type}}</span></div>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-sm-4" *ngIf="isLink">
                    <label for="link">Link</label>
                    <input type="text" formControlName="link" class="form-control">
                </div>

                <div class="col-sm-4" *ngIf="isFile">
                    <label for="file">File</label>
                    <input type="file" (change)="getFile($event)" formControlName="file" class="form-control">
                </div>

                <div class="col-sm-12" *ngIf="isVideo">
                    <label for="video_embed">Video Embed</label>
                    <textarea formControlName="embed" class="form-control" rows="5"></textarea>
                </div>

                <div class="col-sm-12 mt-2">
                    <button class="action-btn bg-dark-blue">Submit</button>
                </div>

            </div>
        </form>
    </div>
  </ng-template>