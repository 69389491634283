import { Component, ElementRef, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ViewChild, TemplateRef} from '@angular/core';
import { Subject } from 'rxjs';
import { CalendarEvent, CalendarView, CalendarEventAction } from 'angular-calendar';
import { environment } from '../../environments/environment';
import { isSameDay, isSameMonth } from 'date-fns';

const filePath = environment.filePath;

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @ViewChild("calenderFormModal",{static:true}) calenderFormModal:ElementRef;
  @ViewChild("calenderEventDeleteModal",{static:true}) calenderEventDeleteModal:ElementRef;
  @ViewChild("calenderClientEventDeleteModal",{static:true}) calenderClientEventDeleteModal:ElementRef;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  EmailScheduleForm: FormGroup;
  adjustAmountModalForm: FormGroup;
  createTallyModalForm: FormGroup;
  emailRequestForm: FormGroup;
  user: any = [];
  toDayDate: any = '';
  lastMothDate: any = '';
  isTrue: boolean = true;
  isDefault: boolean = false;
  isBlank: boolean = false;
  isEmployee: boolean = true;
  isSchedule: boolean = false;
  isAvailability: boolean = false;
  isAll: boolean = false;
  isClientdata: boolean = true;
  isIndividual: boolean = false;
  isMultipel: boolean = false;
  isRefreshpage: boolean = true;
  clientId: any = '';
  allScheduledItems: any = [];
  allClients: any = [];
  response: any = [];
  start_date: any = '';
  end_date: any = '';
  allEmployees: any = [];
  totalAmount: any = '';
  amount: any = '';
  createTallyAmount: any = '';
  pdfPath: any = '';
  employeeId: any = '';//4597
  employeeIds: any = [];
  isFilterClient: boolean = false;
  isSelecteContactType: any = '';
  isSelecteEmployee: any = '';
  availabilitypdfPath: any = '';
  model_type: any = 'schedule';
  clientSelect: boolean = false;
  showClientlist: boolean = false;
  getValueradio: any = '';
  calenderForm: FormGroup;
  openenvelope: boolean = false;
  activeDayIsOpen: boolean = false;
  openENV = false;
  calendarSelectedDate: any = new Date();
  timeframes: any = [];
  date:any = '';
  getDbDate : any = '';

  filePath:any= filePath;
  allScheduledData : any = [];
  eventsData : any = [];
  shiftStartTime:any = '';
  shiftEndTime:any = ''
  shiftSelectionTime:any = '';
  calenderEventId:any = '';
  isCalendardate :any= false;
  clientIds:any = [];
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  refreshCalendar: Subject<void> = new Subject();
  events: CalendarEvent[];
  actions: CalendarEventAction[] = [
    {
      label: "<img src='assets/img/svg-icon/edit-white.svg' width='20' class='ml-2'/>",
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: "<img src='assets/img/svg-icon/delete.svg' width='20' class='ml-2' />",
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Deleted', event);
      },
    },
  ];
  actionsclinet: CalendarEventAction[] = [
    {
      label: "<img src='assets/img/svg-icon/delete.svg' width='20' class='ml-2' />",
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.clientEventDelete('Deleted', event);
      },
    },
  ];

  constructor(
    private api: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) {
    this.user = JSON.parse(localStorage.getItem('reliant_user'));

    this.EmailScheduleForm = this.formBuilder.group({
      message: [''],
    });
    this.adjustAmountModalForm = this.formBuilder.group({
      amount: [''],
    });
    this.createTallyModalForm = this.formBuilder.group({
      note: [''],
    });

    this.emailRequestForm = this.formBuilder.group({
      start_date: ['', [Validators.required]],
      end_date: ['', [Validators.required]],
    });

    this.calenderForm = this.formBuilder.group({
      id: [''],
      client_id: [''],
      notes: [''],
      start_date: [''],
      end_datetime:[''],
      shift_start_time: [''],
      shift_end_time: [''],
      client_cancelled: false,
      employee_cancelled: false,
      employee_late: false,
      employee_ncns: false,
      employee_left_early: false,
      double_shift: false,
      employee_nos: false,
      model_id: [''],
      model_type: false,
      
    });
     // All available time-frames for selection.
    this.timeframes =  [
      { label: '6:00AM - 2:30PM (8)', value: { from: '06:00', to: '14:30', addDays: 0 } },
      { label: '6:00AM - 10:30PM (16)', value: { from: '06:00', to: '22:30', addDays: 0 } },

      { label: '6:30AM - 3:00PM (8)', value: { from: '06:30', to: '15:00', addDays: 0 } },
      { label: '6:30AM - 11:00PM (16)', value: { from: '06:30', to: '23:00', addDays: 0 } },

      { label: '6:45AM - 3:15PM (8)', value: { from: '06:45', to: '15:15', addDays: 0 } },
      { label: '6:45AM - 11:15PM (16)', value: { from: '06:45', to: '23:15', addDays: 0 } },

      { label: '7:00AM - 3:30PM (8)', value: { from: '07:00', to: '15:30', addDays: 0 } },
      { label: '7:00AM - 11:30PM (16)', value: { from: '07:00', to: '23:30', addDays: 0 } },

      { label: '2:00PM - 10:30PM (8)', value: { from: '14:00', to: '22:30', addDays: 0 } },
      { label: '2:00PM - 6:30AM (16)', value: { from: '14:00', to: '06:30', addDays: 0 } },

      { label: '2:30PM - 11:00PM (8)', value: { from: '14:30', to: '23:00', addDays: 0 } },
      { label: '2:30PM - 7:00AM (16)', value: { from: '14:30', to: '07:00', addDays: 0 } },

      { label: '2:45PM - 11:15PM (8)', value: { from: '14:45', to: '23:15', addDays: 0 } },
      { label: '2:45PM - 7:15AM (16)', value: { from: '14:45', to: '07:15', addDays: 0 } },

      { label: '3:00PM - 11:30PM (8)', value: { from: '15:00', to: '23:30', addDays: 0 } },
      { label: '3:00PM - 7:30AM (16)', value: { from: '15:00', to: '07:30', addDays: 0 } },

      { label: '10:00PM - 6:30AM (8)', value: { from: '22:00', to: '06:30', addDays: 0 } },
      { label: '10:00PM - 2:30PM (16)', value: { from: '22:00', to: '14:30', addDays: 0 } },

      { label: '10:30PM - 7:00AM (8)', value: { from: '22:30', to: '07:00', addDays: 0 } },
      { label: '10:30PM - 3:00PM (16)', value: { from: '22:30', to: '15:00', addDays: 0 } },

      { label: '10:45PM - 7:15AM (8)', value: { from: '22:45', to: '07:15', addDays: 0 } },
      { label: '10:45PM - 3:15PM (16)', value: { from: '22:45', to: '15:15', addDays: 0 } },

      { label: '11:00PM - 7:30AM (8)', value: { from: '23:00', to: '07:30', addDays: 0 } },
      { label: '11:00PM - 3:30PM (16)', value: { from: '23:00', to: '15:30', addDays: 0 } },
    ]
    
    this.shiftStartTime = this.timeframes[0].value.from;
    this.shiftEndTime = this.timeframes[0].value.to;
    this.events = this.eventsData;
  }
  formatTime(time){

  }
  ngOnInit(): void {
    const today = new Date()
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 30)
    today.toDateString()
    yesterday.toDateString()
    this.toDayDate = today;
    this.lastMothDate = yesterday;
    // this.getAllScheduled();
    this.refreshCalendar.next();
  }
  selectaType(event) {
    this.isTrue = (event === "clients") ? true : false;
    if (this.isTrue) {
      this.isSelecteContactType = 'clients';
      this.getClients();
    }
    if (event === "employees") {
      this.isSelecteContactType = 'employees';
      this.getEmployees();
    }
    this.events = [];
    this.isDefault = true;
    this.isBlank = (event == null) ? true : false;
    this.start_date = this.end_date = " ";
  }
  selectEmployee(event) {
    this.isEmployee = (event == null) ? true : false;
    (event) ? this.employeeId = event : this.employeeId = '';
    this.getStftalliesAmount();
    // this.get_scheduled_items();
    this.getAllScheduled();
    console.log("this.employeeId==>",this.employeeId)
  }
  getUserType(type) {
    this.isSchedule = false;
    this.isAvailability = false;
    this.isAll = false;
    if (type == 1) {
      this.model_type = 'schedule';
      this.get_scheduled_items();
      this.isSchedule = true;
      this.getAllScheduled();
    }
    if (type == 3) {
      this.isAvailability = true;
      this.isAll = true;
      this.model_type = 'all';
      this.get_scheduled_items();
      this.getAllScheduled();
    }
    if (type == 2) {
      this.isAvailability = true;
      this.isAll = true;
      this.model_type = 'availability';
      this.get_scheduled_items();
      this.events = [];
    }
  }

  selectClient(event) {
    this.isClientdata = (event == null) ? true : false;
   
    if(event){
      this.clientIds = event.toString().split(" ");
      this.getEmployees();
      this.clientId = event;
      this.getAllClientScheduled();
    }else{
      this.activeDayIsOpen = false;
      this.clientIds = [];
      this.clientId = '';
      this.clientId = '';
      this.events = [];
      console.log("Please select any one client");
    }
    
  }
  selectMultipleClient (clients){
    this.events = [];
    if(clients.length){
      this.clientIds = clients;
      this.getAllClientScheduled();
    }else{
      this.events = [];
      console.log("Please select multiple clients");
    }
  }


  userData(type) {
    this.isIndividual = false;
    this.isMultipel = false;
    if (type == 1) {
      this.employeeIds = '';
      this.isIndividual = true;
    } else if (type == 3) {
      this.employeeId = '';
      this.employeeIds = '';
      this.isIndividual = false;
      this.isMultipel = false;
    }
    else {
      this.employeeId = '';
      this.isMultipel = true;
    }
  }

  RefreshPage() {
    this.isClientdata = true;
    this.isEmployee = true;
    this.isTrue = true;
    this.isBlank = true;
    this.isSelecteContactType = '';
    this.events = [];
    this.activeDayIsOpen = false;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  async getClients() {
    let data = {};
    this.api.post('admin/customer/get_customers', data).subscribe((result) => {
      this.response = result;
      this.allClients = this.response.data;
    }, (err) => {

    });
  }

  getPdfPath(path) {
    console.log("path===>",path);
    const d = new Date();
    let url = filePath + path + "?time=" + d.getTime();
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  async getStftalliesAmount() {
    let data = { user_id: this.employeeId, start_date: this.toDayDate, last_date: this.lastMothDate }
    this.api.post('admin/stf_tallies/get_total_amount', data).subscribe((result) => {
      this.response = result;
      this.totalAmount = this.response.data;
      this.amount = this.response.data;
    }, (err) => {

    });
  }

  selectEmployeeId(empId) {
    this.employeeId = [empId];
  }
  downloadPDF(content) {
    this.pdfPath = '';
    let empIds = (this.employeeId) ? this.employeeId : this.employeeIds;
    let data = { client_id: this.clientId, start_date: this.start_date, end_date: this.end_date, employee_id: empIds };
    this.api.post('admin/scheduled_items/pdf', data).subscribe((result) => {
      this.response = result;
      this.pdfPath = this.response.data;
      this.modalService.open(content, { size: 'xl', centered: true });
    }, (err) => {
      this.toastr.error(err.error.message, "Error");
    });
  }
  getEmployees() {
    this.api.get('admin/availability/lists').subscribe((result) => {
      this.response = result;
      this.allEmployees = result.data;

    }, (err) => {
    });
  }
  openEmailSchedule(content) {
    this.modalService.dismissAll();
    this.modalService.open(content, { size: 'sm', centered: true });
  }
  openEmailScheduleBox(content) {
    this.EmailScheduleForm.reset();
    this.modalService.open(content, { size: 'xl', centered: true });
  }
  sendEmailSchedule(data) {
    data.employee_id = this.employeeId;
    data.user_id = this.user.id;
    this.modalService.dismissAll();
  }
  openAdjustAmount(content) {
    this.adjustAmountModalForm.reset();
    this.modalService.open(content, { size: 'sm', centered: true });
  }
  submitAdjustAmountForm(data): void {
    data.user_id = this.employeeId;
    this.api.post('admin/stf_tallies/create', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.modalService.dismissAll();
      this.getStftalliesAmount();
    }, (err) => {
      this.toastr.error(err.error.message, "Error");
    });
  }
  openCreateTally(content, amount) {
    this.createTallyAmount = amount;
    this.createTallyModalForm.reset();
    this.modalService.open(content, { size: 'sm', centered: true });
  }
  submitcreateTallyForm(data) {
    data.amount = this.createTallyAmount;
    data.model_id = this.employeeId;
    data.user_id = this.user.id;
    data.model_type = "App\Models\\Staffing\\Employee";

    this.api.post('admin/core_notes/create', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getStftalliesAmount();
      this.modalService.dismissAll();
    }, (err) => {
      this.toastr.error(err.error.message, "Error");
    });
  }
  filterByClient(key) {
    if (key == 1) { this.isFilterClient = false; }
    if (key == 2) { this.isFilterClient = true; }
    if (key == 3) {
      this.isClientdata = true
      this.isFilterClient = true;
    }
  }
  openEmailRequest(content) {
    this.emailRequestForm.reset();
    this.modalService.open(content, { size: 'xl', centered: true });
  }
  submitFmailRequestForm(data) {
    data.employee_id = this.employeeId;
    data.user_id = this.user.id;
    this.api.post('admin/metrics/create', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.modalService.dismissAll();
    }, (err) => {
      this.toastr.error(err.error.message, "Error");
    });
  }
  openAvailabilityPDF(content) {
    
    // this.pdfPath = "client_schedule_images/client_image.jpg";
    this.modalService.open(content, { size: 'xl', centered: true });
  }
  openAvailabilityDocx() {
    this.pdfPath = filePath + "client_schedule_images/client_image.jpg";
    this.router.navigate([this.pdfPath]);
  }
  async get_scheduled_items() {
    let data = { filter_tems: this.model_type, employee_id: this.employeeId };
    this.api.post('admin/scheduled_items/filter_items', data).subscribe((result) => {
      this.response = result;
      this.availabilitypdfPath= this.response.availabilitypdfPath;
      
      this.pdfPath  = (this.availabilitypdfPath)?this.availabilitypdfPath:'client_schedule_images/client_image.jpg';
      console.log("this.availabilitypdfPath===>", this.availabilitypdfPath);
      console.log("this.pdfPath===>", this.pdfPath);
    }, (err) => {
    });
    return true;
  }

  addEmployee(): void {
    
    if(this.employeeId){  
      this.calenderForm.reset();
      this.modalService.open(this.calenderFormModal, { size: 'md', centered: true });
      this.getClients();
    }
    else if(this.isSelecteContactType === "employees"){
      this.toastr.error("You are unable to edit this date.", "Invalid Date");
    }
    else if(this.clientId)
    {
      this.toastr.error("You can only create items from the employee view.","Unable to Create");
    }
    else{
      this.toastr.error("You are unable to edit this date.", "Invalid Date");
    }
  }

  selectShift(content) {
    this.modalService.open(content, { size: 'md', centered: true });
  }

  submitCalender(data): void {
    let todaydate = this.calendarSelectedDate.getFullYear() + "-" + this.calendarSelectedDate.getMonth() + 1 + "-" +this.calendarSelectedDate.getDate();
    let date = (data.start_date)?data.start_date:todaydate;


    let shift_start_time = (data.shift_start_time)?data.shift_start_time:this.shiftStartTime;
    let shift_end_time = (data.shift_end_time)?data.shift_end_time:this.shiftEndTime;
    data.model_type = 'App\\Models\\User';
    data.model_id = this.employeeId;


    let endPoint = (data.id)?'update':'create';
    if(endPoint =="update")
    {
      data.start_datetime = this.date + " " + shift_start_time;
      data.end_datetime = this.date + " " + shift_end_time;
    }else{
    data.start_datetime = date + " " + shift_start_time;
    data.end_datetime = date + " " + shift_end_time;
    }
    this.api.post('admin/scheduled_items/'+endPoint, data).subscribe((result) => {
    this.response = result;
    this.toastr.success(this.response.message, "Success");
    this.modalService.dismissAll();
    this.getAllScheduled();
    }, (err) => {
      this.toastr.error("Error");
    });

  }

  onChange(time) {
    this.shiftStartTime = time.from;
    this.shiftEndTime = time.to;
  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.isCalendardate = false;
    this.date = date.getFullYear() + "-" + date.getMonth() + 1 + "-" +date.getDate();
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        if(events.length <= 0){
        }
        this.activeDayIsOpen = false;
      } else {
   
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  handleEvent(action: string, event: CalendarEvent): void {
    
    this.modalData = { event, action };
    console.log("action===>",action);
    if(action == "Edited"){
      this.calenderForm.reset();
      this.getClients();
      console.log("event id===>",event);
      let index = this.eventsData.findIndex(e => e.id === event.id);
      let eventData = this.eventsData[index];

      let db_start_date_time = (new Date(eventData.event_info.start_datetime));
      let db_end_date_time = (new Date(eventData.event_info.end_datetime));
      let db_start_time = ('0'+db_start_date_time.getHours()).substr(-2) + ":" + ('0'+db_start_date_time.getMinutes()).substr(-2)
      let db_end_time = ('0'+db_end_date_time.getHours()).substr(-2) + ":" + ('0'+db_end_date_time.getMinutes()).substr(-2);
      this.shiftStartTime = db_start_time;
      this.shiftEndTime = db_end_time;
      let db_date = new Date(eventData.event_info.start_datetime);
      this.date =  db_date.getFullYear() + "-" + (db_date.getMonth() + 1 )+ "-" +db_date.getDate();
      this.isCalendardate = false;
      this.calenderForm.patchValue(eventData.event_info);
      this.modalService.open(this.calenderFormModal, { size: 'md', centered: true });
      // this.activeDayIsOpen = false;
    }
    if(action == "Deleted"){
      this.calenderEventId = event.id;
      this.modalService.open(this.calenderEventDeleteModal, { size: 'md', centered: true });
    }
    if(action == "Clicked"){
      this.modalService.open(this.modalContent, { size: 'md', centered: true });
    }
  }
  calenderEventDelete(){
    this.eventsData = [];
    let data = { id: this.calenderEventId };
    this.api.post('admin/scheduled_items/delete', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.activeDayIsOpen = false;
      this.modalService.dismissAll();
      this.getAllScheduled();
    }, (err) => {
    });
  }
  clientEventDelete(action: string, event: CalendarEvent): void {
    this.calenderEventId = event.id;
    this.modalService.open(this.calenderClientEventDeleteModal, { size: 'md', centered: true });
  }
  calenderClientEventDelete(){
    let data = { id: this.calenderEventId };
    this.api.post('admin/scheduled_items/delete', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.activeDayIsOpen = false;
      this.modalService.dismissAll();
      this.getAllClientScheduled();
    }, (err) => {
    });
  }
  async getAllScheduled() {
    this.events = [];
    this.eventsData = [];
    let data = { model_id: this.employeeId };
    this.api.post('admin/scheduled_items/all_scheduled', data).subscribe((result) => {
      this.response = result;
      this.allScheduledData = this.response.data;
      this.allScheduledData.forEach((element,index) => {
        let title = (element.client != null)?element.client.company_name:" (No Clinet)" ;
          this.eventsData.push({
            id: element.id,
            start: new Date(element.start_datetime),
            end: new Date(element.end_datetime),
            title: title,
            event_info:element,
            actions: this.actions
          });
          this.refreshCalendar.next();
      });
      this.events = [];
      this.events = this.eventsData;
    }, (err) => {
    });
  }
  async getAllClientScheduled() {
    this.eventsData = [];
    if(this.clientIds.length <= 0){
      return;
    }
    let data = { client_id: this.clientIds };
    this.api.post('admin/scheduled_items/get_scheduled_by_clientId', data).subscribe((result) => {
      this.response = result;
      this.allScheduledData = this.response.data;
      this.allScheduledData.forEach((element,index) => {
        let title = (element.user != null)?element.user.name:" (No Employee )" ;
          this.eventsData.push({
            id: element.id,
            start: new Date(element.start_datetime),
            end: new Date(element.end_datetime),
            title: title,
            event_info:element,
            actions: this.actionsclinet
          });
          this.refreshCalendar.next();
      });
      this.events = [];
      this.events = this.eventsData;
    }, (err) => {
    });
  }

  AddEvent(){
    this.shiftStartTime = this.timeframes[0].value.from;
    this.shiftEndTime = this.timeframes[0].value.to;
    this.isCalendardate = true;
    this.addEmployee()
  }
  
}
