<div class="row">
    <div class="col-md-4">
        <div class="card box-margin">
            <div class="card-header bg-green">
                <h6 class="card-title mb-0">{{currentSection.name}}</h6>
            </div>
            <div class="card-body">
                <div lang="row">
                    <div class="col-sm-12">
                        {{ currentSection.video_embed }}

                        {{ currentSection.description }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-8">
        <div class="card">
            <div>
                <ul class="nav nav-tabs mb-0" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="active-01-tab" data-toggle="tab" href="#details" role="tab"
                            aria-controls="overview" aria-selected="true">Details</a>
                    </li>
                    <li class="nav-item" *ngIf="currentSection.get_resourse && currentSection.get_resourse.length">
                        <a class="nav-link mr-0" id="inactive-02-tab" data-toggle="tab" href="#resources" role="tab"
                            aria-controls="section" aria-selected="false">Additional Resourse</a>
                    </li>
                </ul>
            </div>

            <div class="card-body p-0">
                <div class="tab-content list_table p-0" id="ticketList" mb-4>
                    <div class="tab-pane card active show fade" id="details" role="tabpanel"
                        aria-labelledby="active-01-tab">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Details</h6>

                            <div class="btn-group tab_list">
                                <button class="action-btn bg-dark-blue mr-2"
                                    [routerLink]="['/training/course-show/'+currentSection.course_id]"> <i
                                        class="fa fa-arrow-left"></i> Back To Course Overview</button>
                                <button class="action-btn bg-dark-green mr-2 font-20"
                                    (click)="updateCourse(updateSectionModal,currentSection)"><i
                                        class="fa fa-pencil"></i></button>
                                <button class="action-btn bg-dark-blue text-white font-20"
                                    (click)="deleteSection(deleteSectionModal,currentSection)"><i
                                        class="fa fa-trash"></i></button>
                            </div>
                        </div>

                        <div class="card p-4">
                            <p class="text-dark">Please review the information in the section before marking complete
                                from the bottom of the page. For
                                tested sections, you must first complete the assigned test before continuing on.</p>
                            <div [innerHTML]="currentSection.content"></div>

                            <div class="card mb-4" *ngIf="currentSection.test">
                                <div class="alert alert-danger mt-4" role="alert" *ngIf="currentSection.test_submissions && currentSection.sec_complete_status==0 && currentSection.test_submissions.score<=currentSection.test.passing_percentage">
                                    You have not passed this test. After reviewing, you can use the "Retake" button below to try again.
                                </div>

                                <div
                                    class="card-header bg-orange mt-4 mb-4 user-area" *ngIf="currentSection.sec_complete_status && currentSection.test_submissions && currentSection.length!=0">
                                    <h6 class="card-title mb-0 font-16 mt-2 mb-3">You have successfully passed this test! After reviewing your scores, use to button below to follow the next step.</h6>
                                    <a routerLink="/training/my-course" class="action-btn bg-dark-blue mt-3 mb-2 text-white">CLICK TO CONTINUE</a>
                                </div>

                                <div
                                    class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                                    <h6 class="card-title mb-0">{{ currentSection.test.name }}</h6>
                                </div>
                                <div class="card-body border mt-2 test__statistics"
                                    *ngIf="currentSection.test_submissions">
                                    <span class="mr-2">Total Assessed: {{currentSection.test_submissions.total}}</span>
                                    <span class="mr-2">Correctly Answered: <span
                                            [ngClass]="(currentSection.test_submissions.total==currentSection.test_submissions.correct)?'text-success':''">{{currentSection.test_submissions.correct}}</span></span>
                                    <span class="mr-2">Percentage: <span
                                            [ngClass]="(currentSection.test.passing_percentage*100==currentSection.test_submissions.score*100)?'text-success':''">{{
                                            currentSection.test_submissions.score*100 }}%</span></span>
                                    <span class="mr-2">Passing Percentage: {{ currentSection.test.passing_percentage*100
                                        }}%</span>
                                </div>
                                <div [ngClass]="currentSection.test_submissions?'card-body question_sec':'card-body'">
                                    <div class="mb-2" *ngFor="let question of questions; let i=index">
                                        <p class="text-dark"><b>{{i+1}}.) {{question.content}}</b></p>
                                        <div class="" *ngFor="let option of question.question_options; let j=index">
                                            <label for="{{option.id}}"
                                                (click)="getOption(question.id,option.id,option.value,option.correct)"><input
                                                    type="radio" id="{{option.id}}" [(ngModel)]="option.checked"
                                                    name="{{question.id}}" value="{{option.value}}"> &nbsp;
                                                {{option.value}}</label><br>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-footer"
                                    *ngIf="currentSection.test && !currentSection.test_submissions">
                                    <button class="action-btn bg-dark-blue pull-right"
                                        (click)="submitTest(answerSubmissionConfirmModel)">Submit Test</button>
                                </div>

                            </div>

                            <div class="card" *ngIf="!currentSection.test && currentSection.sec_complete_status==0">
                                <div class="card-body text-right">
                                    <button class="action-btn bg-dark-blue"
                                        (click)="completeSection(currentSection.id)">Complete Section</button>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-body text-right">
                                    <span hidden class="text-success" *ngIf="currentSection.sec_complete_status==1">This
                                        section is complete! </span>
                                    <button class="action-btn bg-orange mr-2"
                                        (click)="retakeTest(retakeConfirmModal,currentSection.test_submissions.id)"
                                        *ngIf="currentSection.test_submissions && currentSection.sec_complete_status==0 && currentSection.test_submissions.score<=currentSection.test.passing_percentage">
                                        Retake this test</button>
                                    <button *ngIf="currentSection.test_submissions" class="action-btn bg-dark-blue mr-2"
                                        [routerLink]="['/training/course-show/'+currentSection.course_id]"> <i
                                            class="fa fa-arrow-left"></i> Back To Course Overview</button>
                                </div>
                            </div>

                        </div>


                    </div>
                    <div class="tab-pane card fade" id="resources" role="tabpanel" aria-labelledby="active-01-tab">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Additional Resources</h6>

                            <div class="btn-group tab_list">
                                <!-- <button class="action-btn bg-dark-blue mr-2">Take Test</button> -->
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4 height-card w-100"
                                    *ngFor="let resource of currentSection.get_resourse">
                                    <div class="element-card box-margin">
                                        <div class="element-card-header ">
                                            <div class="element-card-title text-center text-light-black font-16">
                                                {{ resource.name }}
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <div class="element-img mt-4 mb-4">
                                                <i class="fa fa-link fa-2x" *ngIf="resource.type=='Link'"></i>
                                                <i class="fa fa-file-text-o fa-2x" *ngIf="resource.type=='File'"></i>
                                                <i class="fa fa-film fa-2x" *ngIf="resource.type=='Video'"></i>
                                            </div>
                                        </div>
                                        <div class="text-center mb-3">
                                            <a class="action-btn bg-dark-blue text-white" target="_blank"
                                                [href]="getLink(resource)" *ngIf="resource.type=='Link'">Visit Link</a>
                                            <button class="action-btn bg-dark-blue"
                                                (click)="getFile(fileModal,resource)" *ngIf="resource.type=='File'">View
                                                File</button>
                                            <button class="action-btn bg-dark-blue"
                                                (click)="showVideo(videoModal,resource)"
                                                *ngIf="resource.type=='Video'">Visit Video</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<!-- Embed Video -->
<ng-template #videoModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <div class="row">
            <div class="col-sm-12">
                <div class="embed-responsive embed-responsive-16by9" id="embedVideo"></div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Embed Video -->
<ng-template #fileModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <div class="row">
            <div class="col-sm-12">
                <img src="" id="srcImage" alt="">
            </div>
        </div>
    </div>
</ng-template>

<!-- Update Section -->
<ng-template #updateSectionModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h5 class="modal-title" id="exampleModalLabel">Section Details</h5>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <form [formGroup]="sectionForm" (ngSubmit)="submitSection(sectionForm.value)">
            <div class="row">
                <div class="col-sm-4">
                    <label for="">Name <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="name">
                    <span class="text-danger"
                        *ngIf="sectionForm.get('name').errors?.required || sectionForm.get('name').errors?.minlength && sectionForm.get('name').errors">
                        {{formErrorsSection.name}}</span>
                </div>
                <div class="col-sm-4">
                    <label for="">Course</label>
                    <ng-select formControlName="course_id" placeholder="Select" clearAllText="Clear"
                        class="form-control-ng rounded-0">
                        <ng-option *ngFor="let course of courses" [value]="course.id">
                            {{course.name}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="col-sm-4">
                    <label for="">Test</label>
                    <ng-select class="custom-mutiple-select" formControlName="test_id" placeholder="Select"
                        clearAllText="Clear">
                        <ng-option *ngFor="let test of tests" [value]="test.id"> {{ test.name }}</ng-option>
                    </ng-select>
                </div>

                <div class="col-sm-12">
                    <label for="">Resource(s)</label>
                    <ng-select [(ngModel)]="resourceModel" [multiple]="true" class="custom-mutiple-select"
                        formControlName="resources_selection" placeholder="Select" clearAllText="Clear">
                        <ng-option *ngFor="let resource of resources" [value]="resource.id"> <span
                                *ngIf="resource.name">
                                {{resource.name}} </span></ng-option>
                    </ng-select>
                </div>

                <div class="form-group col-md-6">
                    <label for="inputState" class="col-form-label text-light-black font-16">Description</label>
                    <textarea class="form-control" formControlName="description" rows="5"
                        id="description"></textarea>
                </div>
                <div class="form-group col-md-6">
                    <label class="col-form-label text-light-black font-16">Video Embed</label>
                    <textarea class="form-control" formControlName="video_embed" id="video_embed"
                        rows="5"></textarea>
                </div>

                <div class="form-group col-md-12">
                    <label for="">Content</label>
                    <!-- <editor  id="content{{i}}" [init]="tinyMceConfig"></editor> -->

                    <editor id="content" formControlName="content" [init]="{
                        base_url: '/tinymce',
                        suffix: '.min',
                        height: 300,
                        menubar: true,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                    }"></editor>
                </div>
                <div class="col-sm-12">
                    <button type="submit" [disabled]="!sectionForm.valid" id="submit-btn"
                        class="btn btn-success border-r-10 mr-3 mt-2 md-close">SUBMIT</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<!-- Delete Section -->

<ng-template #deleteSectionModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>

    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete this section?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteCurrentSection()" class="btn btn-success  border-r-10">Yes, delete
                it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>
</ng-template>

<ng-template #answerSubmissionConfirmModel let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Confirm</h6>
    </div>

    <div class="modal-body md-b-body">
        <p>You have not entered an answer for every question, are you sure you would like to continue?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="cancelSubmission()"
                class="action-btn bg-danger text-white">Cancel</button>
            <button type="button" (click)="confirmSubmission(answerSubmissionConfirmModel)"
                class="action-btn bg-success text-white">Ok</button>
        </div>
    </div>
</ng-template>
<!-- Retake Modal -->
<ng-template #retakeConfirmModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>

    <div class="modal-body md-b-body">
        <p>Are you sure you would like to retake this test?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="retakeSubmit()" class="btn btn-success  border-r-10">Ok  </button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>
</ng-template>

