import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-crm-dashboard',
  templateUrl: './crm-dashboard.component.html',
  styleUrls: ['./crm-dashboard.component.css']
})
export class CrmDashboardComponent implements OnInit {

  constructor(private api:ApiService,) 
  {
    this.user =  JSON.parse(localStorage.getItem('reliant_user'));
   }

  response:any=[];
  details_crm_dashboard:any=[];
  followUps:any=[];
  totalRecords:any=0;
  activeTab:any=1;
  page:any=1;
  user:any=[];
  myPrioritiesname:any='My Priorities';
  isTrueOne:boolean=false;
  isTruetwo:boolean=false;
  isTrueThree:boolean=false;
  isTrueToday:boolean=true;
  isTrue:boolean=false;
  assignee_id:any;
  assignee_user_id:any;
  ngOnInit(): void {
    this.get_details_dashboard();
    //this.getFollowUps(this.page);
    this.isTrue=true;
    
  }
  get_details_dashboard(){
    this.api.get('admin/follow_ups/get_details_crm_dashboard').subscribe((result)=>{  
    this.response = result;
    this.details_crm_dashboard = this.response.data;
    });
  }
  checkProperty(key){
    this.isTrueThree=false;
    (key==1)?this.myPrioritiesname='My Priorities':'';
    (key==2)?this.myPrioritiesname='My Future Follow Ups':'';
    (key==3)?this.myPrioritiesname='My Past Follow Ups':'';
    (key==2 || key==3)?this.isTrueToday=false:true;
    (key==1)?this.isTrueToday=true:false;
    this.activeTab=key;
    this.getFollowUps(this.page);
    console.log(this.isTrueToday);
  }
  async getFollowUps(page){
    console.log(this.user);
    this.assignee_user_id="&assignee_id="+this.user.id;
    this.api.get('admin/follow_ups/lists?page='+page+'&activeTab='+this.activeTab+this.assignee_user_id).subscribe((result)=>{  
    this.totalRecords = result.data.total;
    this.followUps = result.data.data;
  },(err)=>{

  });
  }
}
