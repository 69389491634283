<div class="row">
  <div class="col-xl-12 box-margin">
      <div class="card">
          <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
              <h6 class="card-title mb-0">Rates</h6>

              <div class="btn-group">

                  <button class="action-btn bg-dark-green mr-2" (click)="addMessageTemplate(messageTemplateFormModal)"><img src="assets/img/svg-icon/add-white.svg" width="20" /></button>
                  <!-- <button class="action-btn bg-dark-blue" data-toggle="modal" data-target="#exampleModalRight"><img src="assets/img/svg-icon/filter-white.svg" width="20" /></button> -->
              </div>
          </div>
          <div class="card-body">
              <div class="tab-content list_table" id="ticketList">
                  <div class="table-responsive list_table">
                      <table class="table  table-nowrap mb-0">
                          <thead>
                              <tr>
                                  <th class="pt-0">Title</th>
                                  <th class="pt-0">Subject</th>
                                  <th class="pt-0">Body</th>
                                  <th class="pt-0">Active</th>
                                  <th class="pt-0">Created At</th>
                                  <th class="pt-0">Updated At</th>
                                  <th class="pt-0">Action</th>
                              </tr>
                          </thead>
                          <tbody>
                              <ng-container *ngIf="messagetemplate.length > 0">
                                  <tr *ngFor="let msg of messagetemplate | paginate: { id: 'message_template_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalRecords }; let i=index">
                                      <td class="text-black">{{msg.title}}</td>
                                      <td class="text-black">{{msg.subject }}</td>
                                      <td class="text-black w-td-c"><div [innerHTML]="msg.body"></div></td>
                                      <td class="text-gray" *ngIf="msg.active==1">Yes</td>
                                      <td class="text-gray" *ngIf="msg.active==0">No</td>
                                      <td class="text-black">{{msg.created_at | date : 'MM/dd/yyyy' }}</td>
                                      <td class="text-black">{{msg.updated_at | date : 'MM/dd/yyyy' }}</td>
                                      <td class="text-right">
                                        <a href="javascript:void(0)" class="mr-2 action-btn bg-edit" title="Update Message Template" (click)="editMessageTemplate(messageTemplateFormModal,i)"><img src="assets/img/svg-icon/edit.svg" width="20" /></a>
                                        <a href="javascript:void(0)" class="action-btn bg-red" title="Delete" (click)="deleteModal(messageTemplateDeleteModal,msg)"><img src="assets/img/svg-icon/delete.svg" width="20" /></a>
                                      </td>
                                  </tr>
                              </ng-container>
                          </tbody>
                      </table>
                      <pagination-controls class="pagination-controls-theme" id="message_template_pagination" (pageChange)="getMessageTemplate(page = $event)" maxSize="9" directionLinks="true"
                      autoHide="true" responsive="true">
                      </pagination-controls>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- Add Client  -->

<ng-template #messageTemplateFormModal let-modal>

<div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
  <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross  click')"
      aria-label="Close">
      <span aria-hidden="true">×</span>
  </button>
</div>

<div class="modal-body md-b-body">
  <form [formGroup]="messagetemplateForm" (ngSubmit)="submitRatesData(messagetemplateForm.value)">
    <div class="form-row">

      <div class="form-group col-md-12">

        <div class="card card-shadow-hide">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Template Details</h6>
            </div>
            <div class="card-body">

              <div class="form-row">
                <div class="form-group col-sm-6">
                  <label for="title">Title <span class="text-danger">*</span></label>
                  <input type="text"  class="form-control rounded-0 form-control-md" formControlName="title" placeholder="Enter Title">
                  <span class="text-danger"
                        *ngIf="messagetemplateForm.get('title').errors?.required || messagetemplateForm.get('title').errors?.minlength && messagetemplateForm.get('title').errors">
                        {{formErrors.title}}</span>
                    <input type="hidden" formControlName="id" value="">
                </div>

                <div class="form-group col-sm-6">
                  <label for="subject">Subject</label>
                  <input type="text"  class="form-control rounded-0 form-control-md" formControlName="subject" placeholder="Enter Subject">
                </div>

                <div class="form-group col-md-12">
                  <label for="body" class="col-form-label text-light-black font-14">Body</label>
                  <editor formControlName="body" [init]="{
                      base_url: '/tinymce',
                      suffix: '.min',
                      height: 350,
                      menubar: false,
                      plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                  }"></editor>
                </div>

              </div>

            </div>
        </div>
      </div>

    </div>
    <div class="btn-modal-group">
        <button type="submit" [disabled]="!messagetemplateForm.valid" id="submit-btn" class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
    </div>
  </form>
</div>

</ng-template>

<!-- Delete Marketing -->
<ng-template #messageTemplateDeleteModal let-modal>

  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
      <h6 class="card-title mb-0 text-center">Delete</h6>
  </div>

  <div class="modal-body md-b-body">
      <p>Are you sure you want to delete?</p>
      <div class="btn-modal-group d-flex justify-content-between">
          <button type="button" (click)="deleteMessageTemplate()" class="btn btn-success  border-r-10">Yes, delete it</button>
          <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
      </div>
  </div>

</ng-template>

<!-- Right Alight Modal -->
<div class="modal fade drawer right-align" id="exampleModalRight" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Right Align Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
