import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';
import { environment } from '../../environments/environment';
import { HelperService } from '../helper/helper.service';

@Component({
  selector: 'app-crm-call-logs-show',
  templateUrl: './crm-call-logs-show.component.html',
  styleUrls: ['./crm-call-logs-show.component.css']
})
export class CrmCallLogsShowComponent implements OnInit {

  callLogsForm: FormGroup;
  ContactForm: FormGroup;
  currentCallLogsId: any;
  response: any;
  callLogsData: any;
  receivedDate: any;
  allContacts: any;
  notes: any;
  modelName: any;
  flags: any;
  editFlag: any;
  statuses: any;
  allTypes: any;
  editStatus: any
  flagDetails: any;
  statusModelRef: any = [];
  statusDetails: any = [];
  flagModelRef: any = [];
  times:any = [];
  isSelectedStatus: boolean = false;
  isSelectedFlag: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private router: Router,
    private helper: HelperService,
    ) {

    this.times = this.helper.getTime();
    this.currentCallLogsId = Number(this.route.snapshot.params['id']);
    this.callLogsForm = this.formBuilder.group({
      received_date: ['', [Validators.required]],
      time: ['', [Validators.required]],
      contact_id: [''],
      notes: ['', [Validators.required]],
      id: [''],
    });
    this.ContactForm = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      contact_id: [''],
      notes: [''],
      title: [''],
      phone: [''],
      phone_mobile: [''],
      phone_home: [''],
      flag_id: [''],
      status_id: [''],
      type_id: [''],
    });
  }

  ngOnInit(): void {
    this.getCallLogsById();
  }
  getCallLogsById() {
    let data = { id: this.currentCallLogsId }
    this.api.post('admin/call_logs/get_data_by_id', data).subscribe((result) => {
      this.response = result;
      this.callLogsData = this.response.data;
      this.notes = this.callLogsData.notes;
      this.receivedDate = this.callLogsData.received_date.slice(0, 10)
      //this.toastr.success(this.response.message,"Success");
      //this.getCallLogs(this.page);
      this.modalService.dismissAll();
      console.log("call Logs Data =================>", this.callLogsData.id);
    }, (err) => {
      console.log(err);
      this.toastr.error(err.error.message, 'Error');
      this.modalService.dismissAll();
    });
  }
  deleteModal(content) {
    // /this.currentCallLogsId = currentCallLogsId.id;
    this.modalService.open(content, { size: 'sm', centered: true });
  }
  deleteCallLogs() {
    let data = { id: this.currentCallLogsId }
    this.api.post('admin/call_logs/delete', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.modalService.dismissAll();
      this.router.navigate(['/crm/view-follow-ups']);
    }, (err) => {
      console.log(err);
      this.toastr.error(err.error.message, 'Error');
      this.modalService.dismissAll();
    });
  }
  editCollLogsModal(content) {
    this.getContacts();
    this.callLogsForm.reset();
    this.receivedDate = this.callLogsData.received_date.slice(0, 10)
    this.callLogsForm.patchValue(this.callLogsData);
    this.modalService.open(content, { size: 'xl', centered: true, scrollable: true });
    console.log(this.receivedDate);
    console.log("call logs data============>", this.callLogsData);
  }
  async getContacts() {
    this.api.get('admin/follow_ups/get_contacts').subscribe((result) => {
      this.response = result;
      this.allContacts = this.response.data;
    }, (err) => {

    });
  }
  submitCallLogsData(data) {
    let endPoint = (data.id) ? 'update' : 'create';
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/call_logs/' + endPoint, data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.modalService.dismissAll();
      this.getCallLogsById();
      //this.closeModal(modalRef);
    }, (err) => {
      this.toastr.error(err.error.message, "Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });

  }
  openContactModal(content) {
    this.ContactForm.reset();
    this.modelName = "contacts";
    this.getFlag();
    this.getStatus();
    this.getType();
    this.modalService.open(content, { size: 'xl', centered: true });
  }
  getFlag() {
    let data = { model_name: this.modelName };
    this.api.post('admin/core_flags/all_flags', data).subscribe((result) => {
      this.response = result;
      this.flags = this.response.data;
    }, (err) => {
    });
    return true;
  }
  getStatus() {
    let data = { model_name: this.modelName };
    this.api.post('admin/core_statuses/all_statuses', data).subscribe((result) => {
      this.response = result;
      this.statuses = this.response.data;
    }, (err) => {
    });
    return true;
  }
  getType() {
    let data = { model_name: this.modelName };
    this.api.post('admin/core_types/lists', data).subscribe((result) => {
      this.response = result;
      this.allTypes = this.response.data;
    }, (err) => {
    });
    return true;
  }
  submitContactData(data) {
    let endPoint = (data.id) ? 'update' : 'create';
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/contacts/' + endPoint, data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getContacts();
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.modalService.dismissAll();
      //this.closeModal(modalRef);
    }, (err) => {
      this.toastr.error(err.error.message, "Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });
  }
  selectStatus(event) {
    (event != null) ? this.isSelectedStatus = true : this.isSelectedStatus = false;
    this.editStatus = this.statuses.filter(function (arr) { return arr.id == event })[0];// this.statuses[event];
  }
  selectFlag(event) {
    (event != null) ? this.isSelectedFlag = true : this.isSelectedFlag = false;
    this.editFlag = this.flags.filter(function (arr) { return arr.id == event })[0];
  }
  openFlagModal(content) {
    this.flagModelRef = [];
    //this.modelName='customers';
    const modalRef = this.modalService.open(content, { size: 'xl', centered: true });
    this.flagDetails = this.editFlag;
    this.flagModelRef.push(modalRef);
    this.closeModal(modalRef);
    //console.log("open flag model =====>",this.flagDetails);
  }
  closeModal(modalRef) {
    modalRef.result.then((data) => {
    }, (reason) => {
      if (reason != 0 && reason != 'Cross  click') {
        this.getFlag();
        this.getStatus();
      }

    });
  }
  openStatusModal(content) {
    this.statusModelRef = [];
    //this.modelName='follow-ups';
    const modalRef = this.modalService.open(content, { size: 'xl', centered: true });
    this.statusDetails = this.editStatus;
    this.statusModelRef.push(modalRef);
    this.closeModal(modalRef);
  }
  flagManagementType(link) {
    this.modalService.dismissAll();
    this.router.navigateByUrl(link);
  }

}
