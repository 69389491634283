import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-modal-monthly-availability',
  templateUrl: './modal-monthly-availability.component.html',
  styleUrls: ['./modal-monthly-availability.component.css']
})
export class ModalMonthlyAvailabilityComponent implements OnInit {
  @Input() todayDate: any;
  @Input() isSubmittedMonthlyAvailability: any;
  weekMonthlyAvailabilityForm: FormGroup;
  number_of_hours: any = [];
  nowTodayDate: any = '';
  isCount: any = 1;
  weeksArray: any = [];
  formData: any = [];
  arr: any = [];
  newData: any = [];
  response: any = [];
  checkUncheckCheckArr: any = [];
  employee_id: any = '';
  isDisabled: boolean = true;
  isArray: any = [];

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer
  ) {
    this.employee_id = this.route.snapshot.params['id'];

    this.weekMonthlyAvailabilityForm = this.formBuilder.group({
      id: [''],
      week_number_of_hours: [''],
      comments: [''],
      sunday: [],
      useremail: this.formBuilder.array([]),
    });
  }

  ngOnInit(): void {
    this.init();
    this.nowTodayDate = new Date().toISOString().split('T')[0];
    this.todayDate = (this.todayDate) ? this.todayDate : this.nowTodayDate;
    for (var x = 0; x <= 100; x++) {
      if (x === 0 || x % 4 === 0) {
        this.number_of_hours.push(x);
      }
    }
    const date = new Date();
    this.monthlyAvailabilityData();
    this.checkUncheckCheckBox();

  }
  init(): void {

  }
  submitWeekMonthlyAvailabilityForm(data) {

    let start_datetime = this.updateDate(1);
    let end_datetime = this.updateDate(7);
    const d = new Date(this.todayDate);
    d.setDate(d.getDate() + 7);
    this.todayDate = new Date(new Date(d)).toISOString();
    this.newData.numberOfHours = data.week_number_of_hours;
    this.newData.comment = data.comments;
    let formdata = { user_id: this.employee_id, start_datetime: start_datetime, end_datetime: end_datetime, data: this.newData }
    this.isArray.push(formdata);
    this.isCount++;
    this.monthlyAvailabilityData();
    if (this.isCount > 6) {
      this.isSubmittedMonthlyAvailability = true;
    }
  }
  monthlyAvailabilityData() {
    this.newData = {
      startDate: this.updateDate(1),
      endDate: this.updateDate(7),
      displayStartDate: this.updateDays(1),
      displayEndDate: this.updateDays(7),
      comment: "",
      numberOfHours: "0",
      days: [{
        date: this.updateDate(1),
        values: [
          {
            value: false,
            name: "Morning (8)"
          },
          {
            value: false,
            name: "Afternoon (8)"
          },
          {
            value: false,
            name: "Night (8)"
          },
          {
            value: false,
            name: "Twelve (12)"
          },
          {
            value: false,
            name: "Double (16)"
          },
          {
            value: false,
            name: "Not Available"
          }
        ],
        dayName: "Sunday",
        displayDate: this.updateDays(1)
      },
      {
        date: this.updateDate(2),
        values: [
          {
            value: false,
            name: "Morning (8)"
          },
          {
            value: false,
            name: "Afternoon (8)"
          },
          {
            value: false,
            name: "Night (8)"
          },
          {
            value: false,
            name: "Twelve (12)"
          },
          {
            value: false,
            name: "Double (16)"
          },
          {
            value: false,
            name: "Not Available"
          }
        ],
        dayName: "Monday",
        displayDate: this.updateDays(2)
      },
      {
        date: this.updateDate(3),
        values: [
          {
            value: false,
            name: "Morning (8)"
          },
          {
            value: false,
            name: "Afternoon (8)"
          },
          {
            value: false,
            name: "Night (8)"
          },
          {
            value: false,
            name: "Twelve (12)"
          },
          {
            value: false,
            name: "Double (16)"
          },
          {
            value: false,
            name: "Not Available"
          }
        ],
        dayName: "Tuesday",
        displayDate: this.updateDays(3)
      },
      {
        date: this.updateDate(4),
        values: [
          {
            value: false,
            name: "Morning (8)"
          },
          {
            value: false,
            name: "Afternoon (8)"
          },
          {
            value: false,
            name: "Night (8)"
          },
          {
            value: false,
            name: "Twelve (12)"
          },
          {
            value: false,
            name: "Double (16)"
          },
          {
            value: false,
            name: "Not Available"
          }
        ],
        dayName: "Wednesday",
        displayDate: this.updateDays(4)
      },
      {
        date: this.updateDate(5),
        values: [
          {
            value: false,
            name: "Morning (8)"
          },
          {
            value: false,
            name: "Afternoon (8)"
          },
          {
            value: false,
            name: "Night (8)"
          },
          {
            value: false,
            name: "Twelve (12)"
          },
          {
            value: false,
            name: "Double (16)"
          },
          {
            value: false,
            name: "Not Available"
          }
        ],
        dayName: "Thursday",
        displayDate: this.updateDays(5)
      },
      {
        date: this.updateDate(6),
        values: [
          {
            value: false,
            name: "Morning (8)"
          },
          {
            value: false,
            name: "Afternoon (8)"
          },
          {
            value: false,
            name: "Night (8)"
          },
          {
            value: false,
            name: "Twelve (12)"
          },
          {
            value: false,
            name: "Double (16)"
          },
          {
            value: false,
            name: "Not Available"
          }
        ],
        dayName: "Friday",
        displayDate: this.updateDays(6)
      },
      {
        date: this.updateDate(7),
        values: [
          {
            value: false,
            name: "Morning (8)"
          },
          {
            value: false,
            name: "Afternoon (8)"
          },
          {
            value: false,
            name: "Night (8)"
          },
          {
            value: false,
            name: "Twelve (12)"
          },
          {
            value: false,
            name: "Double (16)"
          },
          {
            value: false,
            name: "Not Available"
          }
        ],
        dayName: "Saturday",
        displayDate: this.updateDays(7)
      },
      ],
    };
  }
  checkUncheckCheckBox() {
    this.checkUncheckCheckArr = {
      days: [{
        values: [
          {
            value: false,
            name: "Morning (8)",
            isdisabled: false,

          },
          {
            value: false,
            name: "Afternoon (8)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Night (8)",
            isdisabled: false,
          },

          {
            value: false,
            name: "Double (16)",
            isdisabled: true,
          },
          {
            value: false,
            name: "Not Available",
            isdisabled: false,
          }
        ],

      },
      {
        values: [
          {
            value: false,
            name: "Morning (8)",
            isdisabled: false,

          },
          {
            value: false,
            name: "Afternoon (8)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Night (8)",
            isdisabled: false,
          },

          {
            value: false,
            name: "Double (16)",
            isdisabled: true,
          },
          {
            value: false,
            name: "Not Available",
            isdisabled: false,
          }
        ],

      },
      {
        values: [
          {
            value: false,
            name: "Morning (8)",
            isdisabled: false,

          },
          {
            value: false,
            name: "Afternoon (8)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Night (8)",
            isdisabled: false,
          },

          {
            value: false,
            name: "Double (16)",
            isdisabled: true,
          },
          {
            value: false,
            name: "Not Available",
            isdisabled: false,
          }
        ],

      },
      {
        values: [
          {
            value: false,
            name: "Morning (8)",
            isdisabled: false,

          },
          {
            value: false,
            name: "Afternoon (8)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Night (8)",
            isdisabled: false,
          },

          {
            value: false,
            name: "Double (16)",
            isdisabled: true,
          },
          {
            value: false,
            name: "Not Available",
            isdisabled: false,
          }
        ],

      },
      {
        values: [
          {
            value: false,
            name: "Morning (8)",
            isdisabled: false,

          },
          {
            value: false,
            name: "Afternoon (8)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Night (8)",
            isdisabled: false,
          },

          {
            value: false,
            name: "Double (16)",
            isdisabled: true,
          },
          {
            value: false,
            name: "Not Available",
            isdisabled: false,
          }
        ],

      },
      {
        values: [
          {
            value: false,
            name: "Morning (8)",
            isdisabled: false,

          },
          {
            value: false,
            name: "Afternoon (8)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Night (8)",
            isdisabled: false,
          },

          {
            value: false,
            name: "Double (16)",
            isdisabled: true,
          },
          {
            value: false,
            name: "Not Available",
            isdisabled: false,
          }
        ],

      },
      {
        values: [
          {
            value: false,
            name: "Morning (8)",
            isdisabled: false,

          },
          {
            value: false,
            name: "Afternoon (8)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Night (8)",
            isdisabled: false,
          },

          {
            value: false,
            name: "Double (16)",
            isdisabled: true,
          },
          {
            value: false,
            name: "Not Available",
            isdisabled: false,
          }
        ],

      },
      ],
    };
  }
  updateDays(day) {
    const d = new Date(this.todayDate);
    d.setDate(d.getDate() + day);
    return (d.getMonth() + 1) + '/' + d.getDate();
  }
  updateDate(day) {
    const d = new Date(this.todayDate);
    d.setDate(d.getDate() + day);
    return new Date(new Date(d)).toISOString();
  }
  onChange(index, isChecked: boolean, key) {
    let newData = this.newData.days[index];
    newData.values[key].value = isChecked;
    if (key == 0 || key == 1 || key == 2) {

      let checkUncheckCheckArr = this.checkUncheckCheckArr.days[index];
      if (this.newData.days[index].values[0].value || this.newData.days[index].values[1].value || this.newData.days[index].values[2].value) {
        checkUncheckCheckArr.values[3].isdisabled = false;
        checkUncheckCheckArr.values[4].isdisabled = true;
      } else {
        checkUncheckCheckArr.values[3].isdisabled = true;
        checkUncheckCheckArr.values[4].isdisabled = false;
      }
    }
  }
  monthlyAvailabilityPrevious() {
    const d = new Date(this.todayDate);
    d.setDate(d.getDate() - 7);
    this.todayDate = new Date(new Date(d)).toISOString();
    this.monthlyAvailabilityData();
    this.isCount--;
  }
}
