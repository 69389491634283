<div class="col-xl-12 box-margin">
    <div class="card md-0">
        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
            <div>
                <ul class="nav nav-tabs mb-0">
                    <li class="nav-item">
                        <a class="nav-link active" href="javascript:void(0)" data-toggle="tab" aria-controls="overview"
                            aria-selected="true" (click)="Select_view_reports('billable')"
                            value="Billable Hours">Billable Hours</a>

                    <li class="nav-item">
                        <a class="nav-link mr-0" href="javascript:void(0)" data-toggle="tab" aria-controls="section"
                            aria-selected="false" (click)="Select_view_reports('expiring')">Expiring Availability</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0" href="javascript:void(0)" data-toggle="tab" aria-controls="registraion"
                            (click)="Select_view_reports('open')" aria-selected=" false">Open
                            Availability</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0" href="javascript:void(0)" data-toggle="tab" aria-controls="registraion"
                            (click)="Select_view_reports('status')" aria-selected="false">Employees by Status</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0" href="javascript:void(0)" data-toggle="tab" aria-controls="registraion"
                            (click)="Select_view_reports('Upcoming')" aria-selected="false">Upcoming Schedule</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0" href="javascript:void(0)" data-toggle="tab" aria-controls="registraion"
                            (click)="Select_view_reports('EmpFacility')" aria-selected="false">Employees by Facility</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0" href="javascript:void(0)" data-toggle="tab" aria-controls="registraion"
                            (click)="Select_view_reports('Timesheet')" aria-selected="false">Timesheet Report</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0" href="javascript:void(0)" data-toggle="tab" aria-controls="registraion"
                            (click)="Select_view_reports('Activaions')" aria-selected="false">Activations Report</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0" href="javascript:void(0)" data-toggle="tab" aria-controls="registraion"
                            (click)="Select_view_reports('Activity')" aria-selected="false">Activity Report</a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="card-header bg-grey">
            <strong>
                <h5>{{value}}</h5>
            </strong>
        </div>
        <div class="main-content">
            <div class="container-fluid">
                <div class="row">


                    <div class="col-md-4">
                        <!-- Billable Hours start -->
                        <div class="row">
                            <div class="form-group col-md-12" *ngIf="isBillable">
                                <form [formGroup]="billableForm" (ngSubmit)="submitBillableData(billableForm.value)">
                                    <label class="ml-3">Filter by Cleint(s)</label>
                                    <div class="form-group  d-flex mb-12 ml-3">
                                        <div class="form-check pl-0 mb-2" (click)="Billable_box(0)">
                                            <label class="form-check-label button-radio-btn">
                                                <input type="radio" class="form-check-input radio-btn-button"
                                                    formControlName="billableData" [checked]="true" value="all">
                                                <div class="layer"></div>
                                                <div class="button"><span>All</span></div>
                                            </label>
                                        </div>
                                        <div class="form-check pl-0 mb-2" (click)="Billable_box(1)">
                                            <label class="form-check-label button-radio-btn">
                                                <input type="radio" class="form-check-input radio-btn-button"
                                                    formControlName="billableData" value="individual">
                                                <div class="layer"></div>
                                                <div class="button"><span>Individual</span></div>
                                            </label>
                                        </div>
                                        <div class="form-check pl-0 mb-2">
                                            <label class="form-check-label button-radio-btn" (click)="Billable_box(2)">
                                                <input type="radio" class="form-check-input radio-btn-button"
                                                    formControlName="billableData" value="multiple">
                                                <div class="layer"></div>
                                                <div class="button"><span>Multiple</span></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12" *ngIf="isBillable_Individual_Client">
                                        <label>Client</label>
                                        <ng-select placeholder="Select" clearAllText="Clear"
                                            formControlName="clientBillable">
                                            <ng-option value="client1">Cleint1</ng-option>
                                            <ng-option value="client2">Cleint2</ng-option>
                                            <ng-option value="client3">Cleint3</ng-option>
                                            <ng-option value="client4">Cleint4</ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="form-group col-md-12" *ngIf="isBillable_Multiple_Client">
                                        <label>Client(s)</label>
                                        <ng-select placeholder="Select" clearAllText="Clear"
                                            formControlName="clientSBillable">
                                            <ng-option value="client11">Cleint11</ng-option>
                                            <ng-option value="client12">Cleint12</ng-option>
                                            <ng-option value="client13">Cleint13</ng-option>
                                            <ng-option value="client14">Cleint14</ng-option>
                                        </ng-select>
                                    </div>
                                    <!-- <div class="form-group col-md-12"> -->
                                    <label class="ml-3">Filter by Employee(s)</label>
                                    <div class="form-group  d-flex mb-20 ml-3">
                                        <div class="form-check pl-0 mb-2" (click)="Billable_Emp(0)">
                                            <label class="form-check-label button-radio-btn">
                                                <input type="radio" class="form-check-input radio-btn-button"
                                                    formControlName="billableEmp" value="all" checked>
                                                <div class="layer"></div>
                                                <div class="button"><span>All</span></div>
                                            </label>
                                        </div>
                                        <div class="form-check pl-0 mb-2" (click)="Billable_Emp(1)">
                                            <label class="form-check-label button-radio-btn">
                                                <input type="radio" class="form-check-input radio-btn-button"
                                                    formControlName="billableEmp" value="individual">
                                                <div class="layer"></div>
                                                <div class="button"><span>Individual</span></div>
                                            </label>
                                        </div>
                                        <div class="form-check pl-0 mb-2">
                                            <label class="form-check-label button-radio-btn" (click)="Billable_Emp(2)">
                                                <input type="radio" class="form-check-input radio-btn-button"
                                                    formControlName="billableEmp" value="multiple">
                                                <div class="layer"></div>
                                                <div class="button"><span>Multiple</span></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12" *ngIf="isBillable_Individual_Emp">
                                        <label>Employee</label>
                                        <ng-select placeholder="Select" clearAllText="Clear"
                                            class="form-control-ng rounded-0" formControlName="empBillable">
                                            <ng-option value="emp1">Emp1</ng-option>
                                            <ng-option value="emp2">Emp2</ng-option>
                                            <ng-option value="emp3">Emp3</ng-option>
                                            <ng-option value="emp4">Emp4</ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="form-group col-md-12" *ngIf="isBillable_Multiple_Emp">
                                        <label>Employee(s)</label>
                                        <ng-select placeholder="Select" clearAllText="Clear"
                                            class="form-control-ng rounded-0" formControlName="empSBillable">
                                            <ng-option value="emp11">Emp11</ng-option>
                                            <ng-option value="emp12">Emp12</ng-option>
                                            <ng-option value="emp13">Emp13</ng-option>
                                            <ng-option value="emp14">Emp14</ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label class="col-form-label text-light-black ">Start Date</label>
                                        <input class="form-control rounded-0 form-control-md" type="date"
                                            formControlName="startDate">
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label class="col-form-label text-light-black ">End Date</label>
                                        <input class="form-control rounded-0 form-control-md" type="date"
                                            formControlName="endDate">
                                    </div>
                                    <div class="btn-modal-group text-center col-md-12">
                                        <button type="submit" id="submit-btn"
                                            class="btn btn-success btn-sm">SUBMIT</button>
                                    </div>
                                </form>
                            </div>

                        </div>

                        <!-- End Billable Box -->


                        <!-- Expiring Box -->
                        <div class="form-group col-md-12" *ngIf="isExpiring">
                            <form [formGroup]="expiring" (ngSubmit)="submitExpiring(expiring.value)">
                                <label class="ml-3">Filter by Employee(s)</label>
                                <div class="form-group  d-flex mb-4 ml-3">
                                    <div class="form-check pl-0 mb-2" (click)="Expiring_Box(0)">
                                        <label class="form-check-label button-radio-btn">
                                            <input type="radio" class="form-check-input radio-btn-button"
                                                formControlName="expiringData" value="all" checked>
                                            <div class="layer"></div>
                                            <div class="button"><span>All</span></div>
                                        </label>
                                    </div>
                                    <div class="form-check pl-0 mb-2" (click)="Expiring_Box(1)">
                                        <label class="form-check-label button-radio-btn">
                                            <input type="radio" class="form-check-input radio-btn-button"
                                                formControlName="expiringData" value="individual">
                                            <div class="layer"></div>
                                            <div class="button"><span>Individual</span></div>
                                        </label>
                                    </div>
                                    <div class="form-check pl-0 mb-2">
                                        <label class="form-check-label button-radio-btn" (click)="Expiring_Box(2)">
                                            <input type="radio" class="form-check-input radio-btn-button"
                                                formControlName="expiringData" value="multiple">
                                            <div class="layer"></div>
                                            <div class="button"><span>Multiple</span></div>
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group col-md-12" *ngIf="isExpiring_Individual_Emp">
                                    <label>Employee</label>
                                    <ng-select placeholder="Select" clearAllText="Clear"
                                        class="form-control-ng rounded-0" formControlName="employee">
                                        <ng-option value="emp1">Emp1</ng-option>
                                        <ng-option value="mp2">Emp2</ng-option>
                                        <ng-option value="emp3">Emp3</ng-option>
                                        <ng-option value="emp4">Emp4</ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-12" *ngIf="isExpiring_Multiple_Emp">
                                    <label>Employee(s)</label>
                                    <ng-select placeholder="Select" clearAllText="Clear"
                                        class="form-control-ng rounded-0" formControlName="empExpiring">
                                        <ng-option value="emp11">Emp11</ng-option>
                                        <ng-option value="emp12">Emp12</ng-option>
                                        <ng-option value="emp13">Emp13</ng-option>
                                        <ng-option value="emp14">Emp14</ng-option>
                                    </ng-select>
                                </div>
                                <div class="btn-modal-group text-center">
                                    <button type="submit" id="submit-btn" class="btn btn-success btn-sm">SUBMIT</button>
                                </div>
                            </form>
                        </div>
                        <!-- End Expiring Box -->

                        <!-- Open Availability Box  *ngIf="isOpen"  (click)="Open_Availability(0) *ngIf= -->
                        <div class="form-group col-md-12" *ngIf="isOpen">
                            <form [formGroup]="openAvailability"
                                (ngSubmit)="submitOpenAvailability(openAvailability.value)">
                                <label class="ml-3">Filter by Employee(s)</label>
                                <div class="form-group  d-flex mb-20 ml-3">
                                    <div class="form-check pl-0 mb-2" (click)="Open_Availability(0)">
                                        <label class="form-check-label button-radio-btn">
                                            <input type="radio" class="form-check-input radio-btn-button"
                                                formControlName="openData" value="all" checked>
                                            <div class="layer"></div>
                                            <div class="button"><span>All</span></div>
                                        </label>
                                    </div>
                                    <div class="form-check pl-0 mb-2" (click)="Open_Availability(1)">
                                        <label class="form-check-label button-radio-btn">
                                            <input type="radio" class="form-check-input radio-btn-button"
                                                formControlName="openData" value="individual">
                                            <div class="layer"></div>
                                            <div class="button"><span>Individual</span></div>
                                        </label>
                                    </div>
                                    <div class="form-check pl-0 mb-2">
                                        <label class="form-check-label button-radio-btn" (click)="Open_Availability(2)">
                                            <input type="radio" class="form-check-input radio-btn-button"
                                                formControlName="openData" value="multiple">
                                            <div class="layer"></div>
                                            <div class="button"><span>Multiple</span></div>
                                        </label>
                                    </div>
                                </div>

                                <div class="form-group col-md-12" *ngIf="isOpen_Availability_Individual_Emp">
                                    <label>Employee</label>
                                    <ng-select placeholder="Select" formControlName="employee" clearAllText="Clear"
                                        class="form-control-ng rounded-0">
                                        <ng-option value="emp1">Emp1</ng-option>
                                        <ng-option value="emp2">Emp2</ng-option>
                                        <ng-option value="emp3">Emp3</ng-option>
                                        <ng-option value="emp4">Emp4</ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-12" *ngIf="isOpen_Availability_Multiple_Emp">
                                    <label>Employee(s)</label>
                                    <ng-select placeholder="Select" clearAllText="Clear"
                                        class="form-control-ng rounded-0" formControlName="employees">
                                        <ng-option value="emp11">Emp11</ng-option>
                                        <ng-option value="emp12">Emp12</ng-option>
                                        <ng-option value="emp13">Emp13</ng-option>
                                        <ng-option value="emp14">Emp14</ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-12">
                                    <label class="col-form-label text-light-black ">Start Date</label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="startDate"
                                        type="date">
                                </div>
                                <div class="form-group col-md-12">
                                    <label class="col-form-label text-light-black ">End Date</label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="endDate"
                                        type="date">
                                </div>
                                <div class="btn-modal-group form-group col-md-12 text-center">
                                    <button type="submit" class="btn btn-success">SUBMIT</button>
                                </div>
                            </form>
                        </div>

                        <!-- End Open availablity Box -->
                        <!-- Employee By Status Box -->
                        <div class="form-group col-md-12" *ngIf="isEmpStatus">
                            <form [formGroup]="employeeStatus" (ngSubmit)="submitEmployeeStatus(employeeStatus.value)">
                                <div class="form-group col-md-12">
                                    <p>Filter by Status</p>
                                    <ng-select placeholder="All" clearAllText="Clear" formControlName="filterStatus">
                                        <ng-option value="all">All</ng-option>
                                        <ng-option value="recruiting">Recruiting</ng-option>
                                        <ng-option value="active">Active</ng-option>
                                        <ng-option value="inactive">Inactive</ng-option>
                                        <ng-option value="dNR">DNR</ng-option>
                                    </ng-select>
                                </div>
                                <div class="btn-modal-group text-center form-group col-md-12">
                                    <button type="submit" id="submit-btn" class="btn btn-success btn-sm">SUBMIT</button>
                                </div>
                            </form>
                        </div>
                        <!-- Employee By Status End -->
                        <!-- Upcoming schedual  -->
                        <div class="form-group col-md-12" *ngIf="isUpcoming">
                            <form [formGroup]="upcominSchedule"
                                (ngSubmit)="submitUpcominSchedule(upcominSchedule.value)">
                                <div class="form-group col-md-12">
                                    <label class="col-form-label text-light-black ">Start Date</label>
                                    <input class="form-control rounded-0 form-control-md" type="date"
                                        formControlName="startDate">
                                </div>
                                <div class="form-group col-md-12">
                                    <label class="col-form-label text-light-black ">End Date</label>
                                    <input class="form-control rounded-0 form-control-md" type="date"
                                        formControlName="endDate">
                                </div>

                                <div class="form-group col-md-12">
                                    <p>Select by Client(s)</p>
                                    <ng-select placeholder="Select" clearAllText="Clear"
                                        class="form-control-ng rounded-0" formControlName="clients">
                                        <ng-option value="user1">User1</ng-option>
                                        <ng-option value="user2">User2</ng-option>
                                        <ng-option value="user3">User3</ng-option>
                                        <ng-option value="user4">User4</ng-option>
                                    </ng-select>
                                </div>
                                <div class="btn-modal-group text-center form-group col-md-12">
                                    <button type="submit" id="submit-btn" class="btn btn-success btn-sm">SUBMIT</button>
                                </div>
                            </form>
                        </div>
                        <!-- End Upcoming schedual -->

                        <!-- Employee Facility  -->

                        <div class="form-group col-md-12" *ngIf="isEmpFacility">
                            <form [formGroup]="employeeFacility"
                                (ngSubmit)="submitEmployeeFacility(employeeFacility.value)">
                                <div class="form-group col-md-12">
                                    <label class="col-form-label text-light-black ">Start Date</label>
                                    <input class="form-control rounded-0 form-control-md" type="date"
                                        formControlName="startDate">
                                </div>
                                <div class="form-group col-md-12">
                                    <label class="col-form-label text-light-black ">End Date</label>
                                    <input class="form-control rounded-0 form-control-md" type="date"
                                        formControlName="endDate">
                                </div>
                                <div class="form-group col-md-12">
                                    <label>Filter by Client(s)</label>
                                    <ng-select placeholder="Select" clearAllText="Clear"
                                        class="form-control-ng rounded-0" formControlName="clients">
                                        <ng-option value="user11">User11</ng-option>
                                        <ng-option value="user12">User12</ng-option>
                                        <ng-option value="user13">User13</ng-option>
                                        <ng-option value="user14">User14</ng-option>
                                    </ng-select>
                                    <br>
                                    <label>Filter by Specialties(s)</label>
                                    <ng-select placeholder="Select" clearAllText="Clear"
                                        class="form-control-ng rounded-0" formControlName="specialties">
                                        <ng-option value="user21">User21</ng-option>
                                        <ng-option value="user22">User22</ng-option>
                                        <ng-option value="user23">User23</ng-option>
                                        <ng-option value="user24">User24</ng-option>
                                    </ng-select>
                                </div>
                                <div class="btn-modal-group text-center form-group col-md-12">
                                    <button type="submit" id="submit-btn" class="btn btn-success btn-sm">SUBMIT</button>
                                </div>
                            </form>
                        </div>
                        <!-- Employee Facility End -->

                        <!-- Timesheet Reports -->
                        <div class="form-group col-md-12" *ngIf="isTimesheet">
                            <form [formGroup]="timesheet" (ngSubmit)="submitTimesheetData(timesheet.value)">
                                <div class="form-group col-md-12">
                                    <label class="col-form-label text-light-black ">Start Date</label>
                                    <input class="form-control rounded-0 form-control-md" type="date"
                                        formControlName="startDate">
                                </div>
                                <div class="form-group col-md-12">
                                    <label class="col-form-label text-light-black ">End Date</label>
                                    <input class="form-control rounded-0 form-control-md" type="date"
                                        formControlName="endDate">
                                </div>
                                <div class="form-group col-md-12">
                                    <label>Include only new timesheets?</label>
                                    <div class="new-checkbox" *ngIf="!isNo">
                                        <span style="color:rgb(13, 182, 22);"> No </span>
                                        <label class="switch md-trigger md-setperspective" data-modal="modal-18"
                                            (change)="timesheetsChange(0)">
                                            <input type="checkbox" value="0" />
                                            <span class="slider round"></span>
                                        </label>
                                        <span> Yes</span>
                                    </div>
                                    <div class="new-checkbox" *ngIf="isNo">
                                        <span> No </span>
                                        <label class="switch md-trigger md-setperspective" data-modal="modal-18"
                                            (change)="timesheetsChange(1)">
                                            <input type="checkbox" value="1" checked />
                                            <span class="slider round"></span>
                                        </label>
                                        <span style="color:rgb(13, 182, 22);"> Yes </span>
                                    </div>
                                </div>
                                <div class="btn-modal-group text-center form-group col-md-12">
                                    <button type="submit" id="submit-btn" class="btn btn-success btn-sm">SUBMIT</button>
                                </div>
                            </form>
                        </div>

                        <!-- Timesheet Reports End -->
                        <!-- Activaions Reports -->
                        <div class="form-group col-md-12" *ngIf="isActivaions">
                            <form [formGroup]="activaions" (ngSubmit)="submitActivaionsData(activaions.value)">
                                <div class="form-group col-md-12">
                                    <label class="col-form-label text-light-black ">Start Date</label>
                                    <input class="form-control rounded-0 form-control-md" type="date"
                                        formControlName="startDate">
                                </div>
                                <div class="form-group col-md-12">
                                    <label class="col-form-label text-light-black ">End Date</label>
                                    <input class="form-control rounded-0 form-control-md" type="date"
                                        formControlName="endDate">
                                </div>
                                <div class="form-group col-md-12 text-center">
                                    <button type="submit" class="btn btn-success btn-sm">SUBMIT</button>
                                </div>
                            </form>
                        </div>

                        <!-- Activaions Reports End -->

                        <!-- Activity Reports -->

                        <div class="form-group col-md-12" *ngIf="isActivity">
                            <form [formGroup]="activity" (ngSubmit)="submitActivity(activity.value)">
                                <div class="form-group col-md-12">
                                    <label class="col-form-label text-light-black ">Start Date</label>
                                    <input class="form-control rounded-0 form-control-md" type="date"
                                        formControlName="start_date">
                                </div>
                                <div class="form-group col-md-12">
                                    <label class="col-form-label text-light-black ">End Date</label>
                                    <input class="form-control rounded-0 form-control-md" type="date"
                                        formControlName="end_date">
                                </div>
                                <div class="form-group col-md-12 text-center">
                                    <button type="submit" class="btn btn-success btn-sm">SUBMIT</button>
                                </div>
                            </form>
                        </div>

                    </div>
                    <!--Activity Reports end  -->
                    <div class="col-md-8 box-margin ">
                        <div class="card">
                            <div class="card-body bg-gray text-center" style="height:450px;padding-top: 200px;">
                                Your Reports Will Display here
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>