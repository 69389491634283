<div class="main-content">
    <div class="container-fluid">
        <div class="alert alert-danger" role="alert" *ngIf="employee.dnr==1">
            This employee has been moved into the DNR status.
        </div>
        <div class="row">
            <div class="col-xl-4 height-card box-margin">
                <div class="card">
                    <div
                        class="card-header pt-10 pb-10 bg-green user-area d-flex align-items-center justify-content-between">
                        <h6 class="card-title mb-0" *ngIf="employee.dnr==1">Status: <span class="text-dark">DNR</span>
                        </h6>
                        <h6 class="card-title mb-0" *ngIf="employee.pipeline==1 && employee.dnr==0">Status: <span
                                class="text-dark">Recruiting</span></h6>
                        <h6 class="card-title mb-0"
                            *ngIf="employee.dnr==0  && employee.active==0 && employee.pipeline==0">Status: <span
                                class="text-dark">Inactive</span></h6>
                        <h6 class="card-title mb-0"
                            *ngIf="employee.dnr==0  && employee.active==1 && employee.pipeline==0">Status: <span
                                class="text-dark">Active</span></h6>
                    </div>
                    <div class="card-body">
                        <div class="profile-thumb-contact mt-20 text-center">
                            <div class="avatar-area">
                                <img *ngIf="employee.profile_image==''" class="rounded thumb-2-xl border"
                                    (click)="selectImage()" id="showImage" src="assets/img/noimg.jpg" alt="">
                                <img *ngIf="employee.profile_image!=''" class="rounded thumb-2-xl border"
                                    (click)="selectImage()" id="showImage" src="{{filePath+employee.profile_image}}"
                                    alt="">
                            </div>

                            <form [formGroup]="profileImageForm">
                                <input type="file" hidden id="profile_image" accept="image/*"
                                    (change)="uploadImage($event)" formControlName="profile_image">
                            </form>


                            <h5 class="mt-15 font-18 font-weight-600 text-light-black">{{employee.name}}</h5>
                            <p class="mb-3 text-dark" *ngIf="employee.specialty">{{employee.specialty.name}}</p>
                            <!-- <p class="mb-4 text-blue">Since 12/02/2021</p> -->
                            <div class="profile-btn">
                                <a href="javascript:void(0)"
                                    class="action-btn bg-dark-blue font-16 mb-4 mr-2 text-white"
                                    (click)="employeeActivateModal(deactivateModal,0)"
                                    *ngIf="employee.active==1 && employee.dnr==0">Deactivate</a>
                                <a href="javascript:void(0)"
                                    class="action-btn bg-dark-blue font-16 mb-4 mr-2 text-white"
                                    (click)="employeeActivateModal(activateModal,1)"
                                    *ngIf="employee.active==0 && employee.dnr==0">Activate</a>
                                <a href="javascript:void(0)"
                                    class="action-btn bg-danger btn-sm font-16 mb-4 mr-2 text-white"
                                    (click)="employeeDNRModal(dnrProfile,1)" *ngIf="employee.dnr==0">DNR</a>
                                <a href="javascript:void(0)" class="action-btn bg-danger font-16 mb-4 mr-2 text-white"
                                    (click)="employeeDNRModal(restoreDNRProfile,0)" *ngIf="employee.dnr==1">Restore</a>
                                <a [routerLink]="['/dashboard/badge/'+employee.id]"
                                    class="action-btn bg-orange font-16 mb-4 text-white">Edit Badge</a>
                            </div>
                        </div>
                        <div class="personal-information mt-10" *ngIf="employee.dnr==0">
                            <div class="name-text">
                                <h6 class="font-18 text-center text-light-black mt-3">Recruiting</h6>
                                <div class="new-checkbox text-center" *ngIf="employee.pipeline==1">
                                    <!-- Rounded switch -->
                                    <label class="switch">
                                        <input type="checkbox" checked (click)="changePipeline(employee,0)">
                                        <span class="slider round"></span>
                                    </label>
                                </div>

                                <div class="new-checkbox text-center" *ngIf="employee.pipeline==0"
                                    (click)="changePipeline(employee,1)">
                                    <!-- Rounded switch -->
                                    <label class="switch">
                                        <input type="checkbox">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-8 height-card box-margin">
                <div class="card">

                    <div *ngIf="isEmployeeDetails"
                        class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                        <h6 class="card-title mb-0">Employee Details</h6>
                        <div class="btn-group">
                            <button class="action-btn bg-dark-blue mr-2" *ngIf="employee.pipeline==0">Schedule</button>
                            <button class="action-btn bg-orange mr-2" (click)="openNote(addNote,employee)">Add a
                                Note</button>
                            <button class="action-btn bg-dark-green"
                                (click)="editEmployeeModal(employeeFormModal,employee)"><img
                                    src="assets/img/svg-icon/edit-white.svg" width="20" /></button>
                        </div>
                    </div>
                    <div *ngIf="isEmployeeFiles"
                        class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                        <h6 class="card-title mb-0"> Employee Files</h6>
                        <div class="btn-group">
                            <button class="action-btn bg-orange mr-2"
                                (click)="openResetHirePacket(deleteResetHirePacket)">
                                Reset Hire Packet</button>
                            <button class="action-btn bg-orange mr-2" (click)="openResetTraining(deleteResetTraining)">
                                Reset Training</button>
                            <button class="action-btn bg-orange mr-2" (click)="openUploadNewFile(emailFileFormModal)">
                                Email File(s)</button>
                            <button class="action-btn bg-orange mr-2"
                                (click)="openUploadNewFile(uploadNewFileFormModal)"> Add a File</button>
                        </div>
                    </div>
                    <div *ngIf="isEmployeePay"
                        class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                        <h6 class="card-title mb-0"> Rates</h6>
                        <div class="btn-group">
                            <button class="action-btn bg-orange mr-2" (click)="updateEmployeeRates()"> Update
                                Rates</button>
                        </div>
                    </div>
                    <div *ngIf="isEmployeeAvailability || isEmployeeWeekMonthlyAvailability || isSubmittedMonthlyAvailability"
                        class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                        <h6 class="card-title mb-0">Monthly Availability</h6>
                        <div class="btn-group">
                            <button class="action-btn bg-dark-blue mr-2" (click)="openEmailRequest(emailRequestModal)">
                                Email Request</button>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <div class="dashboard-dropdown">
                                    <div class="dropdown">
                                        <button
                                            class="bg-transparent border-0 font-weight-600 text-green dropdown-toggle"
                                            type="button" id="dashboardDropdown50" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                            Details
                                        </button>
                                        <div class="dropdown-menu text-center dropdown-menu-right"
                                            aria-labelledby="dashboardDropdown50" x-placement="bottom-end">
                                            <a class="dropdown-item" href="javascript:void(0);"
                                                (click)="employeeDetails()">Details</a>
                                            <a class="dropdown-item" href="javascript:void(0);"
                                                (click)="employeeFiles()">Files</a>
                                            <a class="dropdown-item" href="javascript:void(0);"
                                                (click)="employeePay()">Pay</a>
                                            <a class="dropdown-item" href="javascript:void(0);"
                                                (click)="employeeAvailability()">Availability</a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="isEmployeeDetails">
                            <div class="col-md-6">
                                <div class="personal-information mt-10">
                                    <div class="name-text">
                                        <h6 class="font-16 text-black">Name: <span
                                                class="text-gray">{{employee.name}}</span></h6>
                                        <h6 class="font-16 text-black">Phone: <span
                                                class="text-gray">{{employee.phone}}</span></h6>
                                        <h6 class="font-16 text-black">Email: <span
                                                class="text-gray">{{employee.email}}</span></h6>
                                        <h6 class="font-16 text-black">Address: <span
                                                class="text-gray">{{employee.address_line_1 + employee.city +
                                                employee.state + employee.zip}}</span></h6>

                                    </div>
                                </div>
                                <div class="map mt-20">
                                    <iframe [src]="getMap(employee)" class="gmaps_p" height="200"></iframe>
                                </div>
                                <div class="view_map mt-20">
                                    <a target="_blank"
                                        href="http://maps.google.com/maps?q={{employee.address_line_1 + employee.city + ' '+ employee.state +' ' + employee.zip}}"
                                        class="text-blue font-weight-600 font-16">Full Map View</a>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="personal-information mt-10">
                                    <div class="name-text">

                                        <h6 class="font-16 text-black">SSN: <span
                                                class="text-gray">{{employee.ssn}}</span></h6>
                                        <h6 class="font-16 text-black">Birthday: <span
                                                class="text-gray">{{employee.birthdate | date : 'MM/dd/yyyy'}}</span>
                                        </h6>
                                        <h6 class="font-16 text-black">Hire Date: <span
                                                class="text-gray">{{employee.hire_date | date : 'MM/dd/yyyy'}}</span>
                                        </h6>
                                        <h6 class="font-16 text-black">Termination Date: <span
                                                class="text-gray">{{employee.termination_date | date :
                                                'MM/dd/yyyy'}}</span></h6>
                                        <div class="profile-table bg-gray notes_in_profile">
                                            <table class="table mb-0">

                                                <tbody>
                                                    <ng-container *ngIf="notes.length > 0">
                                                        <tr *ngFor="let note of notes">
                                                            <td>
                                                                <h3 class="text-black m-0 font-16"> <span
                                                                        *ngIf="note.user">{{note.user.name}}-</span>
                                                                    {{note.created_at | date : 'MM/dd/yyyy'}}</h3>
                                                                <p class="text-gray m-0 font-14">{{note.note}}</p>
                                                            </td>
                                                            <td class="text-right">
                                                                <a href="javascript:void(0)"
                                                                    class="mr-2 action-btn bg-edit profile_page_btn"
                                                                    (click)="editNotes(addNote,note)"><img
                                                                        src="assets/img/svg-icon/edit.svg"
                                                                        width="20"></a><br /><br />
                                                                <a href="javascript:void(0)"
                                                                    class="action-btn bg-red profile_page_btn"
                                                                    (click)="deleteNotesModal(notesDeleteModal,note)"><img
                                                                        src="assets/img/svg-icon/delete.svg"
                                                                        width="20"></a>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="mt-4" [innerHTML]="employee.static_html_block"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="isEmployeeFiles">
                            <div *ngFor="let stf_files of allStfCustomFiles;let i=index" class="col-md-4 mt-3">
                                <div class="personal-information-2 mt-10">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <h6 *ngIf="stf_files.stf_model_custom_files==null"
                                                title="Exp: (No date available)">{{stf_files.field_name}} </h6>
                                            <h6 *ngIf="stf_files.stf_model_custom_files!==null"
                                                title="Exp: ( {{stf_files.stf_model_custom_files.expiration}} )">
                                                {{stf_files.field_name}} </h6>
                                        </div>
                                        <div>
                                            <a *ngIf="stf_files.stf_model_custom_files!==null"
                                                href="javascript:void(0);"
                                                (click)="openEmployeeFilesPopUp(deleteEmployeeFiles,stf_files.stf_model_custom_files.id)"><i
                                                    class="fa fa-times" aria-hidden="true"></i></a>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-center"
                                        *ngIf="stf_files.stf_model_custom_files!==null">
                                        <button class="action-btn bg-dark-blue mr-2"
                                            (click)="openEmployeeFiles(EmployeeFilesModal,stf_files.stf_model_custom_files.path)">Preview
                                        </button>
                                        <button class="action-btn bg-dark-blue mr-2"
                                            (click)="openexpirationDate(expirationDateModal,stf_files.stf_model_custom_files.id,stf_files.stf_model_custom_files.expiration)">Edit</button>
                                    </div>

                                    <div class="text-center mt-2 mb-2 upload__file"
                                        *ngIf="stf_files.stf_model_custom_files==null">
                                        <div class="el-upload__text">
                                            Drop file here or
                                            <em>click to upload</em>
                                        </div>
                                        <input type="file" (change)="uploadEmployeeFiles($event,stf_files.id)" name=""
                                            id="">
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="isEmployeePay">
                            <div class="col-md-10">
                                <div
                                    class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                                    <h6 class="card-title mb-0">Employee Pay Rates</h6>
                                </div>
                                <div class="card-body">
                                    <div class="tab-content list_table" id="ticketList">
                                        <div class="tab-pane active show fade" id="active-01" role="tabpanel"
                                            aria-labelledby="active-01-tab">
                                            <div class="table-responsive list_table">
                                                <table class="table  table-nowrap mb-0">

                                                    <tr>
                                                        <th class="pt-0 w-30"> </th>
                                                        <th class="pt-0">WEEKDAY</th>
                                                        <th class="pt-0"> WEEKEND</th>
                                                    </tr>

                                                    <tbody>
                                                        <tr *ngFor="let emp_pay_rate of allStfModelRates ; let i=index">
                                                            <td>
                                                                <p class="empoloy-name">{{emp_pay_rate.company_name}}
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <div class="form-row">
                                                                    <div class="col-12">
                                                                        <div class="form-group dollar-icon">
                                                                            <input type="text"
                                                                                (change)="payRatesWeekDay(i,'WEEKDAY',$event)"
                                                                                class="form-control"
                                                                                [value]="checkVlaue(emp_pay_rate.stf_model_rates,'WEEKDAY')">
                                                                            <i class="fa fa-usd" aria-hidden="true"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </td>
                                                            <td>
                                                                <div class="form-row">
                                                                    <div class="col-12">
                                                                        <div class="form-group dollar-icon">

                                                                            <input type="text"
                                                                                (change)="payRatesWeekDay(i,'WEEKEND',$event)"
                                                                                class="form-control"
                                                                                [value]="checkVlaue(emp_pay_rate.stf_model_rates,'WEEKEND')">
                                                                            <i class="fa fa-usd" aria-hidden="true"></i>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="isEmployeeAvailability">
                            <div class="col-md-10">
                                <div class="personal-information mt-10">
                                    <div class="name-text">
                                        <h6 class="font-16 text-black">Availability Form Training : <span
                                                class="text-gray"> </span></h6>
                                    </div>
                                </div>
                                <div class="map mt-20">
                                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/MICZP3Lu3mg">
                                    </iframe>
                                </div>

                            </div>
                            <div class="col-md-10">
                                <div class="modal-body md-b-body">
                                    <div class="form-row">
                                        <div class="form-group col-md-4 col-xs-6">
                                            <!-- <label for="">Click to Begin Form</label> -->
                                            <input type="date" [(ngModel)]="todayDate" name="todayDate"
                                                class="form-control rounded-0 form-control-md">
                                        </div>
                                        <div class="form-group col-md-2 col-xs-4">
                                            <button type="submit" class="action-btn bg-dark-green mr-2"
                                                (click)="monthlyAvailabilityStart()">Start</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="isEmployeeWeekMonthlyAvailability">
                            <div class="col-md-10">
                                <!-- <app-modal-monthly-availability [todayDate]="todayDate" [isSubmittedMonthlyAvailability] = "isSubmittedMonthlyAvailability">
                                </app-modal-monthly-availability> -->
                                <form [formGroup]="weekMonthlyAvailabilityForm"
                                    (ngSubmit)="submitWeekMonthlyAvailabilityForm(weekMonthlyAvailabilityForm.value)"
                                    id="my-form">
                                    <div class="form-row">
                                        <div class="form-group col-md-12">
                                            <div class="">
                                                <div
                                                    class="user-area d-flex align-items-center justify-content-between">
                                                    <h6 class="card-title mb-0"> Week #{{isCount}} {{updateDate(0) |
                                                        date:'MM/dd'}}
                                                        - {{updateDate(6) |
                                                        date:'MM/dd'}} <br /></h6>

                                                </div>
                                                <div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-12">
                                                            <label for="inputState"
                                                                class="col-form-label text-light-black font-16">
                                                                Number of hours requested for this week?</label>
                                                            <ng-select formControlName="week_number_of_hours"
                                                                placeholder="Select" clearAllText="Clear"
                                                                class="form-control-ng rounded-0">
                                                                <ng-option *ngFor="let hours of number_of_hours"
                                                                    [value]="hours"> {{hours}} </ng-option>

                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="">
                                                        <div class="tab-content list_table">
                                                            <div class="tab-pane active show fade" id="active-01"
                                                                role="tabpanel" aria-labelledby="active-01-tab">
                                                                <div class="table-responsive list_table">
                                                                    <table class="table  table-nowrap mb-0"
                                                                        style=" width: 100%;">
                                                                        <thead>
                                                                            <tr>
                                                                                <th class="pt-2 "> </th>
                                                                                <th class="pt-2 bg-green">
                                                                                    Morning (8)
                                                                                </th>
                                                                                <th class="pt-2 bg-green">
                                                                                    Afternoon (8)
                                                                                </th>
                                                                                <th class="pt-2 bg-green">
                                                                                    Night (8)
                                                                                </th>
                                                                                <th class="pt-2 bg-green">
                                                                                    Double (16)
                                                                                </th>
                                                                                <th class="pt-2 bg-green">
                                                                                    Not Available
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr
                                                                                *ngFor="let week of newData.days;let i=index">
                                                                                <th class="bg-green pl-3">
                                                                                    {{week.dayName}}
                                                                                    {{week.displayDate}}

                                                                                </th>
                                                                                <td class="text-center">
                                                                                    <input
                                                                                        [checked]="week.values[0].value"
                                                                                        (change)="onChange(i, $event.target.checked,0)"
                                                                                        type="checkbox" />
                                                                                </td>
                                                                                <td class="text-center">
                                                                                    <input
                                                                                        [checked]="week.values[1].value"
                                                                                        (change)="onChange(i, $event.target.checked,1)"
                                                                                        type="checkbox" />
                                                                                </td>
                                                                                <td class="text-center">
                                                                                    <input
                                                                                        [checked]="week.values[2].value"
                                                                                        (change)="onChange(i, $event.target.checked,2)"
                                                                                        type="checkbox" />
                                                                                </td>
                                                                                <td class="text-center">
                                                                                    <input
                                                                                        [disabled]="!checkUncheckCheckArr.days[i].values[3].isdisabled"
                                                                                        [checked]=""
                                                                                        (change)="onChange(i, $event.target.checked,4)"
                                                                                        type="checkbox" />
                                                                                </td>
                                                                                <td class="text-center">
                                                                                    <input
                                                                                        [disabled]="checkUncheckCheckArr.days[i].values[4].isdisabled"
                                                                                        [checked]=""
                                                                                        (change)="onChange(i, $event.target.checked,5)"
                                                                                        type="checkbox" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th class="bg-green pl-3">
                                                                                    Comment(s)
                                                                                </th>
                                                                                <td class="text-center" colspan="6">
                                                                                    <textarea formControlName="comments"
                                                                                        class="form-control rounded-0 form-control-md"
                                                                                        id="exampleTextarea" rows="4">
                                                                                    </textarea>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="btn-modal-group text-left">
                                        <a href="javascript:void(0)" *ngIf="isCount>1"
                                            class="btn btn-success border-r-10 mr-3 md-close"
                                            (click)="monthlyAvailabilityPrevious()"> PREVIOUS </a>

                                        <button *ngIf="isCount<6" type="submit"
                                            [disabled]="!weekMonthlyAvailabilityForm.valid" id="submit-btn"
                                            class="btn btn-success border-r-10 mr-3 md-close">NEXT</button>
                                        <!-- <button *ngIf="isCount>5" type="submit"
                                            [disabled]="!weekMonthlyAvailabilityForm.valid" id="submit-btn"
                                            class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button> -->

                                        <a href="javascript:void(0)" *ngIf="isCount>5"
                                            class="btn btn-success border-r-10 mr-3 md-close"
                                            (click)="opensubmitFinalizeAvailability(finalizeAvailabilityModal)">SUBMIT
                                        </a>
                                    </div>
                                </form>
                                <div class="map mt-20">
                                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/MICZP3Lu3mg">
                                    </iframe>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="isSubmittedMonthlyAvailability">
                            <div class="col-md-10">
                                <div class="personal-information mt-10">
                                    <div class="name-text">
                                        <p> You have successfully completed and submitted your availability form.</p>
                                    </div>
                                </div>
                                <div class="btn-modal-group text-left">
                                    <a href="javascript:void(0)" class="btn btn-success border-r-10 mr-3 md-close"
                                        (click)="printMonthlyAvailability()"> Print Availability </a>
                                    <a href="javascript:void(0)" class="btn btn-success border-r-10 mr-3 md-close"
                                        (click)="logout()"> Logout </a>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>

<!-- Add Note -->

<ng-template #addNote let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0"> Note</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <form [formGroup]="noteForm" (ngSubmit)="submitNote(noteForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <input type="hidden" formControlName="id" value="">
                    <label class="col-form-label text-light-black font-16">Note <span
                            class="text-danger">*</span></label>
                    <textarea required="" class="form-control rounded-0 form-control-md" id="exampleTextarea" rows="4"
                        formControlName="note"></textarea>
                </div>
            </div>

            <div class="form-row">
                <div class="form-group col-md-12">
                    <button type="submit" [disabled]="!noteForm.valid"
                        class="action-btn bg-dark-green mr-2">Submit</button>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<!-- Delete Notes -->
<ng-template #notesDeleteModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>

    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteNotes()" class="btn btn-success  border-r-10">Yes, delete it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>

</ng-template>


<!-- Edit / Employee -->

<ng-template #employeeFormModal let-modal>
    <div class="justify-content-right">
        <button type="button" class="close action-btn modal-dismiss mr-10 p-1" (click)="modal.dismiss('Cross click')"
            aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <form [formGroup]="employeeForm" (ngSubmit)="submitData(employeeForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Create New Employee</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">First
                                        Name<span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="first_name">
                                    <span class="text-danger"
                                        *ngIf="employeeForm.get('first_name').errors?.required || employeeForm.get('first_name').errors?.minlength && employeeForm.get('first_name').errors">
                                        {{formErrors.first_name}}</span>
                                    <input type="hidden" formControlName="id" id="id">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Last
                                        Name<span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="last_name">
                                    <span class="text-danger"
                                        *ngIf="employeeForm.get('last_name').errors?.required || employeeForm.get('last_name').errors?.minlength && employeeForm.get('last_name').errors">
                                        {{formErrors.last_name}}</span>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Email<span
                                            class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="email">
                                    <span class="text-danger"
                                        *ngIf="employeeForm.get('email').errors?.required || employeeForm.get('email').errors?.email  && employeeForm.get('email').errors">
                                        {{formErrors.email}}</span>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState"
                                        class="col-form-label text-light-black font-16">Specialty</label>

                                    <ng-select formControlName="stf_specialty_id" placeholder="Select"
                                        clearAllText="Clear" class="form-control-ng rounded-0">
                                        <ng-option *ngFor="let speciality of specialities" [value]="speciality.id">
                                            {{speciality.name}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState"
                                        class="col-form-label text-light-black font-16">Phone</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="phone">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState" class="col-form-label text-light-black font-16">SSN</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="ssn">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState"
                                        class="col-form-label text-light-black font-16">Birthdate</label>
                                    <input class="form-control rounded-0 form-control-md" type="date"
                                        formControlName="birthdate">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Hire
                                        Date</label>
                                    <input class="form-control rounded-0 form-control-md" type="date"
                                        formControlName="hire_date"
                                        value="{{employee.hire_date | date : 'dd-MM-yyyy'}}">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Termination
                                        Date</label>
                                    <input class="form-control rounded-0 form-control-md" type="date"
                                        formControlName="termination_date">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState"
                                        class="col-form-label text-light-black font-16">Assignee(s)</label>
                                    <ng-select formControlName="assignee" [multiple]="true" placeholder="Select"
                                        clearAllText="Clear" class="form-control-ng rounded-0">
                                        <ng-option *ngFor="let assignee of assignees" [value]="assignee.id">
                                            {{assignee.name}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-3">
                                    <div class="checkbox d-inline">
                                        <label for="checkbox-recurit"
                                            class="col-form-label text-light-black font-16">Recruiting</label>
                                        <div class="new-checkbox toggle-check-box-t mt-15">
                                            <label>No</label>

                                            <!-- Rounded switch -->
                                            <label class="switch ml-15 mr-15">
                                                <input type="checkbox" formControlName="new_recruit" [checked]="isTrue"
                                                    id="checkbox-recurit">
                                                <span class="slider round"></span>
                                            </label>
                                            <label>Yes</label>
                                        </div>
                                        <!-- <input type="checkbox" formControlName="new_recruit" [checked]="isTrue" id="checkbox-recurit"> -->
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <div class="checkbox d-inline">
                                        <label for="checkbox-active"
                                            class="col-form-label text-light-black font-16">Activate Employee
                                            Checklist</label>
                                        <div class="new-checkbox toggle-check-box-t">
                                            <label>No</label>

                                            <!-- Rounded switch -->
                                            <label class="switch ml-15 mr-15">
                                                <input type="checkbox" formControlName="active" [checked]="isTrue"
                                                    id="checkbox-active">
                                                <span class="slider round"></span>
                                            </label>
                                            <label>Yes</label>
                                        </div>
                                        <!-- <input type="checkbox" formControlName="active" [checked]="isTrue" id="checkbox-active"> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Location Information</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Address Line
                                        1</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="address_line_1">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Address Line
                                        2</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="address_line_2">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Apartment
                                        #</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="apartment_number">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">City</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="city">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState"
                                        class="col-form-label text-light-black font-16">State</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="state">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Zip</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="zip">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Pay Details</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="text-help">
                                    Use this section to provide additional pay and hiring information for the employee.
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Pay
                                        Rate</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="pay_rate">
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="inputState" class="col-form-label text-light-black font-14">Pay
                                        Description</label>
                                    <editor formControlName="pay_description" [init]="{
                                      base_url: '/tinymce',
                                      suffix: '.min',
                                      height: 500,
                                      menubar: false,
                                      plugins: [
                                          'advlist autolink lists link image charmap print preview anchor',
                                          'searchreplace visualblocks code fullscreen',
                                          'insertdatetime media table paste code help wordcount'
                                      ],
                                      toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                  }"></editor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Additional Information</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="text-help">Use this section to publish any additional details about the
                                    employee.
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="inputState" class="col-form-label text-light-black font-14">Additional
                                        Information</label>
                                    <editor formControlName="static_html_block" [init]="{
                                      base_url: '/tinymce',
                                      suffix: '.min',
                                      height: 500,
                                      menubar: false,
                                      plugins: [
                                          'advlist autolink lists link image charmap print preview anchor',
                                          'searchreplace visualblocks code fullscreen',
                                          'insertdatetime media table paste code help wordcount'
                                      ],
                                      toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                  }"></editor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-modal-group text-center">
                <button type="submit" [disabled]="!employeeForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 w-30 md-close">SUBMIT</button>
            </div>
        </form>
    </div>
</ng-template>


<!-- activateModal  -->
<ng-template #activateModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Activate Employee</h6>
    </div>

    <div class="modal-body md-b-body">
        <p>Are you sure you want to activate this profile?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="employeeActivate()" class="btn btn-success  border-r-10">Yes</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>

</ng-template>

<!-- deactivateModal  -->
<ng-template #deactivateModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Deactivate Employee</h6>
    </div>

    <div class="modal-body md-b-body">
        <p>Are you sure you want to deactivate this profile?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="employeeActivate()" class="btn btn-success  border-r-10">Yes</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>

</ng-template>


<!-- activateModal  -->
<ng-template #dnrProfile let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">DNR</h6>
    </div>

    <div class="modal-body md-b-body">
        <p>Are you sure you want to move this profile into the DNR status?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="employeeDNR()" class="btn btn-success  border-r-10">Yes</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>

</ng-template>

<!-- deactivateModal  -->
<ng-template #restoreDNRProfile let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">DNR</h6>
    </div>

    <div class="modal-body md-b-body">
        <p>Are you sure you want to restore this profile from the DNR status?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="employeeDNR()" class="btn btn-success btn-sm  border-r-10">Yes</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger btn-sm border-r-10">Cancel</button>
        </div>
    </div>

</ng-template>

<!-- Delete Employee Files -->
<ng-template #deleteEmployeeFiles let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center"> Confirm</h6>
    </div>
    <div class="modal-body md-b-body">
        <p>Are you sure you would like to delete this file?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteEmployeeFilesData()" class="btn btn-success  border-r-10">Yes, delete
                it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>
</ng-template>

<!-- Employee Files Modal -->
<ng-template #EmployeeFilesModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <!-- <h6 class="card-title mb-0">Email Form</h6> -->
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <div class="row">
            <div class="col-md-12 box-margin">
                <div class="card">
                    <div class="card-body">
                        <iframe id="iframeid" [src]="getPdfPath(employeeFilesPath)" width="100%"
                            height="500px"></iframe>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="box-footer">
                    <div class="pull-right">
                        <a target="_blank" [href]="getPdfPath(employeeFilesPath)" class="link-font--larger"><i
                                class="fa fa-share mr-1"></i>View File
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Expiration Date -->
<ng-template #expirationDateModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <!-- <h6 class="card-title mb-0"> Expiration Date</h6> -->
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <div class="form-row">
            <div class="form-group col-md-12">
                <label class="col-form-label text-light-black font-16">Expiration Date </label>
                <input type="date" class="form-control" name="" [(ngModel)]="expiration" id=""
                    (change)="updateExpirationDate()">
            </div>
        </div>
    </div>
</ng-template>

<!-- Employee Upload New File Upload-->
<ng-template #uploadNewFileFormModal let-modal>
    <div class="justify-content-right">
        <button type="button" class="close action-btn modal-dismiss mr-10 p-1" (click)="modal.dismiss('Cross click')"
            aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <form [formGroup]="employeeUploadNewFileForm"
            (ngSubmit)="submitEmployeeUploadNewFile(employeeUploadNewFileForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0"> Upload New File</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Select a
                                        Field <span class="text-danger">*</span></label>

                                    <ng-select formControlName="custom_field" placeholder="Select" clearAllText="Clear"
                                        class="form-control-ng rounded-0">
                                        <ng-option *ngFor="let custom_files of allStfCustomFiles"
                                            [value]="custom_files.id">
                                            {{custom_files.field_name}}
                                        </ng-option>
                                    </ng-select>
                                    <!-- <span class="text-danger"
                                        *ngIf="employeeUploadNewFileForm.get('custom_field').errors?.required && employeeUploadNewFileForm.get('custom_field').errors">
                                        {{formErrors.custom_field}}</span> -->
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">
                                        Expiration</label>
                                    <input class="form-control rounded-0 form-control-md" type="date"
                                        formControlName="expiration">
                                    <input type="hidden" formControlName="id" id="id">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">
                                        File Upload</label>
                                    <div class="file-upload-3">
                                        <div class="el-upload__text">
                                            <p class="mb-0"><i class="fa fa-cloud-upload" aria-hidden="true"></i></p>
                                            Drop file here or <em>click to upload</em>
                                        </div>
                                        <input class="form-control rounded-0 form-control-md" type="file"
                                            formControlName="employee_files" accept="image/*,application/pdf"
                                            (change)="convertImageInBase64($event)">

                                    </div>
                                    <p class="mt-4"> {{fileName}} </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="btn-modal-group text-center">
                <button type="submit" [disabled]="!employeeUploadNewFileForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 w-30 md-close">SUBMIT</button>
            </div>
        </form>
    </div>
</ng-template>

<!-- Email Files-->
<ng-template #emailFileFormModal let-modal>
    <div class="justify-content-right">
        <button type="button" class="close action-btn modal-dismiss mr-10 p-1" (click)="modal.dismiss('Cross click')"
            aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <form [formGroup]="employeeEmailFilesForm" (ngSubmit)="submitemployeeEmailFiles(employeeEmailFilesForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0"> Email File(s)</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="col-md-4">
                                    <label>Contact Type</label>
                                    <div class="form-group  d-flex mb-20">
                                        <div class="form-check pl-0 mb-2">
                                            <label class="form-check-label button-radio-btn">
                                                <input formControlName="type" type="radio"
                                                    class="form-check-input radio-btn-button" (click)="getType($event)"
                                                    value="administrator">
                                                <div class="layer"></div>
                                                <div class="button"><span>Administrator</span></div>
                                            </label>
                                        </div>
                                        <div class="form-check pl-0 mb-2">
                                            <label class="form-check-label button-radio-btn">
                                                <input formControlName="type" type="radio"
                                                    class="form-check-input radio-btn-button" (click)="getType($event)"
                                                    value="scheduler">
                                                <div class="layer"></div>
                                                <div class="button"><span>Scheduler</span></div>
                                            </label>
                                        </div>
                                        <div class="form-check pl-0 mb-2">
                                            <label class="form-check-label button-radio-btn">
                                                <input formControlName="type" type="radio"
                                                    class="form-check-input radio-btn-button" (click)="getType($event)"
                                                    value="all" [checked]='true'>
                                                <div class="layer"></div>
                                                <div class="button"><span>All</span></div>
                                            </label>
                                        </div>

                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16"> Client
                                        Selection</label>
                                    <ng-select formControlName="client_id" placeholder="Select" clearAllText="Clear"
                                        class="form-control-ng rounded-0">
                                        <ng-option *ngFor="let client of allStfModelRates" [value]="client.id">
                                            {{client.company_name}}
                                        </ng-option>
                                    </ng-select>
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">
                                        File(s)</label>
                                    <ng-select formControlName="employee_files" placeholder="Select"
                                        clearAllText="Clear" class="form-control-ng rounded-0">
                                        <ng-option *ngFor="let email_files of allEmailFiles"
                                            [value]="email_files.stf_custom_file.field_name">
                                            {{email_files.stf_custom_file.field_name}}
                                        </ng-option>
                                    </ng-select>
                                </div>

                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="inputState" class="col-form-label text-light-black font-16">
                                        Custom Recipient</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="custom_recipient"
                                        placeholder="Manually enter an email address, if applicable">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="inputState" class="col-form-label text-light-black font-16">
                                        Subject</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="subject">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <label for="inputState" class="col-form-label text-light-black font-16">
                                        Message</label>
                                    <textarea required="" class="form-control rounded-0 form-control-md"
                                        id="exampleTextarea" formControlName="message" rows="4"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="btn-modal-group text-left">
                <button type="submit" [disabled]="!employeeEmailFilesForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
            </div>
        </form>
    </div>
</ng-template>


<!-- delete Reset Hire Packet -->
<ng-template #deleteResetHirePacket let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center"> Reset Hire Packet </h6>
    </div>
    <div class="modal-body md-b-body">
        <p> Are you sure you want to remove this employee's current hire packet?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteResetHirePacketData()" class="btn btn-success  border-r-10">Yes, delete
                it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>
</ng-template>

<!-- delete Reset Training -->
<ng-template #deleteResetTraining let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center"> Reset Training</h6>
    </div>
    <div class="modal-body md-b-body">
        <p> Are you sure you want to remove the training responses for this employee?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteResetTrainingData()" class="btn btn-success  border-r-10">Yes, delete
                it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>
</ng-template>

<!-- Delete Notes -->
<ng-template #finalizeAvailabilityModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center"> Finalize Availability</h6>
    </div>

    <div class="modal-body md-b-body">
        <p> You are about to submit your availability. Once submitted, your availability form will be locked and all
            request for changes must be called into the office. Would you like to proceed?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="submitFinalizeAvailability()" class="btn btn-success  border-r-10">Yes,
                delete it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>

</ng-template>


<!-- open email Request Modal -->
<ng-template #emailRequestModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0">Availability Request</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <form [formGroup]="emailRequestForm" (ngSubmit)="submitFmailRequestForm(emailRequestForm.value)">
            <div class="row">
                <div class="col-md-12">
                    <label> What is the last scheduled date of this employee? <span class="text-danger">*</span>
                    </label>
                    <div class="form-group">
                        <input type="date" class="form-control" formControlName="start_date">
                    </div>
                </div>
                <div class="col-md-12">
                    <label>What date should this schedule end at? <span class="text-danger">*</span></label>
                    <div class="form-group">
                        <input type="date" class="form-control" formControlName="end_date">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="box-footer">
                        <div class="pull-left">
                            <button type="submit" [disabled]="!emailRequestForm.valid"
                                class="btn btn-success btn-sm mb-2"> Send</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>