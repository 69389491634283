import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-administration-libraries',
  templateUrl: './administration-libraries.component.html',
  styleUrls: ['./administration-libraries.component.css']
})
export class AdministrationLibrariesComponent implements OnInit {

  triggerObject:any=[];
  constructor(
    private modalService:NgbModal,
  ) { }

  ngOnInit(): void {
  }

  openModal(content){
    this.modalService.open(content,{ size:'xl',centered:true });
  }

}
