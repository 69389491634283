import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  modalReference: any = '';

  constructor(
    private router : Router,
    private _modalService: NgbModal
    ) { }

  ngOnInit(): void {
    
  }

}
