import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})

export class RolesComponent implements OnInit {

  roleForm: FormGroup;
  roles: any = [];
  permissions: any = [];
  editData: any = {};
  response:any;
  isSaved: boolean = false;
  isError: boolean = false;
  isDeleted: boolean = false;
  formErrors = {
    'name': ''
  };
  validationMessages = {
    'name': {
      'required': 'Name is required.'
    }
  };

  constructor(
    private api: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr:ToastrService
  ) { }

  ngOnInit(): void {
    this.getRoles();
    this.getPermissions();
  }

  init(): void {
    this.roleForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      description: [''],
      permission_id: this.formBuilder.array([]),
    });
    this.roleForm.reset();
    this.roleForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.roleForm);
    });
  }

  async getRoles() {
    this.api.get('admin/role/lists').subscribe((result) => {
      this.roles = result.data;
    }, (err) => {
    });
  }

  async getPermissions() {
    this.api.get('admin/permission/lists').subscribe((result) => {
      this.permissions = result.data;
    }, (err) => {
    });
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else if (abstractControl instanceof FormArray) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  editRole(content, index): void {
    this.init();
    const formArray: FormArray = this.roleForm.get('permission_id') as FormArray;
    this.editData = this.roles[index];
    this.roleForm.patchValue(this.editData);
    this.permissions.map(v => (v.isSelected = false));
    this.permissions.map((permissionObj) => {
      this.editData.permissions.forEach((selectedObj) => {
        if (permissionObj.id == selectedObj.permission_id) {
          permissionObj.isSelected = true;
          formArray.push(new FormControl(selectedObj.permission_id));
        }
      });
    });
    this.modalService.open(content, { size: 'xl', centered: true});
  }

  deleteRole(id): void {
    this.api.post('admin/role/delete', { id: id }).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.getRoles();
      // this.isSaved = false;
      // this.isDeleted = true;
    }, (err) => {
      this.toastr.error(err.error.message,"Error");
    });
  }

  addRole(content): void {
    this.init();
    this.editData = {};
    this.permissions.map(v => (v.isSelected = false));
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  onPermissionChange(event): void {
    const formArray: FormArray = this.roleForm.get('permission_id') as FormArray;
    if (event.target.checked) {
      formArray.push(new FormControl(event.target.value));
    } else {
      let i: number = 0;
      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  submitData(data): void {
    let endPoint = (data.id) ? 'update' : 'create';
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/role/' + endPoint, data).subscribe((result) => {
      this.response=result;
      this.toastr.success(this.response.message,"Success");
      this.getRoles();
      // this.isSaved = true;
      // this.isDeleted = false;
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      (<HTMLButtonElement>document.getElementsByClassName("modal-dismiss")[0]).click();
    }, (err) => {
      this.toastr.error(err.error.message,"Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });
  }
}
