import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../services/api.service';
import { ToastService } from '../services/toast.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {

  employeeForm: FormGroup;
  noteForm: FormGroup;
  specialities: any = [];
  assignees: any = [];
  employees: any = [];
  deleteId: string = '';
  modalReference: any = '';
  totalRecords: any;
  page: any = 1;
  query: string = '&status=1';
  activeTab:any = '1';
  isTrue:boolean = true;
  response:any;
  employee_id:any;
  user:any=[];
  statics:any=[];

  formErrors = {
    'first_name': '',
    'last_name': '',
    'email': '',
    'stf_specialty_id': '',
    'phone': '',
    'ssn': '',
    'birthdate': '',
    'hire_date': '',
    'termination_date': '',
    'assignee': '',
    'new_recruit': '',
    'active': '',
    'address_line_1': '',
    'address_line_2': '',
    'apartment_number': '',
    'city': '',
    'state': '',
    'zip': '',
    'pay_rate': '',
    'pay_description': '',
    'static_html_block': ''
  };
  validationMessages = {
    'first_name': {
      'required': 'First name is required.',
      'minlength': 'Name must be 3 characters long.'
    },
    'last_name': {
      'required': 'Last name is required.',
      'minlength': 'Name must be 3 characters long.'
    },
    'email': {
      'required': 'Email is required.',
      'email': 'Email is invalid'
    },
    'phone': {
      'minlength': 'Phone number must be of 10 digit.'
    }
  };

  constructor(
    private api: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public toastService: ToastService,
    private toastr : ToastrService
  ) {
    this.user =  JSON.parse(localStorage.getItem('reliant_user'));
    this.noteForm = this.formBuilder.group({
      note: [''],
      id:['']
    });
   }

  ngOnInit(): void {
    this.init();
    this.getSpecialties();
    this.getEmployees(this.page);
    this.getStatics();
  }

  init(): void {
    this.employeeForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.minLength(3)]],
      last_name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      stf_specialty_id: [''],
      phone: [''],
      ssn: [''],
      birthdate: [''],
      hire_date: [''],
      termination_date: [''],
      assignee: [''],
      new_recruit: [true],
      active: [true],
      address_line_1: [''],
      address_line_2: [''],
      apartment_number: [''],
      city: [''],
      state: [''],
      zip: [''],
      pay_rate: [''],
      pay_description: [''],
      static_html_block: ['']
    });
    this.employeeForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.employeeForm);
    });
  }

  async getSpecialties() {
    this.api.get('admin/specialities/lists').subscribe((result) => {
      this.specialities = result.data;
    }, (err) => {
    });
  }

  async getEmployees(page) {
    this.page = page;
    this.api.get('admin/employee/lists?page='+page+this.query).subscribe((result) => {
      this.employees = result.data.employees;
      this.totalRecords = result.data.totalRecords;
    }, (err) => {
    });
  }

  async getStatics(){
    this.api.get("admin/employee/statics").subscribe((result)=>{
      this.statics = result.data;
    },(err)=>{

    });
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  deleteModal(modal, id){
    this.deleteId = id;
    this.modalReference = this.modalService.open(modal, { size: 'sm', centered: true });
  }

  deleteUser(): void {
    this.modalReference.close();
    this.api.post('admin/employee/delete', { id: this.deleteId }).subscribe((result) => {
      this.getEmployees(this.page);
    }, (err) => {
    });
  }

  addEmployee(content): void {
    this.employeeForm.reset();
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  submitData(data) {
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/employee/create', data).subscribe((result) => {
      //this.toastService.show('Employee created successfully', { classname: 'bg-success text-light', delay: 5000 });
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.getEmployees(this.page);
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      (<HTMLButtonElement>document.getElementsByClassName("modal-dismiss")[0]).click();
    }, (err) => {
      this.toastr.error(err.error.message,"Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      (<HTMLButtonElement>document.getElementsByClassName("modal-dismiss")[0]).click();
    });
  }

  getActiveInactiveEmployee(value){
    this.activeTab = value;
    this.query = '&status='+value;
    this.getEmployees(1);
  }

  getDnrEmployee(){
    this.activeTab = '2';
    this.query = '&dnr=1';
    this.getEmployees(1);
  }

  openNote(addNote,current_row){
    this.noteForm.reset();
    this.employee_id = current_row.id;
    this.modalService.open(addNote, { size: 'xl', centered: true });
  }

  submitNote(data): void {
    let endPoint = (data.id)?'update':'create';
    data.model_type = 'Employee';
    data.model_id = this.employee_id;
    data.user_id = this.user.id;
    this.api.post('admin/note/'+endPoint, data).subscribe((result) => {
       console.log(result);
       this.response=result;
       this.toastr.success(this.response.message,"Success");
       this.modalService.dismissAll();
    }, (err) => {
      this.toastr.error(err.error.message,'Error');
    });
  }

}
