<div class="row">
    <div class="col-md-8">
        <div class="card">
            <div>
                <ul class="nav nav-tabs mb-0" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link " id="active-01-tab" data-toggle="tab" (click)="viewFollowUps('details')"
                            role="tab" aria-controls="details" href="#details" aria-selected="true"> Details </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0" id="inactive-02-tab" data-toggle="tab"
                            (click)="viewFollowUps('clients')" role="tab" aria-controls="clients" href="#clients"
                            aria-selected="false"> Clients</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0" id="dnr-02-tab" data-toggle="tab" (click)="viewFollowUps('calllogs')"
                            role="tab" aria-controls="calllogs" href="#calllogs" aria-selected="false">Call Logs</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0 active" id="dnr-02-tab" data-toggle="tab"
                            (click)="viewFollowUps('follow-ups')" href="#follow-ups" role="tab"
                            aria-controls="follow-ups" aria-selected="false">Follow Ups</a>
                    </li>
                </ul>

            </div>
            <div *ngIf="!activetab4"
                class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0" *ngIf="!activetab3"> {{textMsg}} </h6>

                <div class="btn-group tab_list" *ngIf="activetab1">
                    <a href="javascript:void(0)" class="mr-2 action-btn bg-edit" title="Update follow Ups"><img
                            src="assets/img/svg-icon/edit.svg" width="20" /></a>

                    <a href="javascript:void(0)" class="action-btn bg-red" title="Delete"><img
                            src="assets/img/svg-icon/delete.svg" width="20" /></a>
                </div>
                <div class="btn-group tab_list" *ngIf="activetab2">
                    <button class="action-btn bg-dark-green mr-2"
                        (click)="addClientsReg(clientRegistrationFormModal)"><img
                            src="assets/img/svg-icon/add-white.svg" width="20" /></button>
                    <!-- <button class="action-btn bg-dark-blue"><img src="assets/img/svg-icon/filter-white.svg"
                            width="20" /></button> -->
                </div>
                <!-- <div class="btn-group tab_list" *ngIf="activetab3">
                    <button class="action-btn bg-dark-green mr-2"
                        (click)="addCallLogsReg(callLogsregistrationFormModal)"><img
                            src="assets/img/svg-icon/add-white.svg" width="20" /></button>
                </div> -->
            </div>
            <div class="card-body" *ngIf="!activetab4">
                <div class="tab-content list_table" id="ticketList">
                    <div *ngIf="activetab1" class="tab-pane active show fade" id="details" role="tabpanel"
                        aria-labelledby="active-01-tab">
                        <div class="row">

                            <div class="col-md-6">
                                <h4 class="text-black font-16"> First Name</h4>
                                <p class="text-gray font-14 m-0"> Email</p>
                            </div>
                            <div class="col-md-6">
                                <h4 class="text-black font-16 sm-font-16 md-font-16"> Last Name</h4>
                                <p class="text-gray font-14 m-0"> Phone (Work)</p>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="activetab2" class="tab-pane fade" id="clients" role="tabpanel"
                        aria-labelledby="active-01-tab">
                        <div class="tab-content list_table" id="courseList">
                            <div class="table-responsive list_table">
                                <table class="table  table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th class="pt-0"> Company Name</th>
                                            <th class="pt-0"> Scheduler Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th class="pt-0">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="allClients.length > 0">
                                            <tr
                                                *ngFor="let client of allClients | paginate: { id: 'client_pagination', itemsPerPage: 2, currentPage: page, totalItems: totalRecords }; let i=index">
                                                <td class="">{{client.company_name}}</td>
                                                <td class="">{{client.scheduler_name}}</td>
                                                <td class="">{{client.scheduler_email}}</td>
                                                <td class="">{{client.phone}}</td>
                                                
                                                <td class="text-black">
                                                    <a (click)="editClient(clientRegistrationFormModal,i)" href="javascript:void(0)" class="mr-2 action-btn bg-edit"
                                                        title="Update Call Script"><img
                                                            src="assets/img/svg-icon/edit.svg" width="20" /></a>
                                                    <a (click)="deleteClientModal(clientDeleteModal,client)" href="javascript:void(0)" class="action-btn bg-red"
                                                        title="Delete"><img src="assets/img/svg-icon/delete.svg"
                                                            width="20" /></a>
                                                </td>

                                            </tr>
                                        </ng-container>
                                        <tr *ngIf="allClients.length==0">
                                            <td colspan="7" class="text-center">Data not found.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <pagination-controls class="pagination-controls-theme" id="client_pagination"
                                    (pageChange)="getClients(page = $event)" maxSize="9" directionLinks="true"
                                    autoHide="true" responsive="true">
                                </pagination-controls>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="activetab3" class="tab-pane fade" id="calllogs" role="tabpanel"
                        aria-labelledby="active-01-tab">
                        <app-modal-call-logs [modelName]="modelName"></app-modal-call-logs>
                    </div>
                </div>
            </div>
            <app-modal-crm-follow-ups *ngIf="activetab4" id="follow-ups" [modelDetails]="modelDetails">
            </app-modal-crm-follow-ups>
        </div>
    </div>
    <div class="col-md-4">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Status : Active <span><i class="fa fa-check text-success"
                            aria-hidden="true"></i></span></h6>
                <div class="btn-group">
                    <button class="action-btn bg-dark-green mr-2" (click)="statusDeactivate() "> Deactivate </button>

                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-4">
                        <div class="  user-area d-flex align-items-center justify-content-between">
                            <div class="image-upload m-3">
                                <label for="file-input">
                                    <span><i class="fa fa-plus p-2" style="font-size:50px;"></i></span>
                                </label>

                                <input id="file-input" type="file" />
                            </div>

                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="  user-area align-items-center justify-content-between">
                            <h2 for="" *ngIf="contactData">{{contactData.name}}</h2>
                            <span *ngIf="contactData"> Since {{contactData.created_date}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Activity Feed</h6>
            </div>
            <div class="card-body card-header">
                <div class="row" *ngIf="!isComments">
                    <div class="col-md-6">
                        <div class="  user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0"> <a href="javascript:void(0)" (click)="showActivity()">
                                    Activity</a> </h6>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="  user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0"> <a href="javascript:void(0)" (click)="showComments()">
                                    Comments</a> </h6>

                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12" *ngIf="isActivity" style=" height: 230px; overflow: auto;">
                        <div class="timeline1">
                            <div class="outer">
                                <div class="card1">
                                    <div class="info">
                                        <h3 class="title">Kevin </h3> <span style="margin: -36px 0 0 88px;">2 days, 10
                                            hours ago</span>
                                        <label for="" style="
                                        line-height: 2;
                                    ">Follow Ups</label>
                                        <label for=""> <b>Title </b> <span> AHAVA | 2022-06-10</span></label>
                                        <label for=""> <b>Notes </b> <span>testing</span></label>
                                        <label for=""> <b>Due Date </b> <span> 2022-06-18</span></label>
                                        <label for=""> <b>Type </b> <span>Text</span></label>
                                        <label for=""> <b>Comment </b> <span> (Empty)</span></label>
                                        <label for=""> <b> Active </b> <span> No</span></label>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="timeline1">
                            <div class="outer">
                                <div class="card1">
                                    <div class="info">
                                        <h3 class="title">Kevin </h3> <span>2 days, 10 hours ago</span>
                                        <label for="">Follow Ups</label>
                                        <label for=""> Title AHAVA <span>2022-06-10</span></label>
                                        <label for=""> Title AHAVA <span>2022-06-10</span></label>
                                        <label for=""> Title AHAVA <span>2022-06-10</span></label>
                                        <label for=""> Title AHAVA <span>2022-06-10</span></label>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="timeline1">
                            <div class="outer">
                                <div class="card1">
                                    <div class="info">
                                        <h3 class="title">Kevin </h3> <span>2 days, 10 hours ago</span>
                                        <label for="">Follow Ups</label>
                                        <label for=""> Title AHAVA <span>2022-06-10</span></label>
                                        <label for=""> Title AHAVA <span>2022-06-10</span></label>
                                        <label for=""> Title AHAVA <span>2022-06-10</span></label>
                                        <label for=""> Title AHAVA <span>2022-06-10</span></label>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-12 commentsList" *ngIf="isCommentsList" style=" height: 200px; overflow: auto;"
                        (scroll)="scrollHandler($event)">
                        <div class="timeline1" *ngFor="let comment of allComments; let i=index">
                            <div class="card1">
                                <div class="info">
                                    <h3 class="title">{{comment.user.name}} </h3> <span style="margin: -36px 0 0 88px;">
                                        {{comment.created_at | dateAgo }} </span>
                                    <p>{{comment.comment}}</p>
                                </div>
                            </div>
                            <hr />
                        </div>

                    </div>
                    <div class="col-md-12 text-center" *ngIf="isCommentsList">
                        <a href="javascript:void(0)" (click)="loadComment()"> Show More</a>
                        <hr />
                    </div>
                    <div class="col-md-12 text-center" *ngIf="isCommentsList">
                        <a href="javascript:void(0)" (click)="openAddComment()">Add Comment</a>
                        <hr />
                    </div>
                    <div class="col-md-12" *ngIf="isComments">
                        <form [formGroup]="CommentForm" (ngSubmit)="submitCommentData(CommentForm.value)">
                            <div class="row">
                                <div class="col-sm-12">
                                    <label for="">Recipient(s)</label>
                                    <ng-select clearAllText="Clear" class=" form-control-ng rounded-0">
                                        <ng-option value="1">Recipient 1 </ng-option>
                                        <ng-option value="2">Recipient 2</ng-option>
                                        <ng-option value="3">Recipient 3</ng-option>
                                        <ng-option value="4">Recipient 4</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-sm-12">
                                    <label for="">Comment</label>
                                    <textarea formControlName="comment" class="form-control" cols="30" rows="6"
                                        value="Hello"></textarea>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6 text-rght">
                                    <button type="submit" id="submit-btn" [disabled]="!CommentForm.valid"
                                        class="action-btn bg-dark-blue mt-2">Submit</button>
                                </div>
                                <div class="col-sm-6 text-left mt-3">
                                    <a href="javascript:void(0)" class="action-btn bg-dark-blue "
                                        (click)="cancelComment()">Cancel</a>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--  client Registration -->
<ng-template #clientRegistrationFormModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <form [formGroup]="clientRegistrationForm" (ngSubmit)="submitClientData(clientRegistrationForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Client Details</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-3">
                                    <label for="company_name" class="col-form-label text-light-black font-16">First Name
                                        <span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="first_name">
                                        <input type="hidden" formControlName="id">

                                </div>
                                <div class="form-group col-md-3">
                                    <label for="company_name" class="col-form-label text-light-black font-16">Last
                                        Name<span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="last_name">

                                </div>
                                <div class="form-group col-md-3">
                                    <label for="company_name" class="col-form-label text-light-black font-16">Phone
                                    </label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="phone">

                                </div>
                                <div class="form-group col-md-3">
                                    <label for="company_name" class="col-form-label text-light-black font-16">Email
                                        <span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="email">

                                </div>
                                <div class="form-group col-md-6">
                                    <label class="col-form-label text-light-black font-16">Related Customer(s) </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select [multiple]="true" class="custom-mutiple-select democlass"
                                            formControlName="customer_id" (change)="selectRelatedCustomer($event)"
                                            placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let customer of allRelatedCustomer;"
                                                value="{{customer.id}}"> {{customer.company_name}}</ng-option>
                                        </ng-select>
                                        <button type="button" class="btn btn-default"><i class="fa fa-plus"></i> Add
                                            New</button>
                                    </div>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="col-form-label text-light-black font-16">Billing Method </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select democlass"
                                            formControlName="billing_method" (change)="selectRelatedCustomer($event)"
                                            placeholder="Select" clearAllText="Clear">
                                            <ng-option value="Email">Email</ng-option>
                                            <ng-option value="Mail">Mail</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Location Details</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="company_name"
                                        class="col-form-label text-light-black font-16">Address</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="address">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="company_name" class="col-form-label text-light-black font-16">Address
                                        Line 2 </label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="address_line_2">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="company_name"
                                        class="col-form-label text-light-black font-16">City</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="city">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="company_name" class="col-form-label text-light-black font-16">State
                                    </label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="state">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="company_name" class="col-form-label text-light-black font-16">Zip Code
                                    </label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="zip_code">
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Additional Information</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label for="company_name" class="col-form-label text-light-black font-16">Account
                                        Manager</label>
                                    <ng-select class="custom-mutiple-select democlass"
                                        (change)="selectRelatedCustomer($event)" formControlName="account_manager_id"
                                        placeholder="Select" clearAllText="Clear">
                                        <ng-option *ngFor="let client of allClientDetails;" value="{{client.id}}">
                                            {{client.name}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="company_name" class="col-form-label text-light-black font-16">Inside
                                        Sales </label>
                                    <ng-select class="custom-mutiple-select democlass"
                                        (change)="selectRelatedCustomer($event)" placeholder="Select"
                                        clearAllText="Clear" formControlName="inside_sales_id">
                                        <ng-option *ngFor="let client of allClientDetails;" value="{{client.id}}">
                                            {{client.name}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="company_name" class="col-form-label text-light-black font-16">Outside
                                        Sales</label>
                                    <ng-select class="custom-mutiple-select democlass"
                                        (change)="selectRelatedCustomer($event)" placeholder="Select"
                                        clearAllText="Clear" formControlName="outside_sales_id">
                                        <ng-option *ngFor="let client of allClientDetails;" value="{{client.id}}">
                                            {{client.name}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Flag </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select democlass" Id="Flag"
                                            (change)="selectFlag($event)" formControlName="flag_id" placeholder="Select"
                                            clearAllText="Clear">

                                            <ng-option *ngFor="let flag of flags; let i = index" value="{{flag.id}}">
                                                {{flag.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" *ngIf="!isSelectedFlag" class="btn btn-default"
                                            (click)="openFlagModal(addFlagModal)"><i class="fa fa-plus"></i> Add
                                            New</button>
                                        <button type="button" *ngIf="isSelectedFlag" class="btn btn-default"
                                            (click)="openFlagModal(addFlagModal)"><i class="fa fa-eye"></i>
                                            View/Edit</button>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Status </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select" (change)="selectStatus($event)"
                                            formControlName="status_id" placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let status of statuses;let i = index"
                                                value="{{status.id}}"> {{status.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" *ngIf="!isSelectedStatus" class="btn btn-default"
                                            (click)="openStatusModal(addStatusModal)"><i class="fa fa-plus"></i> Add
                                            New</button>
                                        <button type="button" *ngIf="isSelectedStatus" class="btn btn-default"
                                            (click)="openStatusModal(addStatusModal)"><i class="fa fa-eye"></i>
                                            View/Edit </button>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Type </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select" formControlName="type_id"
                                            placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let type of allTypes;let i = index" value="{{type.id}}">
                                                {{type.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" class="btn btn-default"
                                            (click)="flagManagementType('/flag-management/types')"><i
                                                class="fa fa-cog"></i> Manage </button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-modal-group">
                <button type="submit" [disabled]="!clientRegistrationForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
            </div>
        </form>
    </div>

    <div class="modal-footer">
        <!-- <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button> -->
    </div>

</ng-template>

<!--  Call Logs Registration -->
<ng-template #callLogsregistrationFormModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <form [formGroup]="callLogsForm" (ngSubmit)="submitCallLogsData(callLogsForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Call Details</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-3">
                                    <label for="received_date" class="col-form-label text-light-black font-16">Received
                                        Date
                                        <span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md"
                                        formControlName="received_date" [(ngModel)]="receivedDate" type="date">

                                </div>
                                <div class="form-group col-md-3">
                                    <label for="time" class="col-form-label text-light-black font-16">Time <span
                                            class="text-danger">*</span></label>
                                    <ng-select class="custom-mutiple-select" (change)="selectStatus($event)"
                                        formControlName="time" placeholder="Select" clearAllText="Clear">
                                        <ng-option *ngFor="let time of times;let i = index" value="{{time.value}}">
                                            {{time.label}}</ng-option>
                                    </ng-select>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="contact_id"
                                        class="col-form-label text-light-black font-16">Contact</label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select formControlName="contact_id" class="custom-mutiple-select"
                                            placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let contact of allContacts;" value="{{contact.id}}">
                                                {{contact.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" (click)="openContactModal(addContactModal)"
                                            class="btn btn-default"><i class="fa fa-plus"></i> Add
                                            New</button>
                                    </div>
                                </div>
                                <div class="form-group col-sm-12">
                                    <label for="notes">Notes <span class="text-danger">*</span> </label>
                                    <textarea formControlName="notes" class="form-control" cols="30"
                                        rows="7"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Call Scripts</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <p>Which script would you like to use for this follow up?</p>
                                    <p>No scripts available.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-modal-group">
                <button type="submit" [disabled]="!callLogsForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
            </div>
        </form>
    </div>

    <div class="modal-footer">
        <!-- <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button> -->
    </div>

</ng-template>

<!-- Flag Modal  -->
<ng-template #addFlagModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Flag Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <app-modal-flag [details]="flagDetails" [flagModelRef]="flagModelRef" [modelName]="modelName"></app-modal-flag>
    </div>

</ng-template>

<!-- Status Modal  -->
<ng-template #addStatusModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Status Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <app-modal-status [details]="statusDetails" [statusModelRef]="statusModelRef" [modelName]="modelName">
        </app-modal-status>
    </div>
</ng-template>


<!-- Contact Modal  -->
<ng-template #addContactModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <form [formGroup]="ContactForm" (ngSubmit)="submitContactData(ContactForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Contact Details</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="first_name" class="col-form-label text-light-black font-16">First Name
                                        <span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="first_name"
                                        type="text">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="last_name" class="col-form-label text-light-black font-16"> Last Name
                                        <span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="last_name"
                                        type="text">
                                </div>
                                <div class="form-group col-md-8">
                                    <label for="email" class="col-form-label text-light-black font-16">Email
                                        <span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="email"
                                        type="text">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="title" class="col-form-label text-light-black font-16">Title </label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="title"
                                        type="text">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="phone" class="col-form-label text-light-black font-16">Phone (Work)
                                    </label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="phone"
                                        type="text">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="phone_mobile" class="col-form-label text-light-black font-16">Phone
                                        (Home)</label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="phone_mobile"
                                        type="text">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="phone_mobile" class="col-form-label text-light-black font-16">Phone
                                        (Mobile)</label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="phone_mobile"
                                        type="text">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-body">
                            <div class="form-row">
                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Flag </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select democlass" Id="Flag"
                                            (change)="selectFlag($event)" formControlName="flag_id" placeholder="Select"
                                            clearAllText="Clear">

                                            <ng-option *ngFor="let flag of flags; let i = index" value="{{flag.id}}">
                                                {{flag.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" *ngIf="!isSelectedFlag" class="btn btn-default"
                                            (click)="openFlagModal(addFlagModal)"><i class="fa fa-plus"></i> Add
                                            New</button>
                                        <button type="button" *ngIf="isSelectedFlag" class="btn btn-default"
                                            (click)="openFlagModal(addFlagModal)"><i class="fa fa-eye"></i>
                                            View/Edit</button>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Status </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select" (change)="selectStatus($event)"
                                            formControlName="status_id" placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let status of statuses;let i = index"
                                                value="{{status.id}}"> {{status.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" *ngIf="!isSelectedStatus" class="btn btn-default"
                                            (click)="openStatusModal(addStatusModal)"><i class="fa fa-plus"></i> Add
                                            New</button>
                                        <button type="button" *ngIf="isSelectedStatus" class="btn btn-default"
                                            (click)="openStatusModal(addStatusModal)"><i class="fa fa-eye"></i>
                                            View/Edit </button>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Type </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select" formControlName="type_id"
                                            placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let type of allTypes;let i = index" value="{{type.id}}">
                                                {{type.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" class="btn btn-default"
                                            (click)="flagManagementType('/flag-management/types')"><i
                                                class="fa fa-cog"></i> Manage </button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-modal-group">
                <button type="submit" [disabled]="!ContactForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
            </div>
        </form>
    </div>

</ng-template>

<!-- Delete  -->
<ng-template #callLogsDeleteModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>

    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteCallLogs()" class="btn btn-success  border-r-10">Yes, delete
                it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>

</ng-template>

<!-- clientDeleteModal  -->
<ng-template #clientDeleteModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>

    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteClient()" class="btn btn-success  border-r-10">Yes, delete
                it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>

</ng-template>