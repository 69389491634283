<form [formGroup]="triggerForm" (ngSubmit)="submitTriggerForm(triggerForm.value)">
    <input type="hidden" formControlName="id">
    <div class="row">
        <div class="col-sm-6">
            <label for="">Enter a name for the trigger.</label>
            <input type="text" formControlName="name" class="form-control">
        </div>
        <div class="col-sm-6">
            <label for=""> Select the type of trigger.</label>
            <ng-select (change)="getData($event)" formControlName="trigger_type">
                <ng-option value="Email (Button)">Email (Button)</ng-option>
            </ng-select>
        </div>
    </div>
    
    <div class="row" *ngIf="isForm">
        <div class="col-sm-6">
            <label for="">Model</label>
            <ng-select formControlName="model_name" (change)="getTableColumn($event)">
                <ng-option *ngFor="let model of models;" value="{{ model.tableName }}">{{model.tableName}}</ng-option>
            </ng-select>
        </div>
        <div class="col-sm-6">
            <label for="">Email Field</label>
            <ng-select formControlName="related_field">
                <ng-option *ngFor="let column of columns;" value="{{column}}">{{column}} </ng-option>
            </ng-select>
        </div>
        <div class="col-sm-4">
            <label for="">Button label</label>
            <input type="text" formControlName="button_label" class="form-control">
        </div>
        <div class="col-sm-4">
            <label for="">Button Icon</label>
            <ng-select formControlName="button_icon" clearAllText="Clear" class="form-control-ng rounded-0">
                <ng-option *ngFor="let icon of icons;" value="{{icon.l}}">{{icon.l}} <i class="fa {{ icon.c }} pull-right"></i></ng-option>
            </ng-select>
        </div>
        <div class="col-sm-4">
            <label for="">Button Location</label>
            <ng-select formControlName="button_location">
                <ng-option value="Details Page - Heading">Details Page - Heading</ng-option>
            </ng-select>
        </div>
        <div class="col-sm-12">
            <label for="">Email Subject</label>
            <input type="text" formControlName="email_subject" class="form-control">
        </div>
        <div class="col-sm-12">
            <label for="">Email Body</label>
            <editor formControlName="email_body" [init]="{
                base_url: '/tinymce',
                suffix: '.min',
                height: 500,
                menubar: true,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
                toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
            }"></editor>
        </div>
        
    </div>
    <div class="row">
        <div class="col-sm-12">
            <button class="action-btn bg-dark-blue mt-2">Submit</button>
        </div>
    </div>
</form>
