import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-crm-follow-up-comment',
  templateUrl: './crm-follow-up-comment.component.html',
  styleUrls: ['./crm-follow-up-comment.component.css']
})
export class CrmFollowUpCommentComponent implements OnInit {

  followupCommentForm:FormGroup;
  page: any = 1;
  totalRecords: any;
  followupcomment:any=[];
  editData:any=[];
  response:any;
  followupcommentid:any;


  formErrors = {
    'comment':''
  }

  validationMessages = {
    'comment':{
      'required': 'Comment is required.',
      'minlength': 'Comment must be 3 characters long.'
    }
  }

  constructor(private api : ApiService, private modalService : NgbModal, private formBuilder : FormBuilder , private toastr:ToastrService) {
    this.followupCommentForm = this.formBuilder.group({
        followupcomment: [''],
    });
    this.getFollowUpComment(this.page);

  }

  ngOnInit(): void {
    this.init();
  }

  init() :void{
    this.followupCommentForm = this.formBuilder.group({
      id: [''],
      comment: ['', [Validators.required, Validators.minLength(3)]],
    });
    this.followupCommentForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.followupCommentForm);
    });
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  getFollowUpComment(page):void{
    this.page = page;
    this.api.get("admin/follow_up_comment/lists?page="+page).subscribe((result)=>{
      this.totalRecords = result.data.total;
      this.followupcomment = result.data.data;
    },(err)=>{

    });
  }

  editFollowFupComment(content, index): void {
    this.followupCommentForm.reset();
    this.editData = this.followupcomment[index];
    this.followupCommentForm.patchValue(this.editData);
    this.modalService.open(content, { size: 'lg', centered: true, scrollable: true });
  }


  addFollowUpComment(content){
    this.followupCommentForm.reset();
    this.editData = {};
    this.modalService.open(content,{ size:'xl',centered:true });
  }

  submitFollowUpCommentData(data):void{
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    let endPoint = (data.id) ? 'update' : 'create';
    this.api.post('admin/follow_up_comment/' + endPoint, data).subscribe((result) => {
      this.response=result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.getFollowUpComment(this.page);
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;

    }, (err) => {
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.toastr.error(err.error.message,"Error");
    });
  }

  deleteModal(content,current_row){
    this.followupcommentid = current_row.id;
    this.modalService.open(content,{ size:'sm',centered:true });
  }

  async deleteFollowUpComment(){

    let data = { id: this.followupcommentid }
    this.api.post('admin/follow_up_comment/delete', data).subscribe((result) => {
       this.response=result;
       this.toastr.success(this.response.message,"Success");
       this.modalService.dismissAll();
       this.getFollowUpComment(this.page);
    }, (err) => {
       console.log(err);
       this.modalService.dismissAll();
       this.toastr.error(err.error.message,'Error');
    });

  }

}
