import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-headernavbar',
  templateUrl: './headernavbar.component.html',
  styleUrls: ['./headernavbar.component.css']
})
export class HeadernavbarComponent implements OnInit {
  modalReference: any = '';
  constructor(private router : Router, private _modalService: NgbModal) {

  }

  ngOnInit(): void {
  }

  logout(): void {
    localStorage.removeItem('reliant_user'); 
    localStorage.removeItem('reliant_role_based_user');
    localStorage.removeItem('isReliantUserLoggedIn');
    this.router.navigate(['/login']);
  }
  toggleMainMenu(event: any){
  var pageWrapper = document.getElementsByClassName('ecaps-page-wrapper')[0];
  var sideMenuArea =  document.getElementsByClassName('ecaps-sidemenu-area')[0];
  //console.log(event.target.classList)

  pageWrapper.classList.contains('menu-collasped-active') ?  pageWrapper.classList.remove('menu-collasped-active') : pageWrapper.classList.add('menu-collasped-active')
  
  
}

mobileToggleMainMenu(event: any){
  var pageWrapper = document.getElementsByClassName('ecaps-page-wrapper')[0];
  var sideMenuArea =  document.getElementsByClassName('ecaps-sidemenu-area')[0];
  //console.log(event.target.classList)

  pageWrapper.classList.contains('mobile-menu-active') ?  pageWrapper.classList.remove('mobile-menu-active') : pageWrapper.classList.add('mobile-menu-active')
 
}

}
