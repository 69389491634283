<div class="main-content">
    <div class="container-fluid">

        <div class="row">
            <!-- Support Ticket -->
            <div class="col-xl-8 height-card box-margin">
                <div class="card">
                    <div class="card-header bg-green d-flex align-items-center justify-content-between">
                        <h6 class="card-title mb-0">{{employee.name}}</h6>
                    </div>

                    <!-- Tab -->
                    <div class="card-body user-area ">
                        <div class="row justify-content-lg-center" id="badge">
                            <div class="height-card box-margin m-2" #front id="front" [style.width.px]="badge_card_width">
                                <div class="card">
                                    <div class="card-header border-0 pb-0" [style.background-color]="topColor">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <div class="badge-profile" [style.height.px]="profile_image"
                                                [style.width.px]="profile_image">
                                                <img class=" user-thumb" [style.height.px]="profile_image"
                                                    [style.width.px]="profile_image" *ngIf="employee.profile_image"
                                                    src="{{filePath+employee.profile_image}}" alt="">
                                                <img class=" user-thumb" [style.height.px]="profile_image"
                                                    [style.width.px]="profile_image" *ngIf="employee.profile_image==''"
                                                    src="assets/img/core-img/noimg.jpg" alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body text-center mt-50">

                                        <h5 class="card-title mb-0 font-weight-600"
                                            [style.font-size.px]="first_name" [style.line-height.px]="first_name">
                                            {{employee.first_name}}</h5>
                                        <p [style.font-size.px]="last_name" [style.line-height.px]="last_name"
                                            [style.margin-bottom.px]="last_name">{{employee.last_name}}</p>

                                        <div class="bag-logo-box d-flex align-items-center justify-content-center">
                                            <div class="bag-logo mr-10">
                                                <img src="assets/img/favicon.ico" [style.height.px]="badge_logo">
                                            </div>
                                            <div class="bag-content text-left">
                                                <h5 class="font-weight-600" [style.font-size.px]="badge_heading">Reliant Staffing</h5>
                                                <p class=" m-0" [style.font-size.px]="badge_subheading">{{employee.phone}}</p>
                                                <p class=" m-0" [style.font-size.px]="badge_subheading">id: #{{getNumber(6,employee.id)}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer" [style.background-color]="bottomColor">
                                        <h1 class="m-0 text-center text-white font-weight-600 text-uppercare"
                                            [style.font-size.px]="speciality" [style.line-height.px]="speciality"
                                            *ngIf="employee.specialty"> {{ employee.specialty.name }} </h1>
                                    </div>
                                </div>
                            </div>

                            <div class=" height-card box-margin m-2" id="back" [style.width.px]="badge_card_width">
                                <div class="card">
                                    <div class="card-header border-0 pb-0" [style.background-color]="topColor">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <div class="badge-profile">
                                                <img [src]="getQrImage(employee)" [style.width.px]="qrcode_width">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body text-center mt-50">

                                        <h5 class="card-title mb-0 font-weight-600" [style.font-size.px]="back_badge_heading">Access Your Profile</h5>
                                        <p class="" [style.font-size.px]="back_badge_subheading">Scan Using Your Phone</p>
                                        <p class="font-weight-600 mt-15 back_badge_heading" [style.font-size.px]="back_badge_heading">Employee Profile</p>
                                        <p class="font-weight-600 back_badge_heading" [style.font-size.px]="back_badge_heading">Availability Form</p>
                                        <p class="font-weight-600 back_badge_heading" [style.font-size.px]="back_badge_heading">Time Sheet</p>
                                    </div>
                                    <!-- <div class="card-footer bg-dark-blue">
                                        <h1 class="m-0 text-center text-white font-22 font-weight-600 text-uppercare">CNA</h1>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Projects of the Month -->
            <div class="col-xl-4 height-card box-margin">
                <div class="card">
                    <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                        <h6 class="card-title mb-0">Layers</h6>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card boxshadow-hide">
                                    <div class="card-body p-0">
                                        <div id="accordion-">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="card mb-30">
                                                        <div class="card-header bg-green">
                                                            <a class="collapsed text-white justify-content-between d-flex align-item-center "
                                                                data-toggle="collapse" href="#accordion-1"
                                                                aria-expanded="false">
                                                                <h6 class="card-title mb-0">Background</h6>
                                                                <i class="fa fa-address-card text-dark"></i>
                                                            </a>
                                                        </div>
                                                        <div id="accordion-1" class="collapse"
                                                            data-parent="#accordion-">

                                                            <div class="card-body">

                                                                <div class="form-row">

                                                                    <div class="form-group col-md-12">
                                                                        <label for="" class="">Primary Color</label>
                                                                        <input (change)="getBottomColor($event)"
                                                                            [value]="bottomColor" type="color"
                                                                            class="form-control color-input">
                                                                    </div>
                                                                    <div class="form-group col-md-12">
                                                                        <label for="input" class="">Secondary
                                                                            Color</label>
                                                                        <input
                                                                            (change)="getTopColor($event)"
                                                                            [value]="topColor" type="color"
                                                                            class="form-control color-input">

                                                                    </div>
                                                                    <div class="form-group col-md-12 mb-0">
                                                                        <label for="example-range">Zoom</label>
                                                                        <input class="custom-range" id="example-range"
                                                                            type="range" name="range" (input)="Zoom($event)" step="0.01" value="0" min="1" max="3.8">
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="card mb-30">
                                                        <div class="card-header bg-green">
                                                            <a class="collapsed text-white justify-content-between d-flex align-item-center "
                                                                data-toggle="collapse" href="#accordion-2"
                                                                aria-expanded="false">
                                                                <h6 class="card-title mb-0">Image</h6>
                                                                <i class="fa fa-image text-dark"></i>
                                                            </a>
                                                        </div>
                                                        <div id="accordion-2" class="collapse" data-parent="#accordion-">
                                                            <div class="card-body">
                                                                <div class="form-group mb-15">
                                                                    <label for="example-range">Constrain to image
                                                                        height?</label>
                                                                    <div class="new-checkbox d-flex align-items-center">
                                                                        <!-- Rounded switch -->
                                                                        <span class="mr-10">NO</span>
                                                                        <label class="switch mr-10 mb-0">
                                                                            <input type="checkbox">
                                                                            <span class="slider round"></span>
                                                                        </label>
                                                                        <span class="ml-10">YES</span>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group mb-15">
                                                                    <label for="example-range">Force image to
                                                                        box?</label>
                                                                    <div class="new-checkbox d-flex align-items-center">
                                                                        <!-- Rounded switch -->
                                                                        <span class="mr-10">NO</span>
                                                                        <label class="switch mr-10 mb-0">
                                                                            <input type="checkbox">
                                                                            <span class="slider round"></span>
                                                                        </label>
                                                                        <span class="ml-10">YES</span>
                                                                    </div>
                                                                </div>

                                                                <div class="form-group mb-0">
                                                                    <label for="example-range">Size</label>
                                                                    <input class="custom-range"
                                                                        (input)="getImageSize($event)"
                                                                        id="example-range" type="range" name="range"
                                                                        min="50" value="97.25" max="200">
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="card mb-30">
                                                        <div class="card-header bg-green">
                                                            <a class="collapsed text-white justify-content-between d-flex align-item-center "
                                                                data-toggle="collapse" href="#accordion-3"
                                                                aria-expanded="false">
                                                                <h6 class="card-title mb-0">Text</h6>
                                                                <i class="fa fa-file-text text-dark"></i>
                                                            </a>
                                                        </div>
                                                        <div id="accordion-3" class="collapse" data-parent="#accordion-">
                                                            <div class="card-body">

                                                                <div class="form-group mb-0">
                                                                    <label for="example-range">First Name</label>
                                                                    <input class="custom-range" id="example-range"
                                                                        type="range" (input)="firstNameSize($event)"
                                                                        min="0" [value]="first_name" max="50">
                                                                </div>
                                                                <div class="form-group mb-0">
                                                                    <label for="example-range">Last Name</label>
                                                                    <input class="custom-range" id="example-range"
                                                                        type="range" min="0"
                                                                        (input)="lastNameSize($event)"
                                                                        [value]="last_name" max="50">
                                                                </div>
                                                                <div class="form-group mb-0">
                                                                    <label for="example-range">Specialty</label>
                                                                    <input class="custom-range" id="example-range"
                                                                        type="range" (input)="specialitySize($event)"
                                                                        min="0" [value]="speciality" max="50">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12 text-center">
                                <button type="button" class="btn bg-orange mb-2 mr-2" (click)="Print('badge')">Print</button>
                                <button type="button" class="btn bg-dark-green mb-2 mr-2" (click)="saveImage('front')">Save
                                    Front</button>
                                <button type="button" class="btn bg-dark-blue mb-2 mr-2" (click)="saveImage('back')">Save
                                    Back</button>
                            </div>

                       </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>