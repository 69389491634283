import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-headernavbar',
  templateUrl: './user-headernavbar.component.html',
  styleUrls: ['./user-headernavbar.component.css']
})
export class UserHeadernavbarComponent implements OnInit {
  user:any =[];
  constructor(
    private router : Router, 
    private _modalService: NgbModal
    ) {
      this.user =  JSON.parse(localStorage.getItem('reliant_role_based_user'));
  }

  ngOnInit(): void {
  }
  logout(): void {
    localStorage.removeItem('reliant_user'); 
    localStorage.removeItem('reliant_role_based_user');
    localStorage.removeItem('isReliantUserLoggedIn');
    this.router.navigate(['/login']);
  }
  toggleMainMenu(event: any){
    var pageWrapper = document.getElementsByClassName('ecaps-page-wrapper')[0];
    var sideMenuArea =  document.getElementsByClassName('ecaps-sidemenu-area')[0];
    //console.log(event.target.classList)
  
    pageWrapper.classList.contains('menu-collasped-active') ?  pageWrapper.classList.remove('menu-collasped-active') : pageWrapper.classList.add('menu-collasped-active')
    
    
  }
  
  mobileToggleMainMenu(event: any){
    var pageWrapper = document.getElementsByClassName('ecaps-page-wrapper')[0];
    var sideMenuArea =  document.getElementsByClassName('ecaps-sidemenu-area')[0];
    //console.log(event.target.classList)
  
    pageWrapper.classList.contains('mobile-menu-active') ?  pageWrapper.classList.remove('mobile-menu-active') : pageWrapper.classList.add('mobile-menu-active')
   
  }
}
