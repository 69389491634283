import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../helper/helper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-crm-follow-ups',
  templateUrl: './modal-crm-follow-ups.component.html',
  styleUrls: ['./modal-crm-follow-ups.component.css']
})
export class ModalCrmFollowUpsComponent implements OnInit {

  @Input() modelName: any;
  @Input() followUpsDetails: any;
  @Input() followUpsModelRef: any;
  @Input() activeTabToday: any;
  @Input() isTrue: any;
  @Input() modelDetails: any;
  @Input() opportunities_id: any;
  @Input() customerid:any;
  followUpsForm: FormGroup;
  flagForm: FormGroup;
  followUpsDetailsForm: FormGroup;

  page: any = 1;
  totalRecords: any = 0;
  details: any = [];
  flags: any = [];
  response: any = [];
  statuses: any = [];
  flagDetails: any = [];
  statusDetails: any = [];
  statusModelRef: any = [];
  states: any = [];
  activeTab: any = 0;
  allClients: any = [];
  editData: any = [];
  editFlag: any = [];
  editStatus: any = [];
  allAssignees: any = [];
  followUps: any = [];
  cartscriptid: any;
  allContacts: any = [];
  currentIndex: any = 0;
  isSelectedFlag: boolean = false;
  isSelectedStatus: boolean = false;
  dueDate: any = '';
  allTodatadata: any = [];
  flagModelRef: any = [];
  callCycle: any = [
    { label: "Every Six Months", value: "Every Six Months", days: 182 },
    { label: "Every Two Weeks", value: "Every Two Weeks", days: 15 },
    { label: "Every Two Years", value: "Every Two Years", days: 730 },
    { label: "Monthly", value: "Monthly", days: 30 },
    { label: "Quarterly", value: "Quarterly", days: 90 },
    { label: "Weekly", value: "Weekly", days: 7 },
    { label: "Yearly", value: "Yearly", days: 365 },
  ];
  allOpportunities: any[];
  user: any = [];
  user_id: any;
  gittingdata: any;
  modelNameTemp: boolean = false
  activeTabTodayTemp: boolean = false;
  isTrueTeam: boolean = false;
  modelDetailsTemp: boolean = false;
  call_cycle: any = '';
  days: any = '';

  formErrors = {
    'id': '',
    'assignee_id': '',
    'type': '',
    'customer_id': '',
    'contact_id': '',
    'flags_selection': '',
    'status_selection': '',
    'due_date': '',
    'notes': '',
    'opportunity_id': '',


  };

  validationMessages = {
    'name': {
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    }
  };

  constructor(
    private api: ApiService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private helperService: HelperService,
    private router: Router,
    private toastr: ToastrService,
  ) {

    this.user = JSON.parse(localStorage.getItem('reliant_user'));
    this.followUpsForm = this.formBuilder.group({
      id: [''],
      due_date: ['', [Validators.required]],
      assignee_id: ['', [Validators.required]],
      type: [''],
      customer_id: ['', [Validators.required]],
      contact_id: [''],
      flags_selection: [''],
      status_selection: [''],
      notes: [''],
      opportunity_id: [],
    });
    this.followUpsDetailsForm = this.formBuilder.group({
      id: [''],
      due_date: ['', [Validators.required]],
      assignee_id: ['', [Validators.required]],
      type: [''],
      customer_id: ['', [Validators.required]],
      contact_id: [''],
      flags_selection: [''],
      status_selection: [''],
      notes: [''],
      opportunity_id: [''],
      call_cycle: [''],
      days: [''],
    });
  }

  ngOnInit(): void {

    (this.opportunities_id != undefined) ? this.opportunities_id = "&opportunities_id=" + this.opportunities_id : this.opportunities_id = "";
    (this.gittingdata !== undefined) ? this.gittingdata = "&assignee_id=" + this.gittingdata : this.gittingdata = '';
    (this.modelName == 'follow-ups') ? this.modelNameTemp = true : this.modelNameTemp = false;
    (this.activeTabToday == 1) ? this.activeTabTodayTemp = true : this.activeTabTodayTemp = false;
    (this.isTrue == true) ? this.isTrueTeam = true : this.isTrueTeam = false;
    (this.isTrue == true) ? this.activeTab = 1 : this.activeTab = 0;
    this.getFollowUps(this.page);
    (this.modelDetails == 'view-follow-ups') ? this.modelDetailsTemp = true : this.modelDetailsTemp = false;
    this.getTodayData();
    console.log("modelName==>",this.modelName)
  }
  async getFollowUps(page) {
    let customerId = (this.modelName == "customer-profile")?"&customer_id="+this.customerid:'';
    this.api.get('admin/follow_ups/lists?page=' + page + '&activeTab=' + this.activeTab + "&assignee_id=" + this.gittingdata+customerId).subscribe((result) => {
      this.totalRecords = result.data.total;
      this.followUps = result.data.data;

    }, (err) => {

    });
  }
  async getClient() {
    this.api.get('admin/follow_ups/get_client').subscribe((result) => {
      this.response = result;
      this.allClients = this.response.data;
    }, (err) => {

    });
  }
  async getAssignee() {
    this.api.get('admin/follow_ups/get_assignee').subscribe((result) => {
      this.response = result;
      this.allAssignees = this.response.data;
    }, (err) => {

    });
  }
  async getContacts() {
    this.api.get('admin/follow_ups/get_contacts').subscribe((result) => {
      this.response = result;
      this.allContacts = this.response.data;
    }, (err) => {

    });
  }
  // async getOpportunities() {
  //   this.api.get('admin/follow_ups/get_opportunities').subscribe((result) => {
  //     this.response = result;
  //     this.allOpportunities = this.response.data;
  //   }, (err) => {

  //   });
  // }

  getAllFollowUps(tabValue) {
    this.activeTab = tabValue
    this.getFollowUps(1)
  }
  crmDashboardDetail(key) {
    (key == 1) ? this.isTrueTeam = true : this.isTrueTeam = false;
    this.activeTab = key;
    (key == 2 || key == 3) ? this.isTrueTeam = false : this.isTrueTeam = true;
    (key == 1 || key == 2 || key == 3) ? this.gittingdata = this.user.id : this.gittingdata = null;
    this.getFollowUps(1);
  }
  addfollowUps(content) {
    this.getFlag();
    this.getStatus();
    this.getClient();
    this.getAssignee();
    this.getContacts();
    this.getAllOpportunities();
    this.followUpsForm.reset();
    this.dueDate = '';
    this.modalService.open(content, { size: 'xl', centered: true });
  }
  getFlag() {
    let data = { model_name: 'follow-ups' };
    this.api.post('admin/core_flags/all_flags', data).subscribe((result) => {
      this.response = result;
      this.flags = this.response.data;
    }, (err) => {
    });
    return true;
  }
  getStatus() {
    let data = { model_name: 'follow-ups' };
    this.api.post('admin/core_statuses/all_statuses', data).subscribe((result) => {
      this.response = result;
      this.statuses = this.response.data;
    }, (err) => {
    });
    return true;
  }
  followUpDetailsModal(content, index, pagename) {
    this.call_cycle='';
    this.days='';
    (pagename == 'modal') ? this.editData = this.followUps[index] : this.editData = this.allTodatadata[index];
    this.getStatus();
    this.dueDate = this.editData.due_date.slice(0, 10)
    //this.followUpsForm.patchValue(this.editData);
    this.followUpsDetailsForm.patchValue(this.editData);
    this.modalService.open(content, { size: 'xl', centered: true });
    this.followUpsDetails = this.editData;

  }
  closeModal(modalRef) {
    modalRef.result.then((data) => {
    }, (reason) => {
      if (reason != 0 && reason != 'Cross  click') {
        this.getFlag();
        this.getStatus();
      }

    });
  }
  deleteModal(content, current_row) {
    this.cartscriptid = current_row.id;
    this.modalService.open(content, { size: 'sm', centered: true });
  }
  async deleteFollowUps() {
    let data = { id: this.cartscriptid }
    this.api.post('admin/follow_ups/delete', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getFollowUps(this.page);
      this.modalService.dismissAll();
    }, (err) => {
      console.log(err);
      this.toastr.error(err.error.message, 'Error');
      this.modalService.dismissAll();
    });
  }

  editfollowUpsModal(content, index): void {
    this.followUpsForm.reset();
    this.getFlag();
    this.getStatus();
    this.getClient();
    this.getAssignee();
    this.getContacts();
    this.getAllOpportunities();
    this.editData = this.followUps[index];
    this.dueDate = this.editData.due_date.slice(0, 10);
    this.followUpsForm.patchValue(this.editData);
    this.modalService.open(content, { size: 'xl', centered: true, scrollable: true });
  }
  editfollowUpsModalDashBord(content, index): void {
    this.followUpsForm.reset();
    this.getFlag();
    this.getStatus();
    this.getClient();
    this.getAssignee();
    this.getContacts();
    this.getAllOpportunities();
    this.editData = this.allTodatadata[index];
    this.dueDate = this.editData.due_date.slice(0, 10);
    this.followUpsForm.patchValue(this.editData);
    this.modalService.open(content, { size: 'xl', centered: true, scrollable: true });
  }
  submitFollowUpsData(data) {
    let endPoint = (data.id) ? 'update' : 'create';
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/follow_ups/' + endPoint, data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getFollowUps(this.page);
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.modalService.dismissAll();
    }, (err) => {
      this.toastr.error(err.error.message, "Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });
  }
  selectFlag(event) {
    (event != null) ? this.isSelectedFlag = true : this.isSelectedFlag = false;
    this.editFlag = this.flags.filter(function (arr) { return arr.id == event })[0];
  }
  selectStatus(event) {
    (event != null) ? this.isSelectedStatus = true : this.isSelectedStatus = false;
    this.editStatus = this.statuses[event];
  }
  openFlagModal(content) {
    this.flagModelRef = [];
    this.modelName = 'follow-ups';
    const modalRef = this.modalService.open(content, { size: 'xl', centered: true });
    this.flagDetails = this.editFlag;
    this.flagModelRef.push(modalRef);
    this.closeModal(modalRef);
  }
  openStatusModal(content) {
    this.statusModelRef = [];
    this.modelName = 'follow-ups';
    const modalRef = this.modalService.open(content, { size: 'xl', centered: true });
    this.statusDetails = this.editStatus;
    this.statusModelRef.push(modalRef);
    this.closeModal(modalRef);
  }
  async getTodayData() {
    let data = { user_id: this.user.id }
    this.api.post('admin/follow_ups/get_today_data', data).subscribe((result) => {
      this.response = result;
      this.allTodatadata = this.response.data.data;
    });
  }
  followUpDetailsUpdate(content) {
    var index = this.currentIndex;
    this.followUpsForm.reset();
    this.getFlag();
    this.getStatus();
    this.getClient();
    this.getAssignee();
    this.getContacts();
    this.editData = this.allTodatadata[index];
    this.followUpsForm.patchValue(this.editData);
    this.modalService.open(content, { size: 'xl', centered: true, scrollable: true });
  }
  async getAllOpportunities() {
    this.api.get('admin/opportunities/all_opportunities').subscribe((result) => {
      this.response = result;
      this.allOpportunities = this.response.data;
    }, (err) => {

    });
  }

  followUpDetailsDelete() {
    console.log('Hello', this.editData);
  }
  selectCallCycle(callcycle) {
    const call_cycle_arr = callcycle.split("_");
    this.call_cycle = call_cycle_arr[0];
    this.days = call_cycle_arr[1];
  }
  submitFollowUpsFinishData(data) {
    data.call_cycle = this.call_cycle;
    data.days = this.days;
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/follow_ups/create', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getFollowUps(this.page);
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.modalService.dismissAll();
    }, (err) => {
      this.toastr.error(err.error.message, "Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });

  }

}
