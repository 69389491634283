import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-specialties',
  templateUrl: './specialties.component.html',
  styleUrls: ['./specialties.component.css']
})
export class SpecialtiesComponent implements OnInit {

  specialtyForm: FormGroup;
  specialties: any = [];
  editData: any = {};
  isSaved: boolean = false;
  isError: boolean = false;
  isDeleted: boolean = false;
  editTitle: String = '';
  response:any;
  formErrors = {
    'name': ''
  };
  validationMessages = {
    'name': {
      'required': 'Name is required.'
    }
  };

  constructor(
    private api: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr:ToastrService
  ) {}

  ngOnInit(): void {
    this.specialtyForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      job_description: ['']
    });
    this.specialtyForm.valueChanges.subscribe((data) => {
      this.validateKeyValuePairs(this.specialtyForm);
    });

    this.getSpecialties();
  }

  async getSpecialties(){
    this.api.get('admin/specialities/lists').subscribe((result) => {
        this.specialties = result.data;
    }, (err) => {
    });
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      this.formErrors[key] = '';
      if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
        const messages = this.validationMessages[key];
        for (const errorKey in abstractControl.errors) {
          if (errorKey) {
            this.formErrors[key] += messages[errorKey] + ' ';
          }
        }
      }
    });
  }

  editSpecialty( content, index ): void{
    this.editTitle = 'Editing Specialty';
    this.editData = this.specialties[index];
    this.specialtyForm.reset();
    this.specialtyForm.patchValue(this.editData);
    this.modalService.open(content, { size: 'lg',  centered: true });
  }

  deleteSpecialty( id ): void{
    this.api.post('admin/specialities/delete', {id: id}).subscribe((result) => {
      this.response=result;
      this.toastr.success(this.response.message,"Success");
      this.getSpecialties();
      this.isSaved = false;
      this.isDeleted = true;
    }, (err) => {
      this.toastr.error(err.error.message,"Error");
    });
  }

  addSpecialty(content): void {
    this.editTitle = 'Create New Specialty';
    this.editData = {};
    this.specialtyForm.reset();
    this.modalService.open(content, { size: 'lg',  centered: true});
  }

  submitData(data): void{
    let endPoint = (data.id)?'update':'create';
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/specialities/'+endPoint, data).subscribe((result) => {
      this.response=result;
      this.toastr.success(this.response.message,"Success");
      this.getSpecialties();
      this.isSaved = true;
      this.isDeleted = false;
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      (<HTMLButtonElement>document.getElementsByClassName("modal-dismiss")[0]).click();
    }, (err) => {
      this.toastr.error(err.error.message,"Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });
  }
}
