import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-administration-call-schedule',
  templateUrl: './administration-call-schedule.component.html',
  styleUrls: ['./administration-call-schedule.component.css']
})
export class AdministrationCallScheduleComponent implements OnInit {
  callScheduleForm:FormGroup;
  isNo: boolean = false;
  constructor(
    private api: ApiService, 
    private modalService: NgbModal, 
    private formBuilder: FormBuilder,
    private toast:ToastrService,
    public toastr:ToastrService,
  ) { }

  ngOnInit(): void {
    this.init();
  }
  init() :void{
    this.callScheduleForm = this.formBuilder.group({
      name:[''],
    });
  }
  submitCallScheduleData(data){
    console.log(data);
  }
  timesheetsChange(key) {

    (key == 0) ? this.isNo = true : this.isNo = false;
    (key == 1) ? this.isNo = false : this.isNo = true;

  }
}
