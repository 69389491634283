<div class="row">
    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Libraries</h6>
            </div>
            <div class="card-body">
                <div class="tab-content list_table" id="courseList">
                   <div class="row">
                       <div class="col-sm-3">
                           <div class="card">
                               <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                                   <h6 class="card-title mb-0"><a routerLink="/administration/triggers" class="text-dark">Triggers</a></h6>
                               </div>
                               <div class="element-card-body p-10 text-center align-items-center">
                                   <i class="fa fa-play-circle-o fa-3x"></i>
                               </div>
                               <div class="card-footer text-center">
                                   <button class="action-btn bg-orange mr-2" (click)="openModal(createTriggerModal)">Create</button>
                                   <button class="action-btn bg-dark-blue" routerLink="/administration/triggers">View All</button>
                               </div>
                           </div>
                       </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
  </div>

  <!-- Add Trigger Model -->
<ng-template #createTriggerModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Trigger Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross  click')"
      aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <app-modal [details]="triggerObject"></app-modal>
    </div>
  </ng-template>
  

  