import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

import { LayoutComponent } from './layout/layout.component';
import { CrmLayoutComponent } from './crm-layout/crm-layout.component';
import { AdministrationLayoutComponent } from './administration-layout/administration-layout.component';
import { TrainingLayoutComponent } from './training-layout/training-layout.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { EmployeeComponent } from './employee/employee.component';
import { CrmClientComponent } from './crm-client/crm-client.component';
import { RolesComponent } from './roles/roles.component';
import { SpecialtiesComponent } from './specialties/specialties.component';
import { UserComponent } from './user/user.component';
import { RecruitingComponent } from './recruiting/recruiting.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { FormsComponent } from './forms/forms.component';
import { TrainingDashboardComponent } from './training-dashboard/training-dashboard.component';
import { CrmDashboardComponent } from './crm-dashboard/crm-dashboard.component';
import { AdministrationDashboardComponent } from './administration-dashboard/administration-dashboard.component';
import { TrainingCoursesComponent } from './training-courses/training-courses.component';
import { CrmMarketingComponent } from './crm-marketing/crm-marketing.component';
import { CrmCallScriptComponent } from './crm-call-script/crm-call-script.component';
import { CrmFollowUpCommentComponent } from './crm-follow-up-comment/crm-follow-up-comment.component';
import { AdministrationRatesComponent } from './administration-rates/administration-rates.component';
import { AdministrationMessageTemplatesComponent } from './administration-message-templates/administration-message-templates.component';
import { AdministrationCustomFilesComponent } from './administration-custom-files/administration-custom-files.component';
import { CrmCustomersProfileComponent } from './crm-customers-profile/crm-customers-profile.component';
import { EmployeeProfileComponent } from './employee-profile/employee-profile.component';
import { TrainingTopicComponent } from './training-topic/training-topic.component';
import { TrainingCertificationComponent } from './training-certification/training-certification.component';
import { EmployeeBadgeComponent } from './employee-badge/employee-badge.component';
import { AdministrationApplicationSettingsComponent } from './administration-application-settings/administration-application-settings.component';
import { AdministrationCompanySettingComponent } from './administration-company-setting/administration-company-setting.component';
import { AdministrationThemeSettingsComponent } from './administration-theme-settings/administration-theme-settings.component';
import { TrainingCourseRegistrationsComponent } from './training-course-registrations/training-course-registrations.component';
import { TrainingCourseBuilderComponent } from './training-course-builder/training-course-builder.component';
import { TrainingCourseShowComponent } from './training-course-show/training-course-show.component';
import { TrainingTestsBuilderComponent } from './training-tests-builder/training-tests-builder.component';
import { TrainingTestsComponent } from './training-tests/training-tests.component';
import { TrainingSectionViewComponent } from './training-section-view/training-section-view.component';
import { TrainingMyCoursesComponent } from './training-my-courses/training-my-courses.component';
import { AdministrationLibrariesComponent } from './administration-libraries/administration-libraries.component';
import { AdministrationTriggersComponent } from './administration-triggers/administration-triggers.component';
import { AdministrationBulkAvailabilityComponent } from './administration-bulk-availability/administration-bulk-availability.component';
import { CrmOpportunitiesComponent } from './crm-opportunities/crm-opportunities.component';
import { CrmFollowUpsComponent } from './crm-follow-ups/crm-follow-ups.component';
import { FlagManagementDashboardComponent } from './flag-management-dashboard/flag-management-dashboard.component';
import { FlagManagementLayoutComponent } from './flag-management-layout/flag-management-layout.component';
import { FlagManagementTypesComponent } from './flag-management-types/flag-management-types.component';
import { FlagManagementFlagsComponent } from './flag-management-flags/flag-management-flags.component';
import { FlagManagementStatusesComponent } from './flag-management-statuses/flag-management-statuses.component';
import { CrmViewFollowUpsComponent } from './crm-view-follow-ups/crm-view-follow-ups.component';
import { CrmCallLogsShowComponent } from './crm-call-logs-show/crm-call-logs-show.component';
import { AdministrationViewReportsComponent } from './administration-view-reports/administration-view-reports.component';
import { AdministrationManageCallsDashboardComponent } from './administration-manage-calls-dashboard/administration-manage-calls-dashboard.component';
import { AdministrationCallsComponent } from './administration-calls/administration-calls.component';
import { AdministrationCallScheduleComponent } from './administration-call-schedule/administration-call-schedule.component';
import { AdministrationSupportDeskComponent } from './administration-support-desk/administration-support-desk.component';
import { CrmOpportunitiesShowComponent } from './crm-opportunities-show/crm-opportunities-show.component';
import { UserLayoutComponent } from './users/user-layout/user-layout.component';
import { UserGuard } from './services/user.guard';
import { ELearningLayoutComponent } from './users/e-learning-layout/e-learning-layout.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'dashboard', component: LayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'employees', component: EmployeeComponent },
      { path: 'employee-profile/:id', component:EmployeeProfileComponent},
      { path: 'badge/:id', component:EmployeeBadgeComponent},
      { path: 'recruiting', component: RecruitingComponent },
      { path: 'schedule', component: ScheduleComponent },
      { path: 'forms', component: FormsComponent },
    ]
  },

  {
    path: 'administration', component: AdministrationLayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'dashboard', component: AdministrationDashboardComponent },
      { path: 'roles', component: RolesComponent },
      { path: 'specialties', component: SpecialtiesComponent },
      { path: 'users', component: UserComponent },
      { path: 'rates', component: AdministrationRatesComponent },
      { path: 'message-templates', component: AdministrationMessageTemplatesComponent },
      { path: 'buik-availability', component:AdministrationBulkAvailabilityComponent },
      { path: 'custome-files', component: AdministrationCustomFilesComponent },
      { path: 'application-setting', component:AdministrationApplicationSettingsComponent },
      { path: 'company-setting', component:AdministrationCompanySettingComponent },
      { path: 'theme-setting', component:AdministrationThemeSettingsComponent },
      { path: 'libraries', component: AdministrationLibrariesComponent },
      { path: 'triggers', component:AdministrationTriggersComponent },
      { path: 'view-reports', component:AdministrationViewReportsComponent},
      { path: 'manage-calls-dashboard' ,component:AdministrationManageCallsDashboardComponent},
      { path: 'calls', component:AdministrationCallsComponent},
      { path: 'call-schedule', component:AdministrationCallScheduleComponent },
      { path: 'support-desk', component:AdministrationSupportDeskComponent},

    ]
  },

  {
    path: 'crm', component: CrmLayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'dashboard', component: CrmDashboardComponent },
      { path: 'marketing', component: CrmMarketingComponent },
      { path: 'clients', component: CrmClientComponent },
      { path: 'call-script', component: CrmCallScriptComponent },
      { path: 'follow-up-comment', component: CrmFollowUpCommentComponent },
      { path: 'customer-profile/:id', component: CrmCustomersProfileComponent },
      { path: 'opportunities', component:CrmOpportunitiesComponent },
      { path: 'follow-ups', component:CrmFollowUpsComponent },
      { path: 'view-follow-ups',component:CrmViewFollowUpsComponent },
      { path: 'call-logs-show/:id',component:CrmCallLogsShowComponent},
      { path: 'opportunities-show/:id',component:CrmOpportunitiesShowComponent},
    ]
  },
  {
    path: 'flag-management', component: FlagManagementLayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'dashboard', component: FlagManagementDashboardComponent },
      { path: 'types', component: FlagManagementTypesComponent },
      { path: 'flags', component:FlagManagementFlagsComponent },
      { path: 'statuses', component:FlagManagementStatusesComponent }
    ]
  },

  {
    path: 'training', component: TrainingLayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'dashboard', component: TrainingDashboardComponent },
      { path: 'courses', component: TrainingCoursesComponent },
      { path: 'course-registrations', component:TrainingCourseRegistrationsComponent },
      { path: 'certifications', component: TrainingCertificationComponent },
      { path: 'topic', component: TrainingTopicComponent },
      { path: 'course-builder', component:TrainingCourseBuilderComponent },
      { path: 'course-builder/:id', component:TrainingCourseBuilderComponent },
      { path: 'course-show/:id', component:TrainingCourseShowComponent },
      { path: 'tests', component:TrainingTestsComponent },
      { path: 'test-builder', component:TrainingTestsBuilderComponent },
      { path: 'test-builder/:id', component:TrainingTestsBuilderComponent },
      { path: 'section-view/:id', component:TrainingSectionViewComponent },
      { path: 'my-course', component:TrainingMyCoursesComponent },
    ]
  },
  // {
  //   path: 'user', component: TrainingLayoutComponent, canActivate: [AuthGuard],
  //   children: [
  //     { path: 'dashboard', component: TrainingDashboardComponent },
      
  //   ]
  // },
  {
    path: 'user', component: UserLayoutComponent, canActivate: [UserGuard],
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
  },
  {
    path: 'usere-training', component: ELearningLayoutComponent, canActivate: [UserGuard],
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
  },

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
