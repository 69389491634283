import { Component, OnInit , Input} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../helper/helper.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-crm-follow-ups',
  templateUrl: './crm-follow-ups.component.html',
  styleUrls: ['./crm-follow-ups.component.css']
})
export class CrmFollowUpsComponent implements OnInit {

  followUpsForm:FormGroup;
  flagForm:FormGroup;
  followUpsDetailsForm:FormGroup;
  page:any=1;
  totalRecords:any=0;
  details:any=[];
  flags:any=[];
  response:any=[];
  statuses:any=[];
  modelName:any;
  flagDetails:any=[];
  flagModelRef:any=[];
  statusDetails:any=[];
  statusModelRef:any=[];
  states:any=[];
  activeTab:any=0;
  activeTabToday:any;
  allClients:any=[];
  editData:any=[];
  editFlag:any=[];
  editStatus:any=[];
  allAssignees:any=[];
  followUps:any=[];
  cartscriptid:any;
  allContacts:any=[];
  currentIndex:any=0;
  isSelectedFlag:boolean=false;
  isSelectedStatus:boolean=false;
  dueDate:any='';
  callCycle:any=[
    {label: "Every Six Months", value: "Every Six Months"},
    {label: "Every Two Weeks", value: "Every Two Weeks"},
    {label: "Every Two Years", value: "Every Two Years"},
    {label: "Monthly", value: "Monthly"},
    {label: "Quarterly", value: "Quarterly"},
    {label: "Weekly", value: "Weekly"},
    {label: "Yearly", value: "Yearly"},
  ];
  allOpportunities:any[];
  user:any=[];
  formErrors = {
    'id':'',
    'assignee_id':'',
    'type':'',
    'customer_id':'',
    'contact_id':'',
    'flags_selection':'',
    'status_selection':'',
    'due_date':'',
    'notes':'',
    'opportunity_id':'',

  };

  validationMessages = {
    'name': {
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    }
  };

  constructor(
    private api:ApiService,
    private formBuilder:FormBuilder,
    private modalService:NgbModal,
    private helperService:HelperService,
    private router:Router,
    private toastr:ToastrService,
  ) { 
    
  }

  ngOnInit(): void {
    
    this.init();
   // this.getFollowUps(this.page);
    this.getStatus();
    this.modelName='follow-ups';
    this.activeTabToday=1;
  }

  init() :void{
      this.followUpsForm = this.formBuilder.group({
      id: [''],
      due_date: ['', [Validators.required ]],
      assignee_id:['',[Validators.required ]],
      type:[''],
      customer_id:['', [Validators.required ]],
      contact_id:[''],
      flags_selection:[''],
      status_selection:[''],
      notes:[''],
      opportunity_id:[],
      
    });
    this.followUpsForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.followUpsForm);
    });
  }
 
  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  

  getFlag(){
    let data = {model_name :'follow-ups'};
    this.api.post('admin/core_flags/all_flags',data).subscribe((result)=>{
      this.response = result;
      this.flags = this.response.data;
    },(err)=>{
    });  
    return true;
  }

  getStatus(){
    let data = {model_name :'follow-ups'};
    this.api.post('admin/core_statuses/all_statuses',data).subscribe((result)=>{
      this.response = result;
      this.statuses = this.response.data;
    },(err)=>{
    });  
    return true;
  }

  openFlagModal(content){
    this.flagModelRef=[];
    this.modelName='follow-ups';
    const modalRef=this.modalService.open(content,{ size:'xl',centered:true });
    this.flagDetails = this.editFlag;
    this.flagModelRef.push(modalRef);
    this.closeModal(modalRef);
  }

  openStatusModal(content){
    this.statusModelRef=[];
    this.modelName='follow-ups';
    const modalRef=this.modalService.open(content,{ size:'xl',centered:true });
    this.statusDetails = this.editStatus;
    this.statusModelRef.push(modalRef);
    this.closeModal(modalRef);
  }

  closeModal(modalRef){
    modalRef.result.then((data) => {
    }, (reason) => {
      if(reason!=0 && reason!='Cross  click'){
        this.getFlag();
        this.getStatus();
      }
      
    });
  }

  flagManagementType(link){
    this.modalService.dismissAll();
    this.router.navigateByUrl(link);
  }
  async getFollowUps(page){
    this.api.get('admin/follow_ups/lists?page='+page+'&activeTab='+this.activeTab).subscribe((result)=>{  
    this.totalRecords = result.data.total;
    this.followUps = result.data.data;
  },(err)=>{

  });
  }
  async getClient(){
    this.api.get('admin/follow_ups/get_client').subscribe((result)=>{  
    this.response = result;
    this.allClients = this.response.data;
  },(err)=>{

  });
  }
  async getContacts(){
    this.api.get('admin/follow_ups/get_contacts').subscribe((result)=>{  
    this.response = result;
    this.allContacts = this.response.data;
  },(err)=>{

  });
  }
  async getAssignee(){
    this.api.get('admin/follow_ups/get_assignee').subscribe((result)=>{  
    this.response = result;
    this.allAssignees = this.response.data;
  },(err)=>{

  });
  }
  async getOpportunities(){
    this.api.get('admin/follow_ups/get_opportunities').subscribe((result)=>{  
    this.response = result;
    this.allOpportunities = this.response.data;
  },(err)=>{

  });
  }
  getAllFollowUps(tabValue){
    this.activeTab=tabValue
    this.getFollowUps(1)
    
  }
  
  submitFollowUpsData(data){
    let endPoint = (data.id)?'update':'create';
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
      this.api.post('admin/follow_ups/'+endPoint,data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.getFollowUps(this.page);
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.modalService.dismissAll();
    }, (err) => {
      this.toastr.error(err.error.message,"Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });
  }
  deleteModal(content,current_row){
    this.cartscriptid = current_row.id;
    this.modalService.open(content,{ size:'sm',centered:true });
  }
  async deleteFollowUps() {
    let data = { id: this.cartscriptid }
    this.api.post('admin/follow_ups/delete', data).subscribe((result) => {
       this.response=result;
       this.toastr.success(this.response.message,"Success");
       this.getFollowUps(this.page);
       this.modalService.dismissAll();
    }, (err) => {
       console.log(err);
       this.toastr.error(err.error.message,'Error');
       this.modalService.dismissAll();
    });
  }
  editfollowUpsModal(content, index): void {
    this.followUpsForm.reset();
    this.getFlag();
    this.getStatus();
    this.getClient();
    this.getAssignee();
    this.getContacts();
    this.editData = this.followUps[index];
    this.dueDate=this.editData.due_date.slice(0,10)
    this.followUpsForm.patchValue(this.editData);
    this.modalService.open(content, { size: 'xl', centered: true, scrollable: true });
  }
  selectFlag(event){
    (event!=null)?this.isSelectedFlag=true:this.isSelectedFlag=false;
    this.editFlag = this.flags.filter(function(arr){return arr.id == event})[0];
  }
  selectStatus(event){
    (event!=null)?this.isSelectedStatus=true:this.isSelectedStatus=false;
    this.editStatus = this.statuses[event];
  }
  followUpDetailsModal(content,index){
    // this.editData=this.followUps[index];
    // this.dueDate=this.editData.due_date.slice(0,10)
    // this.currentIndex=index;
    // this.followUpsForm.reset();
    // this.modalService.open(content,{ size:'xl',centered:true });
  }
  submitFollowUpsFinishData(data){
    console.log(data);
  }
  followUpDetailsUpdate(content){
    var index=this.currentIndex;
    this.followUpsForm.reset();
    this.getFlag();
    this.getStatus();
    this.getClient();
    this.getAssignee();
    this.getContacts();
    this.editData = this.followUps[index];
    this.followUpsForm.patchValue(this.editData);
    this.modalService.open(content, { size: 'xl', centered: true, scrollable: true });
  }
  followUpDetailsDelete(){
    console.log("this.editData",this.editData)
  }
}
