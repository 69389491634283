import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

const filePath = environment.filePath;

const MAX_SIZE = 1048576;
@Component({
  selector: 'app-crm-customers-profile',
  templateUrl: './crm-customers-profile.component.html',
  styleUrls: ['./crm-customers-profile.component.css']
})
export class CrmCustomersProfileComponent implements OnInit {
  noteForm: FormGroup;
  clientForm: FormGroup;
  profileImageForm: FormGroup;
  customerid: any;
  customer: any = [];
  response: any;
  isTrue: boolean = true;
  notes: any = [];
  allClientBillRates: any = [];
  allEmployePayRates: any = [];
  allCustomFiles: any=[];
  note_del_id: any;
  theFile: any = null;
  activetevalue: any;
  imageSrc: string;
  filePath: any = filePath;
  expiration: any;
  stfcustomfileId: any = '';
  employeeFilesPath: any;
  isArray: any = [];
  isCustomerFiles: boolean = false;
  isCustomerCallLogs: boolean = false;
  isCustomerFollowUps: boolean = false;
  isCustomerRates: boolean = false;
  isCustomerDetails: boolean = true;
  modelDetails:any='';
  allStfCustomFiles: any=[];
  isCustomRatestest: boolean = false;

  clientBillRatesLabel = [
    { id: 0, value: "WEEKDAY" },
    { id: 1, value: "WEEKEND" },
    { id: 2, value: "MORNING" },
    { id: 3, value: "MORNING" },
    { id: 4, value: "NIGHT" },
    { id: 5, value: "WEEKEND MORNING" },
    { id: 6, value: "WEEKEND EVENING" },
    { id: 7, value: "WEEKEND NIGHT" },
    { id: 8, value: "HOLIDAY" },
  ]

  formErrors = {
    'company_name': '',
    'phone': '',
    'caller_phone': '',
    'billing_email': '',
    'specialty': '',
    'marketing': '',
    'address_line_1': '',
    'address_line_2': '',
    'city': '',
    'state': '',
    'zip': '',
    'administrator_name': '',
    'administrator_phone': '',
    'administrator_alt_phone': '',
    'administrator_email': '',
    'scheduler_name': '',
    'scheduler_phone': '',
    'scheduler_alt_phone': '',
    'scheduler_email': '',
    'static_html_block': ''

  };
  validationMessages = {
    'company_name': {
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    }
  };
  flagModelRef: any[];
  modelName: string;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer
  ) {
    this.customerid = this.route.snapshot.params['id'];
    this.getCustomerProfile(this.customerid);
    this.getNotes();

    this.noteForm = this.formBuilder.group({
      note: [''],
      id: ['']
    });

    this.clientForm = this.formBuilder.group({
      client: [''],
    });

    this.profileImageForm = this.formBuilder.group({
      profile_image: [''],
    });

  }

  ngOnInit(): void {
    this.modelDetails = "view-follow-ups";
    this.init();
  }

  init(): void {
    this.clientForm = this.formBuilder.group({
      id: [''],
      company_name: ['', [Validators.required, Validators.minLength(3)]],
      phone: [''],
      caller_phone: [''],
      billing_email: [''],
      specialty: [''],
      marketing: [''],
      address_line_1: [''],
      address_line_2: [''],
      city: [''],
      state: [''],
      zip: [''],
      administrator_name: [''],
      administrator_phone: [''],
      administrator_alt_phone: [''],
      administrator_email: [''],
      scheduler_name: [''],
      scheduler_phone: [''],
      scheduler_alt_phone: [''],
      scheduler_email: [''],
      static_html_block: ['']
    });
    this.clientForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.clientForm);
    });
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  async getCustomerProfile(customerid) {
    this.api.get("admin/customer_profile/profile?id=" + customerid).subscribe((result) => {
      this.customer = result.data;
    }, (err) => {

    });
  }

  editMarketing(Content) {
    this.clientForm.reset();
    this.clientForm.patchValue(this.customer);
    this.modalService.open(Content, { size: 'xl', centered: true });
  }

  submitClientData(data): void {
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/customer/update', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getCustomerProfile(this.customerid);
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.modalService.dismissAll();
    }, (err) => {
      this.toastr.error(err.error.message, "Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });
  }



  editNotes(content, data) {
    this.noteForm.reset();
    this.noteForm.patchValue(data);
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  openNote(addNote, current_row) {
    this.modalService.open(addNote, { size: 'xl', centered: true });
  }

  submitNote(data): void {
    let endPoint = (data.id) ? 'update' : 'create';
    data.model_type = 'Client';
    data.model_id = this.customerid;
    this.api.post('admin/note/' + endPoint, data).subscribe((result) => {
      console.log(result);
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getNotes();
      this.modalService.dismissAll();
    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }

  async getNotes() {
    this.api.get("admin/note/lists?model_type=Client&model_id=" + this.customerid).subscribe((result) => {
      this.notes = result.data;
    }, (err) => {

    });
  }

  deleteNotesModal(content, current_row) {
    this.note_del_id = current_row.id;
    this.modalService.open(content, { size: 'sm', centered: true });
  }

  deleteNotes(): void {
    let data = { id: this.note_del_id };
    this.api.post('admin/note/delete', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.modalService.dismissAll();
      this.getNotes();
    }, (err) => {
      console.log(err);
      this.modalService.dismissAll();
      this.toastr.error(err.error.message, 'Error');
    });
  }

  changeMarketing(current_user, value): void {
    let data = { id: current_user.id, value: value };
    this.api.post("admin/customer/update_marking_status", data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getCustomerProfile(this.customerid);
    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }

  customerActivateModal(content, value) {
    this.activetevalue = value;
    this.modalService.open(content, { size: 'sm', centered: true });
  }

  customerActivate() {
    console.log(this.customerid);
    let data = { id: this.customerid, value: this.activetevalue };
    this.api.post("admin/customer/customer_active", data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.modalService.dismissAll();
      this.getCustomerProfile(this.customerid);

    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }

  selectImage() {
    document.getElementById("profile_image").click();
  }

  uploadImage(event) {
    if (event.target.files && event.target.files.length > 0) {

      console.log(event.target.files[0].size);
      // Don't allow file sizes over 1MB
      if (event.target.files[0].size < MAX_SIZE) {
        // Set theFile property
        this.theFile = event.target.files[0];
        console.log(this.theFile);

        const reader = new FileReader();

        reader.readAsDataURL(this.theFile);

        reader.onload = () => {

          this.imageSrc = reader.result as string;
          (<HTMLImageElement>document.getElementById('showImage')).src = this.imageSrc;
          console.log(this.imageSrc);

          let data = { profile_image: this.imageSrc, id: this.customerid };

          console.log(data);

          this.api.post("admin/customer/upload_profile_image", data).subscribe((result) => {
            this.response = result;
            this.toastr.success(this.response.message, "Success");
            this.getCustomerProfile(this.customerid);
            (<HTMLInputElement>document.getElementById("profile_image")).value = '';
          }, (err) => {
            this.toastr.error(err.error.message, "Error");
          });

        }

      }
      else {
        // Display error message
        this.toastr.warning("File: " + event.target.files[0].name + " is too large to upload.", "Warning");
      }
    }
  }


  getMap(employee) {
    let url = 'https://www.google.com/maps/embed/v1/place?q=' + employee.address_line_1 + employee.city + ' ' + employee.state + ' ' + employee.zip + '&key=AIzaSyBSFRN6WWGYwmFi498qXXsD2UwkbmD74v4';

    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  // update new code
  costomersDetails() {
    this.isCustomerDetails = true;
    this.isCustomerFiles = this.isCustomerRates = this.isCustomerCallLogs = this.isCustomerFollowUps = false;

  }
  costomersFiles() {
    this.getStfCustomFiles();
    this.getCustomFiles();
    this.isCustomerFiles = true;
    this.isCustomerRates = this.isCustomerDetails = this.isCustomerCallLogs = this.isCustomerFollowUps = false;
  }

  costomersRates() {
    this.getClientBillRates();
    this.isCustomerRates = true;
    this.isCustomerFiles = this.isCustomerDetails = this.isCustomerCallLogs = this.isCustomerFollowUps = false;
  }
  costomersFollowUps(){
    this.modelName = "customer-profile";
    this.isCustomerFollowUps = true;
    this.isCustomerFiles = this.isCustomerDetails = this.isCustomerCallLogs = this.isCustomerRates= false;
  }
  async getClientBillRates() {
    let data = { owner_model_id: this.customerid, model_type: 'client', model_id: 1 }
    this.api.post('admin/stf_model_rates/client_lists', data).subscribe((result) => {
      this.response = result;
      this.allClientBillRates = this.response.data.client_bill_rates;
      this.allEmployePayRates = this.response.data.employee_pay_rates;
    }, (err) => {
      this.toastr.error(err.error.messsage, "Error");
    });
  }

  async getStfCustomFiles() {
    let data = { model_id: this.customerid, model_type: 'client' }
    this.api.post('admin/stf_custom_files/lists', data).subscribe((result) => {
      this.response = result;
      this.allStfCustomFiles = this.response.data;
    }, (err) => {
      this.toastr.error(err.error.messsage, "Error");
    });
  }
  async getCustomFiles() {
    let data = { model_id: this.customerid, model_type: 'client' }
    this.api.post('admin/stf_custom_files/all_files', data).subscribe((result) => {
      this.response = result;
      this.allCustomFiles = this.response.data;
    }, (err) => {
      this.toastr.error(err.error.messsage, "Error");
    });
  }

  openUploadNewFile(content) {
    this.flagModelRef = [];
    this.modelName = 'client';
    const modalRef = this.modalService.open(content, { size: 'xl', centered: true });
    this.flagModelRef.push(modalRef);
    this.closeModal(modalRef);
  }
  closeModal(modalRef) {
    modalRef.result.then((data) => {

    }, (reason) => {
      if (reason != 0 && reason != 'Cross  click') {
        this.getStfCustomFiles();
        this.getCustomFiles();
      }

    });
  }
  openexpirationDate(content, stf_custom_file_id, expirationDate) {
    expirationDate = (expirationDate) ? expirationDate.slice(0, 10) : '';
    this.expiration = expirationDate;
    this.stfcustomfileId = stf_custom_file_id;
    this.modalService.open(content, { size: 'sm', centered: true });
  }
  updateExpirationDate() {
    let data = { id: this.stfcustomfileId, expiration: this.expiration }
    this.api.updateExpirationDate('admin/stf_model_custom_files/update', data).subscribe((result) => {
      this.modalService.dismissAll();
      this.getCustomFiles();
    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }
  openEmployeeFiles(content, path) {
    path = (path != null) ? path : 'client_schedule_images/client_image.jpg';
    this.employeeFilesPath = path;
    this.modalService.open(content, { size: 'xl', centered: true });
  }
  getPdfPath(path) {
    const d = new Date();
    let url = filePath + path + "?time=" + d.getTime();
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
  deleteEmployeeFilesData() {
    let data = { id: this.stfcustomfileId };
    this.api.post('admin/stf_model_custom_files/delete', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.modalService.dismissAll();
      this.getCustomFiles();
    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }
  openEmployeeFilesPopUp(content, stf_custom_file_id) {
    this.stfcustomfileId = stf_custom_file_id;
    this.modalService.open(content, { size: 'sm', centered: true });
  }
  changeClientBillRates(index, event, label, modelId) {
    // console.log("index=>", index);
    // console.log('amount=>', event.target.value);
    // console.log("label=>", label);
    // console.log('localIndex=>', localIndex);

    let newamount = event.target.value;
    let singalData = this.allClientBillRates[index].stf_model_rates;
    let newindex = singalData.findIndex(x => x.label === label);
    if (newindex >= 0) {
      this.allClientBillRates[index].stf_model_rates[newindex].amount = newamount;
    } else {
      let data = { id: '', amount: newamount, label: label, model_id: modelId, model_type: "client", owner_model_id: this.customerid, stf_rate_id: 1 }
      this.allClientBillRates[index].stf_model_rates.push(data);
    }
  }
  changeEmployeePayRates(index, event, label, modelId) {

    let newamount = event.target.value;
    let singalData = this.allEmployePayRates[index].stf_model_rates;
    let newindex = singalData.findIndex(x => x.label === label);
    if (newindex >= 0) {
      this.allEmployePayRates[index].stf_model_rates[newindex].amount = newamount;
    } else {
      let data = { id: '', amount: newamount, label: label, model_id: modelId, model_type: "client", owner_model_id: this.customerid, stf_rate_id: 2 }
      this.allEmployePayRates[index].stf_model_rates.push(data);
    }
  }
  clientUpdateRates() {
    //console.log("allClientBillRates==>", this.allClientBillRates);

    let data = { client_bill_rates: this.allClientBillRates, employee_pay_rates: this.allEmployePayRates, client_id: this.customerid }
    this.api.post('admin/stf_model_rates/client_update', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.modalService.dismissAll();
      this.getClientBillRates();
    }, (err) => {
      this.toastr.error(err.error.messsage, "Error");
    });
  }
  checkLabel(dbArray, value) {

    let index = dbArray.findIndex(x => x.label === value);
    let data = dbArray[index];
    if (data != undefined) {
      return data.amount;
    } else {
      return null;
    }
  }
  costomersCallLogs() {
    this.modelName = "customer-profile";
    this.isCustomerCallLogs = true;
    this.isCustomerFiles = this.isCustomerFollowUps = this.isCustomerRates = this.isCustomerDetails = false;
  }
  addCallLogsReg(content) {
    // this.callLogsForm.reset();
    // this.receivedDate = '';
    // this.getContacts();
    this.modalService.open(content, { size: 'xl', centered: true });
  }
}
