<!-- ======================================
******* Page Wrapper Area Start **********
======================================= -->
<div class="ecaps-page-wrapper">
    <!-- Page Content -->
    <div class="ecaps-page-content" style="margin-left:0px">
        <!-- Top Header Area -->
        <app-user-headernavbar></app-user-headernavbar>
        <!-- Main Content Area -->
        <div class="main-content">
            <div class="container-fluid">
                <ngx-ui-loader [hasProgressBar]="false" overlayColor="rgba(255,255,255,.4)"></ngx-ui-loader>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
<app-user-toast aria-live="polite" aria-atomic="true" ></app-user-toast>

<!-- ======================================
********* Page Wrapper Area End ***********
======================================= -->