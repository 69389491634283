<ngb-toast *ngFor="let toast of toastService.toasts"  [class]="toast.classname" [autohide]="true"
    [delay]="toast.delay || 5000" animation="true" (hidden)="toastService.remove(toast)" >
    <div><button type="button" class="close text-danger" (click)="toastService.remove(toast)"
        aria-label="Close">
        <span aria-hidden="true">×</span>
    </button></div>
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #text>
        {{ toast.textOrTpl }}
    </ng-template>
</ngb-toast>