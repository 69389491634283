<!-- ======================================
******* Page Wrapper Area Start **********
======================================= -->
<div class="ecaps-page-wrapper">
    <!-- Sidemenu Area -->
    <div class="ecaps-sidemenu-area">
        <!-- Desktop Logo -->
        <div class="ecaps-logo">
            <a href="index.html"><img class="desktop-logo" src="assets/img/core-img/logo.png" alt="Desktop Logo"> <img
                    class="small-logo" src="assets/img/core-img/small-logo.png" alt="Mobile Logo"></a>
        </div>

        <!-- Side Nav -->
        <div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 100%;">
        <div class="ecaps-sidenav" id="ecapsSideNav" style="overflow: hidden; width: auto; height: 100%;">
            <!-- Side Menu Area -->
            <div class="side-menu-area">
                <!-- Sidebar Menu -->
                <nav>
                    <ul class="sidebar-menu" data-widget="tree">
                        <li routerLinkActive="active"><a routerLink="/training/dashboard"><i class='fa fa-home'></i><span>Dashboard</span></a></li>
                        <li routerLinkActive="active"><a routerLink="/training/courses"><i class='fa fa-pie-chart'></i><span>Courses</span></a></li>
                        <li routerLinkActive="active"><a routerLink="/training/tests"><i class='fa fa-group'></i><span>Tests</span></a></li>
                        <li routerLinkActive="active"><a routerLink="/training/course-registrations"><i class='fa fa-ticket'></i><span>Registrations</span></a></li>
                        <li routerLinkActive="active"><a routerLink="/training/certifications"><i class='fa fa-clock-o'></i><span>Certifications</span></a></li>
                        <li routerLinkActive="active"><a routerLink="/training/topic"><i class='fa fa-cog'></i><span>Topics</span></a></li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>
    <!-- Page Content -->
    <div class="ecaps-page-content">
        <!-- Top Header Area -->

        <app-headernavbar></app-headernavbar>
        <!-- Main Content Area -->
        <div class="main-content">
            <div class="container-fluid">
                <ngx-ui-loader [hasProgressBar]="false" overlayColor="rgba(255,255,255,.4)"></ngx-ui-loader>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

<!-- ======================================
********* Page Wrapper Area End ***********
======================================= -->
