<div class="row">
    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Types</h6>
                <div class="btn-group">
                    <button class="action-btn bg-dark-green mr-2" (click)="openTypesModal(addTypesModal)"><img src="assets/img/svg-icon/add-white.svg" width="20" /></button>
                    <!-- <button class="action-btn bg-dark-blue" data-toggle="modal" data-target="#exampleModalRight"><img src="assets/img/svg-icon/filter-white.svg" width="20" /></button> -->
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content list_table" id="ticketList">
                    <div class="table-responsive list_table">
                        <table class="table  table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th class="pt-2">Model</th>
                                    <th class="pt-2">Name</th>
                                    <th class="pt-2">Active</th>
                                    <th class="pt-2">Create At</th>
                                    <th class="pt-2">Updated At</th>
                                    <th class="pt-2">Should Trigger Follow Up? </th>
                                    <th class="pt-2">Number of Days to Follow Up </th>
                                    <th class="pt-2">Scheduled Follow Up Time </th>
                                    <th class="pt-2"> Follow Up Type </th>
                                    <th class="pt-2 text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="allTypes.length > 0">
                                    <tr *ngFor="let script of allTypes | paginate: { id: 'type_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalRecords }; let i=index">
                                        <td class="text-black">{{script.name}}</td>
                                        <td class="text-black">{{script.name}}</td>
                                        <td class="text-black">{{script.name}}</td>
                                        <td class="text-black">{{script.name}}</td>
                                        <td class="text-black">{{script.name}}</td>
                                        <td class="text-gray">{{script.content}}</td>
                                        <td class="text-gray" *ngIf="script.active==1">Yes</td>
                                        <td class="text-gray" *ngIf="script.active==0">No</td>
                                        <td class="text-black">{{script.created_at | date : 'MM/dd/yyyy' }}</td>
                                        <td class="text-black">{{script.updated_at | date : 'MM/dd/yyyy' }}</td>
                                        <td class="text-right">
                                          <a href="javascript:void(0)" class="mr-2 action-btn bg-edit" title="Update Call Script"><img src="assets/img/svg-icon/edit.svg" width="20" /></a>
                                          <a href="javascript:void(0)" class="action-btn bg-red" title="Delete"><img src="assets/img/svg-icon/delete.svg" width="20" /></a>
                                        </td>
                                    </tr>
                                </ng-container>
                                <tr *ngIf="allTypes.length==0">
                                    <td colspan="10" class="text-center">Data Not Found.</td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="pagination-controls-theme" id="type_pagination" (pageChange)="getType(page = $event)" maxSize="9" directionLinks="true"
                        autoHide="true" responsive="true">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>

  <ng-template #addTypesModal let-modal>
    <form [formGroup]="typeForm" (ngSubmit)="submitTypes(typeForm.value)">
        <div class="modal-header mod-h3  custom_padding d-flex align-items-center justify-content-between">
            <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross  click')"
            aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>

        <div class="card-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
            <h6 class="card-title mb-0 text-center">Type Details</h6>
        </div>
        
        <div class="modal-body md-b-body">
            
            <div class="row">
                <div class="col-sm-6">
                    <label for="">Name</label>
                    <input type="text" formControlName="name" class="form-control">
                    <span class="text-danger"*ngIf="typeForm.get('name').errors?.required || typeForm.get('name').errors?.minlength && typeForm.get('name').errors">
                        {{formErrors.name}}</span>
                </div>
                <div class="col-sm-6">
                    <label for="">Model</label>
                    <ng-select formControlName="model_name" (change)="getModelName($event)">
                        <ng-option *ngFor="let model of modelNames;" value="{{model.value}}">{{model.value}}</ng-option>
                    </ng-select>
                    <span class="text-danger"*ngIf="typeForm.get('model_name').errors?.required || typeForm.get('model_name').errors?.minlength && typeForm.get('model_name').errors">
                        {{formErrors.model_name}}</span>
                </div>
            </div>
        </div>

        <div class="card-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
            <h6 class="card-title mb-0 text-center">Advanced Settings</h6>
        </div>
        
        <div class="modal-body md-b-body">
            
            <div class="row">
                <div class="col-sm-12">
                    <div class="checkbox d-inline">
                        <label for="enable_type_customization"
                            class="col-form-label text-light-black font-16">Enable custom fields for this type?</label>
                        <div class="new-checkbox toggle-check-box-t mt-15">
                            <label>No</label>
                            <label class="switch ml-15 mr-15">
                                <input type="checkbox" formControlName="enable_type_customization" (click)="checkIsDefault()" id="enable_type_customization" value="1">
                                <span class="slider round"></span>
                            </label>
                            <label>Yes</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="isDefault">
                <div class="card-body">
                    <div class="tab-content list_table" id="ticketList">
                        <div class="table-responsive list_table">
                            <table class="table  table-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th class="pt-2"><i class="fa fa-eye"></i></th>
                                        <th class="pt-2">Name</th>
                                        <th class="pt-2">Required</th>
                                        <th class="pt-2">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="columns.length > 0">
                                        <tr *ngFor="let column of columns ; let i=index">
                                            <td class="text-black"><input type="checkbox" /></td>
                                            <td class="text-black">{{column.Field}}</td>
                                            <td class="text-black" *ngIf="column.Default">Yes</td>
                                            <td class="text-black" *ngIf="!column.Default">No</td>
                                            <td class="text-black" *ngIf="!column.noActions">No Actions </td>
                                            <td class="text-black" *ngIf="column.noActions">
                                                <button type="button" class="close action-btn bg-danger text-white" (click)="columnRemove(i)" aria-label="Close">
                                                    <span aria-hidden="true">×</span>
                                                </button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr *ngIf="columns.length==0">
                                        <td colspan="10" class="text-center">Data Not Found.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="isDefault">
                <div class="col-sm-2">
                    <input type="text"  id="column_name"  class="form-control" placeholder="Enter a name for your field...">
                </div>
                <div class="col-sm-3">
                    <ng-select formControlName="column_type" id="column_type" (change)="getColumnName($event)">
                        <ng-option *ngFor="let coltype of columnType;"  value="{{coltype}}">{{coltype}}</ng-option>
                    </ng-select>
                </div>
                <div class="col-sm-3 text-right">
                    <div class="new-checkbox toggle-check-box-t mt-15">
                        <label for="should_trigger_required"  >No</label>
                        <label class="switch ml-15 mr-15">
                            <input type="checkbox" formControlName="should_trigger_required" id="should_trigger_required" [checked]="isColumnRequired" (click)="columnTriggerRequired()">
                            <span class="slider round"></span>
                        </label>
                        <label for="should_trigger_required">Yes</label>
                    </div>
                </div>
                <div class="col-sm-3 text-right">
                   <button type="button" class="button action-btn bg-dark-blue" (click)="createCustomColumn()"><!----> <span class="button__text">Create New</span></button>
                </div>
            </div>
        </div>

        <div class="card-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between" *ngIf="isFollowUpSetting">
            <h6 class="card-title mb-0 text-center">Follow Up Settings</h6>
        </div>
        
        <div class="modal-body md-b-body" *ngIf="isFollowUpSetting">
            
            <div class="row">
                <div class="col-sm-12">
                    <div class="checkbox d-inline">
                        <label for="should_trigger_follow_up"
                            class="col-form-label text-light-black font-16">Enable Follow Up?</label>
                        <div class="new-checkbox toggle-check-box-t mt-15">
                            <label>No</label>
                            <label class="switch ml-15 mr-15">
                                <input type="checkbox" formControlName="should_trigger_follow_up" [checked]="isEnableFollowUp" (click)="enableFollowUp()" id="should_trigger_follow_up">
                                <span class="slider round"></span>
                            </label>
                            <label>Yes</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="isEnableFollowUp && isFollowUpSetting">
                <div class="col-sm-3">
                    <label for="">Number of Days <span class="text-danger">*</span></label>
                    <input type="text" formControlName="follow_up_days" class="form-control">
                </div>
                <div class="col-sm-3">
                    <label for="">Scheduled Time <span class="text-danger">*</span></label>
                    <ng-select formControlName="follow_up_time">
                        <ng-option *ngFor="let time of timeSchedule;" value="{{time}}">{{time}}</ng-option>
                    </ng-select>
                </div>
                <div class="col-sm-3">
                    <label for="">Follow Up Status <span class="text-danger">*</span></label>
                    <ng-select formControlName="follow_up_status_id">
                        <ng-option *ngFor="let st of status;" value="{{st.id}}" >{{st.name}}</ng-option>
                    </ng-select>
                </div>
                <div class="col-sm-3">
                    <label for="">Follow Up Type <span class="text-danger">*</span></label>
                    <ng-select formControlName="follow_up_type">
                        <ng-option *ngFor="let ot of optType;" value="{{ot}}">{{ot}}</ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 mt-2">
                    <button class="action-btn bg-dark-blue">Submit</button>
                </div>
            </div>
        </div>
    </form>
    
    </ng-template>