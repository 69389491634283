import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const apiURL = environment.apiURL + '/';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isUserLoggedIn: boolean = false;
  constructor(private http: HttpClient) { }

  login(email: string, password: string) {
    return this.http.post(apiURL + 'auth/login', {
      email: email,
      password: password
    })
  }

  getAuthStatus() {
    var user = JSON.parse(localStorage.getItem('reliant_user'));
    if (user) {
      return true;
    } else {
      return false;
    }
  }
  

  getAuthUser() {
    var user = JSON.parse(localStorage.getItem('reliant_user'));
    return user;
  }

  getUserAuthStatus() {
    var user = JSON.parse(localStorage.getItem('reliant_role_based_user'));
    if (user) {
      return true;
    } else {
      return false;
    }
  }
  getUserData() {
    var user = JSON.parse(localStorage.getItem('reliant_role_based_user'));
    return user;
  }


}
