import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';


@Component({
  selector: 'app-administration-triggers',
  templateUrl: './administration-triggers.component.html',
  styleUrls: ['./administration-triggers.component.css'],
})
export class AdministrationTriggersComponent implements OnInit {

  triggers:any=[];
  details:any=[];
  page:any=1;
  totalRecords:any=0;
  response:any=[];
  deleteId:any;
  triggerObject:any=[];

  constructor(
    private modalService:NgbModal,
    private api:ApiService,
    private toastr:ToastrService
  ) {
    this.getTriggers(this.page);
   }

  ngOnInit(): void {
  }

  getTriggers(page){
    this.api.get('admin/libraries/lists?page='+page).subscribe((result)=>{
      this.response = result;
      this.triggers = this.response.data.data;
      this.totalRecords = this.response.data.total; 
    },(err)=>{
    });
    return true;
  }

  openModal(content){
    this.triggerObject = [];
    const modalRef=this.modalService.open(content,{ size:'xl',centered:true });
    this.closeModal(modalRef);
  }

  deleteModal(content,current_row){
    this.deleteId=current_row.id;
    this.modalService.open(content,{ size:'sm',centered:true });
  }

  getTriggersModalPage(){
    this.getTriggers(this.page);
  }

  updateModal(content,current_row){
    this.triggerObject = current_row;
    console.log(this.triggerObject);
    const modalRef=this.modalService.open(content,{ size:'xl',centered:true });
    this.closeModal(modalRef);
  }

  closeModal(modalRef){
    modalRef.result.then((data) => {
      //console.log('On CLose');
    }, (reason) => {
      this.getTriggers(this.page);
    });
  }

  deleteTrigger(){
    let data = {id:this.deleteId};
    this.api.post('admin/libraries/delete',data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Succes");
      this.getTriggers(this.page);
      this.modalService.dismissAll();
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

}
