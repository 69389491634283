import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  response: any;
  isUserLoggedIn: boolean = false;
  isError: boolean = false;
  errorMessage: string = '';

  public loginForm: FormGroup;
  constructor(private auth: AuthService, private api: ApiService, private router: Router, private toastr: ToastrService) {

  }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
  }

  doLogin() {
    let email = this.loginForm.value.email;
    let password = this.loginForm.value.password;
    this.auth.login(email, password).subscribe((result) => {
      this.response = result;
      if (this.response.data.token) {
        this.isUserLoggedIn = true;
        let index = this.response.data.roles.findIndex(x => x.name === "super");
        if (index > -1) {
          localStorage.setItem('isReliantUserLoggedIn', this.isUserLoggedIn ? "true" : "false");
          localStorage.setItem('reliant_user', JSON.stringify(this.response.data));
          this.router.navigate(['/dashboard/home']);
        } else {
          localStorage.setItem('reliant_role_based_user', JSON.stringify(this.response.data));
          this.router.navigate(['/user/dashboard']);
        }
        this.toastr.success(this.response.message, 'Success');
      }
    }, (err) => {
      this.isError = true;
      this.toastr.error(err.error.message, 'Error');
      this.errorMessage = err.error.message;
    });
  }

  closeAlert(): void {
    this.isError = false;
    this.errorMessage = '';
  }

}
