<div class="row">
  <div class="col-xl-12 box-margin">
      <div class="card">
          <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
              <h6 class="card-title mb-0">Marketing</h6>
              <ul class="nav nav-tabs mb-0" role="tablist">
                  <li class="nav-item">
                      <a class="nav-link show active" id="active-01-tab" data-toggle="tab" href="#active-01" role="tab" aria-controls="active-01" aria-selected="true">Active</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link mr-0" id="inactive-02-tab" data-toggle="tab" href="#inactive-02" role="tab" aria-controls="inactive-02" aria-selected="false">Inactive</a>
                  </li>

              </ul>
              <div class="btn-group">

                  <button class="action-btn bg-dark-green mr-2"><img src="assets/img/svg-icon/add-white.svg" (click)="addMarketing(marketingFormModal)" width="20" /></button>
                  <!-- <button class="action-btn bg-dark-blue"><img src="assets/img/svg-icon/filter-white.svg" width="20" /></button> -->
              </div>
          </div>
          <div class="card-body">
              <div class="tab-content list_table" id="ticketList">
                  <div class="tab-pane active show fade" id="active-01" role="tabpanel"
                      aria-labelledby="active-01-tab">
                      <div class="table-responsive list_table">
                          <table class="table  table-nowrap mb-0">
                              <thead>
                                  <tr>
                                      <th class="pt-0">Facility</th>
                                      <th class="pt-0">Contact</th>
                                      <th class="pt-0">Specialty</th>
                                      <th class="pt-0">Email</th>
                                      <th class="pt-0">Phone</th>
                                      <th class="pt-0">Submitted</th>
                                      <th class="pt-0 text-center">Actions</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <ng-container *ngIf="marketings.length > 0">
                                      <tr *ngFor="let marketing of marketings | paginate: { id: 'active_marketing_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalRecords }; let i=index">
                                          <td class="text-black"><a class="text-black" [routerLink]="['/crm/customer-profile/'+marketing.id]">{{marketing.company_name}}</a></td>
                                          <td class="text-gray">{{marketing.administrator_name}}</td>
                                          <td class="text-black">{{marketing.specialty}}</td>
                                          <td class="text-gray">{{marketing.administrator_email}}</td>
                                          <td class="text-black">{{marketing.scheduler_phone}}</td>

                                          <td class="text-success" *ngIf="marketing.submitted!=null">{{marketing.submitted | date : 'MM/dd/yyyy' }}</td>
                                          <td class="text-black" *ngIf="marketing.submitted==null"  (click)="openSubmittedModal(submittedModal,marketing)"><img src="assets/img/svg-icon/edit.svg" width="20" /> Enter Date</td>
                                          <!-- <td class="text-primary" *ngIf="marketing.active==1">Active</td>
                                          <td class="text-danger" *ngIf="marketing.active==0">InActive</td> -->
                                          <td class="text-right">
                                            <a href="javascript:void(0)" class="mr-2 action-btn bg-edit" title="Add Note" (click)="openNote(addNote,marketing)"><img src="assets/img/svg-icon/copy.svg" width="20" /></a>
                                            <a href="javascript:void(0)" class="action-btn bg-red" title="Delete" (click)="openDeletePopUp(deleteMarketing,marketing)"><img src="assets/img/svg-icon/delete.svg" width="20" /></a>
                                          </td>
                                      </tr>
                                    </ng-container>
                              </tbody>
                          </table>
                          <pagination-controls class="pagination-controls-theme" id="active_marketing_pagination" (pageChange)="getActiveMarketing(page = $event)" maxSize="9" directionLinks="true"
                          autoHide="true" responsive="true">
                         </pagination-controls>
                      </div>
                  </div>
                  <div class="tab-pane fade" id="inactive-02" role="tabpanel"
                      aria-labelledby="inactive-02-tab">
                      <div class="table-responsive list_table">
                          <table class="table  table-nowrap mb-0">
                              <thead>
                                  <tr>
                                      <th class="pt-0">Facility</th>
                                      <th class="pt-0">Contact</th>
                                      <th class="pt-0">Specialty</th>
                                      <th class="pt-0">Email</th>
                                      <th class="pt-0">Phone</th>
                                      <th class="pt-0">Submitted</th>
                                      <th class="pt-0 text-center">Actions</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <ng-container *ngIf="inactiveMarketings.length > 0">
                                      <tr *ngFor="let mrkt of inactiveMarketings | paginate: { id: 'inactive_marketing_pagination', itemsPerPage: 20, currentPage: inactivePage, totalItems: totalRecordInactive }; let i=index">
                                          <td class="text-black"><a class="text-black" [routerLink]="['/crm/customer-profile/'+mrkt.id]">{{mrkt.company_name}}</a></td>
                                          <td class="text-gray">{{mrkt.administrator_name}}</td>
                                          <td class="text-black">{{mrkt.specialty}}</td>
                                          <td class="text-gray">{{mrkt.administrator_email}}</td>
                                          <td class="text-black">{{mrkt.scheduler_phone}}</td>
                                          <td class="text-success" *ngIf="mrkt.submitted!=null">{{mrkt.submitted | date : 'MM/dd/yyyy' }}</td>
                                          <td class="text-black" *ngIf="mrkt.submitted==null"  (click)="openSubmittedModal(submittedModal,mrkt)"><img src="assets/img/svg-icon/edit.svg" width="20" /> Enter Date</td>
                                          <!-- <td class="text-danger" *ngIf="mrkt.active==1">Active</td>
                                          <td class="text-danger" *ngIf="mrkt.active==0">InActive</td> -->
                                          <td class="text-right">
                                            <a href="javascript:void(0)" class="mr-2 action-btn bg-edit" title="Add Note" (click)="openNote(addNote,mrkt)"><img src="assets/img/svg-icon/copy.svg" width="20" /></a>
                                            <a href="javascript:void(0)" class="action-btn bg-red" title="Delete" (click)="openDeletePopUp(deleteMarketing,mrkt)"><img src="assets/img/svg-icon/delete.svg" width="20" /></a>
                                          </td>
                                      </tr>
                                    </ng-container>
                              </tbody>
                          </table>
                          <pagination-controls class="pagination-controls-theme" id="inactive_marketing_pagination" (pageChange)="getInactiveMarketing(inactivePage = $event)" maxSize="9" directionLinks="true"
                          autoHide="true" responsive="true">
                         </pagination-controls>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>


<!-- Add Submitted Date -->

<ng-template #submittedModal let-modal>
  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
      <h6 class="card-title mb-0">Enter Date</h6>
      <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross click')"
          aria-label="Close">
          <span aria-hidden="true">×</span>
      </button>
  </div>
  <div class="modal-body md-b-body">
      <form [formGroup]="submittedForm" (ngSubmit)="submittedDateSubmit(submittedForm.value)">
          <div class="form-row">
              <div class="form-group col-md-12">
                  <label class="col-form-label text-light-black font-16">Please enter the date that this contract was submitted.</label>
                  <input type="date" class="form-control rounded-0 form-control-md" rows="4" formControlName="submitted">
              </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-12">
               <button type="submit" [disabled]="!submittedForm.valid" class="action-btn bg-dark-green mr-2">Submit</button>
            </div>
          </div>
      </form>
  </div>
</ng-template>

<!-- Add Note -->

<ng-template #addNote let-modal>
  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
      <h6 class="card-title mb-0">Add Note</h6>
      <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross click')"
          aria-label="Close">
          <span aria-hidden="true">×</span>
      </button>
  </div>
  <div class="modal-body md-b-body">
      <form [formGroup]="noteForm" (ngSubmit)="submitNote(noteForm.value)">
          <div class="form-row">
              <div class="form-group col-md-12">
                  <label class="col-form-label text-light-black font-16">Note</label>
                  <textarea class="form-control rounded-0 form-control-md" id="exampleTextarea" rows="4" formControlName="note"></textarea>
              </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-12">
               <button type="submit" [disabled]="!noteForm.valid" class="action-btn bg-dark-green mr-2">Add Note</button>
            </div>
          </div>
      </form>
  </div>
</ng-template>


<!-- Add Marketing  -->

<ng-template #marketingFormModal let-modal>

  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
    <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross  click')"
        aria-label="Close">
        <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="modal-body md-b-body">
    <form [formGroup]="clientForm" (ngSubmit)="submitClientData(clientForm.value)">
      <div class="form-row">

        <div class="form-group col-md-12">

          <div class="card card-shadow-hide">
              <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                  <h6 class="card-title mb-0">Client Details</h6>
              </div>
              <div class="card-body">

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="company_name" class="col-form-label text-light-black font-16">Name<span class="text-danger">*</span></label>
                    <input class="form-control rounded-0 form-control-md" type="text" formControlName="company_name">
                    <span class="text-danger"
                        *ngIf="clientForm.get('company_name').errors?.required || clientForm.get('company_name').errors?.minlength && clientForm.get('company_name').errors">
                        {{formErrors.company_name}}</span>
                    <input type="hidden" formControlName="id" value="">
                  </div>


                  <div class="form-group col-md-4">
                    <label for="inputState" class="col-form-label text-light-black font-16">Primary Phone</label>
                    <input class="form-control rounded-0 form-control-md" type="text" formControlName="phone">

                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputState" class="col-form-label text-light-black font-16">Primary Phone</label>
                    <input class="form-control rounded-0 form-control-md" type="email" formControlName="phone">

                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputState" class="col-form-label text-light-black font-16">Billing Email</label>
                    <input class="form-control rounded-0 form-control-md" type="text" formControlName="billing_email">
                  </div>

                  <div class="form-group col-md-4">
                    <label for="inputState" class="col-form-label text-light-black font-16">Specialty</label>
                    <input class="form-control rounded-0 form-control-md" type="text" formControlName="specialty">
                  </div>

                  <div class="form-group col-md-4">
                    <div class="checkbox d-inline">
                        <label for="checkbox-recurit"
                            class="col-form-label text-light-black font-16">Marketing</label>
                        <div class="new-checkbox toggle-check-box-t mt-15">
                            <label>No</label>

                            <!-- Rounded switch -->
                            <label class="switch ml-15 mr-15">
                                <input type="checkbox" formControlName="marketing" [checked]="isTrue" id="checkbox-marketing">
                                <span class="slider round"></span>
                            </label>
                            <label>Yes</label>
                        </div>
                    </div>
                  </div>

                </div>

              </div>
          </div>
        </div>

        <div class="form-group col-md-12">
          <div class="card card-shadow-hide">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Location Details</h6>
            </div>
            <div class="card-body">

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="address_line_1" class="col-form-label text-light-black font-16">Address Line 1</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="address_line_1">
                </div>


                <div class="form-group col-md-6">
                  <label for="inputState" class="col-form-label text-light-black font-16">Address Line 2</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="address_line_2">

                </div>

                <div class="form-group col-md-4">
                  <label for="inputState" class="col-form-label text-light-black font-16">City</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="city">

                </div>

                <div class="form-group col-md-4">
                  <label for="inputState" class="col-form-label text-light-black font-16">State</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="state">
                </div>

                <div class="form-group col-md-4">
                  <label for="inputState" class="col-form-label text-light-black font-16">Zip</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="zip">
                </div>

              </div>

            </div>
          </div>
        </div>


        <div class="form-group col-md-6">

          <div class="card card-shadow-hide">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Administrator Information</h6>
            </div>
            <div class="card-body">

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="administrator_name" class="col-form-label text-light-black font-16">Name</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="administrator_name">
                </div>


                <div class="form-group col-md-6">
                  <label for="administrator_phone" class="col-form-label text-light-black font-16">Office Phone</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="administrator_phone">

                </div>

                <div class="form-group col-md-6">
                  <label for="administrator_alt_phone" class="col-form-label text-light-black font-16">Cell Phone</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="administrator_alt_phone">

                </div>

                <div class="form-group col-md-6">
                  <label for="administrator_email" class="col-form-label text-light-black font-16">Email</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="administrator_email">
                </div>

              </div>

            </div>
          </div>

        </div>


        <div class="form-group col-md-6">

          <div class="card card-shadow-hide">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Scheduler Information</h6>
            </div>
            <div class="card-body">

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="scheduler_name" class="col-form-label text-light-black font-16">Name</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="scheduler_name">
                </div>


                <div class="form-group col-md-6">
                  <label for="scheduler_phone" class="col-form-label text-light-black font-16">Office Phone</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="scheduler_phone">

                </div>

                <div class="form-group col-md-6">
                  <label for="scheduler_alt_phone" class="col-form-label text-light-black font-16">Cell Phone</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="scheduler_alt_phone">

                </div>

                <div class="form-group col-md-6">
                  <label for="scheduler_email" class="col-form-label text-light-black font-16">Email</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="scheduler_email">
                </div>

              </div>

            </div>
          </div>

        </div>

        <div class="form-group col-md-12">
          <div class="card card-shadow-hide">
              <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                  <h6 class="card-title mb-0">Additional Information</h6>
              </div>
              <div class="card-body">
                  <div class="form-row">
                      <div class="text-help">Use this section to publish any additional details about the employee.
                      </div>
                      <div class="form-group col-md-12">
                          <label for="inputState" class="col-form-label text-light-black font-14">Additional
                              Information</label>
                          <editor formControlName="static_html_block" [init]="{
                              base_url: '/tinymce',
                              suffix: '.min',
                              height: 500,
                              menubar: false,
                              plugins: [
                                  'advlist autolink lists link image charmap print preview anchor',
                                  'searchreplace visualblocks code fullscreen',
                                  'insertdatetime media table paste code help wordcount'
                              ],
                              toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                          }"></editor>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      </div>
      <div class="btn-modal-group">
          <button type="submit" [disabled]="!clientForm.valid" id="submit-btn" class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <!-- <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button> -->
  </div>

</ng-template>

<!-- Delete Marketing -->
<ng-template #deleteMarketing let-modal>
  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
      <h6 class="card-title mb-0 text-center">Delete</h6>
  </div>
  <div class="modal-body md-b-body">
      <p>Are you sure you want to delete?</p>
      <div class="btn-modal-group d-flex justify-content-between">
          <button type="button" (click)="deleteMarketingData()" class="btn btn-success  border-r-10">Yes, delete it</button>
          <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
      </div>
  </div>
</ng-template>


