import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flag-management-dashboard',
  templateUrl: './flag-management-dashboard.component.html',
  styleUrls: ['./flag-management-dashboard.component.css']
})
export class FlagManagementDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
