<div class="row">

    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Recruits</h6>
                <ul class="nav nav-tabs mb-0" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link show active" id="active-01-tab" data-toggle="tab"
                            href="#active-01" role="tab" aria-controls="active-01"
                            aria-selected="true">Priority</a>
                            <!-- <a class="nav-link {{ (activeTab == 1)?'active':'' }}" id="active-01-tab" data-toggle="tab"
                            href="javascript:void(0)" role="tab" aria-controls="active-01" aria-selected="true"
                            (click)="getRecruitsData(1)">Priority</a> -->
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0" id="inactive-02-tab" data-toggle="tab"
                            href="#inactive-02" role="tab" aria-controls="inactive-02"
                            aria-selected="false">New</a>
                            <!-- <a class="nav-link mr-0 {{ (activeTab == 0)?'active':'' }}" id="inactive-02-tab"
                            data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="inactive-02"
                            aria-selected="false" (click)="getRecruitsData(2)">New</a> -->
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0" id="inactive-03-tab" data-toggle="tab"
                            href="#inactive-03" role="tab" aria-controls="inactive-03"
                            aria-selected="false">Pending</a>
                        <!-- <a class="nav-link mr-0 {{ (activeTab == 2)?'active':'' }}" id="dnr-02-tab" data-toggle="tab"
                            href="javascript:void(0)" role="tab" aria-controls="dnr-02" aria-selected="false"
                            (click)="getRecruitsData(3)">Pending</a> -->
                    </li>
                </ul>
                <div class="btn-group">

                    <button class="action-btn bg-dark-green mr-2" (click)="addRecruter(recruterFormModal)"><img src="assets/img/svg-icon/add-white.svg" width="20" /></button>
                    <!-- <button class="action-btn bg-dark-blue"><img src="assets/img/svg-icon/filter-white.svg"
                            width="20" /></button> -->
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content list_table" id="ticketList">
                    <div class="tab-pane active show fade" id="active-01" role="tabpanel"
                        aria-labelledby="active-01-tab">
                        <div class="table-responsive list_table">
                            <table class="table mb-0">
                                <thead>
                                    <tr>
                                        <th class="pt-0"></th>
                                        <th class="pt-0"></th>
                                        <th class="pt-0">Last Name</th>
                                        <th class="pt-0">First Name</th>
                                        <th class="pt-0">Specialty</th>
                                        <th class="pt-0">Recruiter</th>
                                        <th class="pt-0">Email</th>
                                        <th class="pt-0">Phone</th>
                                        <th class="pt-0">Email</th>
                                        <th class="pt-0">Text</th>
                                        <th class="pt-0">Call</th>
                                        <th class="pt-0">Resume</th>
                                        <th class="pt-0">Application</th>
                                        <th class="pt-0">Hire Packet</th>
                                        <th class="pt-0 text-right">Actions </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="priorityusers.length > 0">
                                    <tr *ngFor="let user of priorityusers | paginate: { id: 'priority_users_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalRecords };; let i=index">
                                        <td class="text-black">
                                            <div class="form-group">
                                                <div class="checkbox d-inline">
                                                    <input type="checkbox" checked id="favorites_model_1_{{i}}" (change)="recruitsModelUpdate(user.id)" />
                                                    <label for="favorites_model_1_{{i}}" class="col-red col-green"></label>
                                                </div>
                                            </div>   
                                        </td>
                                        <td class="text-black">
                                            <div class="form-group">
                                                <div class="checkbox d-inline">
                                                    <input type="checkbox" class="slider" id="flag_recent_1_{{i}}" *ngIf="user.flag_recent==1" checked (change)="recruitsUpdate(user.id,'flag_recent',user.flag_recent)" />
                                                    <input type="checkbox" *ngIf="user.flag_recent!=1" id="flag_recent_1_{{i}}" (change)="recruitsUpdate(user.id,'flag_recent',user.flag_recent)" />
                                                    <label for="flag_recent_1_{{i}}" class="col-red col-green"></label>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-black"> <a class="text-black" [routerLink]="['/dashboard/employee-profile/'+user.id]"> {{user.first_name }} </a> </td>
                                        <td class="text-black"><a class="text-black" [routerLink]="['/dashboard/employee-profile/'+user.id]"> {{user.last_name }}</a></td>
                                        <td class="text-black">{{user.specialty.name}}  </td>
                                        <td class="text-gray" *ngIf="user.new_recruit==1">Terry</td>
                                        <td class="text-gray" *ngIf="user.new_recruit!=1" >Theresa </td>
                                        <td class="text-primary"> <a class="text-primary" href="mailto:{{user.email}}">{{user.email}}</a></td>
                                        <td class="text-primary"><a class="text-primary" href="tel:{{user.phone}}">{{user.phone}} </a></td>
                                        <td class="text-green">
                                            <div class="form-group">
                                                <div class="checkbox d-inline">
                                                    <input type="checkbox" *ngIf="user.strike_email && user.strike_email.includes(1)" checked value="1" id="strike_email_1_{{i}}" (click)="strikeEmail(user.id,'strike_email',1)" /> 
                                                    <input type="checkbox" *ngIf="user.strike_email && !user.strike_email.includes(1)" value="1" id="strike_email_1_{{i}}" (click)="strikeEmail(user.id,'strike_email',1)" /> &nbsp; 
                                                    <label for="strike_email_1_{{i}}" class="col-red"></label>
                                                </div>
                                                <div class="checkbox d-inline">
                                                    <input type="checkbox" *ngIf="user.strike_email && user.strike_email.includes(2)" checked value="2" id="strike_email_2_{{i}}" (click)="strikeEmail(user.id,'strike_email',2)" /> 
                                                    <input type="checkbox" *ngIf="user.strike_email && !user.strike_email.includes(2)" value="2" id="strike_email_2_{{i}}" (click)="strikeEmail(user.id,'strike_email',2)" /> &nbsp; 
                                                    <label for="strike_email_2_{{i}}" class="col-red"></label>
                                                </div>
                                                <div class="checkbox d-inline">
                                                    <input type="checkbox" *ngIf="user.strike_email && user.strike_email.includes(3)" checked value="3" id="strike_email_3_{{i}}" (click)="strikeEmail(user.id,'strike_email',3)" /> 
                                                    <input type="checkbox" *ngIf="user.strike_email && !user.strike_email.includes(3)" value="3" id="strike_email_3_{{i}}" (click)="strikeEmail(user.id,'strike_email',3)" /> &nbsp; 
                                                    <label for="strike_email_3_{{i}}" class="col-red"></label>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-black">
                                            <div class="form-group">
                                                <div class="checkbox d-inline">
                                                    <input type="checkbox" *ngIf="user.strike_text && user.strike_text.includes(1)" checked value="1" id="strike_text_1_{{i}}" (click)="strikeEmail(user.id,'strike_text',1)" /> 
                                                    <input type="checkbox" *ngIf="!user.strike_text || user.strike_text && !user.strike_text.includes(1)" value="1" id="strike_text_1_{{i}}" (click)="strikeEmail(user.id,'strike_text',1)" /> &nbsp;
                                                    <label for="strike_text_1_{{i}}" class="col-red"></label>
                                                </div>
                                                <div class="checkbox d-inline">
                                                    <input type="checkbox" *ngIf="user.strike_text && user.strike_text.includes(2)" checked value="2" id="strike_text_2_{{i}}" (click)="strikeEmail(user.id,'strike_text',2)" /> 
                                                    <input type="checkbox" *ngIf="!user.strike_text || user.strike_text && !user.strike_text.includes(2)" value="2" id="strike_text_2_{{i}}" (click)="strikeEmail(user.id,'strike_text',2)" /> &nbsp;  
                                                    <label for="strike_text_2_{{i}}" class="col-red"></label>
                                                </div>
                                                <div class="checkbox d-inline">
                                                    <input type="checkbox" *ngIf="user.strike_text && user.strike_text.includes(3)" checked value="3" id="strike_text_3_{{i}}" (click)="strikeEmail(user.id,'strike_text',3)" /> 
                                                    <input type="checkbox" *ngIf="!user.strike_text || user.strike_text && !user.strike_text.includes(3)" value="3" id="strike_text_3_{{i}}" (click)="strikeEmail(user.id,'strike_text',3)" /> &nbsp;
                                                    <label for="strike_text_3_{{i}}" class="col-red"></label>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-black">
                                            <div class="form-group">
                                                <div class="checkbox d-inline">
                                                    <input type="checkbox" *ngIf="user.strike_call && user.strike_call.includes(1)" checked value="1" id="strike_call_1_{{i}}" (click)="strikeEmail(user.id,'strike_call',1)" /> 
                                                    <input type="checkbox" *ngIf="!user.strike_call || user.strike_call && !user.strike_call.includes(1)" value="1" id="strike_call_1_{{i}}" (click)="strikeEmail(user.id,'strike_call',1)" /> &nbsp;
                                                    <label for="strike_call_1_{{i}}" class="col-red"></label>
                                                </div>
                                                <div class="checkbox d-inline">
                                                    <input type="checkbox" *ngIf="user.strike_call && user.strike_call.includes(2)" checked value="2" id="strike_call_2_{{i}}" (click)="strikeEmail(user.id,'strike_call',2)" /> 
                                                    <input type="checkbox" *ngIf="!user.strike_call || user.strike_call && !user.strike_call.includes(2)" value="2" id="strike_call_2_{{i}}" (click)="strikeEmail(user.id,'strike_call',2)" /> &nbsp; 
                                                    <label for="strike_call_2_{{i}}" class="col-red"></label>
                                                </div>
                                                <div class="checkbox d-inline">
                                                    <input type="checkbox" *ngIf="user.strike_call && user.strike_call.includes(3)" checked value="3" id="strike_call_3_{{i}}" (click)="strikeEmail(user.id,'strike_call',3)" /> 
                                                    <input type="checkbox" *ngIf="!user.strike_call || user.strike_call && !user.strike_call.includes(3)" value="3" id="strike_call_3_{{i}}" (click)="strikeEmail(user.id,'strike_call',3)" /> &nbsp;
                                                    <label for="strike_call_3_{{i}}" class="col-red"></label>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-black">
                                            <div class="new-checkbox" *ngIf="user.creds==1">
                                                <!-- Rounded switch -->
                                                <label class="switch md-trigger md-setperspective" data-modal="modal-18" (change)="recruitsUpdate(user.id,'creds',user.creds)">
                                                    <input type="checkbox" value="{{user.cards}}" checked>
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                            <div class="new-checkbox" *ngIf="user.creds!=1">
                                                <!-- Rounded switch -->
                                                <label class="switch md-trigger md-setperspective" data-modal="modal-18" (change)="recruitsUpdate(user.id,'creds',user.creds)">
                                                    <input type="checkbox" value="{{user.cards}}">
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <td class="text-green">
                                            <div class="new-checkbox" *ngIf="user.app==1">
                                                <!-- Rounded switch -->
                                                <label class="switch md-trigger md-setperspective" data-modal="modal-18" (change)="recruitsUpdate(user.id,'app',user.app)">
                                                    <input type="checkbox" value="{{user.app}}" checked />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                            <div class="new-checkbox" *ngIf="user.app!=1">
                                                <label class="switch md-trigger md-setperspective" data-modal="modal-18" (change)="recruitsUpdate(user.id,'app',user.app)">
                                                    <input type="checkbox" value="{{user.app}}" />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <td class="text-green">
                                            <div class="new-checkbox" *ngIf="user.hp==1">
                                                <label class="switch md-trigger md-setperspective" data-modal="modal-19" (change)="recruitsUpdate(user.id,'hp',user.hp)">
                                                    <input type="checkbox" value="{{user.hp}}" checked />
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                            <div class="new-checkbox" *ngIf="user.hp!=1">
                                                <label class="switch md-trigger md-setperspective" data-modal="modal-19" (change)="recruitsUpdate(user.id,'hp',user.hp)">
                                                    <input type="checkbox">
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <td class="text-right">
                                            <a href="javascript:void(0)" (click)="openNote(addNote,user.id)" class="action-btn bg-edit md-trigger md-setperspective" data-modal="modal-20"><img src="assets/img/svg-icon/copy.svg" width="20" /></a>
                                            <!-- <a href="#" class="action-btn bg-red md-trigger md-setperspective" data-modal="modal-20"><img src="assets/img/svg-icon/delete.svg" width="20" /></a> -->
                                        </td>
                                    </tr>
                                    </ng-container>
                                    <tr *ngIf="priorityusers.length == 0">
                                        <td class="text-gray text-center" colspan="5">No Recruits found.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <pagination-controls class="pagination-controls-theme" id="priority_users_pagination" (pageChange)="getUsers(page = $event)" maxSize="9" directionLinks="true"
                        autoHide="true" responsive="true">
                        </pagination-controls>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="inactive-02" role="tabpanel"
                        aria-labelledby="inactive-02-tab">
                        <div class="table-responsive list_table">
                            <table class="table mb-0">
                                <thead>
                                    <tr>
                                        <th class="pt-0"></th>
                                        <th class="pt-0"></th>
                                        <th class="pt-0">Last Name</th>
                                        <th class="pt-0">First Name</th>
                                        <th class="pt-0">Specialty</th>
                                        <th class="pt-0">Recruiter</th>
                                        <th class="pt-0">Email</th>
                                        <th class="pt-0">Phone</th>
                                        <th class="pt-0">Email</th>
                                        <th class="pt-0">Text</th>
                                        <th class="pt-0">Call</th>
                                        <th class="pt-0">Resume</th>
                                        <th class="pt-0">Application</th>
                                        <th class="pt-0">Hire Packet</th>
                                        <th class="pt-0 text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="recruitsNewUsers.length > 0">
                                        <tr *ngFor="let user of recruitsNewUsers | paginate: { id: 'new_recruits_users_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalRecords }; let j=index; ">
                                            <td class="text-black"><input type="checkbox" (change)="addFavoritesUser(user.id,'new')"/>   
                                            </td>
                                            <td class="text-black">
                                                <div class="form-group">
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" class="slider" id="new_flag_recent_1_{{j}}" *ngIf="user.flag_recent==1" checked (change)="recruitsUpdate(user.id,'flag_recent',user.flag_recent)" />
                                                        <input type="checkbox" *ngIf="user.flag_recent!=1" id="new_flag_recent_1_{{j}}" (change)="recruitsUpdate(user.id,'flag_recent',user.flag_recent)" />
                                                        <label for="new_flag_recent_1_{{j}}" class="col-red col-green"></label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-black"> <a class="text-black" [routerLink]="['/dashboard/employee-profile/'+user.id]"> {{user.first_name }}</a> </td>
                                            <td class="text-black"><a class="text-black" [routerLink]="['/dashboard/employee-profile/'+user.id]"> {{user.last_name }}</a></td>
                                            <td class="text-black">{{user.specialty.name}}  </td>
                                            <td class="text-gray" *ngIf="user.new_recruit==1">Terry</td>
                                            <td class="text-gray" *ngIf="user.new_recruit!=1" >Theresa </td>
                                            <td class="text-primary"> <a class="text-primary" href="mailto:{{user.email}}">{{user.email}}</a></td>
                                            <td class="text-primary"><a class="text-primary" href="tel:{{user.phone}}">{{user.phone}} </a></td>
                                            <td class="text-green">
                                                <div class="form-group">
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_email && user.strike_email.includes(1)" checked   value="1" id="new_strike_email_1_{{j}}" (click)="strikeEmail(user.id,'strike_email',1)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_email || !user.strike_email.includes(1)"  value="1" id="new_strike_email_1_{{j}}" (click)="strikeEmail(user.id,'strike_email',1)" /> &nbsp; 
                                                        <label for="new_strike_email_1_{{j}}" class="col-red"></label>
                                                    </div>
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_email && user.strike_email.includes(2)" checked value="2" id="new_strike_email_2_{{j}}" (click)="strikeEmail(user.id,'strike_email',2)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_email || !user.strike_email.includes(2)" value="2" id="new_strike_email_2_{{j}}" (click)="strikeEmail(user.id,'strike_email',2)" /> &nbsp; 
                                                        <label for="new_strike_email_2_{{j}}" class="col-red"></label>
                                                    </div>
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_email && user.strike_email.includes(3)" checked value="3" id="new_strike_email_3_{{j}}" (click)="strikeEmail(user.id,'strike_email',3)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_email || !user.strike_email.includes(3)" value="3" id="new_strike_email_3_{{j}}" (click)="strikeEmail(user.id,'strike_email',3)" /> &nbsp; 
                                                        <label for="new_strike_email_3_{{j}}" class="col-red"></label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-black">
                                                <div class="form-group">
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_text && user.strike_text.includes(1)" checked value="1" id="new_strike_text_1_{{j}}" (click)="strikeEmail(user.id,'strike_text',1)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_text || !user.strike_text.includes(1)" value="1" id="new_strike_text_1_{{j}}" (click)="strikeEmail(user.id,'strike_text',1)" /> &nbsp;
                                                        <label for="new_strike_text_1_{{j}}" class="col-red"></label>
                                                    </div>
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_text && user.strike_text.includes(2)" checked value="2" id="new_strike_text_2_{{j}}" (click)="strikeEmail(user.id,'strike_text',2)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_text || !user.strike_text.includes(2)" value="2" id="new_strike_text_2_{{j}}" (click)="strikeEmail(user.id,'strike_text',2)" /> &nbsp;  
                                                        <label for="new_strike_text_2_{{j}}" class="col-red"></label>
                                                    </div>
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_text && user.strike_text.includes(3)" checked value="3" id="new_strike_text_3_{{j}}" (click)="strikeEmail(user.id,'strike_text',3)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_text || !user.strike_text.includes(3)" value="3" id="new_strike_text_3_{{j}}" (click)="strikeEmail(user.id,'strike_text',3)" /> &nbsp;
                                                        <label for="new_strike_text_3_{{j}}" class="col-red"></label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-black">
                                                <div class="form-group">
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_call && user.strike_call.includes(1)" checked value="1" id="mew_strike_call_1_{{j}}" (click)="strikeEmail(user.id,'strike_call',1)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_call || !user.strike_call.includes(1)" value="1" id="mew_strike_call_1_{{j}}" (click)="strikeEmail(user.id,'strike_call',1)" /> &nbsp;
                                                        <label for="mew_strike_call_1_{{j}}" class="col-red"></label>
                                                    </div>
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_call && user.strike_call.includes(2)" checked value="2" id="new_strike_call_2_{{j}}" (click)="strikeEmail(user.id,'strike_call',2)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_call || !user.strike_call.includes(2)" value="2" id="new_strike_call_2_{{j}}" (click)="strikeEmail(user.id,'strike_call',2)" /> &nbsp; 
                                                        <label for="new_strike_call_2_{{j}}" class="col-red"></label>
                                                    </div>
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_call && user.strike_call.includes(3)" checked value="3" id="new_strike_call_3_{{j}}" (click)="strikeEmail(user.id,'strike_call',3)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_call || !user.strike_call.includes(3)" value="3" id="new_strike_call_3_{{j}}" (click)="strikeEmail(user.id,'strike_call',3)" /> &nbsp;
                                                        <label for="new_strike_call_3_{{j}}" class="col-red"></label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-black">
                                                <div class="new-checkbox" *ngIf="user.creds==1">
                                                    <!-- Rounded switch -->
                                                    <label class="switch md-trigger md-setperspective" data-modal="modal-18" (change)="recruitsUpdate(user.id,'creds',user.creds)">
                                                        <input type="checkbox" value="{{user.cards}}" checked>
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div class="new-checkbox" *ngIf="user.creds!=1">
                                                    <!-- Rounded switch -->
                                                    <label class="switch md-trigger md-setperspective" data-modal="modal-18" (change)="recruitsUpdate(user.id,'creds',user.creds)">
                                                        <input type="checkbox" value="{{user.cards}}">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td class="text-green">
                                                <div class="new-checkbox" *ngIf="user.app==1">
                                                    <!-- Rounded switch -->
                                                    <label class="switch md-trigger md-setperspective" data-modal="modal-18" (change)="recruitsUpdate(user.id,'app',user.app)">
                                                        <input type="checkbox" value="{{user.app}}" checked />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div class="new-checkbox" *ngIf="user.app!=1">
                                                    <label class="switch md-trigger md-setperspective" data-modal="modal-18" (change)="recruitsUpdate(user.id,'app',user.app)">
                                                        <input type="checkbox" value="{{user.app}}" />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td class="text-green">
                                                <div class="new-checkbox" *ngIf="user.hp==1">
                                                    <label class="switch md-trigger md-setperspective" data-modal="modal-19" (change)="recruitsUpdate(user.id,'hp',user.hp)">
                                                        <input type="checkbox" value="{{user.hp}}" checked />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div class="new-checkbox" *ngIf="user.hp!=1">
                                                    <label class="switch md-trigger md-setperspective" data-modal="modal-19" (change)="recruitsUpdate(user.id,'hp',user.hp)">
                                                        <input type="checkbox">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td class="text-right">
                                                <a href="javascript:void(0)" (click)="openNote(addNote,user.id)" class="action-btn bg-edit md-trigger md-setperspective" data-modal="modal-20"><img src="assets/img/svg-icon/copy.svg" width="20" /></a>
                                                <!-- <a href="#" class="action-btn bg-red md-trigger md-setperspective" data-modal="modal-20"><img src="assets/img/svg-icon/delete.svg" width="20" /></a> -->
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr *ngIf="recruitsNewUsers.length == 0">
                                        <td class="text-gray text-center" colspan="5">No Recruits found.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <pagination-controls class="pagination-controls-theme" id="new_recruits_users_pagination" (pageChange)="getNewUsers(page = $event)" maxSize="9" directionLinks="true"
                        autoHide="true" responsive="true">
                        </pagination-controls>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="inactive-03" role="tabpanel"
                        aria-labelledby="inactive-03-tab">
                        <div class="table-responsive list_table">
                            <table class="table mb-0">
                                <thead>
                                    <tr>
                                        <th class="pt-0"></th>
                                        <th class="pt-0"></th>
                                        <th class="pt-0">Last Name</th>
                                        <th class="pt-0">First Name</th>
                                        <th class="pt-0">Specialty</th>
                                        <th class="pt-0">Recruiter</th>
                                        <th class="pt-0">Email</th>
                                        <th class="pt-0">Phone</th>
                                        <th class="pt-0">Email</th>
                                        <th class="pt-0">Text</th>
                                        <th class="pt-0">Call</th>
                                        <th class="pt-0">Resume</th>
                                        <th class="pt-0">Application</th>
                                        <th class="pt-0">Hire Packet</th>
                                        <th class="pt-0 text-right">Actions </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="totalPendingRecords > 0">
                                        <tr *ngFor="let user of recruitsPendingUsers | paginate: { id: 'pending_recruits_users_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalPendingRecords }; let j=index; ">
                                            <td class="text-black"><input type="checkbox" (change)="addFavoritesUser(user.id,'pending')"/>
                                            </td>
                                            <td class="text-black">
                                                <div class="form-group">
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" class="slider" id="pending_flag_recent_1_{{j}}" *ngIf="user.flag_recent==1" checked (change)="recruitsUpdate(user.id,'flag_recent',user.flag_recent)" />
                                                        <input type="checkbox" *ngIf="user.flag_recent!=1" id="pending_flag_recent_1_{{j}}" (change)="recruitsUpdate(user.id,'flag_recent',user.flag_recent)" />
                                                        <label for="pending_flag_recent_1_{{j}}" class="col-red col-green"></label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-black"> <a class="text-black" [routerLink]="['/dashboard/employee-profile/'+user.id]"> {{user.first_name }}</a> </td>
                                            <td class="text-black"><a class="text-black" [routerLink]="['/dashboard/employee-profile/'+user.id]"> {{user.last_name }}</a></td>
                                            <td class="text-black">{{user.specialty.name}}  </td>
                                            <td class="text-gray" *ngIf="user.new_recruit==1">Terry</td>
                                            <td class="text-gray" *ngIf="user.new_recruit!=1" >Theresa </td>
                                            <td class="text-primary"> <a class="text-primary" href="mailto:{{user.email}}">{{user.email}}</a></td>
                                            <td class="text-primary"><a class="text-primary" href="tel:{{user.phone}}">{{user.phone}} </a></td>
                                            <td class="text-green">
                                                <div class="form-group">
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_email && user.strike_email.includes(1)" checked   value="1" id="pending_strike_email_1_{{j}}" (click)="strikeEmail(user.id,'strike_email',1)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_email || !user.strike_email.includes(1)"  value="1" id="pending_strike_email_1_{{j}}" (click)="strikeEmail(user.id,'strike_email',1)" /> &nbsp; 
                                                        <label for="pending_strike_email_1_{{j}}" class="col-red"></label>
                                                    </div>
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_email && user.strike_email.includes(2)" checked value="2" id="pending_strike_email_2_{{j}}" (click)="strikeEmail(user.id,'strike_email',2)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_email || !user.strike_email.includes(2)" value="2" id="pending_strike_email_2_{{j}}" (click)="strikeEmail(user.id,'strike_email',2)" /> &nbsp; 
                                                        <label for="pending_strike_email_2_{{j}}" class="col-red"></label>
                                                    </div>
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_email && user.strike_email.includes(3)" checked value="3" id="pending_strike_email_3_{{j}}" (click)="strikeEmail(user.id,'strike_email',3)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_email || !user.strike_email.includes(3)" value="3" id="pending_strike_email_3_{{j}}" (click)="strikeEmail(user.id,'strike_email',3)" /> &nbsp; 
                                                        <label for="pending_strike_email_3_{{j}}" class="col-red"></label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-group">
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_text && user.strike_text.includes(1)" checked value="1" id="pending_strike_text_1_{{j}}" (click)="strikeEmail(user.id,'strike_text',1)" /> 
                                                        <input type="checkbox" *ngIf="!(user.strike_text && user.strike_text.includes(1))" value="1" id="pending_strike_text_1_{{j}}" (click)="strikeEmail(user.id,'strike_text',1)" /> &nbsp;
                                                        <label for="pending_strike_text_1_{{j}}" class="col-red"></label>
                                                    </div>
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_text && user.strike_text.includes(2)" checked value="2" id="pending_strike_text_2_{{j}}" (click)="strikeEmail(user.id,'strike_text',2)" /> 
                                                        <input type="checkbox" *ngIf="!(user.strike_text && user.strike_text.includes(2))" value="2" id="pending_strike_text_2_{{j}}" (click)="strikeEmail(user.id,'strike_text',2)" /> &nbsp;  
                                                        <label for="pending_strike_text_2_{{j}}" class="col-red"></label>
                                                    </div>
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_text && user.strike_text.includes(3)" checked value="3" id="pending_strike_text_3_{{j}}" (click)="strikeEmail(user.id,'strike_text',3)" /> 
                                                        <input type="checkbox" *ngIf="!(user.strike_text && user.strike_text.includes(3))" value="3" id="pending_strike_text_3_{{j}}" (click)="strikeEmail(user.id,'strike_text',3)" /> &nbsp;
                                                        <label for="pending_strike_text_3_{{j}}" class="col-red"></label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-group">
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_call && user.strike_call.includes(1)" checked value="1" id="pending_strike_call_1_{{j}}" (click)="strikeEmail(user.id,'strike_call',1)" /> 
                                                        <input type="checkbox" *ngIf="!(user.strike_call && user.strike_call.includes(1))" value="1" id="pending_strike_call_1_{{j}}" (click)="strikeEmail(user.id,'strike_call',1)" /> &nbsp;
                                                        <label for="pending_strike_call_1_{{j}}" class="col-red"></label>
                                                    </div>
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_call && user.strike_call.includes(2)" checked value="2" id="pending_strike_call_2_{{j}}" (click)="strikeEmail(user.id,'strike_call',2)" /> 
                                                        <input type="checkbox" *ngIf="!(user.strike_call && user.strike_call.includes(2))" value="2" id="pending_strike_call_2_{{j}}" (click)="strikeEmail(user.id,'strike_call',2)" /> &nbsp; 
                                                        <label for="pending_strike_call_2_{{j}}" class="col-red"></label>
                                                    </div>
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_call && user.strike_call.includes(3)" checked value="3" id="pending_strike_call_3_{{j}}" (click)="strikeEmail(user.id,'strike_call',3)" /> 
                                                        <input type="checkbox" *ngIf="!(user.strike_call && user.strike_call.includes(3))" value="3" id="pending_strike_call_3_{{j}}" (click)="strikeEmail(user.id,'strike_call',3)" /> &nbsp;
                                                        <label for="pending_strike_call_3_{{j}}" class="col-red"></label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-black">
                                                <div class="new-checkbox" *ngIf="user.creds==1">
                                                  
                                                    <label class="switch md-trigger md-setperspective" data-modal="modal-18" (change)="recruitsUpdate(user.id,'creds',user.creds)">
                                                        <input type="checkbox" value="{{user.cards}}" checked>
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div class="new-checkbox" *ngIf="user.creds!=1">
                                                   
                                                    <label class="switch md-trigger md-setperspective" data-modal="modal-18" (change)="recruitsUpdate(user.id,'creds',user.creds)">
                                                        <input type="checkbox" value="{{user.cards}}">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td class="text-green">
                                                <div class="new-checkbox" *ngIf="user.app==1">
                                                   
                                                    <label class="switch md-trigger md-setperspective" data-modal="modal-18" (change)="recruitsUpdate(user.id,'app',user.app)">
                                                        <input type="checkbox" value="{{user.app}}" checked />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div class="new-checkbox" *ngIf="user.app!=1">
                                                    <label class="switch md-trigger md-setperspective" data-modal="modal-18" (change)="recruitsUpdate(user.id,'app',user.app)">
                                                        <input type="checkbox" value="{{user.app}}" />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td class="text-green">
                                                <div class="new-checkbox" *ngIf="user.hp==1">
                                                    <label class="switch md-trigger md-setperspective" data-modal="modal-19" (change)="recruitsUpdate(user.id,'hp',user.hp)">
                                                        <input type="checkbox" value="{{user.hp}}" checked />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div class="new-checkbox" *ngIf="user.hp!=1">
                                                    <label class="switch md-trigger md-setperspective" data-modal="modal-19" (change)="recruitsUpdate(user.id,'hp',user.hp)">
                                                        <input type="checkbox">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td class="text-right">
                                                <a href="javascript:void(0)" (click)="openNote(addNote,user.id)" class="action-btn bg-edit md-trigger md-setperspective" data-modal="modal-20"><img src="assets/img/svg-icon/copy.svg" width="20" /></a>
                                              
                                            </td>
                                        </tr>
                                        <!-- <tr *ngFor="let user of recruitsPendingUsers | paginate: { id: 'pending_recruits_users_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalPendingRecords }; let j=index; ">
                                            <td class="text-black"><input type="checkbox" /> {{user.id}}
                                            </td>
                                            <td class="text-black">
                                                <div class="form-group">
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" class="slider" id="pending_flag_recent_1_{{j}}" *ngIf="user.flag_recent==1" checked (change)="recruitsUpdate(user.id,'flag_recent',user.flag_recent)" />
                                                        <input type="checkbox" *ngIf="user.flag_recent!=1" id="pending_flag_recent_1_{{j}}" (change)="recruitsUpdate(user.id,'flag_recent',user.flag_recent)" />
                                                        <label for="pending_flag_recent_1_{{j}}" class="col-red col-green"></label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-black"> <a class="text-black" [routerLink]="['/dashboard/employee-profile/'+user.id]"> {{user.first_name }}</a> </td>
                                            <td class="text-black"><a class="text-black" [routerLink]="['/dashboard/employee-profile/'+user.id]"> {{user.last_name }}</a></td>
                                            <td class="text-black">{{user.specialty.name}}  </td>
                                            <td class="text-gray" *ngIf="user.new_recruit==1">Terry</td>
                                            <td class="text-gray" *ngIf="user.new_recruit!=1" >Theresa </td>
                                            <td class="text-primary"> <a class="text-primary" href="mailto:{{user.email}}">{{user.email}}</a></td>
                                            <td class="text-primary"><a class="text-primary" href="tel:{{user.phone}}">{{user.phone}} </a></td>
                                            <td class="text-green">
                                                <div class="form-group">
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_email && user.strike_email.includes(1)" checked   value="1" id="pending_strike_email_1_{{j}}" (click)="strikeEmail(user.id,'strike_email',1)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_email.includes(1)"  value="1" id="pending_strike_email_1_{{j}}" (click)="strikeEmail(user.id,'strike_email',1)" /> &nbsp; 
                                                        <label for="pending_strike_email_1_{{j}}" class="col-red"></label>
                                                    </div>
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_email && user.strike_email.includes(2)" checked value="2" id="pending_strike_email_2_{{j}}" (click)="strikeEmail(user.id,'strike_email',2)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_email.includes(2)" value="2" id="pending_strike_email_2_{{j}}" (click)="strikeEmail(user.id,'strike_email',2)" /> &nbsp; 
                                                        <label for="pending_strike_email_2_{{j}}" class="col-red"></label>
                                                    </div>
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_email && user.strike_email.includes(3)" checked value="3" id="pending_strike_email_3_{{j}}" (click)="strikeEmail(user.id,'strike_email',3)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_email.includes(3)" value="3" id="pending_strike_email_3_{{j}}" (click)="strikeEmail(user.id,'strike_email',3)" /> &nbsp; 
                                                        <label for="pending_strike_email_3_{{j}}" class="col-red"></label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-black">
                                                <div class="form-group">
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_text && user.strike_text.includes(1)" checked value="1" id="pending_strike_text_1_{{j}}" (click)="strikeEmail(user.id,'strike_text',1)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_text.includes(1)" value="1" id="pending_strike_text_1_{{j}}" (click)="strikeEmail(user.id,'strike_text',1)" /> &nbsp;
                                                        <label for="pending_strike_text_1_{{j}}" class="col-red"></label>
                                                    </div>
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_text && user.strike_text.includes(2)" checked value="2" id="pending_strike_text_2_{{j}}" (click)="strikeEmail(user.id,'strike_text',2)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_text.includes(2)" value="2" id="pending_strike_text_2_{{j}}" (click)="strikeEmail(user.id,'strike_text',2)" /> &nbsp;  
                                                        <label for="pending_strike_text_2_{{j}}" class="col-red"></label>
                                                    </div>
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_text && user.strike_text.includes(3)" checked value="3" id="pending_strike_text_3_{{j}}" (click)="strikeEmail(user.id,'strike_text',3)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_text.includes(3)" value="3" id="pending_strike_text_3_{{j}}" (click)="strikeEmail(user.id,'strike_text',3)" /> &nbsp;
                                                        <label for="pending_strike_text_3_{{j}}" class="col-red"></label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-black">
                                                <div class="form-group">
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_call && user.strike_call.includes(1)" checked value="1" id="pending_strike_call_1_{{j}}" (click)="strikeEmail(user.id,'strike_call',1)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_call.includes(1)" value="1" id="pending_strike_call_1_{{j}}" (click)="strikeEmail(user.id,'strike_call',1)" /> &nbsp;
                                                        <label for="pending_strike_call_1_{{j}}" class="col-red"></label>
                                                    </div>
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_call && user.strike_call.includes(2)" checked value="2" id="pending_strike_call_2_{{j}}" (click)="strikeEmail(user.id,'strike_call',2)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_call.includes(2)" value="2" id="pending_strike_call_2_{{j}}" (click)="strikeEmail(user.id,'strike_call',2)" /> &nbsp; 
                                                        <label for="pending_strike_call_2_{{j}}" class="col-red"></label>
                                                    </div>
                                                    <div class="checkbox d-inline">
                                                        <input type="checkbox" *ngIf="user.strike_call && user.strike_call.includes(3)" checked value="3" id="pending_strike_call_3_{{j}}" (click)="strikeEmail(user.id,'strike_call',3)" /> 
                                                        <input type="checkbox" *ngIf="!user.strike_call.includes(3)" value="3" id="pending_strike_call_3_{{j}}" (click)="strikeEmail(user.id,'strike_call',3)" /> &nbsp;
                                                        <label for="pending_strike_call_3_{{j}}" class="col-red"></label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-black">
                                                <div class="new-checkbox" *ngIf="user.creds==1">
                                                  
                                                    <label class="switch md-trigger md-setperspective" data-modal="modal-18" (change)="recruitsUpdate(user.id,'creds',user.creds)">
                                                        <input type="checkbox" value="{{user.cards}}" checked>
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div class="new-checkbox" *ngIf="user.creds!=1">
                                                   
                                                    <label class="switch md-trigger md-setperspective" data-modal="modal-18" (change)="recruitsUpdate(user.id,'creds',user.creds)">
                                                        <input type="checkbox" value="{{user.cards}}">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td class="text-green">
                                                <div class="new-checkbox" *ngIf="user.app==1">
                                                   
                                                    <label class="switch md-trigger md-setperspective" data-modal="modal-18" (change)="recruitsUpdate(user.id,'app',user.app)">
                                                        <input type="checkbox" value="{{user.app}}" checked />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div class="new-checkbox" *ngIf="user.app!=1">
                                                    <label class="switch md-trigger md-setperspective" data-modal="modal-18" (change)="recruitsUpdate(user.id,'app',user.app)">
                                                        <input type="checkbox" value="{{user.app}}" />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td class="text-green">
                                                <div class="new-checkbox" *ngIf="user.hp==1">
                                                    <label class="switch md-trigger md-setperspective" data-modal="modal-19" (change)="recruitsUpdate(user.id,'hp',user.hp)">
                                                        <input type="checkbox" value="{{user.hp}}" checked />
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                                <div class="new-checkbox" *ngIf="user.hp!=1">
                                                    <label class="switch md-trigger md-setperspective" data-modal="modal-19" (change)="recruitsUpdate(user.id,'hp',user.hp)">
                                                        <input type="checkbox">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td class="text-right">
                                                <a href="javascript:void(0)" (click)="openNote(addNote,user.id)" class="action-btn bg-edit md-trigger md-setperspective" data-modal="modal-20"><img src="assets/img/svg-icon/copy.svg" width="20" /></a>
                                              
                                            </td>
                                        </tr> -->
                                    </ng-container>
                                    <tr *ngIf="totalPendingRecords == 0">
                                        <td class="text-gray text-center" colspan="5">No Recruits found.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <pagination-controls class="pagination-controls-theme" id="pending_recruits_users_pagination" (pageChange)="getPendingUsers(page = $event)" maxSize="9" directionLinks="true"
                        autoHide="true" responsive="true">
                        </pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #recruterFormModal let-modal>
    <div class="justify-content-right">
        <button type="button" class="close action-btn modal-dismiss mr-10 p-1" (click)="modal.dismiss('Cross click')"
            aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <form [formGroup]="recruterForm" (ngSubmit)="submitData(recruterForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Create New Employee</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">First
                                        Name<span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="first_name">
                                    <span class="text-danger" *ngIf="recruterForm.get('first_name').errors?.required || recruterForm.get('first_name').errors?.minlength && recruterForm.get('first_name').errors">
                                        {{formErrors.first_name}}</span>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Last
                                        Name<span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="last_name">
                                    <span class="text-danger"  *ngIf="recruterForm.get('last_name').errors?.required || recruterForm.get('last_name').errors?.minlength && recruterForm.get('last_name').errors">
                                        {{formErrors.last_name}}</span>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Email<span
                                            class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" type="text" formControlName="email">
                                    <span class="text-danger" *ngIf="recruterForm.get('email').errors?.required || recruterForm.get('email').errors?.email  && recruterForm.get('email').errors">
                                        {{formErrors.email}}</span>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Specialty</label>

                                    <ng-select formControlName="stf_specialty_id" placeholder="Select"
                                        clearAllText="Clear" class="form-control-ng rounded-0">
                                        <ng-option *ngFor="let speciality of specialities" [value]="speciality.id">
                                            {{speciality.name}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Phone</label>
                                    <input class="form-control rounded-0 form-control-md" type="text" formControlName="phone">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState" class="col-form-label text-light-black font-16">SSN</label>
                                    <input class="form-control rounded-0 form-control-md" type="text" formControlName="ssn">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState"
                                        class="col-form-label text-light-black font-16">Birthdate</label>
                                    <input class="form-control rounded-0 form-control-md" type="date" formControlName="birthdate">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Hire Date</label>
                                    <input class="form-control rounded-0 form-control-md" type="date" formControlName="hire_date">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Termination
                                        Date</label>
                                    <input class="form-control rounded-0 form-control-md" type="date" formControlName="termination_date">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState"
                                        class="col-form-label text-light-black font-16">Assignee(s)</label>
                                    <ng-select formControlName="assignee" [multiple]="true" placeholder="Select"
                                        clearAllText="Clear" class="form-control-ng rounded-0">
                                        <ng-option *ngFor="let assignee of assignees" [value]="assignee.id">
                                            {{assignee.name}}
                                        </ng-option>
                                    </ng-select>
                                </div>

                                <div class="form-group col-md-3">
                                    <div class="checkbox d-inline">
                                        <label for="checkbox-recurit"
                                            class="col-form-label text-light-black font-16">Recruiting</label>
                                        <div class="new-checkbox toggle-check-box-t mt-15">
                                            <label>No</label>

                                            <!-- Rounded switch -->
                                            <label class="switch ml-15 mr-15">
                                                <input type="checkbox" formControlName="pipeline" [(ngModel)]="isTruePipeline" id="checkbox-recurit">
                                                <span class="slider round"></span>
                                            </label>
                                            <label>Yes</label>
                                        </div>
                                        <input type="hidden" formControlName="active" [(ngModel)]="active" />
                                        <!-- <input type="checkbox" formControlName="new_recruit" [checked]="isTrue" id="checkbox-recurit"> -->
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <div class="checkbox d-inline">
                                        <label for="checkbox-active"
                                            class="col-form-label text-light-black font-16">Activate Employee
                                            Checklist</label>
                                        <div class="new-checkbox toggle-check-box-t">
                                            <label>No</label>

                                            <!-- Rounded switch -->
                                            <label class="switch ml-15 mr-15">
                                                <input type="checkbox" formControlName="new_recruit" [(ngModel)]="isTrueRecruit" id="checkbox-active">
                                                <span class="slider round"></span>
                                            </label>
                                            <label>Yes</label>
                                        </div>
                                        <!-- <input type="checkbox" formControlName="active" [checked]="isTrue" id="checkbox-active"> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Location Information</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Address Line
                                        1</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="address_line_1">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Address Line
                                        2</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="address_line_2">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Apartment
                                        #</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="apartment_number">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">City</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="city">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState"
                                        class="col-form-label text-light-black font-16">State</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="state">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Zip</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="zip">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Pay Details</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="text-help">
                                    Use this section to provide additional pay and hiring information for the employee.
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Pay
                                        Rate</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="pay_rate">
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="inputState" class="col-form-label text-light-black font-14">Pay
                                        Description</label>
                                    <editor formControlName="pay_description" [init]="{
                                        base_url: '/tinymce',
                                        suffix: '.min',
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                    }"></editor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Additional Information</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="text-help">Use this section to publish any additional details about the employee.
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="inputState" class="col-form-label text-light-black font-14">Additional
                                        Information</label>
                                    <editor formControlName="static_html_block" [init]="{
                                        base_url: '/tinymce',
                                        suffix: '.min',
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                    }"></editor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-modal-group text-center">
                <button type="submit" [disabled]="!recruterForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 w-30 md-close">SUBMIT</button>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #addNote let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0"> Note</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross click')"
            aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <form [formGroup]="noteForm" (ngSubmit)="submitNote(noteForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <input type="hidden" formControlName="id" value="{{employee_id}}">
                    <label class="col-form-label text-light-black font-16">Note <span class="text-danger">*</span></label>
                    <textarea required="" class="form-control rounded-0 form-control-md" id="exampleTextarea" rows="4" formControlName="note"></textarea>
                </div>
            </div>
  
            <div class="form-row">
              <div class="form-group col-md-12">
                 <button type="submit" [disabled]="!noteForm.valid" class="action-btn bg-dark-green mr-2">Submit</button>
              </div>
            </div>
        </form>
    </div>
  </ng-template>