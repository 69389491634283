import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';
import { environment } from '../../environments/environment';

const filePath = environment.filePath;

@Component({
  selector: 'app-training-course-show',
  templateUrl: './training-course-show.component.html',
  styleUrls: ['./training-course-show.component.css']
})
export class TrainingCourseShowComponent implements OnInit {

  courseRegForm:FormGroup;
  courseForm:FormGroup;
  currentCourseId:any;
  courseIdModel:any;
  courses:any=[];
  response:any=[];
  page:any=1;
  totalRecords:any;
  courseRegisters:any=[];
  tabText:any='Overview';
  deleteId:any;
  customers:any=[];
  course:any=[];
  filePath:any=filePath;
  instructors:any=[];
  topics:any=[];
  certifications:any=[];
  addedInstructor:any=[];
  topicModel:any=[];
  certificateModel:any=[];
  instructorModel:any=[];
  min:any=0;
  max:any=0;
  featuredImage:any='';
  headerImage:any='';
  currentUser:any;
  totalSection:any=0;
  completeSection:any=0;

  formErrors = {
    'id':[''],
    'user_id':[''],
    'course_id':['']
  }

  validationMessages = {
    'user_id':{
      'required':'Select user'
    },
    'course_id':{
      'required':'Select course'
    }
  }

  formErrorsCourse = {
    'name':'',
    'featured_image':'',
    'header_image':'',
    'description':'',
    'brief':'',
    'featured_video_embed':'',
    'webinar_chat_embed':'',
    'schedule_date':'',
    'registration_end_date':'',
    'course_date':'',
    'status':'',
    'visibility':'',
    'location_address_line_1':'',
    'location_address_line_2':'',
    'location_city':'',
    'location_state':'',
    'location_zip':'',
    'instructor':'',
    'cost':'',
    'topic':'',
    'certification':'',
    'sponsor':'',
    'published':'',
  }

  validationMessagesCourse = {
    'name':{
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    }
  }

  constructor(
    private route:ActivatedRoute,
    private api:ApiService,
    private formBuilder:FormBuilder,
    private modalService:NgbModal,
    private toastr:ToastrService,
    private router:Router
  ) { 
    this.currentUser = JSON.parse(localStorage.getItem('reliant_user'));
    this.currentCourseId = Number(this.route.snapshot.params['id']);
    this.getSingleCourse(this.currentCourseId);
    this.getCourseRegistration(this.page,this.currentCourseId);
    this.getCourses();
    this.getUsers();
  }

  ngOnInit(): void {
    this.init();
  }

  init(){
    this.courseRegForm = this.formBuilder.group({
      id:[''],
      user_id: ['', [Validators.required]],
      course_id: ['', [Validators.required]]
    });

    this.courseForm = this.formBuilder.group({
      id:[''],
      featured_image:[''],
      header_image:[''],
      description:[''],
      brief:[''],
      featured_video_embed:[''],
      webinar_chat_embed:[''],
      schedule_date:[''],
      registration_end_date:[''],
      course_date:[''],
      status:[''],
      visibility:[''],
      name:['',[Validators.required]],
      location_address_line_1:[''],
      location_address_line_2:[''],
      location_city:[''],
      location_state:[''],
      location_zip:[''],
      instructor:[''],
      cost:[''],
      topic:[''],
      certification:[''],
      sponsor:[''],
      published:['']
    });

    this.courseForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairsCourse(this.courseForm);
    });
 
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  validateKeyValuePairsCourse(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairsCourse(abstractControl);
      } else {
        this.formErrorsCourse[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessagesCourse[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrorsCourse[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  async getSingleCourse(courseId){
    let data = {id:courseId,user_id:this.currentUser.id};
    this.api.post('admin/courses/single_course',data).subscribe((result)=>{
      this.response = result;
      this.course = this.response.data;

      this.course.course_instructor.forEach((element)=>{
        element.instructors.forEach((ele)=>{
          this.addedInstructor.push(ele.name);
        });
      });
      this.topicModel=[];
      this.certificateModel=[];
      this.instructorModel=[];
      this.response.data.course_topics.forEach((element)=>{
        this.topicModel.push(Number(element?.topic_id));
      });
      this.response.data.course_instructor.forEach((element)=>{
        this.instructorModel.push(element?.user_id);
      });
      this.response.data.certification_courses.forEach((element)=>{
        this.certificateModel.push(Number(element?.certification_id));
      });
      this.course.newSectionId='';
      this.totalSection=this.course.sections.length;
      this.course.sections.forEach((element)=>{
        if(element.test_id!=0 && this.response.data.newSectionId=='' && element.section_completions.length==0){
          this.course.newSectionId = element.id;
        }
        if(element.section_completions.length==1){
          this.completeSection++;
        }
      });
      console.log(this.completeSection);
      console.log(this.totalSection);
      console.log(this.course.newSectionId);
    },(err)=>{
    });
    return true;
  }

  getTabString(value){
    this.tabText=value;
  }

  async getCourses(){
    this.api.get('admin/courses/all_courses').subscribe((result)=>{
      this.courses = result.data;
    },(err)=>{
    });
    return true;
  }

  getUsers(){
    this.api.get('admin/course_registration/get_user').subscribe((result)=>{
      this.customers = result.data;
    },(err)=>{
    });
    return true;
  }

  getCourseRegistration(page,courseId){
    this.api.get('admin/course_registration/lists?page='+page+'&course_id='+courseId).subscribe((result)=>{
      this.totalRecords = result.data.total;
      this.courseRegisters = result.data.data;
    },(err)=>{
    });
    return true;
  }

  addCourseReg(content){
    this.courseRegForm.reset();
    this.courseIdModel = Number(this.currentCourseId);
    this.modalService.open(content, { size:'lg',centered:true });
  }

  submitCourseRegData(data):void{
    let endPoint = (data.id)?'update':'create';
    this.api.post('admin/course_registration/'+endPoint,data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.getCourseRegistration(this.page,this.currentCourseId);
      this.getSingleCourse(this.currentCourseId);
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

  editCourseRegistration(content,index){
    this.courseRegForm.reset();
    let editData = this.courseRegisters[index];
    let x = {
      id:editData.id,user_id:Number(editData.user_id)
    }
    this.courseIdModel=Number(editData.course_id);
    this.courseRegForm.patchValue(x);
    this.modalService.open(content, { size:'lg',centered:true });
  }

  deleteModal(content,current_row){
    this.deleteId = current_row.id;
    console.log(this.deleteId);
    this.modalService.open(content,{ size:'sm',centered:true });
  }


  deleteRegistration(){
    let data = {id:this.deleteId};
    this.api.post('admin/course_registration/delete',data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.getCourseRegistration(this.page,this.currentCourseId);
      this.getSingleCourse(this.currentCourseId);
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

  deleteCourse():void{
    let data={id:this.deleteId};
    this.api.post('admin/courses/delete',data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.router.navigateByUrl('/training/courses');
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    }); 
  }

  editModel(content,current_row){
     this.courseForm.reset();
     this.courseForm.patchValue(this.course);
     this.min = Number(this.course.min_registrations);
     this.max = Number(this.course.max_registrations);
     this.modalService.open(content,{ size:'xl',centered:true });
     this.getInstructors();
     this.getTopics();
     this.getCertification();
  }

  minReg(value){
    if(this.min!=0 || value==1) this.min = this.min+value;
  }

  maxReg(value){
    if(this.max!=0 || value==1) this.max = this.max+value;
  }

  onFeaturedImageChange(event)  {
    this.featuredImage = event.target.files;
  }

  onHeaderImageChange(event)  {
    this.headerImage = event.target.files;
  }

  getInstructors(){
    this.api.get('admin/course_registration/get_user').subscribe((result)=>{
      this.instructors = result.data;
    },(err)=>{
    });
    return true;
  }
  getTopics(){
    this.api.get('admin/topics/all_lists').subscribe((result)=>{
      this.topics = result.data;
    },(err)=>{
    });
    return true;
  }
  getCertification(){
    this.api.get('admin/certifications/all_lists').subscribe((result)=>{
      this.certifications = result.data;
    },(err)=>{
    });
    return true;
  }

  updateData(data){
    const formData =  new  FormData();
    formData.append('featured_image',this.featuredImage[0]);
    formData.append('header_image',this.headerImage[0]);
    formData.append('id',data.id);
    formData.append('description',data.description);
    formData.append('brief',data.brief);
    formData.append('featured_video_embed',data.featured_video_embed);
    formData.append('webinar_chat_embed',data.webinar_chat_embed);
    formData.append('schedule_date',(data.schedule_date)?data.schedule_date:this.course.schedule_date);
    formData.append('registration_end_date',(data.registration_end_date)?data.registration_end_date:this.course.registration_end_date);
    formData.append('course_date',data.course_date);
    formData.append('status',(data.status)?data.status:this.course.status);
    formData.append('visibility',data.visibility);
    formData.append('name',data.name);
    formData.append('location_address_line_1',(data.location_address_line_1)?data.location_address_line_1:this.course.location_address_line_1);
    formData.append('location_address_line_2',(data.location_address_line_2)?data.location_address_line_2:this.course.location_address_line_2);
    formData.append('location_city',(data.location_city)?data.location_city:this.course.location_city);
    formData.append('location_state',(data.location_state)?data.location_state:this.course.location_state);
    formData.append('location_zip',(data.location_zip)?data.location_zip:this.course.location_zip);
    formData.append('instructor',data.instructor);
    formData.append('cost',data.cost);
    formData.append('topic',data.topic);
    formData.append('certification',data.certification);
    formData.append('published',data.published);
    formData.append('max_registrations',this.max);
    formData.append('min_registrations',this.min);

    let endPoint = (data.id || this.currentCourseId)?'update':'create';
    this.api.uploadFile('admin/courses/'+endPoint,formData).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.getSingleCourse(this.currentCourseId);
      this.modalService.dismissAll();
    },(err)=>{
      this.toastr.error(err.error.message,"Error")
    });
  }

  registerCurrentUser(){
    let data = {id:'',course_id:this.currentCourseId,user_id:this.currentUser.id};
    this.submitCourseRegData(data);
  }

}
