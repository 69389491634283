import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  userForm: FormGroup;
  roles: any = [];
  users: any = [];
  editData: any = {};
  isAlert: boolean = false;
  alertMessage: string = '';
  alertClass: string = '';
  alertBckClass: string ='';
  deleteId: string = '';
  modalReference: any = '';
  totalRecords: any;
  response:any;
  page: any = 1;

  formErrors = {
    'name': '',
    'email': '',
    'phone': '',
    'roles': '',
    'password': '',
    'password_confirmation': ''
  };
  validationMessages = {
    'name': {
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    },
    'email': {
      'required': 'Email is required.',
      'email': 'Email is invalid'
    },
    'phone': {
      'minlength': 'Phone number must be of 10 digit.'
    },
    'roles': {
      'required': 'Role is required.'
    },
    'password': {
      'required': 'Password is required.',
      'minlength': 'Password must be 10 characters long.'
    },
    'password_confirmation': {
      'confirmedValidator': 'Confirm Password is not matched'
    }
  };

  constructor(
    private api: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public toastr:ToastrService,
  ) { }

  ngOnInit(): void {
    this.init();
    this.getRoles();
    this.getUsers(this.page);
  }

  init(): void {
    this.userForm = this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.minLength(10)]],
      roles: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(10)]],
      password_confirmation: ['']
    }, {
      validator: this.confirmedValidator('password', 'password_confirmation')
    });
    this.userForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.userForm);
    });
  }

  confirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  async getRoles() {
    this.api.get('admin/role/lists').subscribe((result) => {
      this.roles = result.data;
    }, (err) => {
    });
  }

  async getUsers(page) {
    this.api.get('admin/user/lists?page='+page).subscribe((result) => {
      this.users = [];
      this.totalRecords = result.data.total;
      result.data.data.forEach((obj) => {
        var roles = [];
        obj.roles.forEach((e) => {
          roles.push(e.role_id);
        });
        obj.roles = roles;
        this.users.push(obj);
      });
    }, (err) => {
    });
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  editUser(content, index): void {
    this.userForm.reset();
    this.editData = this.users[index];
    this.userForm.patchValue(this.editData);
    this.alertClass = 'success';
    this.alertMessage = 'User has been successfully updated.';
    this.modalService.open(content, { size: 'lg', centered: true, scrollable: true });
  }

  deleteModal(modal, id){
    this.deleteId = id;
    this.modalReference = this.modalService.open(modal, { size: 'sm', centered: true });
  }

  deleteUser(): void {
    let id = this.deleteId;
    this.modalReference.close();
    this.api.post('admin/user/delete', { id: id }).subscribe((result) => {
      this.response=result;
      this.toastr.success(this.response.message,"Success");
      this.getUsers(this.page);
      // this.alertClass = 'success';
      // this.alertMessage = 'User has been successfully deleted.';
      // this.isAlert = true;
    }, (err) => {
      this.toastr.error(err.error.message,"Error");
      // this.alertClass = 'danger';
      // this.alertMessage = err.error.message;
      // this.isAlert = true;
    });
  }

  addUser(content): void {
    this.userForm.reset();
    this.editData = {};
    this.alertClass = 'success';
    this.alertMessage = 'User has been successfully added.';
    this.modalService.open(content, { size: 'lg', centered: true, scrollable: true });
  }

  submitData(data): void {
    let endPoint = (data.id) ? 'update' : 'create';
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/user/' + endPoint, data).subscribe((result) => {
      this.response=result;
      this.toastr.success(this.response.message,"Success");
      this.getUsers(this.page);
      this.isAlert = true;
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      (<HTMLButtonElement>document.getElementsByClassName("modal-dismiss")[0]).click();
    }, (err) => {
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      (<HTMLButtonElement>document.getElementsByClassName("modal-dismiss")[0]).click();
      this.alertClass = 'danger';
      this.alertMessage = err.error.message;
      this.toastr.error(err.error.message,"Error");
      this.isAlert = true;
    });
  }

  getUserRoles(value) {
    let role_name = [];
    this.roles.forEach((e) => {
      value.forEach((id) => {
        if (e.id == id) {
          role_name.push(e.name);
        }
      });
    });
    return role_name.join(', ');
  }

  closeAlert(): void{
    this.isAlert = false;
    this.alertMessage = '';
  }

}
