import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-user-toast',
  templateUrl: './user-toast.component.html',
  styleUrls: ['./user-toast.component.css']
})
export class UserToastComponent implements OnInit {

  constructor(public toastService: ToastService) { }
  
  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }

  ngOnInit(): void {
  }

}
