import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-training-courses',
  templateUrl: './training-courses.component.html',
  styleUrls: ['./training-courses.component.css']
})
export class TrainingCoursesComponent implements OnInit {

  response:any=[];
  page:any=1;
  courses:any=[];
  totalRecords:any;
  delete_id:any;

  constructor(
    private api:ApiService,
    private toastr:ToastrService,
    private modalService:NgbModal
  ) {
    this.getCourses(this.page);
   }

  ngOnInit(): void {
  }

  async getCourses(page){
    this.api.get('admin/courses/lists?page='+page).subscribe((result)=>{
      this.totalRecords=  result.data.total;
      this.courses = result.data.data;
    },(err)=>{

    });
  }

  deleteModal(content,current_row){
    this.delete_id = current_row.id;
    this.modalService.open(content,{ size:'sm', centered:true });
  }

  deleteCourse():void{
    let data={id:this.delete_id};
    this.api.post('admin/courses/delete',data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.getCourses(this.page);
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    }); 
  }

}
