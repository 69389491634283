import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-flag-management-layout',
  templateUrl: './flag-management-layout.component.html',
  styleUrls: ['./flag-management-layout.component.css']
})
export class FlagManagementLayoutComponent implements OnInit {

  modalReference: any = '';
  constructor(private router : Router, private _modalService: NgbModal) { }

  ngOnInit(): void {
  }

  logout(): void {
    localStorage.removeItem('reliant_user'); 
    localStorage.removeItem('isReliantUserLoggedIn');
    localStorage.removeItem('reliant_role_based_user');
    this.router.navigate(['/login']);
    this.modalReference.close();
  }

  open(name) {
    this.modalReference = this._modalService.open(name, { size: 'sm', centered: true });
  }

}
