<div class="row">
  <div class="col-xl-12 box-margin">
      <div class="card">
          <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
              <h6 class="card-title mb-0">Online Training Instructions</h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <p>All employees of Reliant Staffing must complete the online training modules in order to work at any of our facilities.</p>
                <p><u>Click on the "My Courses" tab on the left side of the screen to access the training modules.</u></p>
                <p>Each module has content to review and a test associated with the material. You must pass each module with at least an 80%. If you do not pass the exam, we encourage you to review the content and retake the exam. The exams can be take as many times as necessary to pass.</p>
              </div>
            </div>
          </div>
      </div>
  </div>
</div>
