import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-crm-client',
  templateUrl: './crm-client.component.html',
  styleUrls: ['./crm-client.component.css']
})
export class CrmClientComponent implements OnInit {
  noteForm: FormGroup;
  clientForm: FormGroup;
  page: any = 1;
  totalRecords: any;
  customers: any = [];
  current_client : any;
  response:any;

  totalRecordInactive: any;
  inactiveCustomers: any = [];
  inactivePage: any = 1;
  isTrue:boolean = true;

  formErrors = {
    'company_name': '',
    'phone':'',
    'caller_phone':'',
    'billing_email':'',
    'specialty':'',
    'marketing':'',
    'address_line_1':'',
    'address_line_2':'',
    'city':'',
    'state':'',
    'zip':'',
    'administrator_name':'',
    'administrator_phone':'',
    'administrator_alt_phone':'',
    'administrator_email':'',
    'scheduler_name':'',
    'scheduler_phone':'',
    'scheduler_alt_phone':'',
    'scheduler_email':'',
    'static_html_block':''

  };
  validationMessages = {
    'company_name': {
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    }
  };



  constructor(private api: ApiService, private modalService: NgbModal, private formBuilder: FormBuilder,private toast:ToastrService) {
    this.noteForm = this.formBuilder.group({
        note: [''],
    });

    this.clientForm = this.formBuilder.group({
      client: [''],
    });

    this.getClients(this.page);
    this.getInactiveClients(this.inactivePage);
  }

  ngOnInit(): void {
    this.init();
  }


  init() :void{
    this.clientForm = this.formBuilder.group({
      id: [''],
      company_name: ['', [Validators.required, Validators.minLength(3)]],
      phone:[''],
      caller_phone:[''],
      billing_email:[''],
      specialty:[''],
      marketing:[''],
      address_line_1:[''],
      address_line_2:[''],
      city:[''],
      state:[''],
      zip:[''],
      administrator_name:[''],
      administrator_phone:[''],
      administrator_alt_phone:[''],
      administrator_email:[''],
      scheduler_name:[''],
      scheduler_phone:[''],
      scheduler_alt_phone:[''],
      scheduler_email:[''],
      static_html_block:['']
    });
    this.clientForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.clientForm);
    });
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  async getClients(page) {
    this.page = page;
    this.api.get('admin/clients/lists?page='+page).subscribe((result) => {
        this.totalRecords = result.data.total;
        this.customers = result.data.data;

    }, (err) => {

    });
  }

  async getInactiveClients(inactivePage) {
    this.inactivePage = inactivePage;
    let status = 0;
    this.api.get('admin/clients/lists?status='+status+'&page='+inactivePage).subscribe((result) => {
        this.totalRecordInactive = result.data.total;
        this.inactiveCustomers = result.data.data;
    }, (err) => {

    });
  }

  openNote(addNote, current_row): void {
    this.current_client = current_row;
    this.modalService.open(addNote, { size: 'xl', centered: true });
  }

  submitNote(data): void {
    console.log(this.current_client);
    data.model_type = 'Client';
    data.model_id = this.current_client.id;
    this.api.post('admin/note/create', data).subscribe((result) => {
       console.log(result);
       this.response=result;
       this.toast.success(this.response.message,"Success");
       this.modalService.dismissAll();
    }, (err) => {
      this.toast.error(err.error.message,'Error');
    });
  }

  async deleteClient(client) {
    let data = { id: client.id}
    this.api.post('admin/clients/delete', data).subscribe((result) => {
       this.response=result;
       this.toast.success(this.response.message,"Success");
       this.getClients(this.page);
    }, (err) => {
       console.log(err);
       this.toast.error(err.error.message,'Error');
    });
  }

  addClient(Content){
    this.clientForm.reset();
    this.modalService.open(Content, { size: 'xl', centered: true});
  }

  submitClientData(data):void{
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/marketing/create', data).subscribe((result) => {
      this.response = result;
      this.toast.success(this.response.message,"Success");
      this.getClients(this.page);
      this.getInactiveClients(this.inactivePage);
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.modalService.dismissAll();
    }, (err) => {
      this.toast.error(err.error.message,"Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });
  }


}
