import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';
import { environment } from '../../environments/environment';

const filePath = environment.filePath;

const MAX_SIZE = 1048576;
@Component({
  selector: 'app-employee-profile',
  templateUrl: './employee-profile.component.html',
  styleUrls: ['./employee-profile.component.css']
})
export class EmployeeProfileComponent implements OnInit {

  noteForm: FormGroup;
  profileImageForm: FormGroup;
  employeeForm: FormGroup;
  employeeUploadNewFileForm: FormGroup;
  employeeEmailFilesForm: FormGroup;
  weekMonthlyAvailabilityForm: FormGroup;
  emailRequestForm: FormGroup;
  response: any;
  employee_id: any;
  employee: any = [];
  notes: any = [];
  note_del_id: any;
  user: any = [];
  filePath: any = filePath;
  specialities: any = [];
  assignees: any = [];
  isTrue: boolean = true;
  activetevalue: any;
  theFile: any = null;
  imageSrc: string;
  isEmployeeDetails: boolean = true;
  isEmployeeAvailability: boolean = false;
  isEmployeeFiles: boolean = false;
  isEmployeePay: boolean = false;
  isEmployeeWeekMonthlyAvailability: boolean = false;
  isSubmittedMonthlyAvailability: boolean = false;
  allStfCustomFiles: any = [];
  stfcustomfileId: any = '';
  employeeFilesPath: any = '';
  expiration: any = '';
  allStfModelRates: any = [];
  selectEmployeeFiles: any = '';
  fileName: any = '';
  allEmailFiles: any = [];
  payRateData: any = [];
  todayDate: any = '';
  nextDate: any = '';
  number_of_hours: any = [];
  nowTodayDate: any = '';
  isCount: any = 1;
  weeksArray: any = [];
  formData: any = [];
  arr: any = [];
  newData: any = [];
  checkUncheckCheckArr: any = [];
  isDisabled: boolean = true;
  isArray: any = [];



  formErrors = {
    'id': '',
    'first_name': '',
    'last_name': '',
    'email': '',
    'stf_specialty_id': '',
    'phone': '',
    'ssn': '',
    'birthdate': '',
    'hire_date': '',
    'termination_date': '',
    'assignee': '',
    'new_recruit': '',
    'active': '',
    'address_line_1': '',
    'address_line_2': '',
    'apartment_number': '',
    'city': '',
    'state': '',
    'zip': '',
    'pay_rate': '',
    'pay_description': '',
    'static_html_block': '',
  };
  validationMessages = {
    'first_name': {
      'required': 'First name is required.',
      'minlength': 'Name must be 3 characters long.'
    },
    'last_name': {
      'required': 'Last name is required.',
      'minlength': 'Name must be 3 characters long.'
    },
    'email': {
      'required': 'Email is required.',
      'email': 'Email is invalid'
    },
    'phone': {
      'minlength': 'Phone number must be of 10 digit.'
    },
    'custom_field': {
      'required': 'Select a Field is required.'
    }
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer

  ) {

    this.user = JSON.parse(localStorage.getItem('reliant_user'));
    this.employee_id = this.route.snapshot.params['id'];
    this.getEmployeeData(this.employee_id);
    this.getNotes();

    this.noteForm = this.formBuilder.group({
      note: [''],
      id: ['']
    });

    this.profileImageForm = this.formBuilder.group({
      profile_image: [''],
    });

    this.weekMonthlyAvailabilityForm = this.formBuilder.group({
      id: [''],
      week_number_of_hours: [''],
      comments: [''],
    });

    this.emailRequestForm = this.formBuilder.group({
      start_date: ['', [Validators.required]],
      end_date: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.init();
    this.getSpecialties();
    this.todayDate = new Date().toISOString().split('T')[0];
    this.init();
    this.nowTodayDate = new Date().toISOString().split('T')[0];
    this.todayDate = (this.todayDate) ? this.todayDate : this.nowTodayDate;

    const date = new Date();
    this.monthlyAvailabilityData();
    this.checkUncheckCheckBox();

    for (var x = 0; x <= 100; x++) {
      if (x === 0 || x % 4 === 0) {
        this.number_of_hours.push(x);
      }
    }
    //this.getStfEmployeeAvailability();
  }

  init(): void {
    this.employeeForm = this.formBuilder.group({
      id: [''],
      first_name: ['', [Validators.required, Validators.minLength(3)]],
      last_name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      stf_specialty_id: [''],
      phone: [''],
      ssn: [''],
      birthdate: [''],
      hire_date: [''],
      termination_date: [''],
      assignee: [''],
      new_recruit: [true],
      active: [true],
      address_line_1: [''],
      address_line_2: [''],
      apartment_number: [''],
      city: [''],
      state: [''],
      zip: [''],
      pay_rate: [''],
      pay_description: [''],
      static_html_block: ['']
    });
    this.employeeForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.employeeForm);
    });

    this.employeeUploadNewFileForm = this.formBuilder.group({
      id: [''],
      custom_field: ['', [Validators.required]],
      expiration: [''],
      employee_files: [''],

    });
    this.employeeUploadNewFileForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.employeeUploadNewFileForm);
    });

    this.employeeEmailFilesForm = this.formBuilder.group({
      id: [''],
      type: [''],
      client_id: [''],
      employee_files: [''],
      custom_recipient: [''],
      subject: [''],
      message: ['']

    });
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  async getSpecialties() {
    this.api.get('admin/specialities/lists').subscribe((result) => {
      this.specialities = result.data;
    }, (err) => {
    });
  }

  getEmployeeData(employee_id) {
    this.api.get("admin/employee/employee-profile?employee_id=" + employee_id).subscribe((result) => {
      this.employee = result.data;
    }, (err) => {

    });
  }

  async getNotes() {
    this.api.get("admin/note/lists?model_type=Employee&model_id=" + this.employee_id).subscribe((result) => {
      this.notes = result.data;
    }, (err) => {

    });
  }


  editNotes(content, data) {
    this.noteForm.reset();
    this.noteForm.patchValue(data);
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  openNote(addNote, current_row) {
    this.noteForm.reset();
    this.modalService.open(addNote, { size: 'xl', centered: true });
  }

  submitNote(data): void {
    let endPoint = (data.id) ? 'update' : 'create';
    data.model_type = 'Employee';
    data.model_id = this.employee_id;
    data.user_id = this.user.id;
    this.api.post('admin/note/' + endPoint, data).subscribe((result) => {

      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getNotes();
      this.modalService.dismissAll();
    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }

  deleteNotesModal(content, current_row) {
    this.note_del_id = current_row.id;
    this.modalService.open(content, { size: 'sm', centered: true });
  }

  deleteNotes(): void {
    let data = { id: this.note_del_id };
    this.api.post('admin/note/delete', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.modalService.dismissAll();
      this.getNotes();
    }, (err) => {
      this.modalService.dismissAll();
      this.toastr.error(err.error.message, 'Error');
    });
  }

  editEmployeeModal(content, current_employee) {
    this.employeeForm.reset();
    this.employeeForm.patchValue(current_employee);
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  submitData(data) {
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/employee/update', data).subscribe((result) => {
      //this.toastService.show('Employee created successfully', { classname: 'bg-success text-light', delay: 5000 });
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getEmployeeData(this.employee_id);
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      (<HTMLButtonElement>document.getElementsByClassName("modal-dismiss")[0]).click();
    }, (err) => {
      this.toastr.error(err.error.message, "Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      (<HTMLButtonElement>document.getElementsByClassName("modal-dismiss")[0]).click();
    });
  }

  employeeActivateModal(content, value) {
    this.activetevalue = value;
    this.modalService.open(content, { size: 'sm', centered: true });
  }

  employeeActivate() {
    let data = { id: this.employee_id, value: this.activetevalue };
    this.api.post("admin/employee/active_status", data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.modalService.dismissAll();
      this.getEmployeeData(this.employee_id);

    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }


  employeeDNRModal(content, value) {
    this.activetevalue = value;
    this.modalService.open(content, { size: 'sm', centered: true });
  }

  employeeDNR() {
    let data = { id: this.employee_id, value: this.activetevalue, user_id: this.user.id };

    this.api.post("admin/employee/dnr_status", data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.modalService.dismissAll();
      this.getEmployeeData(this.employee_id);

    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }


  changePipeline(current_row, value) {

    let data = { id: current_row.id, value: value };
    this.api.post("admin/employee/pipeline", data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.modalService.dismissAll();
      this.getEmployeeData(this.employee_id);

    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }



  selectImage() {
    document.getElementById("profile_image").click();
  }

  uploadImage(event) {
    if (event.target.files && event.target.files.length > 0) {

      console.log(event.target.files[0].size);
      // Don't allow file sizes over 1MB
      if (event.target.files[0].size < MAX_SIZE) {
        // Set theFile property
        this.theFile = event.target.files[0];
        console.log(this.theFile);

        const reader = new FileReader();

        reader.readAsDataURL(this.theFile);

        reader.onload = () => {

          this.imageSrc = reader.result as string;
          (<HTMLImageElement>document.getElementById('showImage')).src = this.imageSrc;
          console.log(this.imageSrc);

          let data = { profile_image: this.imageSrc, id: this.employee_id };

          console.log(data);

          this.api.post("admin/employee/upload_profile_image", data).subscribe((result) => {
            this.response = result;
            this.toastr.success(this.response.message, "Success");
            this.getEmployeeData(this.employee_id);
            (<HTMLInputElement>document.getElementById("profile_image")).value = '';
          }, (err) => {
            this.toastr.error(err.error.message, "Error");
          });

        }

      }
      else {
        // Display error message
        this.toastr.warning("File: " + event.target.files[0].name + " is too large to upload.", "Warning");
      }
    }
  }

  getMap(employee) {
    let url = 'https://www.google.com/maps/embed/v1/place?q=' + employee.address_line_1 + employee.city + ' ' + employee.state + ' ' + employee.zip + '&key=AIzaSyBSFRN6WWGYwmFi498qXXsD2UwkbmD74v4';

    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  async getStfCustomFiles() {
    //let data = { model_id: this.employee_id , modle_type:'employee'}
    let data = { model_id: this.employee_id, model_type: 'employee' }
    this.api.post('admin/stf_custom_files/lists', data).subscribe((result) => {
      this.response = result;
      this.allStfCustomFiles = this.response.data;
      //this.toastr.success(this.response.message,"Success");
    }, (err) => {
      this.toastr.error(err.error.messsage, "Error");
    });
  }

  employeeDetails() {
    this.isEmployeeDetails = true;
    this.isEmployeeAvailability = this.isEmployeeFiles = this.isEmployeePay = this.isEmployeeWeekMonthlyAvailability = false;
  }
  employeeFiles() {
    this.getStfCustomFiles();
    this.isEmployeeFiles = true;
    this.isEmployeeAvailability = this.isEmployeeDetails = this.isEmployeePay = this.isEmployeeWeekMonthlyAvailability = false;
  }

  employeeAvailability() {
    this.isEmployeeAvailability = true;
    this.isEmployeeFiles = this.isEmployeeDetails = this.isEmployeeWeekMonthlyAvailability = this.isEmployeePay = this.isSubmittedMonthlyAvailability = false;
  }
  employeePay() {
    this.getStfModelRates();
    this.isEmployeePay = true;
    this.isEmployeeFiles = this.isEmployeeAvailability = this.isEmployeeDetails = this.isEmployeeWeekMonthlyAvailability = false;
  }

  uploadEmployeeFiles(event, file_id) {
    if (event.target.files && event.target.files.length > 0) {

      console.log("size==>", event.target.files[0].size);
      // Don't allow file sizes over 1MB
      if (event.target.files[0].size < MAX_SIZE) {
        // Set theFile property
        this.theFile = event.target.files[0];
        console.log("this.theFile ==>", this.theFile);

        const reader = new FileReader();

        reader.readAsDataURL(this.theFile);

        reader.onload = () => {

          this.imageSrc = reader.result as string;
          (<HTMLImageElement>document.getElementById('showImage')).src = this.imageSrc;
          console.log("imageSrc==>", this.imageSrc);
          let expiration_value = '';
          let data = { employee_files: this.imageSrc, model_id: this.employee_id, stf_custom_file_id: file_id, model_type: 'App\\Models\\Staffing\\Employee', expiration: expiration_value, user_id: this.user.id };

          console.log("Data==>", data);

          this.api.post("admin/stf_model_custom_files/create", data).subscribe((result) => {
            this.response = result;
            this.toastr.success(this.response.message, "Success");
            this.getStfCustomFiles();
            (<HTMLInputElement>document.getElementById("profile_image")).value = '';
          }, (err) => {
            this.toastr.error(err.error.message, "Error");
          });
        }
      }
      else {
        // Display error message
        this.toastr.warning("File: " + event.target.files[0].name + " is too large to upload.", "Warning");
      }
    }
  }
  openEmployeeFilesPopUp(content, stf_custom_file_id) {

    this.stfcustomfileId = stf_custom_file_id;
    this.modalService.open(content, { size: 'sm', centered: true });
  }
  getPdfPath(path) {
    const d = new Date();
    // let url = 'https://staging.reliantstaffing.co/api_backend/public/' + path + "?time=" + d.getTime();
    let url = filePath + path + "?time=" + d.getTime();
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }
  openEmployeeFiles(content, path) {
    this.employeeFilesPath = path;
    this.modalService.open(content, { size: 'xl', centered: true });
  }
  deleteEmployeeFilesData() {
    let data = { id: this.stfcustomfileId };
    this.api.post('admin/stf_model_custom_files/delete', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.modalService.dismissAll();
      this.getStfCustomFiles();
    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }
  openexpirationDate(content, stf_custom_file_id, expirationDate) {
    expirationDate = (expirationDate) ? expirationDate.slice(0, 10) : '';
    this.expiration = expirationDate;
    this.stfcustomfileId = stf_custom_file_id;
    this.modalService.open(content, { size: 'sm', centered: true });
  }
  updateExpirationDate() {
    let data = { id: this.stfcustomfileId, expiration: this.expiration }
    this.api.updateExpirationDate('admin/stf_model_custom_files/update', data).subscribe((result) => {
      this.modalService.dismissAll();
      this.getStfCustomFiles();
    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }
  openUploadNewFile(content) {
    this.employeeUploadNewFileForm.reset();
    this.employeeEmailFilesForm.reset();
    this.getEmailFiles();
    this.getStfModelRates();
    this.modalService.open(content, { size: 'xl', centered: true });
  }
  submitEmployeeUploadNewFile(data) {
    data.model_type = 'employee';
    data.model_id = this.employee_id;
    data.employee_files = this.selectEmployeeFiles;

    this.api.post('admin/stf_model_custom_files/add_employee_files', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.modalService.dismissAll();
      this.getStfCustomFiles();
      this.modalService.dismissAll();
    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }
  getType(event) {
    console.log("event.target.value==>", event.target.value);
  }
  submitemployeeEmailFiles(data) {
    console.log("submitemployeeEmailFiles===>", data);
  }

  openResetHirePacket(content) {
    this.modalService.open(content, { size: 'sm', centered: true });
  }
  deleteResetHirePacketData() {
    let data = { model_id: this.employee_id, field_name: 'Hire Packet' }
    this.api.post('admin/stf_model_custom_files/reset_hire_packet', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.modalService.dismissAll();
      this.getStfCustomFiles();
    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }
  openResetTraining(content) {
    this.modalService.open(content, { size: 'sm', centered: true });
  }
  deleteResetTrainingData() {
    let data = { model_id: this.employee_id }
    this.api.post('admin/stf_model_custom_files/reset_training', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.modalService.dismissAll();
      this.getStfCustomFiles();
    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }

  async getStfModelRates() {
    let data = { model_id: this.employee_id }
    this.api.post('admin/stf_model_rates/lists', data).subscribe((result) => {
      this.response = result;
      this.allStfModelRates = this.response.data;
    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }
  async getEmailFiles() {
    let data = { model_id: this.employee_id, model_type: 'App\\Models\\Staffing\\Employee' }
    this.api.post('admin/stf_model_custom_files/lists', data).subscribe((result) => {
      this.response = result;
      this.allEmailFiles = this.response.data;
    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }

  convertImageInBase64(event) {
    if (event.target.files && event.target.files.length > 0) {

      // Set theFile property
      this.theFile = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.theFile);
      reader.onload = () => {
        this.selectEmployeeFiles = reader.result as string;
      }
      this.fileName = this.theFile.name;
    } else {
      this.selectEmployeeFiles = '';
      this.fileName = '';
    }
  }

  updateEmployeeRates() {
    let data = { data: this.allStfModelRates }
    this.api.post('admin/stf_model_rates/update', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getStfModelRates();
    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }
  checkVlaue(dbArray, value) {
    let index = dbArray.findIndex(x => x.label === value);
    let data = dbArray[index];
    if (data != undefined) {
      return data.amount;
    } else {
      return null;
    }
  }
  payRatesWeekDay(index, weeklabel, event) {

    let amount = event.target.value
    let localindex = this.allStfModelRates[index].stf_model_rates.findIndex(x => x.label === weeklabel);
    if (localindex > -1) {
      this.allStfModelRates[index].stf_model_rates[localindex].amount = amount;
    } else {
      if (this.allStfModelRates[index].stf_model_rates.length > 0) {
        let weekindex = 1;
        this.allStfModelRates[index].stf_model_rates[weekindex] = { id: null, model_id: this.allStfModelRates[index].id, owner_model_id: this.employee_id, amount: amount, label: weeklabel, stf_rate_id: 3, model_type: 'App\\Models\\Staffing\\Employee' }
      } else {
        let weekindex = 0;
        this.allStfModelRates[index].stf_model_rates[weekindex] = { id: null, model_id: this.allStfModelRates[index].id, owner_model_id: this.employee_id, amount: amount, label: weeklabel, stf_rate_id: 3, model_type: 'App\\Models\\Staffing\\Employee' }
      }

    }
    console.log("this.allStfModelRates[index].stf_model_rates==>", this.allStfModelRates[index].stf_model_rates);
  }
  monthlyAvailabilityStart() {
    if (!this.todayDate) {
      this.toastr.warning("Please enter the date that you would like to begin entry for.", 'Start Date');
      return false;
    }

    let today = new Date(this.todayDate);
    let day = today.getDay();
    let newdate;
    if (day != 0) {
      newdate = new Date(this.updateDate(7 - day));
      let date = newdate.getFullYear() + '-' + (newdate.getMonth() + 1) + '-' + newdate.getDate();
      this.todayDate = date;
      this.monthlyAvailabilityData();
    }
    this.getStfEmployeeAvailability();
    this.isEmployeeWeekMonthlyAvailability = true;
    this.isEmployeeDetails = this.isEmployeeAvailability = this.isEmployeeFiles = this.isEmployeePay = false;
  }
  warningMsg() {
    return this.toastr.warning("You must select a box for each day of the week.", 'Date Not Set');
  }
  submitWeekMonthlyAvailabilityForm(data) {
    if (!data.week_number_of_hours) {
      this.toastr.warning("Please enter the number of hours you would like to request before proceeding.", 'Number of Hours');
      return false;
    }
    let sunday = this.newData.days[0].values;
    let monday = this.newData.days[1].values;
    let tuesday = this.newData.days[2].values;
    let wednesday = this.newData.days[3].values;
    let thursday = this.newData.days[4].values;
    let friday = this.newData.days[5].values;
    let saturday = this.newData.days[6].values;
    if (!(sunday[0].value || sunday[1].value || sunday[2].value || sunday[4].value || sunday[5].value)) {
      this.warningMsg();
      return false;
    }
    if (!(monday[0].value || monday[1].value || monday[2].value || monday[4].value || monday[5].value)) {
      this.warningMsg();
      return false;
    }
    if (!(tuesday[0].value || tuesday[1].value || tuesday[2].value || tuesday[4].value || tuesday[5].value)) {
      this.warningMsg();
      return false;
    }
    if (!(wednesday[0].value || wednesday[1].value || wednesday[2].value || wednesday[4].value || wednesday[5].value)) {
      this.warningMsg();
      return false;
    }
    if (!(thursday[0].value || thursday[1].value || thursday[2].value || thursday[4].value || thursday[5].value)) {
      this.warningMsg();
      return false;
    }
    if (!(friday[0].value || friday[1].value || friday[2].value || friday[4].value || friday[5].value)) {
      this.warningMsg();
      return false;
    }
    if (!(saturday[0].value || saturday[1].value || saturday[2].value || saturday[4].value || saturday[5].value)) {
      this.warningMsg();
      return false;
    }

    let start_datetime = this.updateDate(1);
    let end_datetime = this.updateDate(7);

    const d = new Date(this.todayDate);
    d.setDate(d.getDate() + 7);
    this.todayDate = new Date(new Date(d)).toISOString();
    this.newData.numberOfHours = data.week_number_of_hours;
    this.newData.comment = data.comments;
    let formdata = { user_id: this.employee_id, start_datetime: start_datetime, end_datetime: end_datetime, data: this.newData }

    if (!this.isArray[this.isCount - 1]) {
      this.isArray.push(this.newData);

    }

    this.isCount++;
    this.monthlyAvailabilityData();
    this.api.post('admin/stf_employee_availability/create', formdata).subscribe((result) => {
      this.response = result;
      if (this.isCount > 6) {
        this.isSubmittedMonthlyAvailability = true;
        console.log('isSubmittedMonthlyAvailability', this.isSubmittedMonthlyAvailability);
        this.isEmployeeWeekMonthlyAvailability = false;
        this.modalService.dismissAll();
      }
      this.toastr.success(this.response.message, "Success");
      this.weekMonthlyAvailabilityForm.reset();
      this.getStfEmployeeAvailability();
      this.monthlyAvailabilityData();
    }, (err) => {
      this.toastr.error(err.error.message, "Error");
    });
  }
  monthlyAvailabilityData() {
    this.newData = {
      startDate: this.updateDate(1),
      endDate: this.updateDate(7),
      displayStartDate: this.updateDays(1),
      displayEndDate: this.updateDays(7),
      comment: "",
      numberOfHours: "0",
      days: [{
        date: this.updateDate(1),
        values: [
          {
            value: false,
            name: "Morning (8)"
          },
          {
            value: false,
            name: "Afternoon (8)"
          },
          {
            value: false,
            name: "Night (8)"
          },
          {
            value: false,
            name: "Twelve (12)"
          },
          {
            value: false,
            name: "Double (16)"
          },
          {
            value: false,
            name: "Not Available"
          }
        ],
        dayName: "Sunday",
        displayDate: this.updateDays(0)
      },
      {
        date: this.updateDate(2),
        values: [
          {
            value: false,
            name: "Morning (8)"
          },
          {
            value: false,
            name: "Afternoon (8)"
          },
          {
            value: false,
            name: "Night (8)"
          },
          {
            value: false,
            name: "Twelve (12)"
          },
          {
            value: false,
            name: "Double (16)"
          },
          {
            value: false,
            name: "Not Available"
          }
        ],
        dayName: "Monday",
        displayDate: this.updateDays(1)
      },
      {
        date: this.updateDate(3),
        values: [
          {
            value: false,
            name: "Morning (8)"
          },
          {
            value: false,
            name: "Afternoon (8)"
          },
          {
            value: false,
            name: "Night (8)"
          },
          {
            value: false,
            name: "Twelve (12)"
          },
          {
            value: false,
            name: "Double (16)"
          },
          {
            value: false,
            name: "Not Available"
          }
        ],
        dayName: "Tuesday",
        displayDate: this.updateDays(2)
      },
      {
        date: this.updateDate(4),
        values: [
          {
            value: false,
            name: "Morning (8)"
          },
          {
            value: false,
            name: "Afternoon (8)"
          },
          {
            value: false,
            name: "Night (8)"
          },
          {
            value: false,
            name: "Twelve (12)"
          },
          {
            value: false,
            name: "Double (16)"
          },
          {
            value: false,
            name: "Not Available"
          }
        ],
        dayName: "Wednesday",
        displayDate: this.updateDays(3)
      },
      {
        date: this.updateDate(5),
        values: [
          {
            value: false,
            name: "Morning (8)"
          },
          {
            value: false,
            name: "Afternoon (8)"
          },
          {
            value: false,
            name: "Night (8)"
          },
          {
            value: false,
            name: "Twelve (12)"
          },
          {
            value: false,
            name: "Double (16)"
          },
          {
            value: false,
            name: "Not Available"
          }
        ],
        dayName: "Thursday",
        displayDate: this.updateDays(4)
      },
      {
        date: this.updateDate(6),
        values: [
          {
            value: false,
            name: "Morning (8)"
          },
          {
            value: false,
            name: "Afternoon (8)"
          },
          {
            value: false,
            name: "Night (8)"
          },
          {
            value: false,
            name: "Twelve (12)"
          },
          {
            value: false,
            name: "Double (16)"
          },
          {
            value: false,
            name: "Not Available"
          }
        ],
        dayName: "Friday",
        displayDate: this.updateDays(5)
      },
      {
        date: this.updateDate(7),
        values: [
          {
            value: false,
            name: "Morning (8)"
          },
          {
            value: false,
            name: "Afternoon (8)"
          },
          {
            value: false,
            name: "Night (8)"
          },
          {
            value: false,
            name: "Twelve (12)"
          },
          {
            value: false,
            name: "Double (16)"
          },
          {
            value: false,
            name: "Not Available"
          }
        ],
        dayName: "Saturday",
        displayDate: this.updateDays(6)
      },
      ],
    };
  }
  updateDays(day) {
    const d = new Date(this.todayDate);
    d.setDate(d.getDate() + day);
    return (d.getMonth() + 1) + '/' + d.getDate();
  }
  updateDate(day) {
    const d = new Date(this.todayDate);
    d.setDate(d.getDate() + day);
    return new Date(new Date(d)).toISOString();
  }
  onChange(index, isChecked: boolean, key) {
    let newData = this.newData.days[index];
    newData.values[key].value = isChecked;
    if (key == 0 || key == 1 || key == 2) {
      let checkUncheckCheckArr = this.checkUncheckCheckArr.days[index];
      if (newData.values[0].value || newData.values[1].value || newData.values[2].value) {
        newData.values[4].value = true;
        newData.values[5].value = false;
        checkUncheckCheckArr.values[3].isdisabled = true;
        checkUncheckCheckArr.values[4].isdisabled = true;
      } else {
        newData.values[4].value = false;
        newData.values[5].value = true;
        checkUncheckCheckArr.values[3].isdisabled = false;
        checkUncheckCheckArr.values[4].isdisabled = false;
      }
    }
  }
  monthlyAvailabilityPrevious() {
    const d = new Date(this.todayDate);
    d.setDate(d.getDate() - 7);
    this.todayDate = new Date(new Date(d)).toISOString();
    this.monthlyAvailabilityData();
    this.isCount--;
    this.newData = this.isArray[this.isCount - 1];
    this.getStfEmployeeAvailability();
  }
  opensubmitFinalizeAvailability(content) {
    this.modalService.open(content, { size: 'sm', centered: true });
  }
  submitFinalizeAvailability() {
    this.submitWeekMonthlyAvailabilityForm(this.weekMonthlyAvailabilityForm.value)
  }
  printMonthlyAvailability() {
    let data = { client_id: this.user.id, employee_id: [] }
    this.api.availabilityPdf('admin/scheduled_items/pdf', data).subscribe((result) => {
      this.modalService.dismissAll();
    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }
  logout(): void {
    localStorage.removeItem('reliant_user');
    localStorage.removeItem('reliant_role_based_user');
    localStorage.removeItem('isReliantUserLoggedIn');
    this.router.navigate(['/login']);
  }
  checkUncheckCheckBox() {
    this.checkUncheckCheckArr = {
      days: [{
        values: [
          {
            value: false,
            name: "Morning (8)",
            isdisabled: false,

          },
          {
            value: false,
            name: "Afternoon (8)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Night (8)",
            isdisabled: false,
          },

          {
            value: false,
            name: "Double (16)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Not Available",
            isdisabled: false,
          }
        ],

      },
      {
        values: [
          {
            value: false,
            name: "Morning (8)",
            isdisabled: false,

          },
          {
            value: false,
            name: "Afternoon (8)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Night (8)",
            isdisabled: false,
          },

          {
            value: false,
            name: "Double (16)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Not Available",
            isdisabled: false,
          }
        ],

      },
      {
        values: [
          {
            value: false,
            name: "Morning (8)",
            isdisabled: false,

          },
          {
            value: false,
            name: "Afternoon (8)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Night (8)",
            isdisabled: false,
          },

          {
            value: false,
            name: "Double (16)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Not Available",
            isdisabled: false,
          }
        ],

      },
      {
        values: [
          {
            value: false,
            name: "Morning (8)",
            isdisabled: false,

          },
          {
            value: false,
            name: "Afternoon (8)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Night (8)",
            isdisabled: false,
          },

          {
            value: false,
            name: "Double (16)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Not Available",
            isdisabled: false,
          }
        ],

      },
      {
        values: [
          {
            value: false,
            name: "Morning (8)",
            isdisabled: false,

          },
          {
            value: false,
            name: "Afternoon (8)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Night (8)",
            isdisabled: false,
          },

          {
            value: false,
            name: "Double (16)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Not Available",
            isdisabled: false,
          }
        ],

      },
      {
        values: [
          {
            value: false,
            name: "Morning (8)",
            isdisabled: false,

          },
          {
            value: false,
            name: "Afternoon (8)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Night (8)",
            isdisabled: false,
          },

          {
            value: false,
            name: "Double (16)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Not Available",
            isdisabled: false,
          }
        ],

      },
      {
        values: [
          {
            value: false,
            name: "Morning (8)",
            isdisabled: false,

          },
          {
            value: false,
            name: "Afternoon (8)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Night (8)",
            isdisabled: false,
          },

          {
            value: false,
            name: "Double (16)",
            isdisabled: false,
          },
          {
            value: false,
            name: "Not Available",
            isdisabled: false,
          }
        ],

      },
      ],
    };
  }


  openEmailRequest(content) {
    this.emailRequestForm.reset();
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  submitFmailRequestForm(data) {
    data.employee_id = this.employee_id;
    data.user_id = this.user.id;
    this.api.post('admin/metrics/create', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.modalService.dismissAll();
    }, (err) => {
      this.toastr.error(err.error.message, "Error");
    });
  }

  getStfEmployeeAvailability() {
    let start_datetime = this.updateDate(1);
    let end_datetime = this.updateDate(7);
    let data = { user_id: this.employee_id, end_datetime: end_datetime, start_datetime: start_datetime };
    this.api.post("admin/stf_employee_availability/lists", data).subscribe((result) => {
      this.response = result;

      let data;
      if (this.response.data) {
        data = JSON.parse(this.response?.data?.configuration);
        console.log("Data==>", data);
        this.newData = data;
        this.weekMonthlyAvailabilityForm.patchValue({
          'comments': data.comment,
          'week_number_of_hours': data.numberOfHours,
        });
      }
      //this.toastr.success(this.response.message, "Success");
      this.modalService.dismissAll();
    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }

}
