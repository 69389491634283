<div class="row">
    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Opportunities</h6>
                <div class="btn-group">
                    <button class="action-btn bg-dark-green mr-2"
                        (click)="addOpportunities(opportunitiesFormModal)"><img src="assets/img/svg-icon/add-white.svg"
                            width="20" /></button>
                    <!-- <button class="action-btn bg-dark-blue" data-toggle="modal" data-target="#exampleModalRight"><img
                            src="assets/img/svg-icon/filter-white.svg" width="20" /></button> -->
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content list_table" id="ticketList">
                    <div class="table-responsive list_table">
                        <table class="table  table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th> </th>
                                    <th class="pt-2">Opportunity </th>
                                    <th class="pt-2">Open Date </th>
                                    <th class="pt-2">Estimated Close Date </th>
                                    <th class="pt-2">Days Open </th>
                                    <th class="pt-2">Opportunity Total </th>
                                    <th class="pt-2 text-center">Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="allOpportunities.length > 0">
                                    <tr
                                        *ngFor="let opportunities of allOpportunities | paginate: { id: 'opportunities_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalRecords }; let i=index">
                                        <td> <span *ngIf="opportunities.model_flags && opportunities.model_flags.core_flags"
                                                class="mr-2 action-btn"> <i
                                                    title="{{opportunities.model_flags.core_flags.name}}"
                                                    class='fa {{opportunities.model_flags.core_flags.icon}}'></i></span>
                                        </td>
                                        <td class="text-black"> <a
                                                [routerLink]="['/crm/opportunities-show/'+opportunities.id]">
                                                {{opportunities.title }}</a></td>
                                        <td class="text-gray"> {{opportunities.created_at | date : 'MM/dd/yyyy' }}</td>
                                        <td class="text-gray">{{opportunities.estimated_close_date | date : 'MM/dd/yyyy'
                                            }}</td>
                                        <td class="text-black">{{calculateDiff(opportunities.created_at)}} </td>
                                        <!-- <td class="text-black">{{script.updated_at | date : 'MM/dd/yyyy' }}</td> -->
                                        <td> <span *ngIf="opportunities.opportunity_total">
                                                ${{opportunities.opportunity_total }}</span> <span
                                                *ngIf="!opportunities.opportunity_total"> $0.00 </span></td>
                                        <td class="text-right">
                                            <a href="javascript:void(0)"
                                                (click)="editOpportunitiesDeleteModal(opportunitiesFormModal,i)"
                                                class="mr-2 action-btn bg-edit" title="Update Call Script"><img
                                                    src="assets/img/svg-icon/edit.svg" width="20" /></a>
                                            <a href="javascript:void(0)"
                                                (click)="deleteModal(opportunitiesDeleteModal,opportunities)"
                                                class="action-btn bg-red" title="Delete"><img
                                                    src="assets/img/svg-icon/delete.svg" width="20" /></a>
                                        </td>

                                    </tr>
                                </ng-container>

                                <tr *ngIf="allOpportunities.length==0">
                                    <td colspan="7" class="text-center">Data not found.</td>
                                </tr>

                            </tbody>
                        </table>
                        <pagination-controls class="pagination-controls-theme" id="call_script_pagination"
                            (pageChange)="getOpportunities(page = $event)" maxSize="9" directionLinks="true"
                            autoHide="true" responsive="true">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Add opportunitiesFormModal  -->

<ng-template #opportunitiesFormModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <form [formGroup]="opportunitiesForm" (ngSubmit)="submitOpportunitiesData(opportunitiesForm.value)">
            <div class="form-row">

                <div class="form-group col-md-12">

                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Opportunity Details </h6>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-8">
                                    <label for="">Name <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" formControlName="title">
                                    <span class="text-danger"
                                        *ngIf="opportunitiesForm.get('title').errors?.required || opportunitiesForm.get('title').errors?.minlength && opportunitiesForm.get('title').errors">
                                        {{formErrors.title}}</span>
                                </div>

                                <div class="col-md-4">
                                    <label>Is this for a new or existing customer?</label>
                                    <div class="form-group  d-flex mb-20">
                                        <div class="form-check pl-0 mb-2">
                                            <label class="form-check-label button-radio-btn" (click)="getUserType(1)">
                                                <input type="radio" class="form-check-input radio-btn-button"
                                                    formControlName="new_or_existing" value="New">
                                                <div class="layer"></div>
                                                <div class="button"><span>New</span></div>
                                            </label>
                                        </div>
                                        <div class="form-check pl-0 mb-2">
                                            <label class="form-check-label button-radio-btn" (click)="getUserType(0)">
                                                <input type="radio" class="form-check-input radio-btn-button"
                                                    formControlName="new_or_existing" value="Existing">
                                                <div class="layer"></div>
                                                <div class="button"><span>Existing</span></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="isExisting">
                                <div class="col-md-6">
                                    <label class="col-form-label text-light-black font-16">Client</label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select" formControlName="customer_id"
                                            placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let client of allClients;let i = index"
                                                value="{{client.id}}"> {{client.company_name}}</ng-option>
                                        </ng-select>
                                        <button type="button" (click)="openClientModal(clientFormModal)"
                                            class="btn btn-default"><i class="fa fa-plus"></i> Add
                                            New</button>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <label class="col-form-label text-light-black font-16">Contact</label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select" formControlName="contact_id"
                                            placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let contact of allContacts;let i = index"
                                                value="{{contact.id}}"> {{contact.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" (click)="openContactModal(addContactModal)" class=" btn
                                            btn-default"><i class="fa fa-plus"></i> Add New</button>
                                    </div>
                                </div>

                            </div>

                            <div class="row" *ngIf="isNew">
                                <div class="form-group col-md-6">
                                    <label for="inputState" class="col-form-label text-light-black font-16">First
                                        Name</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="customer_first_name">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Last
                                        Name</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="customer_last_name">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState"
                                        class="col-form-label text-light-black font-16">Phone</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="customer_phone">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState"
                                        class="col-form-label text-light-black font-16">Email</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="customer_email">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">
                                        Company Name</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="customer_company_name">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Address Line
                                        1</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="customer_address_line_1">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">
                                        Address Line 2</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="customer_address_line_2">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">
                                        City</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="customer_address_city">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState"
                                        class="col-form-label text-light-black font-16">State</label>
                                    <ng-select formControlName="customer_address_state">
                                        <ng-option *ngFor="let state of states;" value="state.short">{{state.long}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Zip</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="customer_address_zip">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-4">
                                    <label for="">Total Value</label>
                                    <input type="text" formControlName="opportunity_total" class="form-control">
                                </div>

                                <div class="form-group col-sm-4">
                                    <label for="">Assignee</label>
                                    <ng-select formControlName="user_id">
                                        <ng-option *ngFor="let user of allAssigneeDetails;let i = index"
                                            value="{{user.id}}"> {{user.name}}</ng-option>
                                    </ng-select>
                                </div>

                                <div class="form-group col-sm-4">
                                    <label for="">Estimated Close Date</label>
                                    <input type="date" [(ngModel)]="EstimatedCloseDate" class="form-control"
                                        formControlName="estimated_close_date">
                                </div>

                                <div class="col-sm-12">
                                    <label for="">Details</label>
                                    <textarea formControlName="details" class="form-control" cols="30"
                                        rows="10"></textarea>
                                </div>

                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Flag</label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select" formControlName="flags_selection"
                                            placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let flag of flags;" value="{{flag.id}}"> {{flag.name}}
                                            </ng-option>
                                        </ng-select>
                                        <button type="button" class="btn btn-default"
                                            (click)="openFlagModal(addFlagModal)"><i class="fa fa-plus"></i> Add
                                            New</button>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Status</label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select" formControlName="status_selection"
                                            placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let status of statuses" value="{{status.id}}">
                                                {{status.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" class="btn btn-default"
                                            (click)="openStatusModal(addStatusModal)"><i class="fa fa-plus"></i> Add
                                            New</button>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Type</label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select" formControlName="type_selection"
                                            placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let type of allTypes" value="{{type.id}}">
                                                {{type.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" class="btn btn-default"
                                            (click)="flagManagementType('/flag-management/types')"><i
                                                class="fa fa-cog"></i> Manage</button>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div class="btn-modal-group">
                <button type="submit" [disabled]="!opportunitiesForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
            </div>
        </form>
    </div>

</ng-template>

<!-- Delete  -->
<ng-template #callScriptDeleteModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>

    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" class="btn btn-success  border-r-10">Yes, delete it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>

</ng-template>

<!-- Right Alight Modal -->
<div class="modal fade drawer right-align" id="exampleModalRight" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Right Align Modal title</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</div>

<!-- Flag Modal  -->
<ng-template #addFlagModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Flag Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <app-modal-flag [details]="flagDetails" [flagModelRef]="flagModelRef" [modelName]="modelName"></app-modal-flag>
    </div>

</ng-template>

<!-- Status Modal  -->
<ng-template #addStatusModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Status Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <app-modal-status [details]="statusDetails" [statusModelRef]="statusModelRef" [modelName]="modelName">
        </app-modal-status>
    </div>

</ng-template>



<!-- Contact Modal  -->
<ng-template #addContactModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <form [formGroup]="ContactForm" (ngSubmit)="submitContactData(ContactForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Contact Details</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="first_name" class="col-form-label text-light-black font-16">First Name
                                        <span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="first_name"
                                        type="text">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="last_name" class="col-form-label text-light-black font-16"> Last Name
                                        <span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="last_name"
                                        type="text">
                                </div>
                                <div class="form-group col-md-8">
                                    <label for="email" class="col-form-label text-light-black font-16">Email
                                        <span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="email"
                                        type="text">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="title" class="col-form-label text-light-black font-16">Title </label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="title"
                                        type="text">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="phone" class="col-form-label text-light-black font-16">Phone (Work)
                                    </label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="phone"
                                        type="text">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="phone_mobile" class="col-form-label text-light-black font-16">Phone
                                        (Home)</label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="phone_mobile"
                                        type="text">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="phone_mobile" class="col-form-label text-light-black font-16">Phone
                                        (Mobile)</label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="phone_mobile"
                                        type="text">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-body">
                            <div class="form-row">
                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Flag </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select democlass" Id="Flag"
                                            (change)="selectFlag($event)" formControlName="flag_id" placeholder="Select"
                                            clearAllText="Clear">

                                            <ng-option *ngFor="let flag of flags; let i = index" value="{{flag.id}}">
                                                {{flag.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" *ngIf="!isSelectedFlag" class="btn btn-default"
                                            (click)="openFlagModal(addFlagModal)"><i class="fa fa-plus"></i> Add
                                            New</button>
                                        <button type="button" *ngIf="isSelectedFlag" class="btn btn-default"
                                            (click)="openFlagModal(addFlagModal)"><i class="fa fa-eye"></i>
                                            View/Edit</button>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Status </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select" (change)="selectStatus($event)"
                                            formControlName="status_id" placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let status of statuses;let i = index"
                                                value="{{status.id}}"> {{status.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" *ngIf="!isSelectedStatus" class="btn btn-default"
                                            (click)="openStatusModal(addStatusModal)"><i class="fa fa-plus"></i> Add
                                            New</button>
                                        <button type="button" *ngIf="isSelectedStatus" class="btn btn-default"
                                            (click)="openStatusModal(addStatusModal)"><i class="fa fa-eye"></i>
                                            View/Edit </button>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Type </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select" formControlName="type_id"
                                            placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let type of allTypes;let i = index" value="{{type.id}}">
                                                {{type.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" class="btn btn-default"
                                            (click)="flagManagementType('/flag-management/types')"><i
                                                class="fa fa-cog"></i> Manage </button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-modal-group">
                <button type="submit" [disabled]="!ContactForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
            </div>
        </form>
    </div>

</ng-template>

<!-- add Client Modal  -->

<ng-template #clientFormModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <form [formGroup]="clientRegistrationForm" (ngSubmit)="submitClientData(clientRegistrationForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Client Details</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-3">
                                    <label for="company_name" class="col-form-label text-light-black font-16">First Name
                                        <span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="first_name">
                                    <!-- <span class="text-danger">Hello</span> -->
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="company_name" class="col-form-label text-light-black font-16">Last
                                        Name<span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="last_name">
                                    <!-- <span class="text-danger">Hello</span> -->
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="company_name" class="col-form-label text-light-black font-16">Phone
                                    </label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="phone">
                                    <!-- <span class="text-danger">Hello</span> -->
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="company_name" class="col-form-label text-light-black font-16">Email
                                        <span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="email">
                                    <!-- <span class="text-danger">Hello</span> -->
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label text-light-black font-16">Related Customer(s) </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select [multiple]="true" class="custom-mutiple-select democlass"
                                            formControlName="customer_id" (change)="selectRelatedCustomer($event)"
                                            placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let customer of allRelatedCustomer;"
                                                value="{{customer.id}}"> {{customer.company_name}}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label class="col-form-label text-light-black font-16">Billing Method </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select democlass"
                                            formControlName="billing_method" (change)="selectRelatedCustomer($event)"
                                            placeholder="Select" clearAllText="Clear">
                                            <ng-option value="Email">Email</ng-option>
                                            <ng-option value="Mail">Mail</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Location Details</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="company_name"
                                        class="col-form-label text-light-black font-16">Address</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="address_line_1">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="company_name" class="col-form-label text-light-black font-16">Address
                                        Line 2 </label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="address_line_2">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="company_name"
                                        class="col-form-label text-light-black font-16">City</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="city">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="company_name" class="col-form-label text-light-black font-16">State
                                    </label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="state">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="company_name" class="col-form-label text-light-black font-16">Zip Code
                                    </label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="location_zip">
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Additional Information</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label for="company_name" class="col-form-label text-light-black font-16">Account
                                        Manager</label>
                                    <ng-select class="custom-mutiple-select democlass"
                                        (change)="selectRelatedCustomer($event)" formControlName="account_manager_id"
                                        placeholder="Select" clearAllText="Clear">
                                        <ng-option *ngFor="let client of allClientDetails;" value="{{client.id}}">
                                            {{client.name}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="company_name" class="col-form-label text-light-black font-16">Inside
                                        Sales </label>
                                    <ng-select class="custom-mutiple-select democlass"
                                        (change)="selectRelatedCustomer($event)" placeholder="Select"
                                        clearAllText="Clear" formControlName="sales_person_id">
                                        <ng-option *ngFor="let client of allClientDetails;" value="{{client.id}}">
                                            {{client.name}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="company_name" class="col-form-label text-light-black font-16">Outside
                                        Sales</label>
                                    <ng-select class="custom-mutiple-select democlass"
                                        (change)="selectRelatedCustomer($event)" placeholder="Select"
                                        clearAllText="Clear" formControlName="outside_sales_person_id">
                                        <ng-option *ngFor="let client of allClientDetails;" value="{{client.id}}">
                                            {{client.name}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Flag </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select democlass" Id="Flag"
                                            (change)="selectFlag($event)" formControlName="flag_id" placeholder="Select"
                                            clearAllText="Clear">

                                            <ng-option *ngFor="let flag of flags; let i = index" value="{{flag.id}}">
                                                {{flag.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" *ngIf="!isSelectedFlag" class="btn btn-default"
                                            (click)="openFlagModal(addFlagModal)"><i class="fa fa-plus"></i> Add
                                            New</button>
                                        <button type="button" *ngIf="isSelectedFlag" class="btn btn-default"
                                            (click)="openFlagModal(addFlagModal)"><i class="fa fa-eye"></i>
                                            View/Edit</button>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Status </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select" (change)="selectStatus($event)"
                                            formControlName="status_id" placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let status of statuses;let i = index"
                                                value="{{status.id}}"> {{status.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" *ngIf="!isSelectedStatus" class="btn btn-default"
                                            (click)="openStatusModal(addStatusModal)"><i class="fa fa-plus"></i> Add
                                            New</button>
                                        <button type="button" *ngIf="isSelectedStatus" class="btn btn-default"
                                            (click)="openStatusModal(addStatusModal)"><i class="fa fa-eye"></i>
                                            View/Edit </button>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Type </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select" formControlName="type_id"
                                            placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let type of allTypes;let i = index" value="{{type.id}}">
                                                {{type.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" class="btn btn-default"
                                            (click)="flagManagementType('/flag-management/types')"><i
                                                class="fa fa-cog"></i> Manage </button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-modal-group">
                <button type="submit" [disabled]="!clientRegistrationForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
            </div>
        </form>
    </div>

</ng-template>

<!-- Delete Marketing -->
<ng-template #opportunitiesDeleteModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>

    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteOpportunities()" class="btn btn-success  border-r-10">Yes, delete
                it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>

</ng-template>