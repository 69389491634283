<div class="row">
    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Application Settings</h6>
            </div>
            <div class="card-body">


              <form [formGroup]="settingForm" (ngSubmit)="submitForm(settingForm.value)">
                  <input type="hidden" formControlName="id">
                <div class="row">
                    <div class="col-sm-4">
                        <label>Empty Note Text</label>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <input type="text" class="form-control" formControlName="app_empty_note_text" placeholder="Please enter empty note text">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <label for="">Enable comments within the activity feed?</label>
                        <div class="form-group" >
                            
                            <div class="checkbox d-inline">
                                <div class="new-checkbox toggle-check-box-t mt-15">
                                    <label>No</label>
                                    <label class="switch ml-15 mr-15">
                                        <input type="checkbox" [(ngModel)]="isComment" formControlName="app_enable_activity_feed_comments">
                                        <span class="slider round"></span>
                                    </label>
                                    <label>Yes</label>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <button class="action-btn bg-dark-blue">Submit</button>
                    </div>
                </div>

              </form>

            </div>
        </div>
    </div>
  </div>