<header class="top-header-area d-flex align-items-center justify-content-between ">
    <div class="left-side-content-area d-flex align-items-center">
        <!-- Mobile Logo -->
        <div class="mobile-logo mr-3 mr-sm-4">
            <a href="javascript:void(0)"><img src="assets/img/core-img/small-logo.png" alt="Mobile Logo"></a>
        </div>

        <!-- Triggers -->
        <div class="ecaps-triggers mr-1 mr-sm-3">
            <div class="menu-collasped" id="menuCollasped" (click)="toggleMainMenu($event)">
                <i class='bx bx-menu'></i>
            </div>
            <div class="mobile-menu-open" id="mobileMenuOpen" (click)="mobileToggleMainMenu($event)">
                <i class='bx bx-menu'></i>
            </div>
        </div>

        <!-- Left Side Nav -->
        <ul class="left-side-navbar d-flex align-items-center">
            <li class="hide-phone app-search">
                <input type="text" class="form-control" placeholder="Search...">
                <span class="bx bx-search-alt"></span>
            </li>
        </ul>
    </div>

    <div class="right-side-navbar d-flex align-items-center justify-content-end">
        <!-- Mobile Trigger -->
        <div class="right-side-trigger" id="rightSideTrigger">
            <i class='bx bx-menu-alt-right'></i>
        </div>

        <!-- Top Bar Nav -->
        <ul class="right-side-content d-flex align-items-center">
            <li class="nav-item">
                <span>02:00</span>
            </li>
            <li class="nav-item dropdown">
                <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false"><i class='bx bx-bell bx-tada'></i> <span
                        class="active-status"></span></button>
                <div class="dropdown-menu dropdown-menu-right">
                    <!-- Top Notifications Area -->
                    <div class="top-notifications-area">
                        <!-- Heading -->
                        <div class="notifications-heading">
                            <div class="heading-title">
                                <h6>Notifications</h6>
                            </div>
                            <span>07 New</span>
                        </div>

                        <div class="notifications-box" id="notificationsBox">
                            <a href="#" class="dropdown-item">
                                <i class='bx bx-shopping-bag'></i>
                                <div>
                                    <span>Lorem Ipsum is.</span>
                                    <p class="mb-0 font-12">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.</p>
                                </div>
                            </a>

                            <a href="#" class="dropdown-item">
                                <i class='bx bx-shopping-bag'></i>
                                <div>
                                    <span>Lorem Ipsum is.</span>
                                    <p class="mb-0 font-12">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.</p>
                                </div>
                            </a>

                            <a href="#" class="dropdown-item">
                                <i class='bx bx-shopping-bag'></i>
                                <div>
                                    <span>Lorem Ipsum is.</span>
                                    <p class="mb-0 font-12">Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry.</p>
                                </div>
                            </a>


                        </div>
                    </div>
                </div>
            </li>

        </ul>
    </div>
</header>
<div class="site_page_header" id="site_header">
    <ul class="-primary custom-primary">
        <li routerLinkActive="active">
            <a routerLink="/user/profile/">Profile</a>
        </li>
        <li routerLinkActive="active">
            <a routerLink="/user/direct-deposit/">Direct Deposit</a>
        </li>
        <li routerLinkActive="active">
            <a routerLink="/user/employee-documents">Documents</a>
        </li>
        <li routerLinkActive="active">
            <a routerLink="/usere-training/e-learning-dashboard">TRAINING</a>
        </li>
        <!-- <li routerLinkActive="active">
            <a routerLink="/dashboard/forms">Availability Form</a>
        </li> -->
        <li routerLinkActive="active">
            <a routerLink="/user/schedule">Schedule</a>
        </li>
        <li routerLinkActive="active">
            <a href="javascript:void(0)" (click)="logout()">LOGOUT</a>
        </li>
    </ul>
</div>