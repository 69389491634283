<div class="row">
    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Courses</h6>
                <!-- <ul class="nav nav-tabs mb-0" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link show active" id="active-01-tab" data-toggle="tab" href="#active-01" role="tab" aria-controls="active-01" aria-selected="true">Active</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0" id="inactive-02-tab" data-toggle="tab" href="#inactive-02" role="tab" aria-controls="inactive-02" aria-selected="false">Inactive</a>
                    </li>
  
                </ul> -->
                <div class="btn-group">
                    <a class="action-btn bg-dark-green mr-2" routerLink="/training/course-builder"><img src="assets/img/svg-icon/add-white.svg" width="20" /></a>
                    <!-- <button class="action-btn bg-dark-blue" data-toggle="modal" data-target="#exampleModalRight"><img src="assets/img/svg-icon/filter-white.svg" width="20" /></button> -->
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content list_table" id="courseList">
                    <div class="table-responsive list_table">
                        <table class="table  table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th class="pt-0">Name</th>
                                    <th class="pt-0">Course Type</th>
                                    <th class="pt-0">Status</th>
                                    <th class="pt-0">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="courses.length > 0">
                                    <tr *ngFor="let course of courses | paginate: { id: 'course_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalRecords }; let i=index">
                                        <td class="text-black">{{course.name}}</td>
                                        <td class="text-black">{{course.visibility }}</td>
                                        <td class="text-black">{{course.status }}</td>
                                        <td class="text-right">
                                          <a [routerLink]="['/training/course-builder/'+course.id]" class="mr-2 action-btn bg-edit" title="Update Course"><img src="assets/img/svg-icon/edit.svg" width="20" /></a>
                                          <a href="javascript:void(0)" class="action-btn bg-red" title="Delete" (click)="deleteModal(courseDeleteModal,course)"><img src="assets/img/svg-icon/delete.svg" width="20" /></a>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                        <pagination-controls class="pagination-controls-theme" id="course_pagination" (pageChange)="getCourses(page = $event)" maxSize="9" directionLinks="true"
                        autoHide="true" responsive="true">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  
  <!-- Delete Course -->
  <ng-template #courseDeleteModal let-modal>
  
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>
  
    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteCourse()" class="btn btn-success  border-r-10">Yes, delete it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>
  
  </ng-template>
  

  