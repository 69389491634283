import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-modal-status',
  templateUrl: './modal-status.component.html',
  styleUrls: ['./modal-status.component.css']
})
export class ModalStatusComponent implements OnInit {
  @Input() details: any;
  @Input() statusModelRef: any;
  @Input() modelName: any;
  statusForm: FormGroup;
  response: any = [];
  modelNameTemp: any;
  isDeactivate: boolean = false;
  modelNames: any = [{ label: "Contacts", value: "Contacts" }, { label: "Customers", value: "Customers" }, { label: "Opportunities", value: "Opportunities" }, { label: "follow ups", value: "follow ups" }];

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.modelNameTemp = this.modelName;
    if (this.details) {
      setTimeout(() => {
        this.statusForm.patchValue(this.details);
      }, 100);
    }
    this.statusForm = this.formBuilder.group({
      id: [''],
      model_name: [''],
      name: [''],
      is_default: [''],
      should_deactivate: [''],
      message: ['']
    });

  }

  submitStatusForm(data) {
    let endPoint = (data.id) ? 'update' : 'create';
    this.api.post('admin/core_statuses/' + endPoint, data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.statusModelRef[0].dismiss();
    }, (err) => {
      this.toastr.error(err.error.message, "Error");
    });
  }

}
