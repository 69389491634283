<div class="row">
    <div class="col-xl-12 box-margin" *ngIf="isSaved">
        <div class="alert alert-success alert-dismissible fade show" role="alert">
            <strong>Successfully! </strong> Role has been saved.
        </div>
    </div>
    <div class="col-xl-12 box-margin" *ngIf="isDeleted">
        <div class="alert alert-success alert-dismissible fade show" role="alert">
            <strong>Successfully! </strong> Role has been deleted.
        </div>
    </div>
    <div class="col-xl-12 box-margin" *ngIf="isError">
        <div class="alert alert-danger alert-dismissible fade show" role="alert">
            <strong>Error! </strong>
        </div>
    </div>
    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Roles</h6>
                <div class="btn-group">
                    <button class="action-btn bg-dark-green mr-2" (click)="addRole(roleFormModal)"><img
                            src="assets/img/svg-icon/add-white.svg" width="20" /></button>
                    <!-- <button class="action-btn bg-dark-blue"><img src="assets/img/svg-icon/filter-white.svg"
                            width="20" /></button> -->
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content list_table" id="ticketList">
                    <div class="table-responsive list_table">
                        <table class="table  table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th class="pt-0">Name</th>
                                    <th class="pt-0">Description</th>
                                    <th class="pt-0 text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="roles.length > 0; else empty_content">
                                    <tr *ngFor="let role of roles; let i = index">
                                        <td class="text-black text-capitalize">{{ role.name }}</td>
                                        <td class="text-gray">{{ role.description }}</td>
                                        <td class="text-right">
                                            <a href="javascript:void(0)" (click)="editRole(roleFormModal, i)"
                                                class="mr-2 action-btn bg-orange"><img
                                                    src="assets/img/svg-icon/edit-white.svg" width="20" /></a>
                                            <a href="javascript:void(0)" (click)="deleteRole(role.id)"
                                                class="action-btn bg-red"><img src="assets/img/svg-icon/delete.svg"
                                                    width="20" /></a>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-template #empty_content>
                                    <tr>
                                        <td class="text-black" colspan="3">No Roles Found</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #roleFormModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0">Role Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross click')"
            aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <form [formGroup]="roleForm" (ngSubmit)="submitData(roleForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="inputState" class="col-form-label text-light-black font-16">Name<span class="text-danger">*</span></label>
                    <input class="form-control rounded-0 form-control-md" type="text" formControlName="name">
                    <span class="text-danger"
                        *ngIf="roleForm.get('name').errors?.required && roleForm.get('name').errors">
                        {{formErrors.name}}</span>
                    <input type="hidden" formControlName="id" value="">
                </div>
                


                <div class="form-group col-md-12">
                    <label for="inputState" class="col-form-label text-light-black font-16">Description</label>
                    <textarea class="form-control rounded-0 form-control-md" id="exampleTextarea" rows="4"
                        formControlName="description"></textarea>
                </div>
                <div class="form-group col-md-12">
                    <div class="form-row">
                        <div class="form-group col-md-3" *ngFor="let permission of permissions; let i = index">
                            <div class="form-group">
                                <div class="checkbox d-inline">
                                    <input type="checkbox" name="checkbox-permission" [checked]="permission.isSelected" (change)="onPermissionChange($event)" id="checkbox-{{ permission.id }}" value="{{ permission.id }}">
                                    <label for="checkbox-{{ permission.id }}" class="cr">{{ permission.name }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-modal-group text-center">
                <button type="submit" [disabled]="!roleForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 w-30 md-close">SUBMIT</button>
            </div>
        </form>
    </div>
</ng-template>