import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-training-tests',
  templateUrl: './training-tests.component.html',
  styleUrls: ['./training-tests.component.css']
})
export class TrainingTestsComponent implements OnInit {

  tests:any=[];
  page:any=1;
  totalRecords:any=0;
  delete_id:any;
  constructor(
    private api:ApiService,
    private modalService:NgbModal
  ) {
    this.getTests(this.page);
   }

  ngOnInit(): void {
  }

  async getTests(page){
    this.api.get('admin/tests/lists?page='+page).subscribe((result)=>{
      this.tests = result.data.data;
      this.totalRecords = result.total;
    },(err)=>{
    });
  }

  deleteModal(content,current_row){
    this.delete_id = current_row.id;
    this.modalService.open(content,{ size:'sm',centered:true });
  }

  deleteTest():void{
    
  }

}
