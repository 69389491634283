<div class="row">
    <div class="col-md-6 col-xl-4 height-card box-margin">
        <div class="card border-r-20">
            <div class="card-body">
                <!-- Single Widget -->
                <div class="single-widget-area">
                   

                    <div class="total-profit  d-flex align-items-center justify-content-between">
                        <h6 class="mb-0">Contacts</h6>
                        <div class="counter font-30 font-weight-bold" data-comma-separated="true"> {{details_crm_dashboard.contacts}}</div>
                    </div>
                    <div class="profit-icon border-r-10 ">
                        <span><i class="fa fa-phone"></i></span> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-4 height-card box-margin">
        <div class="card border-r-20">
            <div class="card-body">
                <!-- Single Widget -->
                <div class="single-widget-area">
                   

                    <div class="total-profit  d-flex align-items-center justify-content-between">
                        <h6 class="mb-0">Clients</h6>
                        <div class="counter font-30 font-weight-bold" data-comma-separated="true">{{details_crm_dashboard.clients}}</div>
                    </div>
                    <div class="profit-icon border-r-10 ">
                        <span><i class="fa fa-address-book-o"></i></span> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-4 height-card box-margin">
        <div class="card border-r-20">
            <div class="card-body">
                <!-- Single Widget -->
                <div class="single-widget-area">
                    <div class="total-profit  d-flex align-items-center justify-content-between">
                        <h6 class="mb-0">Opportunities</h6>
                        <div class="counter font-30 font-weight-bold" data-comma-separated="true"> {{details_crm_dashboard.opportunities}}</div>
                    </div>
                    <div class="profit-icon border-r-10">
                        <span><i class="fa fa-paper-plane"></i></span> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <app-modal-crm-follow-ups [details]="followUpsDetails" [statusModelRef]="followUpsModelRef" [modelName]="modelName" ></app-modal-crm-follow-ups> -->
<app-modal-crm-follow-ups [isTrue]="isTrue"></app-modal-crm-follow-ups>
<!-- <div class="row">
    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0"  >{{myPrioritiesname}}</h6>
                <div class="btn-group">
                    <button class="action-btn bg-dark-green mr-2 active " data-toggle="tab"
                    href="javascript:void(0)" role="tab" aria-controls="active-01" aria-selected="true" (click)="checkProperty(1)"><span><i class="fa fa-calendar"></i></span> Today</button> 
                    <button class="action-btn bg-dark-green mr-2" (click)="checkProperty(2)"> Future</button>
                    <button class="action-btn bg-dark-green mr-2" (click)="checkProperty(3)"> Past</button>
                </div>
            </div>
           
        </div>
    </div>
</div> -->