import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-administration-application-settings',
  templateUrl: './administration-application-settings.component.html',
  styleUrls: ['./administration-application-settings.component.css']
})
export class AdministrationApplicationSettingsComponent implements OnInit {

  settingForm:FormGroup;
  response:any=[];
  isTrue:boolean=false;
  isComment:boolean=false;

  constructor(
    private api:ApiService,
    private modalService:NgbModal,
    private toastr:ToastrService,
    private formBuilder:FormBuilder
  ) {

    this.getApplicationSetting();

    this.settingForm = this.formBuilder.group({
      id:[''],
      app_empty_note_text:[''],
      app_enable_activity_feed_comments:['']
    });
   }

  ngOnInit(): void {
  }

  async getApplicationSetting(){
    this.api.get('admin/applicationsetting/list').subscribe((result)=>{
      let data = result.data;
      if(data.app_enable_activity_feed_comments==1){
        this.isComment = true;
      }
      this.settingForm.patchValue({'app_empty_note_text':data.app_empty_note_text,'id':result.data.id});
    },(err)=>{

    });
  }

  submitForm(data):void{
    let endPoint = (data.id)?'update':'create';
    console.log(endPoint);
    this.api.post('admin/applicationsetting/'+endPoint,data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.getApplicationSetting();
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

}
