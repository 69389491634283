<div class="row">
  <div class="col-xl-4 height-card box-margin">
    <div class="card">
      <div class="card-header pt-10 pb-10 bg-green user-area d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0">Status: <span class="text-warning"
            *ngIf="customer.active==1 && customer.marketing==1">Marketing</span>
          <span class="text-green" *ngIf="customer.active==1 && customer.marketing==0">Active</span>
          <span class="text-dark" *ngIf="customer.active==0 && customer.marketing==1">Marketing</span>
          <span class="text-danger" *ngIf="customer.active==0 && customer.marketing==0">Inactive</span>
        </h6>
      </div>
      <div class="card-body">
        <div class="profile-thumb-contact mt-20 text-center">
          <div class="avatar-area">
            <img *ngIf="customer.profile_image==''" class="rounded thumb-2-xl border" (click)="selectImage()"
              id="showImage" src="assets/img/noimg.jpg" alt="">
            <img *ngIf="customer.profile_image!=''" class="rounded thumb-2-xl border" (click)="selectImage()"
              id="showImage" src="{{filePath+customer.profile_image}}" alt="">
          </div>

          <form [formGroup]="profileImageForm">
            <input type="file" hidden id="profile_image" accept="image/*" (change)="uploadImage($event)"
              formControlName="profile_image">
          </form>




          <h5 class="mt-15 font-18 font-weight-600 text-light-black">{{customer.company_name}}</h5>
          <p class="mb-4 text-blue">Since {{customer.created_at | date : 'MM/dd/yyyy'}}</p>
          <div class="profile-btn">
            <a href="javascript:void(0)" class="action-btn bg-orange font-16"
              (click)="customerActivateModal(deactivateModal,0)" *ngIf="customer.active==1">Deactivate</a>
            <a href="javascript:void(0)" class="action-btn bg-orange font-16"
              (click)="customerActivateModal(activateModal,1)" *ngIf="customer.active==0">Activate</a>
          </div>
        </div>
        <div class="personal-information mt-10">
          <div class="name-text">
            <h6 class="font-18 text-center text-light-black mt-2">Marketing</h6>
            <!-- <h6 class="font-18 text-center text-light-black" *ngIf="customer.marketing==0">Client</h6> -->
            <div class="new-checkbox text-center" *ngIf="customer.marketing==1">
              <!-- Rounded switch -->
              <label class="switch">
                <input type="checkbox" checked (click)="changeMarketing(customer,0)">
                <span class="slider round"></span>
              </label>
            </div>

            <div class="new-checkbox text-center" *ngIf="customer.marketing==0" (click)="changeMarketing(customer,1)">
              <!-- Rounded switch -->
              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-8 height-card box-margin">
    <div class="card">
      <div *ngIf="isCustomerDetails"
        class="card-header bg-green user-area d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0"> Client Details</h6>
        <div class="btn-group">
          <!-- <button class="action-btn bg-dark-blue mr-2">Schedule</button> -->
          <button class="action-btn bg-dark-green mr-2" title="Add a note" (click)="openNote(addNote,customer)">Add a
            Note
            <!--<img src="assets/img/svg-icon/add-white.svg" width="20" />-->
          </button>
          <button class="action-btn bg-orange" title="Edit" (click)="editMarketing(customerFormModal)"> Edit
            <!--<img src="assets/img/svg-icon/edit-white.svg" width="20" />-->
          </button>
        </div>
      </div>
      <div *ngIf="isCustomerFiles"
        class="card-header bg-green user-area d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0"> Client Files</h6>
        <div class="btn-group">
          <button class="action-btn bg-orange" title="Add a File"
            (click)="openUploadNewFile(uploadNewFileFormModal)">Add a File</button>
        </div>
      </div>
      <div *ngIf="isCustomerRates"
        class="card-header bg-green user-area d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0"> Rates</h6>
        <div class="btn-group">
          <button class="action-btn bg-orange" (click)="clientUpdateRates()"> Update Rates</button>
        </div>
      </div>
      <!-- <div *ngIf="isCustomerCallLogs"
        class="card-header bg-green user-area d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0"> Call Logs</h6>
        <div class="btn-group">
          <button class="action-btn bg-dark-blue" (click)="addCallLogsReg('callLogsregistrationFormModal')">Add a Call
            Log</button>
        </div>
      </div> -->
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 text-right col_dropdown">
            <div class="dashboard-dropdown">
              <div class="dropdown">
                <button class="bg-transparent border-0 font-weight-600 text-green dropdown-toggle" type="button"
                  id="dashboardDropdown50" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Details
                </button>
                <div class="dropdown-menu text-center dropdown-menu-right" aria-labelledby="dashboardDropdown50"
                  x-placement="bottom-end">
                  <a class="dropdown-item" href="javascript:void(0);" (click)="costomersDetails()">Details </a>
                  <a class="dropdown-item" href="javascript:void(0);" (click)="costomersFiles()">Files </a>
                  <a class="dropdown-item" href="javascript:void(0);" (click)="costomersRates()">Rates </a>
                  <a class="dropdown-item" href="javascript:void(0);" (click)="costomersCallLogs()">Call Logs </a>
                  <a class="dropdown-item" href="javascript:void(0);" (click)="costomersFollowUps()">Follow Ups </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="isCustomerDetails">
          <div class="col-md-6">
            <div class="personal-information mt-10">
              <div class="name-text">
                <h6 class="font-16 text-black">Name: <span class="text-gray">{{customer.company_name}}</span></h6>
                <h6 class="font-16 text-black">Primary Phone: <span class="text-gray">{{customer.phone}}</span></h6>
                <h6 class="font-16 text-black">Attendant Phone:: <span
                    class="text-gray">{{customer.caller_phone}}</span></h6>
                <h6 class="font-16 text-black">Billing Email: <span class="text-gray">{{customer.billing_email}}</span>
                </h6>
                <h6 class="font-16 text-black">Speciality: <span class="text-gray">{{customer.specialty}}</span></h6>
                <h6 class="font-16 text-black">Address: <span class="text-gray">{{customer.address_line_1}}
                    {{customer.city}} {{customer.state}} {{customer.zip}}</span></h6>

              </div>
            </div>
            <div class="map mt-20">
              <iframe [src]="getMap(customer)" class="gmaps_p" height="200"></iframe>
            </div>
            <div class="view_map mt-20">
              <a href="http://maps.google.com/maps?q={{customer.address_line_1 + customer.city + ' '+ customer.state +' ' + customer.zip}}"
                target="_blank" class="text-blue font-weight-600 font-16">Full Map View</a>
            </div>
          </div>

          <div class="col-md-6">
            <div class="personal-information mt-10">
              <div class="name-text">
                <div class="d-flex align-items-center justify-content-between">
                  <div>
                    <!-- <h6 class="font-16 text-black">SSN: <span class="text-gray">663322</span></h6> -->
                    <h6 class="font-16 text-black">Administrator</h6>
                  </div>
                </div>
                <div class="name-text mb-4">
                  <h6 class="font-16 text-black">Name: <span class="text-gray">{{customer.administrator_name}}</span>
                  </h6>
                  <h6 class="font-16 text-black">Office Phone: <span
                      class="text-gray">{{customer.administrator_phone}}</span></h6>
                  <h6 class="font-16 text-black">Cell Phone: <span
                      class="text-gray">{{customer.administrator_alt_phone}}</span></h6>
                  <h6 class="font-16 text-black">Email: <span class="text-gray">{{customer.administrator_email}}</span>
                  </h6>

                </div>

                <div class="name-text mt-4">
                  <h6 class="font-16 text-black">SCHEDULER</h6>
                  <h6 class="font-16 text-black">Name: <span class="text-gray">{{customer.scheduler_name}}</span></h6>
                  <h6 class="font-16 text-black">Office Phone: <span
                      class="text-gray">{{customer.scheduler_phone}}</span></h6>
                  <h6 class="font-16 text-black">Cell Phone: <span
                      class="text-gray">{{customer.scheduler_alt_phone}}</span></h6>
                  <h6 class="font-16 text-black">Email: <span class="text-gray">{{customer.scheduler_email}}</span></h6>

                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6" [innerHTML]="customer.static_html_block"></div>
          <div class="col-sm-6">
            <div class="profile-table mt-4 bg-gray notes_in_profile">
              <table class="table mb-0">
                <tbody>
                  <ng-container *ngIf="notes.length > 0">
                    <tr *ngFor="let note of notes">
                      <td>
                        <h3 class="text-black m-0 font-16">{{note.created_at | date : 'MM/dd/yyyy'}}</h3>
                        <p class="text-gray m-0 font-14">{{note.note}}</p>
                      </td>
                      <td class="text-right">
                        <a href="javascript:void(0)" class="mr-2 action-btn bg-edit profile_page_btn"
                          (click)="editNotes(addNote,note)"><img src="assets/img/svg-icon/edit.svg"
                            width="20"></a><br /><br />
                        <a href="javascript:void(0)" class="action-btn bg-red profile_page_btn"
                          (click)="deleteNotesModal(notesDeleteModal,note)"><img src="assets/img/svg-icon/delete.svg"
                            width="20"></a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="isCustomerFiles && allCustomFiles.length>0">
            <div  *ngFor="let costomer_files of allCustomFiles;let i=index" class="col-md-4 mt-3">
              <div  class="personal-information-2 mt-10">
                <div class="d-flex justify-content-between">
                  <div>
                    <!-- <h6 *ngIf="stf_files.stf_model_custom_files==null" title="Exp: (No date available)">
                      {{stf_files.field_name}} </h6> -->

                    <h6 *ngIf="costomer_files.expiration!=null" class="client_text">
                      {{costomer_files.stf_custom_file.field_name}}
                      <span class="name_text">Exp: {{costomer_files.expiration | date : 'MM/dd/yyyy' }}</span>
                    </h6>

                    <h6 *ngIf="costomer_files.expiration==null" class="client_text">
                      {{costomer_files.stf_custom_file.field_name}}
                      <span class="name_text"> Exp: (No date available)</span>
                    </h6>

                  </div>
                  <div>
                    <a href="javascript:void(0);"
                      (click)="openEmployeeFilesPopUp(deleteEmployeeFiles,costomer_files.id)"><i class="fa fa-times"
                        aria-hidden="true"></i></a>
                  </div>
                </div>

                <div class="d-flex justify-content-center">
                  <button class="action-btn bg-dark-blue mr-2"
                    (click)="openEmployeeFiles(EmployeeFilesModal,costomer_files.path)">Preview
                  </button>
                  <button class="action-btn bg-dark-blue mr-2"
                    (click)="openexpirationDate(expirationDateModal,costomer_files.id,costomer_files.expiration)">Edit</button>
                </div>
              </div>
            </div>
        
        </div>
        <div class="row" *ngIf="allCustomFiles.length==0">
          <p class="ml-2">No files available.</p>
        </div>
        <div class="row" *ngIf="isCustomerRates">
          <div class="col-md-10">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
              <h6 class="card-title mb-0"> Client Bill Rates</h6>
            </div>
            <div class="card-body">
              <div class="tab-content list_table" id="ticketList">
                <div class="tab-pane active show fade" id="active-01" role="tabpanel" aria-labelledby="active-01-tab">
                  <div class="table-responsive list_table">
                    <table class="table  table-wrap mb-0">
                      <tr>
                        <th class="text-center w-30"> </th>
                        <th class="text-center">WEEKDAY</th>
                        <th class="text-center"> WEEKEND</th>
                        <th class="text-center">MORNING</th>
                        <th class="text-center"> EVENING</th>
                        <th class="text-center">NIGHT</th>
                        <th class="text-center"> WEEKEND MORNING</th>
                        <th class="text-center">WEEKEND EVENING</th>
                        <th class="text-center"> WEEKEND NIGHT</th>
                        <th class="text-center"> HOLIDAY</th>
                      </tr>
                      <tbody>
                        <tr *ngFor="let rates of allClientBillRates; let i=index">
                          <td>
                            <p class="empoloy-name"> {{rates.name}} </p>
                          </td>
                          <td *ngFor="let label of clientBillRatesLabel; let j=index">
                            <div class="form-row">
                              <div class="col-12 p-0">
                                <div class="form-group dollar-icon">

                                  <input *ngIf="rates.stf_model_rates.length>0" type="number"
                                    class="form-control bill-rates"
                                    [value]="checkLabel(rates.stf_model_rates,label.value)"
                                    (change)="changeClientBillRates(i,$event,label.value,rates.id)">

                                  <input *ngIf="rates.stf_model_rates.length==0" type="number"
                                    class="form-control bill-rates" [value]=""
                                    (change)="changeClientBillRates(i,$event,label.value,rates.id)">
                                  <i class="fa fa-usd" aria-hidden="true"></i>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-10">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
              <h6 class="card-title mb-0">Employee Pay Rates</h6>
            </div>
            <div class="card-body">
              <div class="tab-content list_table" id="ticketList">
                <div class="tab-pane active show fade" id="active-01" role="tabpanel" aria-labelledby="active-01-tab">
                  <div class="table-responsive list_table">
                    <table class="table  table-wrap mb-0">

                      <tr>
                        <th class="w-30"> </th>
                        <th class="">WEEKDAY</th>
                        <th class=""> WEEKEND</th>
                      </tr>

                      <tbody>
                        <tr *ngFor="let emp_rates of allEmployePayRates; let i=index">
                          <td>
                            <p class="empoloy-name"> {{emp_rates.name}} </p>
                          </td>
                          <td>
                            <div class="form-row">
                              <div class="col-12 p-0">
                                <div class="form-group dollar-icon client_bill_bates">
                                  <input type="number" min="0"
                                    (change)="changeEmployeePayRates(i,$event,'WEEKDAY',emp_rates.id)"
                                    class="form-control bill-rates"
                                    [value]="checkLabel(emp_rates.stf_model_rates,'WEEKDAY')">
                                  <i class="fa fa-usd" aria-hidden="true"></i>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="form-row">
                              <div class="col-12 p-0">
                                <div class="form-group dollar-icon client_bill_bates">
                                  <input type="number"
                                    (change)="changeEmployeePayRates(i,$event,'WEEKEND',emp_rates.id)"
                                    class="form-control bill-rates"
                                    [value]="checkLabel(emp_rates.stf_model_rates,'WEEKEND')">
                                  <i class="fa fa-usd" aria-hidden="true"></i>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row col_logs_row" *ngIf="isCustomerCallLogs">
          <div class="col-lg-11 col-xl-11 col-md-11 col-sm-12 mt-3 ">
            <app-modal-call-logs [modelName]="modelName"></app-modal-call-logs>
          </div>
        </div>
        <div class="row col_logs_row" *ngIf="isCustomerFollowUps">
          <div class="col-lg-11 col-xl-11 col-md-11 col-sm-12 mt-3">
            <app-modal-crm-follow-ups [modelDetails]="modelDetails" [modelName]="modelName" [customerid]="customerid">
            </app-modal-crm-follow-ups>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>


<!-- Add Note -->
<ng-template #addNote let-modal>
  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
    <h6 class="card-title mb-0"> Note</h6>
    <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
      (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body md-b-body">
    <form [formGroup]="noteForm" (ngSubmit)="submitNote(noteForm.value)">
      <div class="form-row">
        <div class="form-group col-md-12">
          <input type="hidden" formControlName="id" value="">
          <label class="col-form-label text-light-black font-16">Note <span class="text-danger">*</span></label>
          <textarea required="" class="form-control rounded-0 form-control-md" id="exampleTextarea" rows="4"
            formControlName="note"></textarea>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-12">
          <button type="submit" [disabled]="!noteForm.valid" class="action-btn bg-dark-green mr-2">Submit</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<!-- Delete Notes -->
<ng-template #notesDeleteModal let-modal>

  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
    <h6 class="card-title mb-0 text-center">Delete</h6>
  </div>

  <div class="modal-body md-b-body">
    <p>Are you sure you want to delete?</p>
    <div class="btn-modal-group d-flex justify-content-between">
      <button type="button" (click)="deleteNotes()" class="btn btn-success  border-r-10">Yes, delete it</button>
      <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
    </div>
  </div>

</ng-template>

<!-- activateModal  -->
<ng-template #activateModal let-modal>

  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
    <h6 class="card-title mb-0 text-center">Activate Client</h6>
  </div>

  <div class="modal-body md-b-body">
    <p>Are you sure you want to activate this profile?</p>
    <div class="btn-modal-group d-flex justify-content-between">
      <button type="button" (click)="customerActivate()" class="btn btn-success  border-r-10">Yes</button>
      <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
    </div>
  </div>

</ng-template>

<!-- activateModal  -->
<ng-template #deactivateModal let-modal>

  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
    <h6 class="card-title mb-0 text-center">Deactivate Client</h6>
  </div>

  <div class="modal-body md-b-body">
    <p>Are you sure you want to deactivate this profile?</p>
    <div class="btn-modal-group d-flex justify-content-between">
      <button type="button" (click)="customerActivate()" class="btn btn-success  border-r-10">Yes</button>
      <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
    </div>
  </div>

</ng-template>

<!-- Add Marketing  -->

<ng-template #customerFormModal let-modal>

  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
    <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
      (click)="modal.dismiss('Cross  click')" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="modal-body md-b-body">
    <form [formGroup]="clientForm" (ngSubmit)="submitClientData(clientForm.value)">
      <div class="form-row">

        <div class="form-group col-md-12">

          <div class="card card-shadow-hide">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
              <h6 class="card-title mb-0">Client Details</h6>
            </div>
            <div class="card-body">

              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="company_name" class="col-form-label text-light-black font-16">Name<span
                      class="text-danger">*</span></label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="company_name">
                  <span class="text-danger"
                    *ngIf="clientForm.get('company_name').errors?.required || clientForm.get('company_name').errors?.minlength && clientForm.get('company_name').errors">
                    {{formErrors.company_name}}</span>
                  <input type="hidden" formControlName="id" value="">
                </div>


                <div class="form-group col-md-4">
                  <label for="inputState" class="col-form-label text-light-black font-16">Primary Phone</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="phone">

                </div>

                <div class="form-group col-md-4">
                  <label for="inputState" class="col-form-label text-light-black font-16">Primary Phone</label>
                  <input class="form-control rounded-0 form-control-md" type="email" formControlName="phone">

                </div>

                <div class="form-group col-md-4">
                  <label for="inputState" class="col-form-label text-light-black font-16">Billing Email</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="billing_email">
                </div>

                <div class="form-group col-md-4">
                  <label for="inputState" class="col-form-label text-light-black font-16">Specialty</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="specialty">
                </div>

                <div class="form-group col-md-4">
                  <div class="checkbox d-inline">
                    <label for="checkbox-recurit" class="col-form-label text-light-black font-16">Marketing</label>
                    <div class="new-checkbox toggle-check-box-t mt-15">
                      <label>No</label>

                      <!-- Rounded switch -->
                      <label class="switch ml-15 mr-15">
                        <input type="checkbox" formControlName="marketing" [checked]="isTrue" id="checkbox-marketing">
                        <span class="slider round"></span>
                      </label>
                      <label>Yes</label>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

        <div class="form-group col-md-12">
          <div class="card card-shadow-hide">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
              <h6 class="card-title mb-0">Location Details</h6>
            </div>
            <div class="card-body">

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="address_line_1" class="col-form-label text-light-black font-16">Address Line 1</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="address_line_1">
                </div>


                <div class="form-group col-md-6">
                  <label for="inputState" class="col-form-label text-light-black font-16">Address Line 2</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="address_line_2">

                </div>

                <div class="form-group col-md-4">
                  <label for="inputState" class="col-form-label text-light-black font-16">City</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="city">

                </div>

                <div class="form-group col-md-4">
                  <label for="inputState" class="col-form-label text-light-black font-16">State</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="state">
                </div>

                <div class="form-group col-md-4">
                  <label for="inputState" class="col-form-label text-light-black font-16">Zip</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="zip">
                </div>

              </div>

            </div>
          </div>
        </div>


        <div class="form-group col-md-6">

          <div class="card card-shadow-hide">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
              <h6 class="card-title mb-0">Administrator Information</h6>
            </div>
            <div class="card-body">

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="administrator_name" class="col-form-label text-light-black font-16">Name</label>
                  <input class="form-control rounded-0 form-control-md" type="text"
                    formControlName="administrator_name">
                </div>


                <div class="form-group col-md-6">
                  <label for="administrator_phone" class="col-form-label text-light-black font-16">Office Phone</label>
                  <input class="form-control rounded-0 form-control-md" type="text"
                    formControlName="administrator_phone">

                </div>

                <div class="form-group col-md-6">
                  <label for="administrator_alt_phone" class="col-form-label text-light-black font-16">Cell
                    Phone</label>
                  <input class="form-control rounded-0 form-control-md" type="text"
                    formControlName="administrator_alt_phone">

                </div>

                <div class="form-group col-md-6">
                  <label for="administrator_email" class="col-form-label text-light-black font-16">Email</label>
                  <input class="form-control rounded-0 form-control-md" type="text"
                    formControlName="administrator_email">
                </div>

              </div>

            </div>
          </div>

        </div>


        <div class="form-group col-md-6">

          <div class="card card-shadow-hide">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
              <h6 class="card-title mb-0">Scheduler Information</h6>
            </div>
            <div class="card-body">

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="scheduler_name" class="col-form-label text-light-black font-16">Name</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="scheduler_name">
                </div>


                <div class="form-group col-md-6">
                  <label for="scheduler_phone" class="col-form-label text-light-black font-16">Office Phone</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="scheduler_phone">

                </div>

                <div class="form-group col-md-6">
                  <label for="scheduler_alt_phone" class="col-form-label text-light-black font-16">Cell Phone</label>
                  <input class="form-control rounded-0 form-control-md" type="text"
                    formControlName="scheduler_alt_phone">

                </div>

                <div class="form-group col-md-6">
                  <label for="scheduler_email" class="col-form-label text-light-black font-16">Email</label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="scheduler_email">
                </div>

              </div>

            </div>
          </div>

        </div>

        <div class="form-group col-md-12">
          <div class="card card-shadow-hide">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
              <h6 class="card-title mb-0">Additional Information</h6>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="text-help">Use this section to publish any additional details about the employee.
                </div>
                <div class="form-group col-md-12">
                  <label for="inputState" class="col-form-label text-light-black font-14">Additional
                    Information</label>
                  <editor formControlName="static_html_block" [init]="{
                              base_url: '/tinymce',
                              suffix: '.min',
                              height: 500,
                              menubar: false,
                              plugins: [
                                  'advlist autolink lists link image charmap print preview anchor',
                                  'searchreplace visualblocks code fullscreen',
                                  'insertdatetime media table paste code help wordcount'
                              ],
                              toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                          }"></editor>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="btn-modal-group">
        <button type="submit" [disabled]="!clientForm.valid" id="submit-btn"
          class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <!-- <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button> -->
  </div>

</ng-template>

<!-- Employee Upload New File Upload-->
<ng-template #uploadNewFileFormModal let-modal>
  <div class="justify-content-right">
    <button type="button" class="close action-btn modal-dismiss mr-10 p-1" (click)="modal.dismiss('Cross click')"
      aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body md-b-body">
    <app-modal-upload-files [modelName]="modelName" [customerid]="customerid" [allStfCustomFiles]="allStfCustomFiles">
    </app-modal-upload-files>
  </div>
</ng-template>

<!-- Expiration Date -->
<ng-template #expirationDateModal let-modal>
  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
    <!-- <h6 class="card-title mb-0"> Expiration Date</h6> -->
    <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
      (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body md-b-body">
    <div class="form-row">
      <div class="form-group col-md-12">
        <label class="col-form-label text-light-black font-16">Expiration Date </label>
        <input type="date" class="form-control" name="" [(ngModel)]="expiration" id=""
          (change)="updateExpirationDate()">
      </div>
    </div>
  </div>
</ng-template>

<!-- Employee Files Modal -->
<ng-template #EmployeeFilesModal let-modal>
  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
    <!-- <h6 class="card-title mb-0">Email Form</h6> -->
    <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
      (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body md-b-body">
    <div class="row">
      <div class="col-md-12 box-margin">
        <div class="card">
          <div class="card-body">
            <iframe id="iframeid" [src]="getPdfPath(employeeFilesPath)" width="100%" height="500px"></iframe>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="box-footer">
          <div class="pull-right">
            <a target="_blank" [href]="getPdfPath(employeeFilesPath)" class="link-font--larger"><i
                class="fa fa-share mr-1"></i>View File
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Delete Employee Files -->
<ng-template #deleteEmployeeFiles let-modal>
  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
    <h6 class="card-title mb-0 text-center"> Confirm</h6>
  </div>
  <div class="modal-body md-b-body">
    <p>Are you sure you would like to delete this file?</p>
    <div class="btn-modal-group d-flex justify-content-between">
      <button type="button" (click)="deleteEmployeeFilesData()" class="btn btn-success  border-r-10">Yes, delete
        it</button>
      <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
    </div>
  </div>
</ng-template>