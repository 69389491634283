import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-administration-calls',
  templateUrl: './administration-calls.component.html',
  styleUrls: ['./administration-calls.component.css']
})
export class AdministrationCallsComponent implements OnInit {
  callsForm:FormGroup;
  page: any = 1;
  totalRecords: any;
  response:any;
  calls:any=[];
  callaData:any;
  modalReference:any='';
  constructor(
    private api: ApiService, 
    private modalService: NgbModal, 
    private formBuilder: FormBuilder,
    private toast:ToastrService,
    public toastr:ToastrService,
    ) { }

  ngOnInit(): void {
    this.init();
    this.getCalls(this.page)
  }
  init() :void{
    this.callsForm = this.formBuilder.group({
      name:[''],
    });
  }
  async getCalls(page) {
    this.page = page;
    this.api.get('admin/calls/lists?page='+page).subscribe((result) => {
        this.totalRecords = result.data.total;
        this.calls = result.data.data;
    }, (err) => {

    });
  }
  addCalls(Content){
   // this.callsForm.reset();
    this.modalService.open(Content, { size: 'xl', centered: true});
  }
  submitCallsData(data){
    console.log(data);
  }
  deleteCallsData(){
    let data = {id : this.callaData.id};
    this.modalReference.close();
    this.api.post('admin/calls/delete', data).subscribe((result) => {
      this.response=result;
      this.toastr.success(this.response.message,"Success");
      this.getCalls(this.page);
   }, (err) => {
      console.log(err);
      this.toastr.error(err.error.message,'Error');
   });
  }
  openDeletePopUp(deleteMarketing,current_row){
    this.callaData = current_row;
    this.modalReference = this.modalService.open(deleteMarketing, { size: 'sm', centered: true });
  }
}
