import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap, map, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';


const apiURL = environment.apiURL + '/';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  token: any;
  constructor(private http: HttpClient) {
    
  }

  get(endPoint: string): Observable<any> {
    var user = JSON.parse(localStorage.getItem('reliant_user'));
    if (user) {
      this.token = user.token;
    }
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(apiURL + endPoint, { headers: reqHeader });
  }

  post(endPoint: string, data: any) {
    var user = JSON.parse(localStorage.getItem('reliant_user'));
    var reliant_role_based_user = JSON.parse(localStorage.getItem('reliant_role_based_user'));
    if (user) {
      this.token = user.token;
    }
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.post(apiURL + endPoint, data, { headers: reqHeader }).pipe(
      map(data => {
        return data;
      })
    )
  }
  userget(endPoint: string): Observable<any> {
    var reliant_role_based_user = JSON.parse(localStorage.getItem('reliant_role_based_user'));
    if (reliant_role_based_user) {
      this.token = reliant_role_based_user.token;
    }
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });

    return this.http.get(apiURL + endPoint, { headers: reqHeader });
  }
  userpost(endPoint: string, data: any) {
    var reliant_role_based_user = JSON.parse(localStorage.getItem('reliant_role_based_user'));
    if (reliant_role_based_user) {
      this.token = reliant_role_based_user.token;
    }
    var reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.post(apiURL + endPoint, data, { headers: reqHeader }).pipe(
      map(data => {
        return data;
      })
    )
  }

  uploadFile(endPoint: string, data: any) {
    var user = JSON.parse(localStorage.getItem('reliant_user'));
    var reliant_role_based_user = JSON.parse(localStorage.getItem('reliant_role_based_user'));
    if (user || reliant_role_based_user) {
      this.token = user.token;
    }
    
    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.post(apiURL + endPoint, data, { headers: reqHeader }).pipe(
      map(data => {
        return data;
      })
    )
  }
  availabilityPdf(endPoint: string, data: any) {
    var user = JSON.parse(localStorage.getItem('reliant_user'));
    if (user) {
      this.token = user.token;
    }
    
    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.post(apiURL + endPoint, data, { headers: reqHeader }).pipe(
      map(data => {
        return data;
      })
    )
  }

  updateExpirationDate(endPoint: string, data: any) {
    var user = JSON.parse(localStorage.getItem('reliant_user'));
    if (user) {
      this.token = user.token;
    }
    
    var reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.post(apiURL + endPoint, data, { headers: reqHeader }).pipe(
      map(data => {
        return data;
      })
    )
  }

}
