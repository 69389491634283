import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../services/api.service';
import { HelperService } from '../helper/helper.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-crm-opportunities',
  templateUrl: './crm-opportunities.component.html',
  styleUrls: ['./crm-opportunities.component.css']
})
export class CrmOpportunitiesComponent implements OnInit {

  opportunities:any=[];
  opportunitiesForm:FormGroup;
  clientRegistrationForm:FormGroup;
  ContactForm:FormGroup;
  clientForm:FormGroup;
  page:any=0;
  totalRecords:any=0;
  isNew:boolean=false;
  isExisting:boolean=false;
  isSelectedStatus:boolean=false;
  isSelectedFlag:boolean=false;
  details:any=[];
  flags:any=[];
  response:any=[];
  statuses:any=[];
  modelName:any;
  flagDetails:any=[];
  flagModelRef:any=[];
  statusDetails:any=[];
  statusModelRef:any=[];
  states:any=[];
  allAssigneeDetails:any=[];
  allContacts:any=[];
  allClients:any=[];
  allTypes:any[];
  editFlag:any[];
  editStatus:any=[];
  user:any=[];
  allRelatedCustomer:any=[];
  allClientDetails:any=[];
  allOpportunities:any=[];
  opportunities_id:any;
  editOpportunities:any=[];
  EstimatedCloseDate:any=[];
  clientModelRef:any=[];

  formErrors = {
    'id':'',
    'title':'',
    'new_or_existing':'',
    'customer_id':'',
    'contact_id':'',
    'customer_first_name':'',
    'customer_last_name':'',
    'customer_phone':'',
    'customer_email':'',
    'customer_company_name':'',
    'customer_address_line_1':'',
    'customer_address_line_2':'',
    'customer_address_city':'',
    'customer_address_state':'',
    'customer_address_zip':'',
    'opportunity_total':'',
    'user_id':'',
    'flags_selection':'',
    'status_selection':'',
    'type_selection':'',
    'estimated_close_date':'',
    'details':'',

  };

  validationMessages = {
    'title': {
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    }
  };
  

  constructor(
    private api:ApiService,
    private formBuilder:FormBuilder,
    private modalService:NgbModal,
    private helperService:HelperService,
    private router:Router,
    private helper:HelperService,
    private toastr: ToastrService,
  ) { 
  }

  ngOnInit(): void {
    
    this.init();
    this.states = this.helper.getState();
    this.getOpportunities(this.page);
  }

  init() :void{
    
    //this.states = this.helperService.getState();
    this.opportunitiesForm = this.formBuilder.group({
      id: [''],
      title: ['', [Validators.required, Validators.minLength(3)]],
      new_or_existing:[''],
      customer_id:[''],
      contact_id:[''],
      customer_first_name:[''],
      customer_last_name:[''],
      customer_phone:[''],
      customer_email:[''],
      customer_company_name:[''],
      customer_address_line_1:[''],
      customer_address_line_2:[''],
      customer_address_city:[''],
      customer_address_state:[''],
      customer_address_zip:[''],
      opportunity_total:[''],
      user_id:[''],
      flags_selection:[''],
      status_selection:[''],
      type_selection:[''],
      estimated_close_date:[''],
      details:['']
      
    });
    this.ContactForm = this.formBuilder.group({
      first_name: ['',[Validators.required]],
      last_name: ['',[Validators.required]],
      email: ['',[Validators.required]],
      contact_id: [''],
      notes: [''],
      title: [''],
      phone: [''],
      phone_mobile: [''],
      phone_home: [''],
      flag_id: [''],
      status_id: [''],
      type_id: [''],
    });
    this.opportunitiesForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.opportunitiesForm);
    });
    this.clientForm = this.formBuilder.group({
      client: [''],
    });
    this.user = JSON.parse(localStorage.getItem('reliant_user'));
    this.clientRegistrationForm = this.formBuilder.group({
      first_name: [''],
      last_name: [''],
      phone: [''],
      email: [''],
      customer_id: [''],
      billing_method: [''],
      address_line_1: [''],
      address_line_2: [''],
      city: [''],
      state: [''],
      location_zip: [''],
      account_manager_id: [''],
      sales_person_id: [''],
      outside_sales_person_id: [''],
      flag_id: [''],
      status_id: [''],
      type_id: [''],
    });
    this.clientForm = this.formBuilder.group({
      id: [''],
      company_name: ['', [Validators.required, Validators.minLength(3)]],
      phone:[''],
      caller_phone:[''],
      billing_email:[''],
      specialty:[''],
      marketing:[''],
      address_line_1:[''],
      address_line_2:[''],
      city:[''],
      state:[''],
      zip:[''],
      administrator_name:[''],
      administrator_phone:[''],
      administrator_alt_phone:[''],
      administrator_email:[''],
      scheduler_name:[''],
      scheduler_phone:[''],
      scheduler_alt_phone:[''],
      scheduler_email:[''],
      static_html_block:['']
    });
  }
  
  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }
  async getAssigneeDetails() {
    this.api.get('admin/course_registration/get_user').subscribe((result) => {
      this.response = result;
      this.allAssigneeDetails = this.response.data;

    }, (err) => {

    });
  }
  async getClientDetails() {
    this.api.get('admin/course_registration/get_user').subscribe((result) => {
      this.response = result;
      this.allClientDetails = this.response.data;

    }, (err) => {

    });
  }
  async getRelatedCustomer() {
    this.api.get('admin/follow_ups/get_client').subscribe((result) => {
      this.response = result;
      this.allRelatedCustomer = this.response.data;
    }, (err) => {

    });
  }
  async getContactsDetails() {
    this.api.get('admin/contacts/all_contacts').subscribe((result) => {
      this.response = result;
      this.allContacts = this.response.data;

    }, (err) => {

    });
  }

  getOpportunities(page){
    this.api.get('admin/opportunities/lists').subscribe((result) => {
      this.response = result;
      this.allOpportunities = this.response.data.data;

    }, (err) => {

    });
  }

  getUserType(type){
    this.isNew=false;
    this.isExisting=false;
    if(type==1){
      this.isNew=true;
    }else{
      this.isExisting=true;
    }
  }
  getClients(){
    let data = {};
    this.api.post('admin/customer/get_customers',data).subscribe((result)=>{
      this.response = result;
      this.allClients = this.response.data;
    },(err)=>{

    });
  }
  getType(){
    let data = {model_name :this.modelName};
    this.api.post('admin/core_types/lists',data).subscribe((result)=>{
      this.response = result;
      this.allTypes = this.response.data;
    },(err)=>{
    });  
    return true;
  }
  openContactModal(content){
    this.ContactForm.reset();
    this.modelName="contacts";
    this.getFlag();
    this.getStatus();
    this.getType();
    this.modalService.open(content, { size: 'xl', centered: true });
  }
  openClientModal(content){
    this.clientRegistrationForm.reset();
    this.getFlag();
    this.getStatus();
    this.getType();
    this.getRelatedCustomer();
    this.getClientDetails();
    const modalRef=this.modalService.open(content, { size: 'xl', centered: true });
    this.clientModelRef.push(modalRef);
    this.closeModal(modalRef);
  }
  addOpportunities(content){
    this.EstimatedCloseDate='';
    this.isNew=false;
    this.isExisting=false;
    this.opportunitiesForm.reset();
    this.getFlag();
    this.getStatus();
    this.getType();
    this.getAssigneeDetails();
    this.getContactsDetails();
    this.getClients();
    
    this.modalService.open(content,{ size:'xl',centered:true });
  }

  getFlag(){
    let data = {model_name :'Opportunities'};
    this.api.post('admin/core_flags/all_flags',data).subscribe((result)=>{
      this.response = result;
      this.flags = this.response.data;
    },(err)=>{
    });  
    return true;
  }

  getStatus(){
    let data = {model_name :'Opportunities'};
    this.api.post('admin/core_statuses/all_statuses',data).subscribe((result)=>{
      this.response = result;
      this.statuses = this.response.data;
    },(err)=>{
    });  
    return true;
  }

  openFlagModal(content){
    this.flagModelRef=[];
    this.modelName='Opportunities';
    const modalRef=this.modalService.open(content,{ size:'xl',centered:true });
    this.flagModelRef.push(modalRef);
    this.closeModal(modalRef);
  }

  openStatusModal(content){
    this.statusModelRef=[];
    this.modelName='Opportunities';
    const modalRef=this.modalService.open(content,{ size:'xl',centered:true });
    this.statusModelRef.push(modalRef);
    this.closeModal(modalRef);
  }

  closeModal(modalRef){
    modalRef.result.then((data) => {
      
    }, (reason) => {
      if(reason!=0 && reason!='Cross  click'){
        this.getFlag();
        this.getStatus();
      }
      
    });
  }

  flagManagementType(link){
    this.modalService.dismissAll();
    this.router.navigateByUrl(link);
  }

 
  
  submitOpportunitiesData(data){
    let endPoint = (data.id)?'update':'create';
    data.model_name="Opportunities";
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
      this.api.post('admin/opportunities/'+endPoint,data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.modalService.dismissAll();
      //this.closeModal(modalRef);
      this.getOpportunities(this.page);
    }, (err) => {
      this.toastr.error(err.error.message,"Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });
  }
  submitContactData(data){
    data.model_name="Opportunities";
    let endPoint = (data.id)?'update':'create';
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
      this.api.post('admin/contacts/'+endPoint,data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      //this.modalService.dismissAll();
      //const modalRef=this.modalService.open(content,{ size:'xl',centered:true });
      //this.closeModal(modalRef);
    }, (err) => {
      this.toastr.error(err.error.message,"Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });
  }
  selectRelatedCustomer(event){

  }
  selectFlag(event){
    (event!=null)?this.isSelectedFlag=true:this.isSelectedFlag=false;
    this.editFlag = this.flags.filter(function(arr){return arr.id == event})[0];
  }
  selectStatus(event){
    (event!=null)?this.isSelectedStatus=true:this.isSelectedStatus=false;
    this.editStatus =this.statuses.filter(function(arr){return arr.id == event})[0];// this.statuses[event];
  }
  submitClientData(data){
    data.model_name="customers";
    let endPoint = (data.id)?'update':'create';
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
      this.api.post('admin/customer/'+endPoint,data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.modalService.dismissAll();
      //const modalRef=this.modalService.open(content,{ size:'xl',centered:true });
      //this.closeModal(modalRef);
    }, (err) => {
      this.toastr.error(err.error.message,"Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });
    // const modalRef=this.clientModelRef;
    // this.closeModal(modalRef);
    // console.log(this.clientModelRef)
    // this.clientModelRef[0].result.dismiss();

  }
  deleteModal(content, current_row) {
    this.opportunities_id = current_row.id;
    this.modalService.open(content, { size: 'sm', centered: true });
    
  }
  deleteOpportunities(){
    let data = { id: this.opportunities_id }
    this.api.post('admin/opportunities/delete', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getOpportunities(this.page);
      this.modalService.dismissAll();
    }, (err) => {
      console.log(err);
      this.toastr.error(err.error.message, 'Error');
      this.modalService.dismissAll();
    });
  }
  editOpportunitiesDeleteModal(content,index){
    this.opportunitiesForm.reset();
    this.getFlag();
    this.getStatus();
    this.getAssigneeDetails();
    this.getContactsDetails();
    this.getClients();
    this.editOpportunities = this.allOpportunities[index];
    (this.editOpportunities.new_or_existing=="New")?this.isNew=true:this.isNew=false;
    (this.editOpportunities.new_or_existing=="Existing")?this.isExisting=true:this.isExisting=false;
    this.EstimatedCloseDate =(this.editOpportunities.estimated_close_date)?this.editOpportunities.estimated_close_date.slice(0,10):'';
    this.opportunitiesForm.patchValue(this.editOpportunities);
    this.modalService.open(content, { size: 'xl', centered: true, scrollable: true });

  }
  calculateDiff(sentOn){

    let todayDate = new Date();
    let sentOnDate = new Date(sentOn);
    sentOnDate.setDate(sentOnDate.getDate());
    let differenceInTime = todayDate.getTime() - sentOnDate.getTime();
    // To calculate the no. of days between two dates
    let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24)); 
    return differenceInDays;
}
}
