import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';
import { environment } from '../../environments/environment';
import * as htmlToImage from 'html-to-image';

const filePath = environment.filePath;
const siteURL = environment.siteURL;

@Component({
  selector: 'app-employee-badge',
  templateUrl: './employee-badge.component.html',
  styleUrls: ['./employee-badge.component.css']
})
export class EmployeeBadgeComponent implements OnInit {

  user:any = [];
  employee_id:any;
  employee:any = [];
  filePath:any = filePath;
  siteURL:any = siteURL;
  topColor:any = '#0d5491';
  bottomColor:any = '#25bc0b';
  first_name:any = 25;
  last_name:any = 14;
  speciality:any = 25;
  profile_image:any = 97.25;
  badge_card_height:any = 289.225;
  badge_card_width:any = 208;
  badge_heading:any = 12;
  badge_subheading:any = 9;
  qrcode_width:any = 100;
  back_badge_heading:any = 15;
  back_badge_subheading:any = 12;
  badge_logo:any = 70;

  Zoom(val){
    let value = val.target.value;
    this.first_name = 25*value;
    this.last_name = 14*value;
    this.speciality = 25*value;
    this.profile_image = 97.25*value;
    this.badge_card_height = 184*value;
    this.badge_card_width = 189.225*value;
    this.badge_heading = 12*value;
    this.badge_subheading = 9*value;
    this.qrcode_width = 100*value ;
    this.back_badge_heading = 15*value;
    this.back_badge_subheading = 12*value;
    this.badge_logo = 70*value;
  }

  constructor(
    private route:ActivatedRoute,
    private api:ApiService,
    private modalService:NgbModal,
    private toastr:ToastrService,
    private formBuilder:FormBuilder,
    private sanitizer: DomSanitizer

  ){

      this.user =  JSON.parse(localStorage.getItem('reliant_user'));
      this.employee_id = this.route.snapshot.params['id'];
      this.getEmployeeData(this.employee_id);
   }

  ngOnInit(): void {

  }

  async getEmployeeData(employee_id){
    this.api.get("admin/employee/employee-profile?employee_id="+employee_id).subscribe((result)=>{
      this.employee = result.data;
    },(err)=>{

    });
  }

  getTopColor(value){
    this.topColor=value.target.value; 
  }

  getBottomColor(value){
    this.bottomColor=value.target.value;
  }

  firstNameSize(value){
    console.log(value.target.value);
    this.first_name = value.target.value;
  }

  lastNameSize(value){
    this.last_name = value.target.value;
  }

  specialitySize(value){
    this.speciality = value.target.value;
  }

  getImageSize(value){
    this.profile_image = value.target.value;
  }

  saveImage(id){
    htmlToImage.toPng(document.getElementById(id), { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = id+'.png';
        link.href = dataUrl;
        link.click();
      });
  }

  Print(id){
    var printContents = document.getElementById(id).innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  getNumber(no_of_digit,number){
    number = number+'';
    var length = number.length;
    for(var i = length ; i<no_of_digit; i++)
    {
      number = '0'+number;
    }
    return number;
  }

  getQrImage(employee){
    let url = 'https://chart.googleapis.com/chart?cht=qr&chs=500x500&chl='+siteURL+'staffing/timesheet/'+this.getNumber(6,employee.id);
    return url;
  }

}
