import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';
import { HelperService } from '../helper/helper.service';

@Component({
  selector: 'app-administration-company-setting',
  templateUrl: './administration-company-setting.component.html',
  styleUrls: ['./administration-company-setting.component.css']
})
export class AdministrationCompanySettingComponent implements OnInit {

  companySettingForm:FormGroup;
  filedata:any;
  selectedImage:any;
  response:any=[];
  states:any=[];

  constructor(
    private api:ApiService,
    private toastr:ToastrService,
    private formBuilder:FormBuilder,
    private helper:HelperService
  ) { 
    this.getCompanySetting();
    

    this.companySettingForm = this.formBuilder.group({
      id:[''],
      admin_company_name:[''],
      admin_company_phone:[''],
      admin_company_logo:[''],
      admin_company_location_address_line_1:[''],
      admin_company_location_address_line_2:[''],
      admin_company_location_city:[''],
      admin_company_location_state:[''],
      admin_company_location_zip:[''],
    });
  }

  ngOnInit(): void {
    this.states = this.helper.getState();
  }

  async getCompanySetting(){
    this.api.get('admin/applicationsetting/list').subscribe((result)=>{
      let data = result.data;
      this.companySettingForm.patchValue({'id':data.id,'admin_company_name':data.admin_company_name,'admin_company_phone':data.admin_company_phone,'admin_company_location_address_line_1':data.admin_company_location_address_line_1,'admin_company_location_address_line_2':data.admin_company_location_address_line_2,'admin_company_location_city':data.admin_company_location_city,'admin_company_location_state':data.admin_company_location_state,'admin_company_location_zip':data.admin_company_location_zip});
    },(err)=>{
    });
    return true;
  } 

  fileEvent(event){
    if (event.target.files && event.target.files.length > 0) {
     
      // Set theFile property
      this.selectedImage = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.selectedImage);
      reader.onload = () => {
      this.selectedImage = reader.result as string;
      console.log(this.selectedImage);
    }
    }else{
      this.selectedImage='';
    }
  }

  submitForm(data):void{

    let endPoint = (data.id)?'update':'create';
    data.logo = this.selectedImage;

    this.api.post('admin/company_setting/'+endPoint,data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.selectedImage='';
      this.getCompanySetting();
    },(err)=>{
      this.toastr.error(err.error.messsage,"Error");
    });
    
  }

}
