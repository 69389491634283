<div class="row">
    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">My Courses</h6>
                
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-3" *ngFor="let course of myCourse; let i=index">
                       <div class="border">
                        <img src="assets/img/noimg.jpg" alt="">
                        <div class="card-header bg-light-custom text-center">{{course.visibility}}</div>
                        <div class="card-body">
                           <h4>{{course.name}}</h4>
                           <p>{{course.brief}}</p>
                        </div>
                        <a class="d-block text-center mb-3" [routerLink]="['/training/course-show/'+course.id]">View Course</a>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>

  

  