import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [DatePipe]
})
export class HomeComponent implements OnInit {

  reminderForm:FormGroup;
  time:any=[''];
  user :any=[];
  totalRecords:any;
  reminders:any=[];
  response:any;
  page:any=1;

  pageallnotification:any=1;
  pagereadnotification:any=1;
  pageunreadnotification:any=1;

  delete_id:any;
  date:any;
  latest_date:any;

  allnotification:any=[];
  totalRecordsAllNotification:any;
  readnotification:any=[];
  totalRecordsReadNotification:any;
  unreadnotification:any=[];
  totalRecordsUnreadNotification:any;


  formErrors = {
    'title':'',
    'date':'',
    'time':'',
    'type':'',
    'notes':''
  }

  validationMessages ={
    'title':{
      'required':"Enter Title",
      'minlength':"Title must be 3 characters long"
    },
    'date':{
      'required':"Select Date"
    },
    'time':{
      'required':"Select Time"
    },
    'type':{
      'required':"Select Type"
    }
  }

  constructor(
    private auth: AuthService,
    private api: ApiService,
    private router : Router,
    private modalService:NgbModal,
    private formBuilder:FormBuilder,
    private toastr:ToastrService,
    private datePipe: DatePipe
    ) {
    this.user =  JSON.parse(localStorage.getItem('reliant_user'));

    this.date=new Date();
    this.latest_date =this.datePipe.transform(this.date, 'yyyy-MM-dd');
      console.log(this.latest_date);
    this.reminderForm = this.formBuilder.group({
      reminders: [''],
    });

    this.getReminder(this.page);
    this.getAllNotification(this.pageallnotification);
    this.getReadNotification(this.pagereadnotification);
    this.getUnreadNotification(this.pageunreadnotification);
   }

  ngOnInit(): void {
     this.init();
  }

  init() :void{
    this.reminderForm = this.formBuilder.group({
      id: [''],
      title: ['', [Validators.required, Validators.minLength(3)]],
      date:['', [Validators.required]],
      time:['', [Validators.required]],
      type:['', [Validators.required]],
      notes :[''],
    });
    this.reminderForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.reminderForm);
    });
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  testxxx(){
    this.api.get('admin/permission/lists').subscribe((result) => {
        console.log('result here',result);
    }, (err) => {
        console.log(err);
        alert(err.error.message);
    });
  }

  async getReminder(page){
    let userid=this.user.id;

    this.api.get("admin/reminders/lists?page="+page+"&userid="+userid).subscribe((result)=>{
      this.totalRecords = result.data.total;
      this.reminders = result.data.data;
    },(err)=>{

    });
  }

  addReminders(content){
    this.reminderForm.reset();
    this.modalService.open(content,{size:'xl',centered:true});
  }

  submitReminderData(data):void{
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    data.user_id = this.user.id;
    let endPoint = (data.id)?"update":'create';
    console.log(data);
    this.api.post("admin/reminders/"+endPoint,data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.getReminder(this.page);
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });
  }

  editReminders(content,index){
    this.reminderForm.reset();
    let editData = this.reminders[index];
    this.reminderForm.patchValue(editData);
    this.modalService.open(content,{size:"xl",centered:true});
  }

  reminderDelete(content,current_row){
    this.delete_id = current_row.id;
    this.modalService.open(content,{ size:'sm' , centered:true });
  }

  deleteReminder():void{
    let data = { id: this.delete_id }
    this.api.post('admin/reminders/delete', data).subscribe((result) => {
       this.response=result;
       this.toastr.success(this.response.message,"Success");
       this.modalService.dismissAll();
       this.getReminder(this.page);
    }, (err) => {
       console.log(err);
       this.modalService.dismissAll();
       this.toastr.error(err.error.message,'Error');
    });
  }


  async getAllNotification(page){
    let userid=this.user.id;

    this.api.get("admin/notifications/lists?page="+page+"&userid="+userid).subscribe((result)=>{
      this.totalRecordsAllNotification = result.data.total;
      this.allnotification = result.data.data;
    },(err)=>{

    });
  }

  async getReadNotification(page){
    let userid=this.user.id;
    this.api.get("admin/notifications/lists?page="+page+"&userid="+userid+"&status="+1).subscribe((result)=>{
      this.totalRecordsReadNotification = result.data.total;
      this.readnotification = result.data.data;
    },(err)=>{

    });
  }

  async getUnreadNotification(page){
    let userid=this.user.id;

    this.api.get("admin/notifications/lists?page="+page+"&userid="+userid+"&status="+0).subscribe((result)=>{
      this.totalRecordsUnreadNotification = result.data.total;
      this.unreadnotification = result.data.data;
    },(err)=>{

    });
  }

  deleteModal(content,current_row){
    this.delete_id = current_row.id;
    this.modalService.open(content,{ size:'sm',centered:true });
  }

  async deleteNotification(){

  }


}
