import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-training-my-courses',
  templateUrl: './training-my-courses.component.html',
  styleUrls: ['./training-my-courses.component.css']
})
export class TrainingMyCoursesComponent implements OnInit {

  currentUser:any=[];
  response:any=[];
  myCourse:any=[];

  constructor(
    private route:ActivatedRoute,
    private api:ApiService
  ) { 
    this.currentUser = JSON.parse(localStorage.getItem('reliant_user'));
    this.getMyCourse(this.currentUser.id);
  }

  ngOnInit(): void {
  }

  getMyCourse(userid){
    let data = {user_id:userid};
    this.api.post('admin/courses/my_course',data).subscribe((result)=>{
       this.response = result;
       this.myCourse = this.response.data;
    },(err)=>{
    });
  }

}
