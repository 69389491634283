<div class="row">
    <div class="col-xl-12 box-margin" *ngIf="isAlert">
        <div class="alert alert-{{ alertClass }} alert-dismissible fade show alert-{{ alertBckClass }}-background"
            role="alert">
            {{ alertMessage }}
            <button type="button" (click)="closeAlert()" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Users</h6>
                <div class="btn-group">
                    <button class="action-btn bg-dark-green mr-2" (click)="addUser(userFormModal)"><img
                            src="assets/img/svg-icon/add-white.svg" width="20" /></button>
                    <!-- <button class="action-btn bg-dark-blue"><img src="assets/img/svg-icon/filter-white.svg"
                            width="20" /></button> -->
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content list_table" id="ticketList">
                    <div class="table-responsive list_table">
                        <table class="table  table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th class="pt-0">Name</th>
                                    <th class="pt-0">Email</th>
                                    <th class="pt-0">Last Login Date</th>
                                    <th class="pt-0">Roles</th>
                                    <th class="pt-0 text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="users.length > 0">
                                    <tr *ngFor="let user of users | paginate: { id: 'users_pagination', itemsPerPage: 10, currentPage: page, totalItems: totalRecords }; let i=index">
                                        <td class="text-black">{{ user.name }}</td>
                                        <td class="text-gray">{{ user.email }}</td>
                                        <td class="text-black">{{ user.last_login }}</td>
                                        <td class="text-gray">{{ getUserRoles(user.roles) }}</td>
                                        <td class="text-right">
                                            <a href="javascript:void(0)" (click)="editUser(userFormModal, i)"
                                                class="mr-2 action-btn bg-orange"><img
                                                    src="assets/img/svg-icon/edit-white.svg" width="20" /></a>
                                            <a href="javascript:void(0)" (click)="deleteModal(deleteModalBox, user.id)"
                                                class="action-btn bg-red"><img src="assets/img/svg-icon/delete.svg"
                                                    width="20" /></a>
                                        </td>
                                    </tr>
                                </ng-container>
                                <tr *ngIf="users.length == 0">
                                    <td class="text-gray text-center" colspan="5">No Users Found</td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="pagination-controls-theme" id="users_pagination" (pageChange)="getUsers(page = $event)" maxSize="9" directionLinks="true"
                            autoHide="true" responsive="true">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #userFormModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0">User Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <form [formGroup]="userForm" (ngSubmit)="submitData(userForm.value)">
            <div class="form-row">
                <div class="form-group col-md-4">
                    <label for="inputState" class="col-form-label text-light-black font-16">Name</label>
                    <input class="form-control rounded-0 form-control-md" type="text" formControlName="name">
                    <span class="text-danger"
                        *ngIf="userForm.get('name').errors?.required || userForm.get('email').errors?.minlength && userForm.get('name').errors">
                        {{formErrors.name}}</span>
                    <input type="hidden" formControlName="id" value="">
                </div>
                <div class="form-group col-md-4">
                    <label for="inputState" class="col-form-label text-light-black font-16">Email</label>
                    <input class="form-control rounded-0 form-control-md" type="text" formControlName="email">
                    <span class="text-danger"
                        *ngIf="userForm.get('email').errors?.required || userForm.get('email').errors?.email  && userForm.get('email').errors">
                        {{formErrors.email}}</span>
                </div>
                <div class="form-group col-md-4">
                    <label for="inputState" class="col-form-label text-light-black font-16">Phone</label>
                    <input class="form-control rounded-0 form-control-md" type="text" formControlName="phone">
                    <span class="text-danger"
                        *ngIf="userForm.get('phone').errors?.minlength && userForm.get('phone').errors">
                        {{formErrors.phone}}</span>
                </div>
                <div class="form-group col-md-12">
                    <label for="inputState" class="col-form-label text-light-black font-16">Roles</label>
                    <ng-select formControlName="roles" [multiple]="true" placeholder="Select roles"
                        clearAllText="Clear">
                        <ng-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</ng-option>
                    </ng-select>
                    <span class="text-danger"
                        *ngIf="userForm.get('roles').errors?.required && userForm.get('roles').errors">
                        {{formErrors.roles}}</span>
                </div>
                <div class="form-group col-md-6">
                    <label for="inputState" class="col-form-label text-light-black font-16">Password</label>
                    <input class="form-control rounded-0 form-control-md" type="text" formControlName="password">
                    <span class="text-danger"
                        *ngIf="userForm.get('password').errors?.required || userForm.get('password').errors?.minlength && userForm.get('password').errors">
                        {{formErrors.password}}</span>
                </div>
                <div class="form-group col-md-6">
                    <label for="inputState" class="col-form-label text-light-black font-16">Confirm Password</label>
                    <input class="form-control rounded-0 form-control-md" type="text"
                        formControlName="password_confirmation">
                    <span class="text-danger"
                        *ngIf="userForm.get('password_confirmation').errors?.confirmedValidator && userForm.get('password_confirmation').errors">
                        {{formErrors.password_confirmation}}</span>
                </div>
            </div>
            <div class="btn-modal-group text-center">
                <button type="submit" [disabled]="!userForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 w-30 md-close">SUBMIT</button>
            </div>
        </form>
    </div>
</ng-template>
<ng-template #deleteModalBox let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>
    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteUser()" class="btn btn-success  border-r-10">Yes, delete it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>
</ng-template>