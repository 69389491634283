import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-training-topic',
  templateUrl: './training-topic.component.html',
  styleUrls: ['./training-topic.component.css']
})
export class TrainingTopicComponent implements OnInit {

  topics:any=[];
  totalRecords:any;
  page:any=1;
  topicForm:FormGroup;
  response:any=[];
  delete_id:any;

  formErrors = {
    'name':'',
    'description':''
  }

  validationMessages = {
    'name':{
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    }
  }

  constructor(
    private api:ApiService,
    private formBuilder:FormBuilder,
    private toastr:ToastrService,
    private modalService:NgbModal

  ) {
    this.getTopics(this.page);
   }

  ngOnInit(): void {
    this.init();
  }

  init() :void{
    this.topicForm = this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required, Validators.minLength(3)]],
      description:['']
    });
    this.topicForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.topicForm);
    });
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }


  async getTopics(page){
    this.api.get("admin/topics/lists?page="+page).subscribe((result)=>{
      this.totalRecords = result.data.total;
      this.topics = result.data.data;
    },(err)=>{

    });
  }

  addTopic(content){
    this.topicForm.reset();
    this.modalService.open(content,{ size:'lg' , centered:true });
  }

  submitTopicData(data):void{
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    let endPoint = (data.id) ? 'update' : 'create';
    this.api.post('admin/topics/' + endPoint, data).subscribe((result) => {
      this.response=result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.getTopics(this.page);
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;

    }, (err) => {
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.modalService.dismissAll();
      this.toastr.error(err.error.message,"Error");

    });
  }


  editTopic(content,index){
    this.topicForm.reset();
    this.topicForm.patchValue(this.topics[index]);
    this.modalService.open(content,{ size:'lg',centered:true });
  }

  deleteModal(content,current_row){
    this.delete_id = current_row.id;
    this.modalService.open(content,{ size:'sm',centered:true });
  }

  deleteTopic():void{
    let data = { id: this.delete_id }
    this.api.post('admin/topics/delete', data).subscribe((result) => {
       this.response=result;
       this.toastr.success(this.response.message,"Success");
       this.modalService.dismissAll();
       this.getTopics(this.page);
    }, (err) => {
       console.log(err);
       this.modalService.dismissAll();
       this.toastr.error(err.error.message,'Error');
    });
  }



}
