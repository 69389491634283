import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';
import { environment } from '../../environments/environment';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

const filePath = environment.filePath;
@Component({
  selector: 'app-training-section-view',
  templateUrl: './training-section-view.component.html',
  styleUrls: ['./training-section-view.component.css']
})
export class TrainingSectionViewComponent implements OnInit {

  sectionForm:FormGroup;
  sectionId:any=[];
  response:any=[];
  currentSection:any=[];
  embedVideo:any=``;
  filePath:any=filePath;
  file:any='';
  courses:any=[];
  tests:any=[];
  resources:any=[];
  resourceModel:any=[];
  deleteId:any;
  currentUser:any;
  questions:any=[];
  optionArray:any=[];
  currentCourseTest:any=[];
  keepGoing:boolean=true;
  isSubmit:boolean=true;

  formErrorsSection = {
    'id':'',
    'name':'',
    'description':'',
    'content':'',
    'is_hidden':'',
    'video_embed':'',
    'resources_selection':'',
    'course_id':''
  }

  validationMessagesSection = {
    'name':{
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    }
  }

  constructor(
    private router:ActivatedRoute,
    private formBuilder:FormBuilder,
    private api:ApiService,
    private toastr:ToastrService,
    private route:Router,
    private sanitizer:DomSanitizer,
    private modalService:NgbModal
  ) { 
    this.currentUser = JSON.parse(localStorage.getItem('reliant_user'));
    this.sectionId=  this.router.snapshot.params['id'];
    this.getSingleSection(this.sectionId);
    
  }

  ngOnInit(): void {
    this.init();
  }

  init(){
    this.sectionForm = this.formBuilder.group({
      id:[''],
      name: ['', [Validators.required, Validators.minLength(3)]],
      description:[''],
      content:[''],
      is_hidden:[''],
      video_embed:[''],
      resources_selection:[''],
      test_id:[''],
      course_id:['']
    });
    this.sectionForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairsSection(this.sectionForm);
    });
  }

  validateKeyValuePairsSection(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairsSection(abstractControl);
      } else {
        this.formErrorsSection[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessagesSection[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrorsSection[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  getSingleSection(id){
    this.resourceModel=[];
    let data = {id:id,user_id:this.currentUser.id};
    this.api.post('admin/sections/single_section',data).subscribe((result)=>{
      this.response=result;
      this.currentSection=this.response.data;
      this.questions=this.currentSection.questions;
      this.currentCourseTest=this.currentSection.test;
      let testSubmission= (this.currentSection.test_submissions)?this.currentSection.test_submissions.test_submission_answers:'';
      this.questions.forEach((element,j)=>{
        element.question_options.forEach((ele,index)=>{
          ele.checked='';
          if(testSubmission){
            if(testSubmission[j].answer==ele.value){
              ele.checked=ele.value;
            }
          }
        });
      });

      this.questions.forEach((element)=>{
        this.optionArray.push({question_id:element.id,option_id:'',value:'',correct:''});
      });
      this.currentSection.get_resourse.forEach((element)=>{
        this.resourceModel.push(Number(element.id));
      });
    },(err)=>{
    });
    return true;
  }

  getLink(data){
    return this.sanitizer.bypassSecurityTrustResourceUrl(data.link);
  }
  showVideo(content,data){
    this.embedVideo = data.embed;
    this.modalService.open(content,{ size:'xl',centered:true });
    setTimeout(() => {
      let x = document.getElementById('embedVideo') as HTMLElement;
      x.innerHTML = this.embedVideo;
    }, 500);
  }
  getFile(content,data){
    this.modalService.open(content,{ size:'xl',centered:true });
    let x = document.getElementById('srcImage') as HTMLImageElement;
    x.src = this.filePath+data.image;
  }

  updateCourse(content,data){
    this.getCourses();
    this.getTests();
    this.getResources();
    data.course_id=Number(data.course_id);
    data.test_id=Number(data.test_id);
    this.sectionForm.patchValue(data);
    this.modalService.open(content,{ size:'xl',centered:true });

  }

  getCourses(){
    this.api.get('admin/courses/all_courses').subscribe((result)=>{
      this.courses = result.data;
    },(err)=>{
    });
    return true;
  }

  getTests(){
    this.api.get('admin/tests/tests_lists').subscribe((result)=>{
      this.tests = result.data;
    },(err)=>{
    });
    return true;
  }

  getResources(){
    this.api.get('admin/reseourses/all_reseourse').subscribe((result)=>{
      this.resources = result.data;
    },(err)=>{
    });
    return true;
  }
  submitSection(data){
    this.api.post('admin/sections/update',data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.getSingleSection(this.sectionId);
      this.modalService.dismissAll();
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

  deleteSection(content,data){
    this.deleteId=data.id;
    this.modalService.open(content,{ size:'sm',centered:true });
  }
  deleteCurrentSection(){
    let data =  {id:this.deleteId};
    this.api.post('admin/sections/delete',data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.getSingleSection(this.sectionId);
      this.modalService.dismissAll();
      this.route.navigateByUrl('/training/course-show/'+this.currentSection.course_id);
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

  completeSection(sectionId){
    let data = {section_id:sectionId,user_id:this.currentUser.id};
    this.api.post('admin/section_complete/section_complete',data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.getSingleSection(sectionId);
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

  getOption(questionId,optionId,value,correct){
    let index = this.optionArray.findIndex((m)=> m.question_id === questionId);
    if(index !== -1){
      this.optionArray[index] = {question_id: questionId,option_id:optionId, value: value,correct:correct };
    } else {
      this.optionArray.push( {question_id: questionId,option_id:optionId, value: value ,correct:correct } );
    }
  }

  submitTest(content){
    let data = {test_id:this.currentSection.test_id,user_id:this.currentUser.id,section_id:this.currentSection.id,answer:this.optionArray,passing_percentage:this.currentCourseTest.passing_percentage}; 
    if(this.keepGoing){
      this.optionArray.forEach((ele,index)=>{
        if(ele.value=='' && this.keepGoing){
          this.modalService.open(content,{ size:'sm',centered:true });
          this.keepGoing = false;
          this.isSubmit=false;
        }
      });
    }
    if(this.isSubmit){
      this.api.post('admin/test_submissions/create',data).subscribe((result)=>{
        this.response = result;
        this.toastr.success(this.response.message,'Success');
        this.optionArray=[];
        this.getSingleSection(this.sectionId);
      },(err)=>{
        this.toastr.error(err.error.message,"Error");
      });
    }
    
  }

  confirmSubmission(content){
    this.isSubmit=true;
    this.modalService.dismissAll();
    this.submitTest(content);
  }
  cancelSubmission(){
    this.modalService.dismissAll();
    this.keepGoing=true;
  }



  retakeTest(content,id){
    this.deleteId=id;
    this.modalService.open(content,{ size:'sm',centered:true});
  }

  retakeSubmit(){
    let data ={id:this.deleteId,user_id:this.currentUser.id};
    this.api.post('admin/test_submissions/retake',data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.optionArray=[];
      this.getSingleSection(this.sectionId);
      this.modalService.dismissAll();
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

  
  


}
