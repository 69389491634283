<div class="row">
    <div class="col-md-4">
        <div class="card box-margin">
            <div class="card-header bg-green">
                <h6 class="card-title mb-0">{{course.name}}</h6>
            </div>
            <div class="card-body">
               <div class="data_image text-center">
                <img src="{{(course.image)?filePath+course.image:'assets/img/noimg.jpg'}}" class="profile" alt="">
               </div>
               <div class="body_progress-footer">
                <button class="action-btn bg-orange pull-right" (click)="registerCurrentUser()" *ngIf="course.reg_status==0 && course.cost==0">Start Course</button>
                <button class="action-btn bg-dark-blue pull-right"  (click)="registerCurrentUser()" *ngIf="course.reg_status==0 && course.cost!=0">Registration For <span *ngIf="course.cost">${{course.cost}}</span></button>
                <h2 *ngIf="course.reg_status==1 && (completeSection*100)/totalSection!=100"> {{completeSection}} of <span *ngIf="course.sections">{{totalSection}}</span> sections complete.</h2>
                <h2 *ngIf="course.reg_status==1 && (completeSection*100)/totalSection==100">This course is complete!</h2>
                <div class="progress" *ngIf="course.reg_status==1">
                    <div class="progress-bar bg-success" role="progressbar" [style.width.%]="(completeSection*100)/totalSection" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
               </div>
            </div>
        </div>
    </div>
    <div class="col-md-8">
        <div class="card">
            <div>
                <ul class="nav nav-tabs mb-0" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="active-01-tab" data-toggle="tab" (click)="getTabString('Overview')" href="#overview" role="tab"
                            aria-controls="overview" aria-selected="true">Overview</a>
                    </li>
                    <li class="nav-item" *ngIf="course.sections && course.sections.length>0 && course.reg_status==1">
                        <a class="nav-link mr-0" id="inactive-02-tab" data-toggle="tab" (click)="getTabString('Section')" href="#section" role="tab"
                            aria-controls="section" aria-selected="false">Sections</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0" id="dnr-02-tab" data-toggle="tab" (click)="getTabString('Registration')" href="#registraion" role="tab"
                            aria-controls="registraion" aria-selected="false">Registration</a>
                    </li>
                </ul>
            </div>
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">{{ tabText }}</h6>
                
                <div class="btn-group tab_list" *ngIf="tabText=='Overview'">
                    <button class="action-btn bg-dark-blue mr-2" *ngIf="course.newSectionId!='' && course.sections && course.sections.length>0 && course.reg_status==1" [routerLink]="['/training/section-view/'+course.newSectionId]"> <i class="fa fa-check-square-o"></i> Take Test</button>
                    <button class="action-btn bg-dark-green mr-2 font-20" (click)="editModel(courseFormModal,course)"><i class="fa fa-pencil"></i></button>
                    <button class="action-btn bg-dark-blue text-white font-20" (click)="deleteModal(courseDeleteModal,course)"><i class="fa fa-trash"></i></button>
                </div>
                <div class="btn-group tab_list" *ngIf="tabText=='Registration'">
                    <button class="action-btn bg-dark-green mr-2" (click)="addCourseReg(registrationFormModal)"><img src="assets/img/svg-icon/add-white.svg"
                            width="20" /></button>
                    <!-- <button class="action-btn bg-dark-blue"><img src="assets/img/svg-icon/filter-white.svg"
                            width="20" /></button> -->
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content list_table" id="ticketList">
                    <div class="tab-pane active show fade" id="overview" role="tabpanel"
                        aria-labelledby="active-01-tab">
                        <div class="row">
                            <div class="col-sm-12 mb-2" *ngIf="addedInstructor.length">
                                <b>Instructors</b> {{ addedInstructor }}
                            </div>
                            <div class="col-md-12">
                                <p class="text-gray mb-15 font-16" [innerHTML]="course.description"></p>
                            </div>
                            <div class="col-md-4">
                                <h4 class="text-black font-20">Minimum Registrations</h4>
                                <p class="text-gray font-16 m-0">{{(course.min_registrations)?course.min_registrations:'N/A'}}</p>
                            </div>
                            <div class="col-md-4">
                                <h4 class="text-black font-20 sm-font-16 md-font-16">Maximum Registrations</h4>
                                <p class="text-gray font-16 m-0">{{(course.max_registrations)?course.max_registrations:'N/A'}}</p>
                            </div>
                            <div class="col-md-4">
                                <h4 class="text-black font-20">Total Registrations</h4>
                                <p class="text-gray font-16 m-0">{{(totalRecords)?totalRecords:'N/A'}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="section" role="tabpanel" aria-labelledby="active-01-tab">
                        <div class="row"  *ngIf="course.sections">
                            <div class="col-md-12">
                                <p class="text-gray font-16">Select a section below to get started, or continue where you left off.</p>
                            </div>
                            <div class="col-md-12">
                                <div class="section-item border-bottom mt-2" *ngFor="let section of course.sections">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="section-item-title text-black font-18">
                                            <i class="fa fa-check-square-o"></i>&nbsp; {{section.name}} {{section.section_completions.length}}
                                        </div>
                                        <div class="text-blue font-18" *ngIf="section.section_completions.length==0">Not Completed</div>
                                        <div class="font-18 text-success" *ngIf="section.section_completions.length==1"><i class="fa fa-check-circle"></i> Complete</div>
                                    </div>
                                    <p class="text-gray mb-15 font-16"><span *ngIf="section.description">{{section.description}}</span> <span *ngIf="!section.description">No description available.</span> </p>
                                    <div class="text-right mb-15">
                                        <a class="action-btn bg-dark-blue font-16 text-white" [routerLink]="['/training/section-view/'+section.id]">Visit Section</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="registraion" role="tabpanel" aria-labelledby="active-01-tab">
                        <div class="tab-content list_table" id="courseList">
                            <div class="table-responsive list_table">
                                <table class="table  table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th class="pt-0">User</th>
                                            <th class="pt-0">Course</th>
                                            <th class="pt-0">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngIf="courseRegisters.length > 0">
                                            <tr *ngFor="let course of courseRegisters | paginate: { id: 'course_reg_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalRecords }; let i=index">
                                                <td class="text-black"><span *ngIf="course.user">{{course.user.name}}</span></td>
                                                <td class="text-black"><span *ngIf="course.course">{{course.course.name}}</span></td>
                                                <td class="text-right">
                                                  <a href="javascript:void(0)" class="mr-2 action-btn bg-edit" title="Update Rates" (click)="editCourseRegistration(registrationFormModal,i)"><img src="assets/img/svg-icon/edit.svg" width="20" /></a>
                                                  <a href="javascript:void(0)" class="action-btn bg-red" title="Delete" (click)="deleteModal(registrationDeleteModal,course)"><img src="assets/img/svg-icon/delete.svg" width="20" /></a>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <tr *ngIf="courseRegisters.length == 0">
                                            <td class="text-gray text-center" colspan="5">No Course registration found.</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <pagination-controls class="pagination-controls-theme" id="course_reg_pagination" (pageChange)="getCourseRegistration(page = $event,currentCourseId)" maxSize="9" directionLinks="true"
                                autoHide="true" responsive="true">
                                </pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


 <!-- Add Registration  -->
  
 <ng-template #registrationFormModal let-modal>
  
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
      <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross  click')"
          aria-label="Close">
          <span aria-hidden="true">×</span>
      </button>
    </div>
    
    <div class="modal-body md-b-body">
      <form [formGroup]="courseRegForm" (ngSubmit)="submitCourseRegData(courseRegForm.value)">
          <input type="hidden" formControlName="id">
        <div class="form-row">
          <div class="form-group col-md-12">
            <div class="card card-shadow-hide">
                <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                    <h6 class="card-title mb-0">Registration Details</h6>
                </div>
                <div class="card-body">
                  <div class="form-row">
                    <div class="form-group col-sm-6">
                      <label for="user_id">User <span class="text-danger">*</span></label>
                      <ng-select formControlName="user_id" placeholder="Select" clearAllText="Clear" class="form-control-ng rounded-0">
                          <ng-option *ngFor="let customer of customers" [value]="customer.id">
                              {{customer.name}}
                          </ng-option>
                      </ng-select>
                      <span class="text-danger"
                            *ngIf="courseRegForm.get('user_id').errors?.required || courseRegForm.get('user_id').errors?.minlength && courseRegForm.get('user_id').errors">
                            {{formErrors.user_id}}</span>
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="course_id">Course <span class="text-danger">*</span></label>
                      <ng-select formControlName="course_id" [(ngModel)]="courseIdModel" placeholder="Select" clearAllText="Clear" class="form-control-ng rounded-0">
                          <ng-option *ngFor="let course of courses" [value]="course.id">
                              {{course.name}}
                          </ng-option>
                      </ng-select>
                      <span class="text-danger"
                            *ngIf="courseRegForm.get('course_id').errors?.required || courseRegForm.get('course_id').errors?.minlength && courseRegForm.get('course_id').errors">
                            {{formErrors.course_id}}</span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div class="btn-modal-group">
            <button type="submit" [disabled]="!courseRegForm.valid" id="submit-btn" class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
        </div>
      </form>
    </div>
    
</ng-template>
    
<!-- Delete  Registration Data -->
<ng-template #registrationDeleteModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>

    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteRegistration()" class="btn btn-success  border-r-10">Yes, delete it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>

</ng-template>

     <!-- Delete Course -->
<ng-template #courseDeleteModal let-modal>

<div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
    <h6 class="card-title mb-0 text-center">Delete</h6>
</div>

<div class="modal-body md-b-body">
    <p>Are you sure you want to delete?</p>
    <div class="btn-modal-group d-flex justify-content-between">
        <button type="button" (click)="deleteCourse()" class="btn btn-success  border-r-10">Yes, delete it</button>
        <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
    </div>
</div>

</ng-template>


 <!-- Edit Course  -->
  
 <ng-template #courseFormModal let-modal>
  
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
      <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross  click')"
          aria-label="Close">
          <span aria-hidden="true">×</span>
      </button>
    </div>
    
    <div class="modal-body md-b-body">
      <form [formGroup]="courseForm" (ngSubmit)="updateData(courseForm.value)">
          <input type="hidden" formControlName="id">
        <div class="form-row">
          <div class="form-group col-md-12">
            <div class="card card-shadow-hide">
                <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                    <h6 class="card-title mb-0"> Course Details</h6>
                </div>
                <div class="card-body">
                  <div class="form-row">
                    <div class="col-sm-6">
                        <label for="">Name <span class="text-danger">*</span> </label>
                        <input type="text" formControlName="name" class="form-control">
                        <span class="text-danger" *ngIf="courseForm.get('name').errors?.required">
                            {{formErrorsCourse.name}}</span>
                    </div>

                    <div class="col-md-6">
                        <label for="">Topic(s)</label>
                        <ng-select [(ngModel)]="topicModel" [multiple]="true"
                            class="custom-mutiple-select"
                            formControlName="topic" placeholder="Select"
                            clearAllText="Clear">
                            <ng-option *ngFor="let topic of topics"
                                [value]="topic.id"> {{ topic.name }}</ng-option>
                        </ng-select>
                    </div>

                    <div class="col-md-4">
                        <label for="">Certification(s)</label>
                        <ng-select [(ngModel)]="certificateModel" [multiple]="true"
                            class="custom-mutiple-select"
                            formControlName="certification" placeholder="Select"
                            clearAllText="Clear">
                            <ng-option
                                *ngFor="let certification of certifications"
                                [value]="certification.id"> <span
                                    *ngIf="certification.name">
                                    {{certification.name}} </span></ng-option>
                        </ng-select>
                    </div>
                    <div class="col-md-4">
                        <label for="">Sponsor(s)</label>
                        <ng-select [multiple]="true"
                            class="custom-mutiple-select"
                            formControlName="sponsor" placeholder="Select"
                            clearAllText="Clear">
                            <ng-option value="">Select Sponser</ng-option>
                        </ng-select>
                    </div>
                    <div class="col-md-4">
                        <label for="">Instructor</label>
                        <ng-select [(ngModel)]="instructorModel" [multiple]="true" formControlName="instructor"
                            placeholder="Select" clearAllText="Clear">
                            <ng-option *ngFor="let instructor of instructors"
                                [value]="instructor.id"> <span *ngIf="instructor.name">
                                    {{instructor.name}} </span></ng-option>
                        </ng-select>
                    </div>

                    <div class="form-group col-md-4">
                        <label for="example-range">Cost ($)</label>
                        <input type="text" formControlName="cost" class="form-control">
                    </div>

                    <div class="form-group col-md-4">
                        <label for="input" class="">Date</label>
                        <input type="date" formControlName="course_date" class="form-control">
                    </div>

                    <div class="col-md-4">
                        <label>Visibility</label>
                        <div class="form-group  d-flex mb-20">
                            <div class="form-check pl-0 mb-2">
                                <label class="form-check-label button-radio-btn">
                                    <input type="radio" class="form-check-input radio-btn-button"
                                        formControlName="visibility" value="In Person">
                                    <div class="layer"></div>
                                    <div class="button"><span>In Person</span></div>
                                </label>
                            </div>
                            <div class="form-check pl-0 mb-2">
                                <label class="form-check-label button-radio-btn">
                                    <input type="radio" class="form-check-input radio-btn-button"
                                        formControlName="visibility" value="Online">
                                    <div class="layer"></div>
                                    <div class="button"><span>Online</span></div>
                                </label>
                            </div>
                            <div class="form-check pl-0 mb-2">
                                <label class="form-check-label button-radio-btn">
                                    <input type="radio" class="form-check-input radio-btn-button"
                                        formControlName="visibility" value="Webinar" active>
                                    <div class="layer"></div>
                                    <div class="button"><span>Webinar</span></div>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <label>Published</label>
                        <div class="form-group  d-flex mb-20">
                            <div class="form-check pl-0 mb-2">
                                <label class="form-check-label button-radio-btn">
                                    <input type="radio" class="form-check-input radio-btn-button"
                                        formControlName="published" value="0">
                                    <div class="layer"></div>
                                    <div class="button"><span>No</span></div>
                                </label>
                            </div>
                            <div class="form-check pl-0 mb-2">
                                <label class="form-check-label button-radio-btn">
                                    <input type="radio" class="form-check-input radio-btn-button"
                                        formControlName="published" value="1">
                                    <div class="layer"></div>
                                    <div class="button"><span>Yes</span></div>
                                </label>
                            </div>
                           
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label for="">Min Registration</label>
                        <div class="input-group inc_dec mb-3">
                            <div class="input-group-prepend">
                                <button type="button" class="input-group-text"
                                    (click)="minReg(-1)">-</button>
                            </div>
                            <div class="custom-file">
                                <input type="number"
                                    class="form-control text-center" [value]="min"
                                    min="0" max="9999999999999"
                                    id="inputGroupFile01">
                            </div>
                            <div class="input-group-append">
                                <button type="button" class="input-group-text"
                                    (click)="minReg(1)">+</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <label for="">Max Registration</label>
                        <div class="input-group inc_dec mb-3">
                            <div class="input-group-prepend">
                                <button type="button" class="input-group-text"
                                    (click)="maxReg(-1)">-</button>
                            </div>
                            <div class="custom-file">
                                <input type="number"
                                    class="form-control  text-center" [value]="max"
                                    min="0" max="9999999999999"
                                    id="inputGroupFile01">
                            </div>

                            <div class="input-group-append">
                                <button type="button" class="input-group-text"
                                    (click)="maxReg(+1)">+</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-3">
                        <label for="">Featured Image</label>
                        <input type="file" formControlName="featured_image" (change)="onFeaturedImageChange($event)" class="form-control">
                    </div>

                    <div class="col-sm-3">
                        <label for="">Header Image</label>
                        <input type="file" formControlName="header_image" (change)="onHeaderImageChange($event)" class="form-control">
                    </div>


                  </div>
                </div>
            </div>

            <div class="card card-shadow-hide">
                <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                    <h6 class="card-title mb-0">Content</h6>
                </div>

                <div class="form-row">
                    <div class="col-sm-12">
                        <label for="">Short Description</label>
                        <input type="text" formControlName="brief" class="form-control">
                    </div>

                    <div class="col-sm-12">
                        <label for="">Descritpion</label>
                        <editor formControlName="description" [init]="{
                            base_url: '/tinymce',
                            suffix: '.min',
                            height: 350,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                        }"></editor>
                    </div>

                    <div class="form-group col-md-6">
                        <label for="inputState"
                            class="col-form-label text-light-black font-16">Featured video</label>
                        <textarea formControlName="featured_video_embed" class="form-control"
                            rows="5"></textarea>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="col-form-label text-light-black font-16">Chat Embed</label>
                        <textarea formControlName="webinar_chat_embed" class="form-control"
                            rows="5"></textarea>
                    </div>

                </div>



            </div>


          </div>
        </div>
        <div class="btn-modal-group">
            <button type="submit" [disabled]="!courseForm.valid" id="submit-btn" class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
        </div>
      </form>
    </div>
    
</ng-template>