import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-modal-upload-files',
  templateUrl: './modal-upload-files.component.html',
  styleUrls: ['./modal-upload-files.component.css']
})
export class ModalUploadFilesComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer) {

    this.employeeUploadNewFileForm = this.formBuilder.group({
      id: [''],
      custom_field: ['', [Validators.required]],
      expiration: [''],
      employee_files: [''],

    });
    this.employeeUploadNewFileForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.employeeUploadNewFileForm);
    });

  }
  @Input() modelName:any;
  @Input() customerid:any;
  @Input() allStfCustomFiles:any;
  employeeUploadNewFileForm: FormGroup;
  //allStfCustomFiles: any;
  response: any;
  theFile: any = null;
  selectEmployeeFiles: any = '';
  fileName:any;

  ngOnInit(): void {
  }
  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }
  formErrors = {
    'custom_field': '',
  };
  validationMessages = {
    'custom_field': {
      'required': 'Select a Field is required.'
    }
  };
  convertImageInBase64(event) {
    if (event.target.files && event.target.files.length > 0) {

      // Set theFile property
      this.theFile = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.theFile);
      reader.onload = () => {
        this.selectEmployeeFiles = reader.result as string;
      }
      this.fileName = this.theFile.name;
    } else {
      this.selectEmployeeFiles = '';
      this.fileName = '';
    }
  }
  async getStfCustomFiles() {
    let data = { model_id: 409, model_type: 'client' }
    this.api.post('admin/stf_custom_files/lists', data).subscribe((result) => {
      this.response = result;
      this.allStfCustomFiles = this.response.data;
      //this.toastr.success(this.response.message,"Success");
    }, (err) => {
      //this.toastr.error(err.error.messsage, "Error");
    });
  }
  submitEmployeeUploadNewFile(data) {
    data.model_type = this.modelName;
    data.model_id = this.customerid;
    data.employee_files = this.selectEmployeeFiles;
    this.api.post('admin/stf_model_custom_files/add_employee_files', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.modalService.dismissAll();
      this.getStfCustomFiles();
    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    });
  }
}
