import { hasLifecycleHook } from '@angular/compiler/src/lifecycle_reflector';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { title } from 'process';

@Component({
  selector: 'app-administration-view-reports',
  templateUrl: './administration-view-reports.component.html',
  styleUrls: ['./administration-view-reports.component.css']
})
export class AdministrationViewReportsComponent implements OnInit {

  isBillable: boolean = true;
  isExpiring: boolean = false;
  isOpen: boolean = false;
  isEmpStatus: boolean = false;
  isUpcoming: boolean = false;
  isEmpFacility: boolean = false;
  isTimesheet: boolean = false;
  isActivaions: boolean = false;
  isActivity: boolean = false;
  isHeading: any = [''];
  isBillable_Individual_Client: boolean = false;
  isBillable_Multiple_Client: boolean = false;
  isBillable_Individual_Emp: boolean = false;
  isBillable_Multiple_Emp: boolean = false;
  isExpiring_Individual_Emp: boolean = false;
  isExpiring_Multiple_Emp: boolean = false;
  isOpen_Availability_Individual_Emp: boolean = false;
  isOpen_Availability_Multiple_Emp: boolean = false;
  value: any = 'Billable Hours';
  isNo: boolean = false;
  
  isType:boolean=true;

  activity: FormGroup;
  activaions: FormGroup;
  timesheet: FormGroup;
  employeeFacility: FormGroup;
  upcominSchedule: FormGroup;
  employeeStatus: FormGroup;
  openAvailability: FormGroup;
  expiring: FormGroup;
  billableForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
  ) { }




  ngOnInit(): void {
    this.activity = this.formBuilder.group({
      start_date: [''],
      end_date: ['']
    });
    this.activaions = this.formBuilder.group({
      startDate: [''],
      endDate: ['']
    });
    this.timesheet = this.formBuilder.group({
      startDate: [''],
      endDate: ['']
    });
    this.employeeFacility = this.formBuilder.group({
      startDate: [''],
      endDate: [''],
      clients: [''],
      specialties: [''],
    });
    this.upcominSchedule = this.formBuilder.group({
      startDate: [''],
      endDate: [''],
      clients: [''],
    });
    this.employeeStatus = this.formBuilder.group({
      filterStatus: [''],
    });
    this.openAvailability = this.formBuilder.group({
      openData: [''],
      employee: [''],
      employees: [''],
      startDate: [''],
      endDate: [''],
    });
    this.expiring = this.formBuilder.group({
      expiringData: [''],
      employee: [''],
      empExpiring: [''],
    });
    this.billableForm = this.formBuilder.group({
      billableData: [''],
      clientBillable: [''],
      clientSBillable: [''],
      startDate: [''],
      endDate: [''],
      billableEmp: [''],
      empBillable: [''],
      empSBillable: [''],
    });

  }




  submitActivity(data) {
    console.log(data);
    //console.log(this.Activity_Reports.value);
  }
  submitActivaionsData(data) {
    console.log(data);
  }
  submitTimesheetData(data) {
    console.log(data);
  }
  submitEmployeeFacility(data) {
    console.log(data);
  }
  submitUpcominSchedule(data) {
    console.log(data);
  }
  submitEmployeeStatus(data) {
    console.log(data);
  }
  submitOpenAvailability(data) {
    console.log(data);
  }
  submitExpiring(data) {
    console.log(data);
  }
  submitBillableData(data) {
    console.log(data);
  }





  Select_view_reports(event) {
    if (event === "billable") {
      this.isBillable = true;
      this.isExpiring = this.isUpcoming = this.isEmpFacility = this.isEmpStatus = this.isOpen = this.isTimesheet = this.isActivaions = this.isActivity = false;
      this.value = "Billable Hours";
    }
    else if (event === "expiring") {
      this.isExpiring = true;
      this.isBillable = this.isOpen = this.isEmpStatus = this.isEmpFacility = this.isUpcoming = this.isTimesheet = this.isActivaions = this.isActivity = false;
      this.value = "Expiring Availability";
    }
    else if (event === "open") {
      this.isOpen = true;
      this.isBillable = this.isExpiring = this.isEmpStatus = this.isEmpFacility = this.isUpcoming = this.isTimesheet = this.isActivaions = this.isActivity = false;
      this.value = "Open Availability";
    }
    else if (event === "status") {
      this.isEmpStatus = true;
      this.isOpen = this.isBillable = this.isExpiring = this.isEmpFacility = this.isUpcoming = this.isTimesheet = this.isActivaions = this.isActivity = false;
      this.value = "Employees by Status";
    }
    else if (event === "Upcoming") {
      this.isUpcoming = true;
      this.isEmpStatus = this.isOpen = this.isBillable = this.isExpiring = this.isEmpFacility = this.isTimesheet = this.isActivaions = this.isActivity = false;
      this.value = "Upcoming Schedule";
    }
    else if (event === "EmpFacility") {
      this.isEmpFacility = true;
      this.isUpcoming = this.isEmpStatus = this.isOpen = this.isBillable = this.isExpiring = this.isTimesheet = this.isActivaions = this.isActivity = false;
      this.value = "Employees Facility";
    }
    else if (event === "Timesheet") {

      this.isTimesheet = true;
      this.isUpcoming = this.isEmpStatus = this.isOpen = this.isBillable = this.isExpiring = this.isEmpFacility = this.isActivaions = this.isActivity = false;
      this.value = "Timesheet Reports";
    }
    else if (event === "Activaions") {
      this.isActivaions = true;
      this.isUpcoming = this.isEmpStatus = this.isOpen = this.isBillable = this.isExpiring = this.isEmpFacility = this.isTimesheet = this.isActivity = false;
      this.value = "Activaions Reports";
    }
    else if (event === "Activity") {
      this.isActivity = true;
      this.isUpcoming = this.isEmpStatus = this.isOpen = this.isBillable = this.isExpiring = this.isEmpFacility = this.isTimesheet = this.isActivaions = false;
      this.value = "Activity Reports";
    }

    else {
      this.isBillable = true;
    }
  }
  
  Billable_box(type) {
    this.isBillable_Individual_Client = false;
    this.isBillable_Multiple_Client = false;
    if (type == 1) {
      this.isBillable_Individual_Client = true;
    }
    else if (type == 2) {
      this.isBillable_Multiple_Client = true;
    }
    else {
      this.isBillable_Individual_Client = false;
      this.isBillable_Multiple_Client = false;
    }
  }

  Billable_Emp(type) {
    this.isBillable_Individual_Emp = false;
    this.isBillable_Multiple_Emp = false;
    if (type == 1) {
      this.isBillable_Individual_Emp = true;
    }
    else if (type == 2) {
      this.isBillable_Multiple_Emp = true;
    }
    else {
      this.isBillable_Individual_Emp = false;
      this.isBillable_Multiple_Emp = false;
    }
  }

  Expiring_Box(type) {
    this.isExpiring_Individual_Emp = false;
    this.isExpiring_Multiple_Emp = false;
    if (type == 1) {
      this.isExpiring_Individual_Emp = true;
    }
    else if (type == 2) {
      this.isExpiring_Multiple_Emp = true;
    }
    else {
      this.isExpiring_Individual_Emp = false;
      this.isExpiring_Multiple_Emp = false;
    }
  }


  Open_Availability(type) {
    this.isOpen_Availability_Individual_Emp = false;
    this.isOpen_Availability_Multiple_Emp = false;
    if (type == 1) {
      this.isOpen_Availability_Individual_Emp = true;
    }
    else if (type == 2) {
      this.isOpen_Availability_Multiple_Emp = true;
    }
    else {
      this.isOpen_Availability_Individual_Emp = false;
      this.isOpen_Availability_Multiple_Emp = false;
    }
  }

  timesheetsChange(key) {

    (key == 0) ? this.isNo = true : this.isNo = false;
    (key == 1) ? this.isNo = false : this.isNo = true;

  }

}
