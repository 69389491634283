import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../helper/helper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-crm-view-follow-ups',
  templateUrl: './crm-view-follow-ups.component.html',
  styleUrls: ['./crm-view-follow-ups.component.css']
})
export class CrmViewFollowUpsComponent implements OnInit {

  user: any = [];
  clientRegistrationForm: FormGroup;
  callLogsForm: FormGroup;
  ContactForm: FormGroup;
  CommentForm: FormGroup;
  response: any = [];
  allRelatedCustomer: any = [];
  allClientDetails: any = [];
  flagModelRef: any = [];
  flags: any = [];
  flagDetails: any = [];
  editFlag: any = [];
  editStatus: any = [];
  editCollLogs: any = [];
  statuses: any = [];
  statusModelRef: any = [];
  statusDetails: any = [];
  allTypes: any = [];
  allCollLogs: any = [];
  allContacts: any = [];
  allComments: any = [];
  totalRecords: any = 0;
  page: any = 1;
  commentPage: any = 1;
  clientId: any = '';
  call_logstid: any;
  modelName: any;
  contactData: any = [];
  allClients: any = [];
  receivedDate: any = '';
  modelDetails: any = '';
  isSelectedStatus: boolean = false;
  isSelectedFlag: boolean = false;
  activetab1: boolean = false;
  activetab2: boolean = false;
  activetab3: boolean = false;
  activetab4: boolean = true;
  isActivity: boolean = true;
  isComments: boolean = false;
  isCommentsList: boolean = false;
  commentsData: any = [];
  editData: any = [];
  textMsg: any = 'Contact Details';
  tabActive: any = 'follow-ups';
  times = [
    { label: "12:00 AM", value: "0:00" },
    { label: "12:15 AM", value: "0:15" },
    { label: "12:30 AM", value: "0:30" },
    { label: "12:45 AM", value: "0:45" },
    { label: "1:00 AM", value: "1:00" },
    { label: "1:15 AM", value: "1:15" },
    { label: "1:30 AM", value: "1:30" },
    { label: "1:45 AM", value: "1:45" },
    { label: "2:00 AM", value: "2:00" },
    { label: "2:15 AM", value: "2:15" },
    { label: "2:30 AM", value: "2:30" },
    { label: "2:45 AM", value: "2:45" },
    { label: "3:00 AM", value: "3:00" },
    { label: "3:15 AM", value: "3:15" },
    { label: "3:30 AM", value: "3:30" },
    { label: "3:45 AM", value: "3:45" },
    { label: "4:00 AM", value: "4:00" },
    { label: "4:15 AM", value: "4:15" },
    { label: "4:30 AM", value: "4:30" },
    { label: "4:45 AM", value: "4:45" },
    { label: "5:00 AM", value: "5:00" },
    { label: "5:15 AM", value: "5:15" },
    { label: "5:30 AM", value: "5:30" },
    { label: "5:45 AM", value: "5:45" },
    { label: "6:00 AM", value: "6:00" },
    { label: "6:15 AM", value: "6:15" },
    { label: "6:30 AM", value: "6:30" },
    { label: "6:45 AM", value: "6:45" },
    { label: "7:00 AM", value: "7:00" },
    { label: "7:15 AM", value: "7:15" },
    { label: "7:30 AM", value: "7:30" },
    { label: "7:45 AM", value: "7:45" },
    { label: "8:00 AM", value: "8:00" },
    { label: "8:15 AM", value: "8:15" },
    { label: "8:30 AM", value: "8:30" },
    { label: "8:45 AM", value: "8:45" },
    { label: "9:00 AM", value: "9:00" },
    { label: "9:15 AM", value: "9:15" },
    { label: "9:30 AM", value: "9:30" },
    { label: "9:45 AM", value: "9:45" },
    { label: "10:00 AM", value: "10:00" },
    { label: "10:15 AM", value: "10:15" },
    { label: "10:30 AM", value: "10:30" },
    { label: "10:45 AM", value: "10:45" },
    { label: "11:00 AM", value: "11:00" },
    { label: "11:15 AM", value: "11:15" },
    { label: "11:30 AM", value: "11:30" },
    { label: "11:45 AM", value: "11:45" },
    { label: "12:00 PM", value: "12:00" },
    { label: "12:15 PM", value: "12:15" },
    { label: "12:30 PM", value: "12:30" },
    { label: "12:45 PM", value: "12:45" },
    { label: "1:00 PM", value: "13:00" },
    { label: "1:15 PM", value: "13:15" },
    { label: "1:30 PM", value: "13:30" },
    { label: "1:45 PM", value: "13:45" },
    { label: "2:00 PM", value: "14:00" },
    { label: "2:15 PM", value: "14:15" },
    { label: "2:30 PM", value: "14:30" },
    { label: "2:45 PM", value: "14:45" },
    { label: "3:00 PM", value: "15:00" },
    { label: "3:15 PM", value: "15:15" },
    { label: "3:30 PM", value: "15:30" },
    { label: "3:45 PM", value: "15:45" },
    { label: "4:00 PM", value: "16:00" },
    { label: "4:15 PM", value: "16:15" },
    { label: "4:30 PM", value: "16:30" },
    { label: "4:45 PM", value: "16:45" },
    { label: "5:00 PM", value: "17:00" },
    { label: "5:15 PM", value: "17:15" },
    { label: "5:30 PM", value: "17:30" },
    { label: "5:45 PM", value: "17:45" },
    { label: "6:00 PM", value: "18:00" },
    { label: "6:15 PM", value: "18:15" },
    { label: "6:30 PM", value: "18:30" },
    { label: "6:45 PM", value: "18:45" },
    { label: "7:00 PM", value: "19:00" },
    { label: "7:15 PM", value: "19:15" },
    { label: "7:30 PM", value: "19:30" },
    { label: "7:45 PM", value: "19:45" },
    { label: "8:00 PM", value: "20:00" },
    { label: "8:15 PM", value: "20:15" },
    { label: "8:30 PM", value: "20:30" },
    { label: "8:45 PM", value: "20:45" },
    { label: "9:00 PM", value: "21:00" },
    { label: "9:15 PM", value: "21:15" },
    { label: "9:30 PM", value: "21:30" },
    { label: "9:45 PM", value: "21:45" },
    { label: "10:00 PM", value: "22:00" },
    { label: "10:15 PM", value: "22:15" },
    { label: "10:30 PM", value: "22:30" },
    { label: "10:45 PM", value: "22:45" },
    { label: "11:00 PM", value: "23:00" },
    { label: "11:15 PM", value: "23:15" },
    { label: "11:30 PM", value: "23:30" },
    { label: "11:45 PM", value: "23:45" },

  ];

  constructor(
    private api: ApiService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private helperService: HelperService,
    private router: Router,
    private toastr: ToastrService,
    private toast: ToastrService,
  ) {
    this.user = JSON.parse(localStorage.getItem('reliant_user'));
    this.clientRegistrationForm = this.formBuilder.group({
      id: [''],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      phone: [''],
      email: ['', [Validators.required]],
      customer_id: [''],
      billing_method: [''],
      address: [''],
      address_line_2: [''],
      city: [''],
      state: [''],
      zip_code: [''],
      account_manager_id: [''],
      inside_sales_id: [''],
      outside_sales_id: [''],
      flag_id: [''],
      status_id: [''],
      type_id: [''],
    });

    this.callLogsForm = this.formBuilder.group({
      received_date: ['', [Validators.required]],
      time: ['', [Validators.required]],
      contact_id: [''],
      notes: ['', [Validators.required]],
      id: [''],
    });

    this.ContactForm = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      contact_id: [''],
      notes: [''],
      title: [''],
      phone: [''],
      phone_mobile: [''],
      phone_home: [''],
      flag_id: [''],
      status_id: [''],
      type_id: [''],
    });
    this.CommentForm = this.formBuilder.group({
      recipient: [''],
      comment: [''],
    });
  }
  init(): void {
    // this.clientRegistrationForm = this.formBuilder.group({
    //   id: [''],
    //   first_name: ['', [Validators.required]],
    //   phone: [''],
    //   caller_phone: [''],
    //   billing_email: [''],
    //   specialty: [''],
    //   marketing: [''],
    //   address_line_1: [''],
    //   address_line_2: [''],
    //   city: [''],
    //   state: [''],
    //   zip: [''],
    //   administrator_name: [''],
    //   administrator_phone: [''],
    //   administrator_alt_phone: [''],
    //   administrator_email: [''],
    //   scheduler_name: [''],
    //   scheduler_phone: [''],
    //   scheduler_alt_phone: [''],
    //   scheduler_email: [''],
    //   static_html_block: ['']
    // });
    // this.clientRegistrationForm.valueChanges.subscribe(() => {
    //   this.validateKeyValuePairs(this.clientRegistrationForm);
    // });

  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }
  formErrors = {
    'first_name': '',
    'last_name': '',
    'caller_phone': '',
    'billing_email': '',
    'specialty': '',
    'marketing': '',
    'address_line_1': '',
    'address_line_2': '',
    'city': '',
    'state': '',
    'zip': '',
    'administrator_name': '',
    'administrator_phone': '',
    'administrator_alt_phone': '',
    'administrator_email': '',
    'scheduler_name': '',
    'scheduler_phone': '',
    'scheduler_alt_phone': '',
    'scheduler_email': '',
    'static_html_block': ''

  };
  validationMessages = {
    'company_name': {
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    }
  };
  ngOnInit(): void {
    this.modelDetails = "view-follow-ups";
    this.modelName = "follow-ups";
    this.getContactById();
  }
  async getClientDetails() {
    this.api.get('admin/course_registration/get_user').subscribe((result) => {
      this.response = result;
      this.allClientDetails = this.response.data;

    }, (err) => {

    });
  }
  async getContacts() {
    this.api.get('admin/follow_ups/get_contacts').subscribe((result) => {
      this.response = result;
      this.allContacts = this.response.data;
    }, (err) => {

    });
  }
  async getRelatedCustomer() {
    this.api.get('admin/follow_ups/get_client').subscribe((result) => {
      this.response = result;
      this.allRelatedCustomer = this.response.data;
    }, (err) => {

    });
  }
  viewFollowUps(content) {
    if (content == 'details') {
      this.textMsg = 'Contact Details';
      this.activetab1 = true;
      this.activetab2 = this.activetab3 = this.activetab4 = false;
    }
    if (content == 'clients') {
      this.getClients(this.page);
      this.textMsg = 'Clients';
      this.activetab2 = true;
      this.activetab1 = this.activetab3 = this.activetab4 = false;
    }
    if (content == 'calllogs') {
      this.textMsg = 'Call Logs';
      this.activetab3 = true;
      this.activetab1 = this.activetab2 = this.activetab4 = false;
      this.getCallLogs(this.page);
    }
    if (content == 'follow-ups') {
      this.textMsg = 'Follow Ups';
      this.activetab4 = true;
      this.activetab1 = this.activetab3 = this.activetab2 = false;
    }
  }
  async getClients(page) {
    let status = 1;
    this.api.get('admin/clients/lists?status=' + status + '&page=' + page).subscribe((result) => {
      //this.totalRecordInactive = result.data.total;
      this.allClients = result.data.data;
      this.totalRecords = result.data.data;
    }, (err) => {

    });
  }
  addClientsReg(content) {
    this.clientRegistrationForm.reset();
    this.getFlag();
    this.getStatus();
    this.getType();
    this.getRelatedCustomer();
    this.getClientDetails();
    this.modalService.open(content, { size: 'xl', centered: true });
  }
  addCallLogsReg(content) {
    this.callLogsForm.reset();
    this.receivedDate = '';
    this.getContacts();
    this.modalService.open(content, { size: 'xl', centered: true });
  }
  selectRelatedCustomer(event) {

  }
  editClient(content, index): void {
    this.clientRegistrationForm.reset();
    this.editData = this.allClients[index];
    this.clientRegistrationForm.patchValue(this.editData);
    this.modalService.open(content, { size: 'xl', centered: true, scrollable: true });
  }
  deleteClientModal(content, current_row) {
    this.clientId = current_row.id;
    this.modalService.open(content, { size: 'sm', centered: true });
  }
  deleteClient() {
    let data = { id: this.clientId }
    this.api.post('admin/clients/delete', data).subscribe((result) => {
      this.response = result;
      this.toast.success(this.response.message, "Success");
      this.modalService.dismissAll();
      this.getClients(this.page);

    }, (err) => {
      this.toast.error(err.error.message, 'Error');
      this.modalService.dismissAll();
    });
  }
  submitClientData(data): void {
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/customer/create', data).subscribe((result) => {
      this.response = result;
      this.toast.success(this.response.message, "Success");
      this.getClients(this.page);
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.modalService.dismissAll();
    }, (err) => {
      this.toast.error(err.error.message, "Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });
  }
  openFlagModal(content) {
    this.flagModelRef = [];
    //this.modelName='customers';
    const modalRef = this.modalService.open(content, { size: 'xl', centered: true });
    this.flagDetails = this.editFlag;
    this.flagModelRef.push(modalRef);
    this.closeModal(modalRef);
  }
  getFlag() {
    let data = { model_name: this.modelName };
    this.api.post('admin/core_flags/all_flags', data).subscribe((result) => {
      this.response = result;
      this.flags = this.response.data;
    }, (err) => {
    });
    return true;
  }
  closeModal(modalRef) {
    modalRef.result.then((data) => {
    }, (reason) => {
      if (reason != 0 && reason != 'Cross  click') {
        this.getFlag();
        this.getStatus();
      }

    });
  }
  selectFlag(event) {
    (event != null) ? this.isSelectedFlag = true : this.isSelectedFlag = false;
    this.editFlag = this.flags.filter(function (arr) { return arr.id == event })[0];
  }
  selectStatus(event) {
    (event != null) ? this.isSelectedStatus = true : this.isSelectedStatus = false;
    this.editStatus = this.statuses.filter(function (arr) { return arr.id == event })[0];// this.statuses[event];
  }
  openStatusModal(content) {
    this.statusModelRef = [];
    //this.modelName='follow-ups';
    const modalRef = this.modalService.open(content, { size: 'xl', centered: true });
    this.statusDetails = this.editStatus;
    this.statusModelRef.push(modalRef);
    this.closeModal(modalRef);
  }
  getStatus() {
    let data = { model_name: this.modelName };
    this.api.post('admin/core_statuses/all_statuses', data).subscribe((result) => {
      this.response = result;
      this.statuses = this.response.data;
    }, (err) => {
    });
    return true;
  }
  getType() {
    let data = { model_name: this.modelName };
    this.api.post('admin/core_types/lists', data).subscribe((result) => {
      this.response = result;
      this.allTypes = this.response.data;
    }, (err) => {
    });
    return true;
  }
  getCallLogs(page) {
    this.api.get('admin/call_logs/lists?page=' + page).subscribe((result) => {
      this.response = result;
      this.totalRecords = result.data.total;
      this.allCollLogs = result.data.data;
    }, (err) => {
    });
    return true;
  }
  openContactModal(content) {
    this.ContactForm.reset();
    this.modelName = "contacts";
    this.getFlag();
    this.getStatus();
    this.getType();
    this.modalService.open(content, { size: 'xl', centered: true });
  }
  flagManagementType(link) {
    this.modalService.dismissAll();
    this.router.navigateByUrl(link);
  }
  submitCallLogsData(data) {
    let endPoint = (data.id) ? 'update' : 'create';
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/call_logs/' + endPoint, data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getCallLogs(this.page);
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.modalService.dismissAll();
      //this.closeModal(modalRef);
    }, (err) => {
      this.toastr.error(err.error.message, "Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });

  }
  submitContactData(data) {
    let endPoint = (data.id) ? 'update' : 'create';
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/contacts/' + endPoint, data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getContacts();
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      //this.modalService.dismissAll();
      //this.closeModal(modalRef);
    }, (err) => {
      this.toastr.error(err.error.message, "Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });
  }
  editCollLogsModal(content, index) {
    this.getContacts();
    this.callLogsForm.reset();
    this.editCollLogs = this.allCollLogs[index];
    this.receivedDate = this.editCollLogs.received_date.slice(0, 10)
    this.callLogsForm.patchValue(this.editCollLogs);
    this.modalService.open(content, { size: 'xl', centered: true, scrollable: true });
  }
  deleteModal(content, current_row) {
    this.call_logstid = current_row.id;
    this.modalService.open(content, { size: 'sm', centered: true });
  }
  deleteCallLogs() {
    let data = { id: this.call_logstid }
    this.api.post('admin/call_logs/delete', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getCallLogs(this.page);
      this.modalService.dismissAll();
    }, (err) => {
      console.log(err);
      this.toastr.error(err.error.message, 'Error');
      this.modalService.dismissAll();
    });
  }
  statusDeactivate() {
  }
  showActivity() {
    this.isActivity = true;
    this.isComments = false;
    this.isCommentsList = false;
  }
  showComments() {
    this.isActivity = false;
    this.isComments = false;
    this.isCommentsList = true;
    this.getComment(this.commentPage)
  }
  submitCommentData(data) {
    data.user_id = this.user.id;
    let endPoint = (data.id) ? 'update' : 'create';
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/activity_comments/' + endPoint, data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message, "Success");
      this.getComment(1);
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      //this.modalService.dismissAll();
      //this.closeModal(modalRef);
      this.isComments = false;
      this.isCommentsList = true;
    }, (err) => {
      this.toastr.error(err.error.message, "Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });
  }
  openAddComment() {
    this.CommentForm.reset()
    this.isActivity = false;
    this.isCommentsList = false;
    this.isComments = true;

  }
  cancelComment() {
    this.isComments = false;
    this.isCommentsList = true;
  }
  getComment(commentPage) {
    this.api.get('admin/activity_comments/lists?page=' + commentPage).subscribe((result) => {
      this.response = result;
      this.commentsData = result.data.data;
      this.allComments = this.allComments.concat(this.commentsData);
    }, (err) => {
    });
    return true;
  }
  loadComment() {
    this.commentPage += 1;
    this.getComment(this.commentPage);
  }

  getContactById() {
    let data = { id: 2 };
    this.api.post('admin/contacts/get_contact_by_id', data).subscribe((result) => {
      this.response = result;
      this.contactData = this.response.data;
    }, (err) => {
    });
    return true;
  }
  scrollHandler(event) {


  }

}
