import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../helper/helper.service';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-modal-flag',
  templateUrl: './modal-flag.component.html',
  styleUrls: ['./modal-flag.component.css']
})
export class ModalFlagComponent implements OnInit {

  @Input() details:any;
  @Input() flagModelRef:any;
  @Input() modelName:any;
  flagForm:FormGroup;
  isForm:boolean=false;
  icons:any=[];
  models:any=[];
  response:any=[];
  columns:any=[];
  modelNameTemp:any;
  modelNames:any=[{label: "Contacts", value: "Contacts"},{label: "Customers", value: "Customers"},{label: "Opportunities", value: "Opportunities"},{label: "follow ups", value: "follow ups"}];

  formErrors = {
    'id':'',
    'name':'',
    'message':'',
    'message_style':'',
    'icon':'',
    'icon_color':'',
    'model_name':'',
  };

  validationMessages = {
    'name': {
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    }
  };



  constructor(
    private formBuilder:FormBuilder,
    private helperIcon:HelperService,
    private api:ApiService,
    private toastr:ToastrService,
    private modalService:NgbModal,
  ) { 
    this.icons=this.helperIcon.getIocn();
  }

  ngOnInit(): void {
    this.modelNameTemp = this.modelName;
    if(this.details){
      setTimeout(()=>{
        this.flagForm.patchValue(this.details);
        },100);
    }
    this.init();
  }

  init(){
    this.flagForm = this.formBuilder.group({
      id:[''],
      name: ['', [Validators.required, Validators.minLength(3)]],
      message:[''],
      message_style:[''],
      icon:[''],
      icon_color:[''],
      model_name:['']
    });

    this.flagForm.valueChanges.subscribe(()=>{
      this.validateKeyValuePairs(this.flagForm);
    });

  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  submitFlagForm(data){
    let endPoint = (data.id)?'update':'create';
    this.api.post('admin/core_flags/'+endPoint,data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.flagModelRef[0].dismiss();
    },(err=>{
      this.toastr.error(err.error.message,"Error");
    }));
  }



}
