<div class="row">
    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Open Issues</h6>
                <div class="btn-group">
                    <button class="action-btn bg-dark-green mr-2" (click)="openIssuesForm(openIssuesFormModal)"><img
                            src="assets/img/svg-icon/add-white.svg" width="20" /></button>
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content list_table" id="ticketList">
                    <div class="tab-pane active show fade" id="active-01" role="tabpanel"
                        aria-labelledby="active-01-tab">
                        <div class="table-responsive list_table">
                            <table class="table  table-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th class="pt-0">View</th>
                                        <th class="pt-0">Type</th>
                                        <th class="pt-0">Created</th>
                                        <th class="pt-0">Status</th>
                                        <th class="pt-0">Summary</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-black"> </td>
                                        <td class="text-gray"></td>
                                        <td class="text-black"></td>
                                        <td class="text-gray"></td>
                                        <td class="text-black"></td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Closed Issues</h6>
                <div class="btn-group">
                    <!-- <button class="action-btn bg-dark-green mr-2" (click)="addClient(clientFormModal)"><img
                            src="assets/img/svg-icon/add-white.svg" width="20" /></button> -->

                </div>
            </div>
            <div class="card-body">
                <div class="tab-content list_table" id="ticketList">
                    <div class="tab-pane active show fade" id="active-01" role="tabpanel"
                        aria-labelledby="active-01-tab">
                        <div class="table-responsive list_table">
                            <!-- <table class="table  table-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th class="pt-0">Facility</th>
                                        <th class="pt-0">Scheduler</th>
                                        <th class="pt-0">Specialty</th>
                                        <th class="pt-0">Email</th>
                                        <th class="pt-0">Phone</th>
                                        <th class="pt-0">Status</th>
                                        <th class="pt-0 text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="customers.length > 0">
                                        <tr
                                            *ngFor="let customer of customers | paginate: { id: 'active_users_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalRecords }; let i=index">
                                            <td class="text-black"><a class="text-black"
                                                    [routerLink]="['/crm/customer-profile/'+customer.id]">{{customer.company_name}}</a>
                                            </td>
                                            <td class="text-gray">{{customer.scheduler_name}}</td>
                                            <td class="text-black">{{customer.specialty}}</td>
                                            <td class="text-gray">{{customer.scheduler_email}}</td>
                                            <td class="text-black">{{customer.scheduler_phone}}</td>
                                            <td class="text-danger" *ngIf="customer.active==1">Active</td>
                                            <td class="text-danger" *ngIf="customer.active==0">InActive</td>
                                            <td class="text-right">
                                                <a href="javascript:void(0)" class="mr-2 action-btn bg-edit"
                                                    title="Add Note" (click)="openNote(addNote,customer)"><img
                                                        src="assets/img/svg-icon/copy.svg" width="20" /></a>
                                                <a href="javascript:void(0)" class="action-btn bg-red" title="Delete"
                                                    (click)="deleteClient(customer)"><img
                                                        src="assets/img/svg-icon/delete.svg" width="20" /></a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            <pagination-controls class="pagination-controls-theme" id="active_users_pagination"
                                (pageChange)="getClients(page = $event)" maxSize="9" directionLinks="true"
                                autoHide="true" responsive="true">
                            </pagination-controls> -->
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- Add Client  -->

<ng-template #openIssuesFormModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <form [formGroup]="supportDeskForm" (ngSubmit)="submitSupportDeskData(supportDeskForm.value)">
            <div class="form-row">

                <div class="form-group col-md-12">

                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">New Issue</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label for="company_name"
                                        class="col-form-label text-light-black font-16">Priority</label>
                                    <ng-select placeholder="Select" clearAllText="Clear" formControlName="priority">
                                        <ng-option *ngFor="let priority of priorityData;let i = index"
                                            value="{{priority.label}}"> {{priority.label}}</ng-option>

                                    </ng-select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="company_name" class="col-form-label text-light-black font-16">Type of
                                        Request</label>
                                    <ng-select placeholder="Select" clearAllText="Clear " formControlName="type">
                                        <ng-option *ngFor="let type of typeOfRequest;let i = index"
                                            value="{{type.label}}"> {{type.label}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="company_name"
                                        class="col-form-label text-light-black font-16">Notification Email(s)</label>
                                    <input class="form-control rounded-0 form-control-md" value="{{user.name}}"
                                        type="text" formControlName="email">
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="company_name"
                                        class="col-form-label text-light-black font-16">Summary</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="summary">
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="company_name"
                                        class="col-form-label text-light-black font-16">Description</label>
                                    <textarea class="form-control" formControlName="description" cols="30"
                                        rows="7"></textarea>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Attachment (s)</label>
                                        <div class="form-group mb-20">
                                            <input type="file" fomrControlName="attachments" multiple name="attachment"
                                                (change)="onFileChange($event)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-modal-group">
                <button type="submit" [disabled]="!supportDeskForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
            </div>
        </form>
    </div>
</ng-template>