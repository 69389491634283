import { Injectable } from '@angular/core';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  getIocn() {
    return [
      { "c": "fa-500px", "l": "500px" },
      { "c": "fa-adjust", "l": "Adjust" },
      { "c": "fa-adn", "l": "Adn" },
      { "c": "fa-align-center", "l": "Align center" },
      { "c": "fa-align-justify", "l": "Align justify" },
      { "c": "fa-align-left", "l": "Align left" },
      { "c": "fa-align-right", "l": "Align right" },
      { "c": "fa-amazon", "l": "Amazon" },
      { "c": "fa-ambulance", "l": "Ambulance" },
      { "c": "fa-anchor", "l": "Anchor" },
      { "c": "fa-android", "l": "Android" },
      { "c": "fa-angellist", "l": "Angellist" },
      { "c": "fa-angle-double-down", "l": "Angle double down" },
      { "c": "fa-angle-double-left", "l": "Angle double left" },
      { "c": "fa-angle-double-right", "l": "Angle double right" },
      { "c": "fa-angle-double-up", "l": "Angle double up" },
      { "c": "fa-angle-down", "l": "Angle down" },
      { "c": "fa-angle-left", "l": "Angle left" },
      { "c": "fa-angle-right", "l": "Angle right" },
      { "c": "fa-angle-up", "l": "Angle up" },
      { "c": "fa-apple", "l": "Apple" },
      { "c": "fa-archive", "l": "Archive" },
      { "c": "fa-area-chart", "l": "Area chart" },
      { "c": "fa-arrow-circle-down", "l": "Arrow circle down" },
      { "c": "fa-arrow-circle-left", "l": "Arrow circle left" },
      { "c": "fa-arrow-circle-o-down", "l": "Arrow circle o down" },
      { "c": "fa-arrow-circle-o-left", "l": "Arrow circle o left" },
      { "c": "fa-arrow-circle-o-right", "l": "Arrow circle o right" },
      { "c": "fa-arrow-circle-o-up", "l": "Arrow circle o up" },
      { "c": "fa-arrow-circle-right", "l": "Arrow circle right" },
      { "c": "fa-arrow-circle-up", "l": "Arrow circle up" },
      { "c": "fa-arrow-down", "l": "Arrow down" },
      { "c": "fa-arrow-left", "l": "Arrow left" },
      { "c": "fa-arrow-right", "l": "Arrow right" },
      { "c": "fa-arrow-up", "l": "Arrow up" },
      { "c": "fa-arrows", "l": "Arrows" },
      { "c": "fa-arrows-alt", "l": "Arrows alt" },
      { "c": "fa-arrows-h", "l": "Arrows h" },
      { "c": "fa-arrows-v", "l": "Arrows v" },
      { "c": "fa-asterisk", "l": "Asterisk" },
      { "c": "fa-at", "l": "At" },
      { "c": "fa-backward", "l": "Backward" },
      { "c": "fa-balance-scale", "l": "Balance scale" },
      { "c": "fa-ban", "l": "Ban" },
      { "c": "fa-bar-chart", "l": "Bar chart" },
      { "c": "fa-barcode", "l": "Barcode" },
      { "c": "fa-bars", "l": "Bars" },
      { "c": "fa-battery-empty", "l": "Battery empty" },
      { "c": "fa-battery-full", "l": "Battery full" },
      { "c": "fa-battery-half", "l": "Battery half" },
      { "c": "fa-battery-quarter", "l": "Battery quarter" },
      { "c": "fa-battery-three-quarters", "l": "Battery three quarters" },
      { "c": "fa-bed", "l": "Bed" },
      { "c": "fa-beer", "l": "Beer" },
      { "c": "fa-behance", "l": "Behance" },
      { "c": "fa-behance-square", "l": "Behance square" },
      { "c": "fa-bell", "l": "Bell" },
      { "c": "fa-bell-o", "l": "Bell o" },
      { "c": "fa-bell-slash", "l": "Bell slash" },
      { "c": "fa-bell-slash-o", "l": "Bell slash o" },
      { "c": "fa-bicycle", "l": "Bicycle" },
      { "c": "fa-binoculars", "l": "Binoculars" },
      { "c": "fa-birthday-cake", "l": "Birthday cake" },
      { "c": "fa-bitbucket", "l": "Bitbucket" },
      { "c": "fa-bitbucket-square", "l": "Bitbucket square" },
      { "c": "fa-black-tie", "l": "Black tie" },
      { "c": "fa-bold", "l": "Bold" },
      { "c": "fa-bolt", "l": "Bolt" },
      { "c": "fa-bomb", "l": "Bomb" },
      { "c": "fa-book", "l": "Book" },
      { "c": "fa-bookmark", "l": "Bookmark" },
      { "c": "fa-bookmark-o", "l": "Bookmark o" },
      { "c": "fa-briefcase", "l": "Briefcase" },
      { "c": "fa-btc", "l": "Btc" },
      { "c": "fa-bug", "l": "Bug" },
      { "c": "fa-building", "l": "Building" },
      { "c": "fa-building-o", "l": "Building o" },
      { "c": "fa-bullhorn", "l": "Bullhorn" },
      { "c": "fa-bullseye", "l": "Bullseye" },
      { "c": "fa-bus", "l": "Bus" },
      { "c": "fa-buysellads", "l": "Buysellads" },
      { "c": "fa-calculator", "l": "Calculator" },
      { "c": "fa-calendar", "l": "Calendar" },
      { "c": "fa-calendar-check-o", "l": "Calendar check o" },
      { "c": "fa-calendar-minus-o", "l": "Calendar minus o" },
      { "c": "fa-calendar-o", "l": "Calendar o" },
      { "c": "fa-calendar-plus-o", "l": "Calendar plus o" },
      { "c": "fa-calendar-times-o", "l": "Calendar times o" },
      { "c": "fa-camera", "l": "Camera" },
      { "c": "fa-camera-retro", "l": "Camera retro" },
      { "c": "fa-car", "l": "Car" },
      { "c": "fa-caret-down", "l": "Caret down" },
      { "c": "fa-caret-left", "l": "Caret left" },
      { "c": "fa-caret-right", "l": "Caret right" },
      { "c": "fa-caret-square-o-down", "l": "Caret square o down" },
      { "c": "fa-caret-square-o-left", "l": "Caret square o left" },
      { "c": "fa-caret-square-o-right", "l": "Caret square o right" },
      { "c": "fa-caret-square-o-up", "l": "Caret square o up" },
      { "c": "fa-caret-up", "l": "Caret up" },
      { "c": "fa-cart-arrow-down", "l": "Cart arrow down" },
      { "c": "fa-cart-plus", "l": "Cart plus" },
      { "c": "fa-cc", "l": "Cc" },
      { "c": "fa-cc-amex", "l": "Cc amex" },
      { "c": "fa-cc-diners-club", "l": "Cc diners club" },
      { "c": "fa-cc-discover", "l": "Cc discover" },
      { "c": "fa-cc-jcb", "l": "Cc jcb" },
      { "c": "fa-cc-mastercard", "l": "Cc mastercard" },
      { "c": "fa-cc-paypal", "l": "Cc paypal" },
      { "c": "fa-cc-stripe", "l": "Cc stripe" },
      { "c": "fa-cc-visa", "l": "Cc visa" },
      { "c": "fa-certificate", "l": "Certificate" },
      { "c": "fa-chain-broken", "l": "Chain broken" },
      { "c": "fa-check", "l": "Check" },
      { "c": "fa-check-circle", "l": "Check circle" },
      { "c": "fa-check-circle-o", "l": "Check circle o" },
      { "c": "fa-check-square", "l": "Check square" },
      { "c": "fa-check-square-o", "l": "Check square o" },
      { "c": "fa-chevron-circle-down", "l": "Chevron circle down" },
      { "c": "fa-chevron-circle-left", "l": "Chevron circle left" },
      { "c": "fa-chevron-circle-right", "l": "Chevron circle right" },
      { "c": "fa-chevron-circle-up", "l": "Chevron circle up" },
      { "c": "fa-chevron-down", "l": "Chevron down" },
      { "c": "fa-chevron-left", "l": "Chevron left" },
      { "c": "fa-chevron-right", "l": "Chevron right" },
      { "c": "fa-chevron-up", "l": "Chevron up" },
      { "c": "fa-child", "l": "Child" },
      { "c": "fa-chrome", "l": "Chrome" },
      { "c": "fa-circle", "l": "Circle" },
      { "c": "fa-circle-o", "l": "Circle o" },
      { "c": "fa-circle-o-notch", "l": "Circle o notch" },
      { "c": "fa-circle-thin", "l": "Circle thin" },
      { "c": "fa-clipboard", "l": "Clipboard" },
      { "c": "fa-clock-o", "l": "Clock o" },
      { "c": "fa-clone", "l": "Clone" },
      { "c": "fa-cloud", "l": "Cloud" },
      { "c": "fa-cloud-download", "l": "Cloud download" },
      { "c": "fa-cloud-upload", "l": "Cloud upload" },
      { "c": "fa-code", "l": "Code" },
      { "c": "fa-code-fork", "l": "Code fork" },
      { "c": "fa-codepen", "l": "Codepen" },
      { "c": "fa-coffee", "l": "Coffee" },
      { "c": "fa-cog", "l": "Cog" },
      { "c": "fa-cogs", "l": "Cogs" },
      { "c": "fa-columns", "l": "Columns" },
      { "c": "fa-comment", "l": "Comment" },
      { "c": "fa-comment-o", "l": "Comment o" },
      { "c": "fa-commenting", "l": "Commenting" },
      { "c": "fa-commenting-o", "l": "Commenting o" },
      { "c": "fa-comments", "l": "Comments" },
      { "c": "fa-comments-o", "l": "Comments o" },
      { "c": "fa-compass", "l": "Compass" },
      { "c": "fa-compress", "l": "Compress" },
      { "c": "fa-connectdevelop", "l": "Connectdevelop" },
      { "c": "fa-contao", "l": "Contao" },
      { "c": "fa-copyright", "l": "Copyright" },
      { "c": "fa-creative-commons", "l": "Creative commons" },
      { "c": "fa-credit-card", "l": "Credit card" },
      { "c": "fa-crop", "l": "Crop" },
      { "c": "fa-crosshairs", "l": "Crosshairs" },
      { "c": "fa-css3", "l": "Css3" },
      { "c": "fa-cube", "l": "Cube" },
      { "c": "fa-cubes", "l": "Cubes" },
      { "c": "fa-cutlery", "l": "Cutlery" },
      { "c": "fa-dashcube", "l": "Dashcube" },
      { "c": "fa-database", "l": "Database" },
      { "c": "fa-delicious", "l": "Delicious" },
      { "c": "fa-desktop", "l": "Desktop" },
      { "c": "fa-deviantart", "l": "Deviantart" },
      { "c": "fa-diamond", "l": "Diamond" },
      { "c": "fa-digg", "l": "Digg" },
      { "c": "fa-dot-circle-o", "l": "Dot circle o" },
      { "c": "fa-download", "l": "Download" },
      { "c": "fa-dribbble", "l": "Dribbble" },
      { "c": "fa-dropbox", "l": "Dropbox" },
      { "c": "fa-drupal", "l": "Drupal" },
      { "c": "fa-eject", "l": "Eject" },
      { "c": "fa-ellipsis-h", "l": "Ellipsis h" },
      { "c": "fa-ellipsis-v", "l": "Ellipsis v" },
      { "c": "fa-empire", "l": "Empire" },
      { "c": "fa-envelope", "l": "Envelope" },
      { "c": "fa-envelope-o", "l": "Envelope o" },
      { "c": "fa-envelope-square", "l": "Envelope square" },
      { "c": "fa-eraser", "l": "Eraser" },
      { "c": "fa-eur", "l": "Eur" },
      { "c": "fa-exchange", "l": "Exchange" },
      { "c": "fa-exclamation", "l": "Exclamation" },
      { "c": "fa-exclamation-circle", "l": "Exclamation circle" },
      { "c": "fa-exclamation-triangle", "l": "Exclamation triangle" },
      { "c": "fa-expand", "l": "Expand" },
      { "c": "fa-expeditedssl", "l": "Expeditedssl" },
      { "c": "fa-external-link", "l": "External link" },
      { "c": "fa-external-link-square", "l": "External link square" },
      { "c": "fa-eye", "l": "Eye" },
      { "c": "fa-eye-slash", "l": "Eye slash" },
      { "c": "fa-eyedropper", "l": "Eyedropper" },
      { "c": "fa-facebook", "l": "Facebook" },
      { "c": "fa-facebook-official", "l": "Facebook official" },
      { "c": "fa-facebook-square", "l": "Facebook square" },
      { "c": "fa-fast-backward", "l": "Fast backward" },
      { "c": "fa-fast-forward", "l": "Fast forward" },
      { "c": "fa-fax", "l": "Fax" },
      { "c": "fa-female", "l": "Female" },
      { "c": "fa-fighter-jet", "l": "Fighter jet" },
      { "c": "fa-file", "l": "File" },
      { "c": "fa-file-archive-o", "l": "File archive o" },
      { "c": "fa-file-audio-o", "l": "File audio o" },
      { "c": "fa-file-code-o", "l": "File code o" },
      { "c": "fa-file-excel-o", "l": "File excel o" },
      { "c": "fa-file-image-o", "l": "File image o" },
      { "c": "fa-file-o", "l": "File o" },
      { "c": "fa-file-pdf-o", "l": "File pdf o" },
      { "c": "fa-file-powerpoint-o", "l": "File powerpoint o" },
      { "c": "fa-file-text", "l": "File text" },
      { "c": "fa-file-text-o", "l": "File text o" },
      { "c": "fa-file-video-o", "l": "File video o" },
      { "c": "fa-file-word-o", "l": "File word o" },
      { "c": "fa-files-o", "l": "Files o" },
      { "c": "fa-film", "l": "Film" },
      { "c": "fa-filter", "l": "Filter" },
      { "c": "fa-fire", "l": "Fire" },
      { "c": "fa-fire-extinguisher", "l": "Fire extinguisher" },
      { "c": "fa-firefox", "l": "Firefox" },
      { "c": "fa-flag", "l": "Flag" },
      { "c": "fa-flag-checkered", "l": "Flag checkered" },
      { "c": "fa-flag-o", "l": "Flag o" },
      { "c": "fa-flask", "l": "Flask" },
      { "c": "fa-flickr", "l": "Flickr" },
      { "c": "fa-floppy-o", "l": "Floppy o" },
      { "c": "fa-folder", "l": "Folder" },
      { "c": "fa-folder-o", "l": "Folder o" },
      { "c": "fa-folder-open", "l": "Folder open" },
      { "c": "fa-folder-open-o", "l": "Folder open o" },
      { "c": "fa-font", "l": "Font" },
      { "c": "fa-fonticons", "l": "Fonticons" },
      { "c": "fa-forumbee", "l": "Forumbee" },
      { "c": "fa-forward", "l": "Forward" },
      { "c": "fa-foursquare", "l": "Foursquare" },
      { "c": "fa-frown-o", "l": "Frown o" },
      { "c": "fa-futbol-o", "l": "Futbol o" },
      { "c": "fa-gamepad", "l": "Gamepad" },
      { "c": "fa-gavel", "l": "Gavel" },
      { "c": "fa-gbp", "l": "Gbp" },
      { "c": "fa-genderless", "l": "Genderless" },
      { "c": "fa-get-pocket", "l": "Get pocket" },
      { "c": "fa-gg", "l": "Gg" },
      { "c": "fa-gg-circle", "l": "Gg circle" },
      { "c": "fa-gift", "l": "Gift" },
      { "c": "fa-git", "l": "Git" },
      { "c": "fa-git-square", "l": "Git square" },
      { "c": "fa-github", "l": "Github" },
      { "c": "fa-github-alt", "l": "Github alt" },
      { "c": "fa-github-square", "l": "Github square" },
      { "c": "fa-glass", "l": "Glass" },
      { "c": "fa-globe", "l": "Globe" },
      { "c": "fa-google", "l": "Google" },
      { "c": "fa-google-plus", "l": "Google plus" },
      { "c": "fa-google-plus-square", "l": "Google plus square" },
      { "c": "fa-google-wallet", "l": "Google wallet" },
      { "c": "fa-graduation-cap", "l": "Graduation cap" },
      { "c": "fa-gratipay", "l": "Gratipay" },
      { "c": "fa-h-square", "l": "H square" },
      { "c": "fa-hacker-news", "l": "Hacker news" },
      { "c": "fa-hand-lizard-o", "l": "Hand lizard o" },
      { "c": "fa-hand-o-down", "l": "Hand o down" },
      { "c": "fa-hand-o-left", "l": "Hand o left" },
      { "c": "fa-hand-o-right", "l": "Hand o right" },
      { "c": "fa-hand-o-up", "l": "Hand o up" },
      { "c": "fa-hand-paper-o", "l": "Hand paper o" },
      { "c": "fa-hand-peace-o", "l": "Hand peace o" },
      { "c": "fa-hand-pointer-o", "l": "Hand pointer o" },
      { "c": "fa-hand-rock-o", "l": "Hand rock o" },
      { "c": "fa-hand-scissors-o", "l": "Hand scissors o" },
      { "c": "fa-hand-spock-o", "l": "Hand spock o" },
      { "c": "fa-hdd-o", "l": "Hdd o" },
      { "c": "fa-header", "l": "Header" },
      { "c": "fa-headphones", "l": "Headphones" },
      { "c": "fa-heart", "l": "Heart" },
      { "c": "fa-heart-o", "l": "Heart o" },
      { "c": "fa-heartbeat", "l": "Heartbeat" },
      { "c": "fa-history", "l": "History" },
      { "c": "fa-home", "l": "Home" },
      { "c": "fa-hospital-o", "l": "Hospital o" },
      { "c": "fa-hourglass", "l": "Hourglass" },
      { "c": "fa-hourglass-end", "l": "Hourglass end" },
      { "c": "fa-hourglass-half", "l": "Hourglass half" },
      { "c": "fa-hourglass-o", "l": "Hourglass o" },
      { "c": "fa-hourglass-start", "l": "Hourglass start" },
      { "c": "fa-houzz", "l": "Houzz" },
      { "c": "fa-html5", "l": "Html5" },
      { "c": "fa-i-cursor", "l": "I cursor" },
      { "c": "fa-ils", "l": "Ils" },
      { "c": "fa-inbox", "l": "Inbox" },
      { "c": "fa-indent", "l": "Indent" },
      { "c": "fa-industry", "l": "Industry" },
      { "c": "fa-info", "l": "Info" },
      { "c": "fa-info-circle", "l": "Info circle" },
      { "c": "fa-inr", "l": "Inr" },
      { "c": "fa-instagram", "l": "Instagram" },
      { "c": "fa-internet-explorer", "l": "Internet explorer" },
      { "c": "fa-ioxhost", "l": "Ioxhost" },
      { "c": "fa-italic", "l": "Italic" },
      { "c": "fa-joomla", "l": "Joomla" },
      { "c": "fa-jpy", "l": "Jpy" },
      { "c": "fa-jsfiddle", "l": "Jsfiddle" },
      { "c": "fa-key", "l": "Key" },
      { "c": "fa-keyboard-o", "l": "Keyboard o" },
      { "c": "fa-krw", "l": "Krw" },
      { "c": "fa-language", "l": "Language" },
      { "c": "fa-laptop", "l": "Laptop" },
      { "c": "fa-lastfm", "l": "Lastfm" },
      { "c": "fa-lastfm-square", "l": "Lastfm square" },
      { "c": "fa-leaf", "l": "Leaf" },
      { "c": "fa-leanpub", "l": "Leanpub" },
      { "c": "fa-lemon-o", "l": "Lemon o" },
      { "c": "fa-level-down", "l": "Level down" },
      { "c": "fa-level-up", "l": "Level up" },
      { "c": "fa-life-ring", "l": "Life ring" },
      { "c": "fa-lightbulb-o", "l": "Lightbulb o" },
      { "c": "fa-line-chart", "l": "Line chart" },
      { "c": "fa-link", "l": "Link" },
      { "c": "fa-linkedin", "l": "Linkedin" },
      { "c": "fa-linkedin-square", "l": "Linkedin square" },
      { "c": "fa-linux", "l": "Linux" },
      { "c": "fa-list", "l": "List" },
      { "c": "fa-list-alt", "l": "List alt" },
      { "c": "fa-list-ol", "l": "List ol" },
      { "c": "fa-list-ul", "l": "List ul" },
      { "c": "fa-location-arrow", "l": "Location arrow" },
      { "c": "fa-lock", "l": "Lock" },
      { "c": "fa-long-arrow-down", "l": "Long arrow down" },
      { "c": "fa-long-arrow-left", "l": "Long arrow left" },
      { "c": "fa-long-arrow-right", "l": "Long arrow right" },
      { "c": "fa-long-arrow-up", "l": "Long arrow up" },
      { "c": "fa-magic", "l": "Magic" },
      { "c": "fa-magnet", "l": "Magnet" },
      { "c": "fa-male", "l": "Male" },
      { "c": "fa-map", "l": "Map" },
      { "c": "fa-map-marker", "l": "Map marker" },
      { "c": "fa-map-o", "l": "Map o" },
      { "c": "fa-map-pin", "l": "Map pin" },
      { "c": "fa-map-signs", "l": "Map signs" },
      { "c": "fa-mars", "l": "Mars" },
      { "c": "fa-mars-double", "l": "Mars double" },
      { "c": "fa-mars-stroke", "l": "Mars stroke" },
      { "c": "fa-mars-stroke-h", "l": "Mars stroke h" },
      { "c": "fa-mars-stroke-v", "l": "Mars stroke v" },
      { "c": "fa-maxcdn", "l": "Maxcdn" },
      { "c": "fa-meanpath", "l": "Meanpath" },
      { "c": "fa-medium", "l": "Medium" },
      { "c": "fa-medkit", "l": "Medkit" },
      { "c": "fa-meh-o", "l": "Meh o" },
      { "c": "fa-mercury", "l": "Mercury" },
      { "c": "fa-microphone", "l": "Microphone" },
      { "c": "fa-microphone-slash", "l": "Microphone slash" },
      { "c": "fa-minus", "l": "Minus" },
      { "c": "fa-minus-circle", "l": "Minus circle" },
      { "c": "fa-minus-square", "l": "Minus square" },
      { "c": "fa-minus-square-o", "l": "Minus square o" },
      { "c": "fa-mobile", "l": "Mobile" },
      { "c": "fa-money", "l": "Money" },
      { "c": "fa-moon-o", "l": "Moon o" },
      { "c": "fa-motorcycle", "l": "Motorcycle" },
      { "c": "fa-mouse-pointer", "l": "Mouse pointer" },
      { "c": "fa-music", "l": "Music" },
      { "c": "fa-neuter", "l": "Neuter" },
      { "c": "fa-newspaper-o", "l": "Newspaper o" },
      { "c": "fa-object-group", "l": "Object group" },
      { "c": "fa-object-ungroup", "l": "Object ungroup" },
      { "c": "fa-odnoklassniki", "l": "Odnoklassniki" },
      { "c": "fa-odnoklassniki-square", "l": "Odnoklassniki square" },
      { "c": "fa-opencart", "l": "Opencart" },
      { "c": "fa-openid", "l": "Openid" },
      { "c": "fa-opera", "l": "Opera" },
      { "c": "fa-optin-monster", "l": "Optin monster" },
      { "c": "fa-outdent", "l": "Outdent" },
      { "c": "fa-pagelines", "l": "Pagelines" },
      { "c": "fa-paint-brush", "l": "Paint brush" },
      { "c": "fa-paper-plane", "l": "Paper plane" },
      { "c": "fa-paper-plane-o", "l": "Paper plane o" },
      { "c": "fa-paperclip", "l": "Paperclip" },
      { "c": "fa-paragraph", "l": "Paragraph" },
      { "c": "fa-pause", "l": "Pause" },
      { "c": "fa-paw", "l": "Paw" },
      { "c": "fa-paypal", "l": "Paypal" },
      { "c": "fa-pencil", "l": "Pencil" },
      { "c": "fa-pencil-square", "l": "Pencil square" },
      { "c": "fa-pencil-square-o", "l": "Pencil square o" },
      { "c": "fa-phone", "l": "Phone" },
      { "c": "fa-phone-square", "l": "Phone square" },
      { "c": "fa-picture-o", "l": "Picture o" },
      { "c": "fa-pie-chart", "l": "Pie chart" },
      { "c": "fa-pied-piper", "l": "Pied piper" },
      { "c": "fa-pied-piper-alt", "l": "Pied piper alt" },
      { "c": "fa-pinterest", "l": "Pinterest" },
      { "c": "fa-pinterest-p", "l": "Pinterest p" },
      { "c": "fa-pinterest-square", "l": "Pinterest square" },
      { "c": "fa-plane", "l": "Plane" },
      { "c": "fa-play", "l": "Play" },
      { "c": "fa-play-circle", "l": "Play circle" },
      { "c": "fa-play-circle-o", "l": "Play circle o" },
      { "c": "fa-plug", "l": "Plug" },
      { "c": "fa-plus", "l": "Plus" },
      { "c": "fa-plus-circle", "l": "Plus circle" },
      { "c": "fa-plus-square", "l": "Plus square" },
      { "c": "fa-plus-square-o", "l": "Plus square o" },
      { "c": "fa-power-off", "l": "Power off" },
      { "c": "fa-print", "l": "Print" },
      { "c": "fa-puzzle-piece", "l": "Puzzle piece" },
      { "c": "fa-qq", "l": "Qq" },
      { "c": "fa-qrcode", "l": "Qrcode" },
      { "c": "fa-question", "l": "Question" },
      { "c": "fa-question-circle", "l": "Question circle" },
      { "c": "fa-quote-left", "l": "Quote left" },
      { "c": "fa-quote-right", "l": "Quote right" },
      { "c": "fa-random", "l": "Random" },
      { "c": "fa-rebel", "l": "Rebel" },
      { "c": "fa-recycle", "l": "Recycle" },
      { "c": "fa-reddit", "l": "Reddit" },
      { "c": "fa-reddit-square", "l": "Reddit square" },
      { "c": "fa-refresh", "l": "Refresh" },
      { "c": "fa-registered", "l": "Registered" },
      { "c": "fa-renren", "l": "Renren" },
      { "c": "fa-repeat", "l": "Repeat" },
      { "c": "fa-reply", "l": "Reply" },
      { "c": "fa-reply-all", "l": "Reply all" },
      { "c": "fa-retweet", "l": "Retweet" },
      { "c": "fa-road", "l": "Road" },
      { "c": "fa-rocket", "l": "Rocket" },
      { "c": "fa-rss", "l": "Rss" },
      { "c": "fa-rss-square", "l": "Rss square" },
      { "c": "fa-rub", "l": "Rub" },
      { "c": "fa-safari", "l": "Safari" },
      { "c": "fa-scissors", "l": "Scissors" },
      { "c": "fa-search", "l": "Search" },
      { "c": "fa-search-minus", "l": "Search minus" },
      { "c": "fa-search-plus", "l": "Search plus" },
      { "c": "fa-sellsy", "l": "Sellsy" },
      { "c": "fa-server", "l": "Server" },
      { "c": "fa-share", "l": "Share" },
      { "c": "fa-share-alt", "l": "Share alt" },
      { "c": "fa-share-alt-square", "l": "Share alt square" },
      { "c": "fa-share-square", "l": "Share square" },
      { "c": "fa-share-square-o", "l": "Share square o" },
      { "c": "fa-shield", "l": "Shield" },
      { "c": "fa-ship", "l": "Ship" },
      { "c": "fa-shirtsinbulk", "l": "Shirtsinbulk" },
      { "c": "fa-shopping-cart", "l": "Shopping cart" },
      { "c": "fa-sign-in", "l": "Sign in" },
      { "c": "fa-sign-out", "l": "Sign out" },
      { "c": "fa-signal", "l": "Signal" },
      { "c": "fa-simplybuilt", "l": "Simplybuilt" },
      { "c": "fa-sitemap", "l": "Sitemap" },
      { "c": "fa-skyatlas", "l": "Skyatlas" },
      { "c": "fa-skype", "l": "Skype" },
      { "c": "fa-slack", "l": "Slack" },
      { "c": "fa-sliders", "l": "Sliders" },
      { "c": "fa-slideshare", "l": "Slideshare" },
      { "c": "fa-smile-o", "l": "Smile o" },
      { "c": "fa-sort", "l": "Sort" },
      { "c": "fa-sort-alpha-asc", "l": "Sort alpha asc" },
      { "c": "fa-sort-alpha-desc", "l": "Sort alpha desc" },
      { "c": "fa-sort-amount-asc", "l": "Sort amount asc" },
      { "c": "fa-sort-amount-desc", "l": "Sort amount desc" },
      { "c": "fa-sort-asc", "l": "Sort asc" },
      { "c": "fa-sort-desc", "l": "Sort desc" },
      { "c": "fa-sort-numeric-asc", "l": "Sort numeric asc" },
      { "c": "fa-sort-numeric-desc", "l": "Sort numeric desc" },
      { "c": "fa-soundcloud", "l": "Soundcloud" },
      { "c": "fa-space-shuttle", "l": "Space shuttle" },
      { "c": "fa-spinner", "l": "Spinner" },
      { "c": "fa-spoon", "l": "Spoon" },
      { "c": "fa-spotify", "l": "Spotify" },
      { "c": "fa-square", "l": "Square" },
      { "c": "fa-square-o", "l": "Square o" },
      { "c": "fa-stack-exchange", "l": "Stack exchange" },
      { "c": "fa-stack-overflow", "l": "Stack overflow" },
      { "c": "fa-star", "l": "Star" },
      { "c": "fa-star-half", "l": "Star half" },
      { "c": "fa-star-half-o", "l": "Star half o" },
      { "c": "fa-star-o", "l": "Star o" },
      { "c": "fa-steam", "l": "Steam" },
      { "c": "fa-steam-square", "l": "Steam square" },
      { "c": "fa-step-backward", "l": "Step backward" },
      { "c": "fa-step-forward", "l": "Step forward" },
      { "c": "fa-stethoscope", "l": "Stethoscope" },
      { "c": "fa-sticky-note", "l": "Sticky note" },
      { "c": "fa-sticky-note-o", "l": "Sticky note o" },
      { "c": "fa-stop", "l": "Stop" },
      { "c": "fa-street-view", "l": "Street view" },
      { "c": "fa-strikethrough", "l": "Strikethrough" },
      { "c": "fa-stumbleupon", "l": "Stumbleupon" },
      { "c": "fa-stumbleupon-circle", "l": "Stumbleupon circle" },
      { "c": "fa-subscript", "l": "Subscript" },
      { "c": "fa-subway", "l": "Subway" },
      { "c": "fa-suitcase", "l": "Suitcase" },
      { "c": "fa-sun-o", "l": "Sun o" },
      { "c": "fa-superscript", "l": "Superscript" },
      { "c": "fa-table", "l": "Table" },
      { "c": "fa-tablet", "l": "Tablet" },
      { "c": "fa-tachometer", "l": "Tachometer" },
      { "c": "fa-tag", "l": "Tag" },
      { "c": "fa-tags", "l": "Tags" },
      { "c": "fa-tasks", "l": "Tasks" },
      { "c": "fa-taxi", "l": "Taxi" },
      { "c": "fa-television", "l": "Television" },
      { "c": "fa-tencent-weibo", "l": "Tencent weibo" },
      { "c": "fa-terminal", "l": "Terminal" },
      { "c": "fa-text-height", "l": "Text height" },
      { "c": "fa-text-width", "l": "Text width" },
      { "c": "fa-th", "l": "Th" },
      { "c": "fa-th-large", "l": "Th large" },
      { "c": "fa-th-list", "l": "Th list" },
      { "c": "fa-thumb-tack", "l": "Thumb tack" },
      { "c": "fa-thumbs-down", "l": "Thumbs down" },
      { "c": "fa-thumbs-o-down", "l": "Thumbs o down" },
      { "c": "fa-thumbs-o-up", "l": "Thumbs o up" },
      { "c": "fa-thumbs-up", "l": "Thumbs up" },
      { "c": "fa-ticket", "l": "Ticket" },
      { "c": "fa-times", "l": "Times" },
      { "c": "fa-times-circle", "l": "Times circle" },
      { "c": "fa-times-circle-o", "l": "Times circle o" },
      { "c": "fa-tint", "l": "Tint" },
      { "c": "fa-toggle-off", "l": "Toggle off" },
      { "c": "fa-toggle-on", "l": "Toggle on" },
      { "c": "fa-trademark", "l": "Trademark" },
      { "c": "fa-train", "l": "Train" },
      { "c": "fa-transgender", "l": "Transgender" },
      { "c": "fa-transgender-alt", "l": "Transgender alt" },
      { "c": "fa-trash", "l": "Trash" },
      { "c": "fa-trash-o", "l": "Trash o" },
      { "c": "fa-tree", "l": "Tree" },
      { "c": "fa-trello", "l": "Trello" },
      { "c": "fa-tripadvisor", "l": "Tripadvisor" },
      { "c": "fa-trophy", "l": "Trophy" },
      { "c": "fa-truck", "l": "Truck" },
      { "c": "fa-try", "l": "Try" },
      { "c": "fa-tty", "l": "Tty" },
      { "c": "fa-tumblr", "l": "Tumblr" },
      { "c": "fa-tumblr-square", "l": "Tumblr square" },
      { "c": "fa-twitch", "l": "Twitch" },
      { "c": "fa-twitter", "l": "Twitter" },
      { "c": "fa-twitter-square", "l": "Twitter square" },
      { "c": "fa-umbrella", "l": "Umbrella" },
      { "c": "fa-underline", "l": "Underline" },
      { "c": "fa-undo", "l": "Undo" },
      { "c": "fa-university", "l": "University" },
      { "c": "fa-unlock", "l": "Unlock" },
      { "c": "fa-unlock-alt", "l": "Unlock alt" },
      { "c": "fa-upload", "l": "Upload" },
      { "c": "fa-usd", "l": "Usd" },
      { "c": "fa-user", "l": "User" },
      { "c": "fa-user-md", "l": "User md" },
      { "c": "fa-user-plus", "l": "User plus" },
      { "c": "fa-user-secret", "l": "User secret" },
      { "c": "fa-user-times", "l": "User times" },
      { "c": "fa-users", "l": "Users" },
      { "c": "fa-venus", "l": "Venus" },
      { "c": "fa-venus-double", "l": "Venus double" },
      { "c": "fa-venus-mars", "l": "Venus mars" },
      { "c": "fa-viacoin", "l": "Viacoin" },
      { "c": "fa-video-camera", "l": "Video camera" },
      { "c": "fa-vimeo", "l": "Vimeo" },
      { "c": "fa-vimeo-square", "l": "Vimeo square" },
      { "c": "fa-vine", "l": "Vine" },
      { "c": "fa-vk", "l": "Vk" },
      { "c": "fa-volume-down", "l": "Volume down" },
      { "c": "fa-volume-off", "l": "Volume off" },
      { "c": "fa-volume-up", "l": "Volume up" },
      { "c": "fa-weibo", "l": "Weibo" },
      { "c": "fa-weixin", "l": "Weixin" },
      { "c": "fa-whatsapp", "l": "Whatsapp" },
      { "c": "fa-wheelchair", "l": "Wheelchair" },
      { "c": "fa-wifi", "l": "Wifi" },
      { "c": "fa-wikipedia-w", "l": "Wikipedia w" },
      { "c": "fa-windows", "l": "Windows" },
      { "c": "fa-wordpress", "l": "Wordpress" },
      { "c": "fa-wrench", "l": "Wrench" },
      { "c": "fa-xing", "l": "Xing" },
      { "c": "fa-xing-square", "l": "Xing square" },
      { "c": "fa-y-combinator", "l": "Y combinator" },
      { "c": "fa-yahoo", "l": "Yahoo" },
      { "c": "fa-yelp", "l": "Yelp" },
      { "c": "fa-youtube", "l": "Youtube" },
      { "c": "fa-youtube-play", "l": "Youtube play" },
      { "c": "fa-youtube-square", "l": "Youtube square" }
    ];
  }

  getState() {
    return [
      {
        'short': 'AL',
        'long': 'Alabama'
      },
      {
        'short': 'AK',
        'long': 'Alaska'
      },
      {
        'short': 'AZ',
        'long': 'Arizona'
      },
      {
        'short': 'AR',
        'long': 'Arkansas'
      },
      {
        'short': 'CA',
        'long': 'California'
      },
      {
        'short': 'AO',
        'long': 'Colorado'
      },
      {
        'short': 'CT',
        'long': 'Connecticut'
      },
      {
        'short': 'DE',
        'long': 'Delaware'
      },
      {
        'short': 'FL',
        'long': 'Florida'
      },
      {
        'short': 'GA',
        'long': 'Georgia'
      },
      {
        'short': 'HI',
        'long': 'Hawaii'
      },
      {
        'short': 'ID',
        'long': 'Idaho'
      },
      {
        'short': 'IL',
        'long': 'Illinois'
      },
      {
        'short': 'IN',
        'long': 'Indiana'
      },
      {
        'short': 'IA',
        'long': 'Iowa'
      },
      {
        'short': 'KS',
        'long': 'Kansas'
      },
      {
        'short': 'KY',
        'long': 'Kentucky'
      },
      {
        'short': 'LA',
        'long': 'Louisiana'
      },
      {
        'short': 'ME',
        'long': 'Maine'
      },
      {
        'short': 'MD',
        'long': 'Maryland'
      },
      {
        'short': 'MA',
        'long': 'Massachusetts'
      },
      {
        'short': 'MI',
        'long': 'Michigan'
      },
      {
        'short': 'MN',
        'long': 'Minnesota'
      },
      {
        'short': 'MS',
        'long': 'Mississippi'
      },
      {
        'short': 'MO',
        'long': 'Missouri'
      },
      {
        'short': 'MT',
        'long': 'Montana'
      },
      {
        'short': 'NE',
        'long': 'Nebraska'
      },
      {
        'short': 'NV',
        'long': 'Nevada'
      },
      {
        'short': 'NH',
        'long': 'New Hampshire'
      },
      {
        'short': 'NJ',
        'long': 'New Jersey'
      },
      {
        'short': 'NM',
        'long': 'New Mexico'
      },
      {
        'short': 'NY',
        'long': 'New York'
      },
      {
        'short': 'NC',
        'long': 'North Carolina'
      },
      {
        'short': 'ND',
        'long': 'North Dakota'
      },
      {
        'short': 'OH',
        'long': 'Ohio'
      },
      {
        'short': 'OK',
        'long': 'Oklahoma'
      },
      {
        'short': 'OR',
        'long': 'Oregon'
      },
      {
        'short': 'PA',
        'long': 'Pennsylvania'
      },
      {
        'short': 'RI',
        'long': 'Rhode Island'
      },
      {
        'short': 'SC',
        'long': 'South Carolina'
      },
      {
        'short': 'SD',
        'long': 'South Dakota'
      },
      {
        'short': 'TN',
        'long': 'Tennessee'
      },
      {
        'short': 'TX',
        'long': 'Texas'
      },
      {
        'short': 'UT',
        'long': 'Utah'
      },
      {
        'short': 'VT',
        'long': 'Vermont'
      },
      {
        'short': 'VA',
        'long': 'Virginia'
      },
      {
        'short': 'WA',
        'long': 'Washington'
      },
      {
        'short': 'DC',
        'long': 'Washington D.C.'
      },
      {
        'short': 'WV',
        'long': 'West Virginia'
      },
      {
        'short': 'WI',
        'long': 'Wisconsin'
      },
      {
        'short': 'WY',
        'long': 'Wyoming'
      }
    ];
  }

  getScheduleTime(interval) {
    //Data
    let x = {
      slotInterval: interval,
      openTime: '00:00',
      closeTime: '24:00'
    };

    //Format the time
    let startTime = moment(x.openTime, "HH:mm a");

    //Format the end time and the next day to it 
    let endTime = moment(x.closeTime, "HH:mm a");

    //Times
    let allTimes = [];

    //Loop over the times - only pushes time with 30 minutes interval
    while (startTime < endTime) {
      //Push times
      allTimes.push(startTime.format("hh:mm A"));
      //Add interval of 30 minutes
      startTime.add(x.slotInterval, 'minutes');
    }

    return allTimes;

  }
  getTime() {
    return [
      { label: "12:00 AM", value: "0:00" },
      { label: "12:15 AM", value: "0:15" },
      { label: "12:30 AM", value: "0:30" },
      { label: "12:45 AM", value: "0:45" },
      { label: "1:00 AM", value: "1:00" },
      { label: "1:15 AM", value: "1:15" },
      { label: "1:30 AM", value: "1:30" },
      { label: "1:45 AM", value: "1:45" },
      { label: "2:00 AM", value: "2:00" },
      { label: "2:15 AM", value: "2:15" },
      { label: "2:30 AM", value: "2:30" },
      { label: "2:45 AM", value: "2:45" },
      { label: "3:00 AM", value: "3:00" },
      { label: "3:15 AM", value: "3:15" },
      { label: "3:30 AM", value: "3:30" },
      { label: "3:45 AM", value: "3:45" },
      { label: "4:00 AM", value: "4:00" },
      { label: "4:15 AM", value: "4:15" },
      { label: "4:30 AM", value: "4:30" },
      { label: "4:45 AM", value: "4:45" },
      { label: "5:00 AM", value: "5:00" },
      { label: "5:15 AM", value: "5:15" },
      { label: "5:30 AM", value: "5:30" },
      { label: "5:45 AM", value: "5:45" },
      { label: "6:00 AM", value: "6:00" },
      { label: "6:15 AM", value: "6:15" },
      { label: "6:30 AM", value: "6:30" },
      { label: "6:45 AM", value: "6:45" },
      { label: "7:00 AM", value: "7:00" },
      { label: "7:15 AM", value: "7:15" },
      { label: "7:30 AM", value: "7:30" },
      { label: "7:45 AM", value: "7:45" },
      { label: "8:00 AM", value: "8:00" },
      { label: "8:15 AM", value: "8:15" },
      { label: "8:30 AM", value: "8:30" },
      { label: "8:45 AM", value: "8:45" },
      { label: "9:00 AM", value: "9:00" },
      { label: "9:15 AM", value: "9:15" },
      { label: "9:30 AM", value: "9:30" },
      { label: "9:45 AM", value: "9:45" },
      { label: "10:00 AM", value: "10:00" },
      { label: "10:15 AM", value: "10:15" },
      { label: "10:30 AM", value: "10:30" },
      { label: "10:45 AM", value: "10:45" },
      { label: "11:00 AM", value: "11:00" },
      { label: "11:15 AM", value: "11:15" },
      { label: "11:30 AM", value: "11:30" },
      { label: "11:45 AM", value: "11:45" },
      { label: "12:00 PM", value: "12:00" },
      { label: "12:15 PM", value: "12:15" },
      { label: "12:30 PM", value: "12:30" },
      { label: "12:45 PM", value: "12:45" },
      { label: "1:00 PM", value: "13:00" },
      { label: "1:15 PM", value: "13:15" },
      { label: "1:30 PM", value: "13:30" },
      { label: "1:45 PM", value: "13:45" },
      { label: "2:00 PM", value: "14:00" },
      { label: "2:15 PM", value: "14:15" },
      { label: "2:30 PM", value: "14:30" },
      { label: "2:45 PM", value: "14:45" },
      { label: "3:00 PM", value: "15:00" },
      { label: "3:15 PM", value: "15:15" },
      { label: "3:30 PM", value: "15:30" },
      { label: "3:45 PM", value: "15:45" },
      { label: "4:00 PM", value: "16:00" },
      { label: "4:15 PM", value: "16:15" },
      { label: "4:30 PM", value: "16:30" },
      { label: "4:45 PM", value: "16:45" },
      { label: "5:00 PM", value: "17:00" },
      { label: "5:15 PM", value: "17:15" },
      { label: "5:30 PM", value: "17:30" },
      { label: "5:45 PM", value: "17:45" },
      { label: "6:00 PM", value: "18:00" },
      { label: "6:15 PM", value: "18:15" },
      { label: "6:30 PM", value: "18:30" },
      { label: "6:45 PM", value: "18:45" },
      { label: "7:00 PM", value: "19:00" },
      { label: "7:15 PM", value: "19:15" },
      { label: "7:30 PM", value: "19:30" },
      { label: "7:45 PM", value: "19:45" },
      { label: "8:00 PM", value: "20:00" },
      { label: "8:15 PM", value: "20:15" },
      { label: "8:30 PM", value: "20:30" },
      { label: "8:45 PM", value: "20:45" },
      { label: "9:00 PM", value: "21:00" },
      { label: "9:15 PM", value: "21:15" },
      { label: "9:30 PM", value: "21:30" },
      { label: "9:45 PM", value: "21:45" },
      { label: "10:00 PM", value: "22:00" },
      { label: "10:15 PM", value: "22:15" },
      { label: "10:30 PM", value: "22:30" },
      { label: "10:45 PM", value: "22:45" },
      { label: "11:00 PM", value: "23:00" },
      { label: "11:15 PM", value: "23:15" },
      { label: "11:30 PM", value: "23:30" },
      { label: "11:45 PM", value: "23:45" },

    ];
  }
}
