import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-flag-management-flags',
  templateUrl: './flag-management-flags.component.html',
  styleUrls: ['./flag-management-flags.component.css']
})
export class FlagManagementFlagsComponent implements OnInit {
  page:any=1;
  response:any=[];
  flags:any=[];
  totalRecords:any=0;
  deleteId:any=[];
  flagDetails:any=[];
  flagModelRef:any=[];
  modelName:any;

  constructor(
    private api:ApiService,
    private modalService:NgbModal,
    private toastr:ToastrService

  ) {
    this.getFlags(this.page);
   }

  ngOnInit(): void {
  }

  getFlags(page){
    this.api.get('admin/core_flags/lists?page='+page).subscribe((result)=>{
      this.response = result;
      this.flags = this.response.data.data;
      this.totalRecords = this.response.total;
    },(err)=>{

    });
  }

  deleteModal(content,current_row){
    this.deleteId=current_row.id;
    this.modalService.open(content,{ size:'sm',centered:true });
  }

  deleteFlag(){
    let data = {id:this.deleteId};
    this.api.post('admin/core_flags/delete',data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.getFlags(this.page);
    },(err)=>{
      this.toastr.error(err.error.message,"Error");      
    })
  }

  openAddModal(content){
    this.modalService.open(content,{ size:'xl',centered:true });
  }

  editFlagScript(content,index){
    this.flagDetails=this.flags[index];
    this.flagModelRef=[];
    let modalRef = this.modalService.open(content,{ size:'xl',centered:true });
    this.flagModelRef.push(modalRef);
    this.closeModal(modalRef);
  }

  closeModal(modalRef){
    modalRef.result.then((data) => {
    }, (reason) => {
      if(reason!=0 && reason!='Cross  click'){
        this.getFlags(this.page);
      }
      this.flagDetails=[];
    });
  }

}
