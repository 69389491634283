import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-training-course-registrations',
  templateUrl: './training-course-registrations.component.html',
  styleUrls: ['./training-course-registrations.component.css']
})
export class TrainingCourseRegistrationsComponent implements OnInit {

  courseRegForm:FormGroup;
  user:any=[];
  page:any=1;
  courses:any=[];
  courseRegisters:any=[];
  totalRecords:any=[];
  customers:any=[];
  deleteId:any;
  response:any=[];


  formErrors = {
    'id':[''],
    'user_id':[''],
    'course_id':['']
  }

  validationMessages = {
    'user_id':{
      'required':'Select user'
    },
    'course_id':{
      'required':'Select course'
    }
  }

  constructor(
    private api:ApiService,
    private formBuilder:FormBuilder,
    private modalService:NgbModal,
    private toastr:ToastrService
  ) { 
    this.user =  JSON.parse(localStorage.getItem('reliant_user'));
    this.courseRegForm = this.formBuilder.group({
      id:[''],
      user_id:[''],
      course_id:['']
    });
  }

  ngOnInit(): void {
    this.init();
  }

  init() :void{
    this.getCourses();
    this.getUsers();
    this.getCourseRegistration(this.page);
    this.courseRegForm = this.formBuilder.group({
      id:[''],
      user_id: ['', [Validators.required]],
      course_id: ['', [Validators.required]]
    });
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }


  async getCourseRegistration(page){
    this.api.get('admin/course_registration/lists?page='+page).subscribe((result)=>{
      this.totalRecords = result.data.total;
      this.courseRegisters = result.data.data;
    },(err)=>{

    });
  }

  getCourses(){
    this.api.get('admin/courses/all_courses').subscribe((result)=>{
      this.courses = result.data;
    },(err)=>{
    });
    return true;
  }

  getUsers(){
    this.api.get('admin/course_registration/get_user').subscribe((result)=>{
      this.customers = result.data;
    },(err)=>{
    });
    return true;
  }

  addCourseReg(content){
    this.courseRegForm.reset();
    this.modalService.open(content, { size:'lg',centered:true });
  }

  submitCourseRegData(data):void{
    let endPoint = (data.id)?'update':'create';
    this.api.post('admin/course_registration/'+endPoint,data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.getCourseRegistration(this.page);
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

  editCourseRegistration(content,index){
    this.courseRegForm.reset();
    let editData = this.courseRegisters[index];
    let x = {
      id:editData.id,user_id:Number(editData.user_id),course_id:Number(editData.course_id)
    }
    this.courseRegForm.patchValue(x);
    this.modalService.open(content, { size:'lg',centered:true });
  }

  deleteModal(content,current_row){
    this.deleteId = current_row.id;
    console.log(this.deleteId);
    this.modalService.open(content,{ size:'sm',centered:true });
  }


  deleteRegistration(){
    let data = {id:this.deleteId};
    this.api.post('admin/course_registration/delete',data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.getCourseRegistration(this.page);
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

}
