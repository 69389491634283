<div class="ecaps-page-wrapper">
    <!-- Sidemenu Area -->
    <div class="ecaps-sidemenu-area">
        <!-- Desktop Logo -->
        <div class="ecaps-logo">
            <a href="javascript:void(0)"><img class="desktop-logo" src="assets/img/core-img/logo.png"
                    alt="Desktop Logo"> <img class="small-logo" src="assets/img/core-img/small-logo.png"
                    alt="Mobile Logo"></a>
        </div>

        <!-- Side Nav -->
        <div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 100%;">
            <div class="ecaps-sidenav" id="ecapsSideNav" style="overflow: hidden; width: auto; height: 100%;">
                <!-- Side Menu Area -->
                <div class="side-menu-area">
                    <!-- Sidebar Menu -->
                    <nav>
                        <ul class="sidebar-menu" data-widget="tree">
                            <li routerLinkActive="active">
                                <a routerLink="/administration/manage-calls-dashboard">
                                    <i class='fa fa-home'></i>
                                    <span>Dashboard</span>
                                </a>
                            </li>
                            <li routerLinkActive="active">
                                <a routerLink="/administration/calls">
                                    <i class='fa fa-user-md'></i>
                                    <span>Call Logs </span>
                                </a>
                            </li>
                            <li routerLinkActive="active">
                                <a routerLink="/administration/call-schedule">
                                    <i class='fa fa-user'></i>
                                    <span>Schedule</span>
                                </a>
                            </li>

                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-xl-12 box-margin">
        <div class="card">
           
            <div class="card-body">
                <div class="tab-content list_table" id="ticketList">
                    <div class="tab-pane active show fade" id="active-01" role="tabpanel"
                        aria-labelledby="active-01-tab">
                        <form [formGroup]="callScheduleForm" (ngSubmit)="submitCallScheduleData(callScheduleForm.value)">
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <label>Force Call Schedule? </label>
                                    <div class="new-checkbox" *ngIf="!isNo">
                                        <span style="color:rgb(13, 182, 22);"> No </span>
                                        <label class="switch md-trigger md-setperspective" data-modal="modal-18"
                                            (change)="timesheetsChange(0)">
                                            <input type="checkbox" value="0" />
                                            <span class="slider round"></span>
                                        </label>
                                        <span> Yes</span>
                                    </div>
                                    <div class="new-checkbox" *ngIf="isNo">
                                        <span> No </span>
                                        <label class="switch md-trigger md-setperspective" data-modal="modal-18"
                                            (change)="timesheetsChange(1)">
                                            <input type="checkbox" value="1" checked />
                                            <span class="slider round"></span>
                                        </label>
                                        <span style="color:rgb(13, 182, 22);"> Yes </span>
                                    </div>
                                </div>

                                <div class="form-group col-md-12">
                                    <label>Force Call Schedule? </label>
                                   
                                    <div class="new-checkbox" >
                                        <input type="radio" value="Daylight" name="force_call_schedule" > Daylight
                                        <input type="radio" value="Evening" name="force_call_schedule" > Evening
                                    </div>
                                </div>
                              
                            </div>
                           
                        </form>
                    </div>
                </div>
            </div>
            
            <!-- <div class="card-body">
                <div class="tab-content list_table" id="ticketList">
                    <div class="tab-pane active show fade" id="active-01" role="tabpanel"
                        aria-labelledby="active-01-tab">
                        <div class="table-responsive list_table">
                            Hello
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0"> Calls</h6>
            </div>
        </div>
    </div>
</div>