import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';
import { environment } from 'src/environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap, map, retry } from 'rxjs/operators';
const baseURL = environment.baseURL;
const filePath = environment.filePath;

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css']
})
export class FormsComponent implements OnInit {

  emailForm:FormGroup;
  linkForm:FormGroup;
  documentForm:FormGroup;
  user:any=[];
  documents:any=[];
  links:any=[];
  response:any=[];
  linkButton:boolean=false;
  currentlink:string='';
  link_id : any;
  form_id : any;
  formButton:boolean=false;
  theFile: any = null
  selectedImage:string;
  currentform:string;
  message_templates:any=[];
  subject:string='';
  isType:boolean=true;
  isSelection:boolean=false;
  isSpeciality:boolean=false;
  isClient:boolean=false;
  filePath:any= filePath;
  isSubject: boolean = true ;
  type:any='';
  recipient_type:any='';
  specialties:any=[];
  speciality:any='';
  recipients:any=[];
  url_error:any=false;
  files:string[] = [];


  formErrors = {
    'name':'',
    'url':'',
  }

  validationMessages = {
    'name':{
      'required':'Enter name',
      'minlength': 'Name must be 3 characters long.'
    },
    'url':{
      'required':'Enter url',
      'pattern':"Enter valid url"
    },
  }

  formErrorsDoc = {
    'title':'',
    'file':'',
  }

  validationMessagesDoc = {
    'title':{
      'required':'Enter name',
      'minlength': 'Name must be 3 characters long.'
    }
  }

  constructor(
    private api: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public toastr:ToastrService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {

    this.user =  JSON.parse(localStorage.getItem('reliant_user'));

    this.emailForm = this.formBuilder.group({
      type:[''],
      title:[''],
      custom_recipient:[''],
      recipient_type:[''],
      specialty:[''],
      recipients:[''],
      message:[''],
      contact_type:[''],
      documents:[''],
      files:[''],
      message_type:['']
    });

    this.linkForm = this.formBuilder.group({
      name: [''],
      url:['']
    });


    this.documentForm = this.formBuilder.group({
      title: [''],
      file:['']
    });

    this.getForm(this.user.id);
    this.getLink(this.user.id);
    this.getSpeciality();

  }

  ngOnInit(): void {
    this.init();
  }

  init() :void{
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    this.linkForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      url: ['', [Validators.required,Validators.pattern(urlRegex)]]
    });

    this.linkForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.linkForm);
    });

    this.documentForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.minLength(3)]],
      file:['']
    });

    this.documentForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairsDoc(this.documentForm);
    });
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  validateKeyValuePairsDoc(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairsDoc(abstractControl);
      } else {
        this.formErrorsDoc[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessagesDoc[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrorsDoc[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  async getForm(id){
    this.api.get("admin/documents/lists?userid="+id).subscribe((result)=>{
     this.documents = result.data;
    },(err)=>{

    });
  }

  async getLink(id){
    this.api.get("admin/links/lists?userid="+id).subscribe((result)=>{

     this.links = result.data;
    },(err)=>{

    });
  }

  addLink(content){
    this.linkForm.reset();
    this.modalService.open(content,{ size:'xl', centered:true });
  }

  submitLink(data): void {
    let url=data.url;

    if(url.indexOf("http") !== -1  || url.indexOf("https") !== -1){
      this.api.post('admin/links/create',data).subscribe((result)=>{
        this.response = result;
        this.toastr.success(this.response.message,"Success");
        this.getLink(this.user.id);
        this.modalService.dismissAll();
      },(err)=>{
        this.toastr.error(err.error.message,"Error");
      });
    }else{
      this.url_error = true;
      this.formErrors.url='Please use http:// or https://';
    }
  }

  changeLink(index){
    let data = this.links[index];
    
    if(data && data.id){
      this.linkButton=true;
      this.link_id = data.id;
      this.currentlink = data.url;
    }else{
      this.linkButton=false;
      this.currentlink='';
      this.link_id='';
    }
  }

  openUrl(){
    if(this.currentlink!=''){
      window.open(this.currentlink, '_blank');
    }else{
      this.toastr.warning("Please select any link.","Warning");
    }

  }

  deleteLink():void{
    let data= {id : this.link_id};
    this.api.post('admin/links/delete',data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.getLink(this.user.id);
      this.currentlink='';
      this.link_id='';
      window.location.reload();
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

  changeForm(index){
    let data = this.documents[index];
    
    if(data && data.id){
      this.formButton=true;
      this.form_id = data.id;
      this.currentform = data;
    }else{
      this.formButton=false;
      this.currentlink='';
      this.form_id='';
    }
  }


  addForm(content){
    this.documentForm.reset();
    this.modalService.open(content,{ size:'xl', centered:true });
  }

  convertImageInBase64(event){
    if (event.target.files && event.target.files.length > 0) {
     
      // Set theFile property
      this.theFile = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.theFile);
      reader.onload = () => {
      this.selectedImage = reader.result as string;
      console.log(this.selectedImage);
    }
    }else{
      this.selectedImage='';
    }
    
  }


  submitForm(data): void {
    data.file=this.selectedImage;
    data.user_id = this.user.id;
    this.api.post('admin/documents/create',data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.getForm(this.user.id);
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

  deleteForm():void{
    if(this.form_id){
      let data = { id:this.form_id };
      this.api.post('admin/documents/delete',data).subscribe((result)=>{
        this.response = result;
        this.toastr.success(this.response.message,"Success");
        this.getForm(this.user.id);
        window.location.reload();
      },(err)=>{
        this.toastr.error(err.error.message,"Error");
      });
    }else{
      this.toastr.error('Please select form.',"Error");
    }
  }

  getPath(doc){
    // let url='https://staging.reliantstaffing.co/api_backend/public/'+doc.path;
    let url= filePath+doc.path;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  Print(){
      var printContents = document.getElementById('printSection').innerHTML;
			var originalContents = document.body.innerHTML;
			document.body.innerHTML = printContents;
			window.print();
			document.body.innerHTML = originalContents;
  }

  mailModal(content){
    this.emailForm.reset();
    this.modalService.open(content,{ size:'xl',centered:true });
    this.api.get('admin/message_template/alllists').subscribe((result)=>{
       this.message_templates = result.data;
    },(err)=>{

    });
  }

  subjectToggle(){
    this.isSubject = ! this.isSubject;
    if(this.isSubject){
      this.subject = '';
    }
  }

  getTemplate(index){
    let currenttemplate = this.message_templates[index];
    this.subject = currenttemplate.title;
    this.emailForm.patchValue({message:currenttemplate.body});
  }

  getSubject(event){
    console.log(event);
    this.subject = event.target.value;
  }

  getType(event){
    this.type = event.target.value;
    this.isType=false;
    this.checkSpecialityVal();
    this.callUserAPI();
  }

  checkSpecialityVal(){
    if(this.recipient_type=='employees' && this.type!='all'){
      this.isSpeciality=true;
    }else{
      this.isSpeciality=false;
    }

    if(this.recipient_type=='clients'){
      this.isClient = true;
    }else{
      this.isClient = false;
    }
    return true;
  }

  async getSpeciality(){
    this.api.get('admin/specialities/lists').subscribe((result) => {
      this.specialties = result.data;
    }, (err) => {

    });
  } 

  changeSpeciality(index){
    this.speciality = this.specialties[index];
    this.getUser();
  }

  callUserAPI(){
    
    if(this.recipient_type=="clients"){
      this.getClients();
      this.speciality = '';
    }else if(this.recipient_type=="employees"){
      this.getUser();
    }
  }

  getRecipient(val){
    this.recipient_type = val.target.value;
    if(this.type!='' && val.target.value!=''){
      this.isSelection=true;
    }
    this.checkSpecialityVal();
    this.callUserAPI();
  }
  getUser(){
    let data = { status:this.type, recipient_type:this.recipient_type,speciality:this.speciality.id };
    this.api.post('admin/employee/get_employees',data).subscribe((result)=>{
      this.response = result;
      this.recipients = this.response.data;
      this.getSpeciality();
      this.emailForm.patchValue({ recipients:null });
    },(err)=>{

    });
  }

  getClients(){
    console.log(this.type);
    let data = { status:this.type, recipient_type:this.recipient_type };
    this.api.post('admin/customer/get_customers',data).subscribe((result)=>{
      this.response = result;
      this.recipients = this.response.data;
      this.emailForm.patchValue({specialty:null});
      this.emailForm.patchValue({ recipients:null });
    },(err)=>{

    });
  }

  onFileChange(event)  {
    this.files = event.target.files;
    // for  (var i =  0; i <  event.target.files.length; i++)  { 
    //     this.files.push(event.target.files[i]);
    // }
    console.log(this.files);
  }


  sendEmail(data){

    console.log("this.files:", this.files);

    const formData =  new  FormData();
    
    if(data.custom_recipient==null && data.recipient_type==null){
      this.toastr.warning('Please select recipient',"Warning");
      return false;
    }
    
    formData.append('title',this.subject);
    formData.append('type', data.type);
    formData.append('custom_recipient', data.custom_recipient);
    formData.append('recipient_type', data.recipient_type);
    formData.append('specialty', data.specialty);
    formData.append('recipients',data.recipients);
    formData.append('contact_type',data.contact_type);
    formData.append('message',data.message);
    formData.append('message_type',data.message_type);
    formData.append('documents',data.documents);

    for(var i =  0; i <  this.files.length; i++)  {  
      formData.append("attachments[]",  this.files[i]);
    }
    console.log('formData1', formData);

    this.api.uploadFile('admin/sendmail/mail',formData).subscribe((result)=>{
      console.log(result);
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      // this.modalService.dismissAll();
      // this.emailForm.reset();
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }
}
