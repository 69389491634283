import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../services/api.service';
import { HelperService } from '../helper/helper.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-flag-management-types',
  templateUrl: './flag-management-types.component.html',
  styleUrls: ['./flag-management-types.component.css']
})
export class FlagManagementTypesComponent implements OnInit {
  typeForm:FormGroup;
  response:any=[];
  allTypes:any=[];
  totalRecords:any=0;
  page:any=1;
  status:any=[];
  modelNames:any=[{label: "Contacts", value: "Contacts"},{label: "Customers", value: "Customers"},{label: "Opportunities", value: "Opportunities"}];
  isEnableFollowUp:boolean=false;
  optType:any=['Email','Phone','Text'];
  isFollowUpSetting:boolean=false;
  timeSchedule:any=[];
  isDefault:boolean=false;
  columns:any=[];
  column_name:any=[];
  columnType:any=['Currency','Date','Date / Time','Email','HTML','Phone','Text (Long)','Text (Short)','Time','Yes / No'];
  isColumnRequired:boolean=false;
  column_type:any[];
  noActions:boolean=false;

  formErrors ={
    'id':'',
    'name':'',
    'model_name':'',
    'enable_type_customization':'',
    'should_trigger_follow_up':'',
    'follow_up_days':'',
    'follow_up_time':'',
    'follow_up_status_id':'',
    'follow_up_type':'',
    'column_name':'',
    'column_type':'',
    'should_trigger_required':'',
    'custom_fields':'',
    'trigger_required':'',
  }

  validationMessages = {
    'name': {
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    },
    'model_name':{
      'required':'Select model name'
    }
  };

  constructor(
    private api:ApiService,
    private modalService:NgbModal,
    private formBuilder:FormBuilder,
    private helper:HelperService,
    private toastr:ToastrService,
  ) { }

  ngOnInit(): void {
    this.getType(this.page);
    this.init();
  }

  init(){
    this.typeForm = this.formBuilder.group({
      id:[''],
      name: ['', [Validators.required, Validators.minLength(3)]],
      model_name:['',[Validators.required]],
      enable_type_customization:[''],
      should_trigger_follow_up:[''],
      follow_up_days:[''],
      follow_up_time:[''],
      follow_up_status_id:[''],
      follow_up_type:[''],
      column_name:[''],
      column_type:[''],
      should_trigger_required:[''],
      custom_fields:[''],
      trigger_required:[''],
      
    });

    this.typeForm.valueChanges.subscribe(()=>{
      this.validateKeyValuePairs(this.typeForm);
    });
    this.timeSchedule = this.helper.getScheduleTime(15);
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  

  openTypesModal(content){
    this.modalService.open(content,{ size:'xl',centered:true });
  }

  getType(page){
    let data = { };
    this.api.post('admin/core_types/lists',data).subscribe((result)=>{
      this.response = result;
      this.allTypes = this.response.data;
    },(err)=>{
    });  
    return true;
  }

  enableFollowUp(){
    this.isEnableFollowUp = (this.isEnableFollowUp)?false:true;
    if(this.isEnableFollowUp) this.getStatus();
  }

  checkIsDefault(){
    this.isDefault = (this.isDefault)?false:true;
    if(this.isDefault) this.getTableColumn('contacts');
  }

  getModelName(event){
    if(event=='Customers'){
      this.isFollowUpSetting=true;
    }else{
      this.isFollowUpSetting=false;
    }
  }
  getColumnName(event){
  this.column_type = event;//(this.column_type)?false:true;
  }
  submitTypes(data){
    /* let endPoint = (data.id)?'update':'create';
    this.api.post('admin/core_types/'+endPoint,data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.typeForm.reset();
      
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    }); */
    console.log(data);
  }
  getStatus(){
    this.api.get('admin/core_statuses/all_statuses').subscribe((result)=>{
      this.response = result;
      this.status = this.response.data;
    },(err)=>{
    });
  }

  getTableColumn(event){

    // let index = this.models.findIndex((m)=> m.tableName === event);
    // var field =''; 
    // if(index !== -1){
    //   field = this.models[index].table_name;
    // }
    var field =''; 
    field="customers";
    let data = {'table_name':field};
    this.api.post('admin/libraries/get_column_name',data).subscribe((result)=>{ //DESCRIBE users;
      this.response = result;
      this.columns = this.response.data;
      
    },(err)=>{

    });
  }
  columnTriggerRequired(){
    this.isColumnRequired = (this.isColumnRequired)?false:true;
  }
  createCustomColumn(){
    let col_name = document.getElementById('column_name') as HTMLInputElement;
    let column_name = col_name.value;
    let column_type=this.column_type;
    let trigger_required = this.isColumnRequired;
    
    var data=[]//this.response;
    data["Default"] = trigger_required;
    data["Extra"] = '';
    data["Field"] = column_name;
    data["Key"] = '';
    data["Null"] = '';
    data["Type"] = column_type;
    data['noActions']=true;
    (this.columns).push(data);
    let column_name_null=document.getElementById('column_name') as HTMLInputElement;
    column_name_null.value='';
    this.columns;
  }

  columnRemove(index){
    this.columns.splice(index,1);
  }

}