import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators,AbstractControl  } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-administration-rates',
  templateUrl: './administration-rates.component.html',
  styleUrls: ['./administration-rates.component.css']
})
export class AdministrationRatesComponent implements OnInit {

  ratesForm:FormGroup;
  response:any;
  totalRecords: any;
  rates: any = [];
  page: any = 1;
  delete_id:any;
  editData:any=[];

  formErrors = {
    'name':'',
    'model_type':''
  }

  validationMessages = {
    'name':{
      'required' : 'Name is required',
      'minlength': 'Name must be 3 character long'
    }
  }

  constructor(private api:ApiService ,private modalService: NgbModal , private formBuilder:FormBuilder , private toastr:ToastrService) {
    this.ratesForm = this.formBuilder.group({
      rate:[''],
    });
    this.getRates(this.page);
  }

  ngOnInit(): void {
    this.init();
  }

  init() :void{
    this.ratesForm = this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required, Validators.minLength(3)]],
      model_type:['']
    });
    this.ratesForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.ratesForm);
    });
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  async getRates(page){
    this.api.get('admin/rates/lists?page='+page).subscribe((result)=>{
      this.totalRecords = result.data.total;
      this.rates = result.data.data;
    },(err)=>{

    });
  }

  editRates(content,index){
    this.ratesForm.reset();
    this.editData = this.rates[index];
    this.ratesForm.patchValue(this.editData);
    this.modalService.open(content, { size: 'lg', centered: true, scrollable: true });
  }

  addRates(content){
    this.modalService.open(content,{ size:'xl' , centered:true });
  }

  submitRatesData(data):void{
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    let endPoint = (data.id) ? 'update' : 'create';
    this.api.post("admin/rates/"+endPoint,data).subscribe((result)=>{
      this.response = result;
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.getRates(this.page);
    },(err)=>{
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.toastr.error(err.error.message,"Error");
    });

  }

  deleteModal(content,current_row){
    this.delete_id=current_row.id;
    this.modalService.open(content,{size:'sm',centered:true});
  }

  deleteRates():void{
    let data = {id:this.delete_id};
    console.log(data);
    this.api.post("admin/rates/delete",data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.getRates(this.page);
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

}
