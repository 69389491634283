<div class="row">
    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Triggers</h6>
                <div class="btn-group">
  
                    <button class="action-btn bg-dark-green mr-2" (click)="openModal(createTriggerModal)"><img src="assets/img/svg-icon/add-white.svg" width="20" /></button>
                    <!-- <button class="action-btn bg-dark-blue" data-toggle="modal" data-target="#exampleModalRight"><img src="assets/img/svg-icon/filter-white.svg" width="20" /></button> -->
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content list_table" id="ticketList">
                    <div class="table-responsive list_table">
                        <table class="table  table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th class="pt-2">Name</th>
                                    <th class="pt-2">Trigger Type</th>
                                    <th class="pt-2">Related Model</th>
                                    <th class="pt-2">Related Field</th>
                                    <th class="pt-2">Button Label</th>
                                    <th class="pt-2">Button Icon</th>
                                    <th class="pt-2">Button Location</th>
                                    <th class="pt-2">Email Subject</th>
                                    <th class="pt-2">Email Body</th>
                                    <th class="pt-2">Status</th>
                                    <th class="pt-2">Create At</th>
                                    <th class="pt-2">Updated At</th>
                                    <th class="pt-2 text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="triggers.length > 0">
                                    <tr *ngFor="let trigger of triggers | paginate: { id: 'trigger_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalRecords }; let i=index">
                                        <td class="text-gray">{{trigger.name}}</td>
                                        <td class="text-gray">{{trigger.trigger_type}}</td>
                                        <td class="text-gray">{{trigger.model_name}}</td>
                                        <td class="text-gray">{{trigger.related_field}}</td>
                                        <td class="text-gray">{{trigger.button_label}}</td>
                                        <td class="text-gray">{{trigger.button_icon}}</td>
                                        <td class="text-gray">{{trigger.button_location}}</td>
                                        <td class="text-gray">{{trigger.email_subject}}</td>
                                        <td class="text-gray"><div style="word-wrap: break-word" [innerHTML]="trigger.email_body"></div></td>
                                        <td class="text-gray" *ngIf="trigger.active==1">Yes</td>
                                        <td class="text-gray" *ngIf="trigger.active==0">No</td>
                                        <td class="text-black">{{trigger.created_at | date : 'MM/dd/yyyy' }}</td>
                                        <td class="text-black">{{trigger.updated_at | date : 'MM/dd/yyyy' }}</td>
                                        <td class="text-right">
                                          <a href="javascript:void(0)" class="mr-2 action-btn bg-edit" title="Update  Trigger" (click)="updateModal(createTriggerModal,trigger)"><img src="assets/img/svg-icon/edit.svg" width="20" /></a>
                                          <a href="javascript:void(0)" class="action-btn bg-red" title="Delete" (click)="deleteModal(callScriptDeleteModal,trigger)"><img src="assets/img/svg-icon/delete.svg" width="20" /></a>
                                        </td>
                                    </tr>
                                </ng-container>
                                <tr *ngIf="triggers.length == 0">
                                    <td class="text-gray text-center" colspan="5">Data Not Found</td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="pagination-controls-theme" id="trigger_pagination" (pageChange)="getTriggers(page = $event)" maxSize="9" directionLinks="true"
                        autoHide="true" responsive="true">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  
  <!-- Add Trigger Model -->
  <ng-template #createTriggerModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Trigger Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross  click')"
      aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <app-modal [details]="triggerObject"></app-modal>
    </div>
  </ng-template>
  
  <!-- Delete  -->
  <ng-template #callScriptDeleteModal let-modal>
  
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>
  
    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" class="btn btn-success border-r-10" (click)="deleteTrigger()">Yes, delete it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>
  
  </ng-template>
  
  <!-- Right Alight Modal -->
  <div class="modal fade drawer right-align" id="exampleModalRight" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Right Align Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
  