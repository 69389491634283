import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-crm-marketing',
  templateUrl: './crm-marketing.component.html',
  styleUrls: ['./crm-marketing.component.css']
})
export class CrmMarketingComponent implements OnInit {

  noteForm: FormGroup;
  submittedForm:FormGroup;
  clientForm: FormGroup;
  roles: any = [];
  totalRecords: any;
  response:any;
  current_facility:any;
  page: any = 1;
  totalRecordInactive:any;
  marketings :any=[];
  isTrue:boolean = true;


  inactiveMarketings : any = [];
  inactivePage: any = 1;
  modalReference:any='';


  formErrors = {
    'company_name': '',
    'phone':'',
    'caller_phone':'',
    'billing_email':'',
    'specialty':'',
    'marketing':'',
    'address_line_1':'',
    'address_line_2':'',
    'city':'',
    'state':'',
    'zip':'',
    'administrator_name':'',
    'administrator_phone':'',
    'administrator_alt_phone':'',
    'administrator_email':'',
    'scheduler_name':'',
    'scheduler_phone':'',
    'scheduler_alt_phone':'',
    'scheduler_email':'',
    'static_html_block':''

  };
  validationMessages = {
    'company_name': {
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    }
  };

  constructor(
    private api: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public toastr:ToastrService,
  ) {

    this.noteForm = this.formBuilder.group({
        note: [''],
    });
    this.submittedForm = this.formBuilder.group({
      submitted:[''],
    });


    this.clientForm = this.formBuilder.group({
        client: [''],
    });

    this.getActiveMarketing(this.page);
    this.getInactiveMarketing(this.inactivePage);
   }

  ngOnInit(): void {
     this.init();
  }

  init() :void{
    this.clientForm = this.formBuilder.group({
      id: [''],
      company_name: ['', [Validators.required, Validators.minLength(3)]],
      phone:[''],
      caller_phone:[''],
      billing_email:[''],
      specialty:[''],
      marketing:[''],
      address_line_1:[''],
      address_line_2:[''],
      city:[''],
      state:[''],
      zip:[''],
      administrator_name:[''],
      administrator_phone:[''],
      administrator_alt_phone:[''],
      administrator_email:[''],
      scheduler_name:[''],
      scheduler_phone:[''],
      scheduler_alt_phone:[''],
      scheduler_email:[''],
      static_html_block:['']
    });
    this.clientForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.clientForm);
    });
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }


  async getActiveMarketing(page) {
    this.page = page;
    this.api.get('admin/marketing/lists?page='+page).subscribe((result) => {
        this.totalRecords = result.data.total;
        this.marketings = result.data.data;

    }, (err) => {

    });
  }

  async getInactiveMarketing(inactivePage) {
    this.inactivePage = inactivePage;
    let status = 0;
    this.api.get('admin/marketing/lists?status='+status+'&page='+inactivePage).subscribe((result) => {
        this.totalRecordInactive = result.data.total;
        this.inactiveMarketings = result.data.data;
    }, (err) => {

    });
  }


  openNote(addNote,current_row){
    this.current_facility = current_row;
    this.modalService.open(addNote, { size: 'xl', centered: true });
  }

  submitNote(data): void {
    console.log(this.current_facility);
    data.model_type = 'Client';
    data.model_id = this.current_facility.id;
    this.api.post('admin/note/create', data).subscribe((result) => {
       console.log(result);
       this.response=result;
       this.toastr.success(this.response.message,"Success");
       this.modalService.dismissAll();
    }, (err) => {
      this.toastr.error(err.error.message,'Error');
    });
  }

  openDeletePopUp(deleteMarketing,current_row){
    this.current_facility = current_row;
    this.modalReference = this.modalService.open(deleteMarketing, { size: 'sm', centered: true });
  }

  deleteMarketingData(): void  {
    let data = {id : this.current_facility.id};
    this.modalReference.close();
    this.api.post('admin/clients/delete', data).subscribe((result) => {
      this.response=result;
      this.toastr.success(this.response.message,"Success");
      this.getActiveMarketing(this.page);
      this.getInactiveMarketing(this.inactivePage);
   }, (err) => {
      console.log(err);
      this.toastr.error(err.error.message,'Error');
   });
  }

  openSubmittedModal(submittedModal,current_row){
    this.current_facility = current_row;
    this.modalService.open(submittedModal, { size: 'lg', centered: true });
  }

  submittedDateSubmit(data): void {

    console.log(this.current_facility);
    data.id = this.current_facility.id;
    this.api.post('admin/submitted/update', data).subscribe((result) => {
       console.log(result);
       this.response=result;
       this.toastr.success(this.response.message,"Success");
       this.modalService.dismissAll();
       this.getActiveMarketing(this.page);
       this.getInactiveMarketing(this.inactivePage);
    }, (err) => {
      this.toastr.error(err.error.message,'Error');
    });
  }

  addMarketing(Content){
    this.clientForm.reset();
    this.modalService.open(Content, { size: 'xl', centered: true});
  }

  submitClientData(data):void{
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/marketing/create', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.getActiveMarketing(this.page);
       this.getInactiveMarketing(this.inactivePage);
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.modalService.dismissAll();
    }, (err) => {
      this.toastr.error(err.error.message,"Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });
  }


}
