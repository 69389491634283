<div class="row">
    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Company Settings</h6>
            </div>
            <div class="card-body">


              <form [formGroup]="companySettingForm" (ngSubmit)="submitForm(companySettingForm.value)">
                  <input type="hidden" formControlName="id">
                <div class="row">

                    <div class="col-sm-4">
                        <label>Company Name</label>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <input type="text" class="form-control" formControlName="admin_company_name" placeholder="Please enter admin company name">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <label>Company Phone</label>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <input type="text" class="form-control" formControlName="admin_company_phone" placeholder="Please enter admin company phone">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <label>Company Logo</label>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <input type="file" class="form-control" formControlName="admin_company_logo" (change)="fileEvent($event)">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label>Address Line 1</label>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <input type="text" class="form-control" formControlName="admin_company_location_address_line_1" placeholder="Please enter address line 1">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <label>Address Line 2</label>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <input type="text" class="form-control" formControlName="admin_company_location_address_line_2" placeholder="Please enter address line 2">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <label>City</label>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <input type="text" class="form-control" formControlName="admin_company_location_city" placeholder="Please enter city">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <label>State</label>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <select class="form-control" formControlName="admin_company_location_state">
                                    <option *ngFor="let state of states;" value="state.short">{{state.long}}</option>

                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <label>Zip</label>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <input type="text" class="form-control" formControlName="admin_company_location_zip" placeholder="Please enter zip">
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-sm-12">
                        <button class="action-btn bg-dark-blue">Submit</button>
                    </div>
                </div>

              </form>


            </div>
        </div>
    </div>
  </div>