<div class="row">
    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Registrations</h6>
                <div class="btn-group">
  
                    <button class="action-btn bg-dark-green mr-2" (click)="addCourseReg(registrationFormModal)"><img src="assets/img/svg-icon/add-white.svg" width="20" /></button>
                    <!-- <button class="action-btn bg-dark-blue" data-toggle="modal" data-target="#exampleModalRight"><img src="assets/img/svg-icon/filter-white.svg" width="20" /></button> -->
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content list_table" id="ticketList">
                    <div class="table-responsive list_table">
                        <table class="table  table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th class="pt-0">User</th>
                                    <th class="pt-0">Course</th>
                                    <th class="pt-0">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="courseRegisters.length > 0">
                                    <tr *ngFor="let course of courseRegisters | paginate: { id: 'course_reg_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalRecords }; let i=index">
                                        <td class="text-black"><span *ngIf="course.user">{{course.user.name}}</span></td>
                                        <td class="text-black"><span *ngIf="course.course">{{course.course.name}}</span></td>
                                        <td class="text-right">
                                          <a href="javascript:void(0)" class="mr-2 action-btn bg-edit" title="Update Rates" (click)="editCourseRegistration(registrationFormModal,i)"><img src="assets/img/svg-icon/edit.svg" width="20" /></a>
                                          <a href="javascript:void(0)" class="action-btn bg-red" title="Delete" (click)="deleteModal(registrationDeleteModal,course)"><img src="assets/img/svg-icon/delete.svg" width="20" /></a>
                                        </td>
                                    </tr>
                                </ng-container>
                                <tr *ngIf="courseRegisters.length == 0">
                                    <td class="text-gray text-center" colspan="5">No Course registration found.</td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="pagination-controls-theme" id="course_reg_pagination" (pageChange)="getCourseRegistration(page = $event)" maxSize="9" directionLinks="true"
                        autoHide="true" responsive="true">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  
  <!-- Add Registration  -->
  
  <ng-template #registrationFormModal let-modal>
  
  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
    <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross  click')"
        aria-label="Close">
        <span aria-hidden="true">×</span>
    </button>
  </div>
  
  <div class="modal-body md-b-body">
    <form [formGroup]="courseRegForm" (ngSubmit)="submitCourseRegData(courseRegForm.value)">
        <input type="hidden" formControlName="id">
      <div class="form-row">
        <div class="form-group col-md-12">
          <div class="card card-shadow-hide">
              <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                  <h6 class="card-title mb-0">Registration Details</h6>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-sm-6">
                    <label for="user_id">User <span class="text-danger">*</span></label>
                    <ng-select formControlName="user_id" placeholder="Select" clearAllText="Clear" class="form-control-ng rounded-0">
                        <ng-option *ngFor="let customer of customers" [value]="customer.id">
                            {{customer.name}}
                        </ng-option>
                    </ng-select>
                    <span class="text-danger"
                          *ngIf="courseRegForm.get('user_id').errors?.required || courseRegForm.get('user_id').errors?.minlength && courseRegForm.get('user_id').errors">
                          {{formErrors.user_id}}</span>
                  </div>
                  <div class="form-group col-sm-6">
                    <label for="course_id">Course <span class="text-danger">*</span></label>
                    <ng-select formControlName="course_id" placeholder="Select" clearAllText="Clear" class="form-control-ng rounded-0">
                        <ng-option *ngFor="let course of courses" [value]="course.id">
                            {{course.name}}
                        </ng-option>
                    </ng-select>
                    <span class="text-danger"
                          *ngIf="courseRegForm.get('course_id').errors?.required || courseRegForm.get('course_id').errors?.minlength && courseRegForm.get('course_id').errors">
                          {{formErrors.course_id}}</span>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="btn-modal-group">
          <button type="submit" [disabled]="!courseRegForm.valid" id="submit-btn" class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
      </div>
    </form>
  </div>
  
  </ng-template>
  
  <!-- Delete  Registration Data -->
  <ng-template #registrationDeleteModal let-modal>
  
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>
  
    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteRegistration()" class="btn btn-success  border-r-10">Yes, delete it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>
  
  </ng-template>
  
  <!-- Right Alight Modal -->
  <div class="modal fade drawer right-align" id="exampleModalRight" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Right Align Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
  