<div class="row">
    <div class="col-md-6 col-xl-4 height-card box-margin">
        <div class="card border-r-20">
            <div class="card-body">
                <!-- Single Widget -->
                <div class="single-widget-area">
                    <div class="profit-icon border-r-10 bg-light-orange">
                        <img src="assets/img/svg-icon/recruiting.svg" width="25" />
                    </div>

                    <div class="total-profit  d-flex align-items-center justify-content-between">
                        <h6 class="mb-0">Total Recruiting</h6>
                        <div class="counter font-30 font-weight-bold" data-comma-separated="true">{{ statics.new_recruit }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-4 height-card box-margin">
        <div class="card border-r-20">
            <div class="card-body">
                <!-- Single Widget -->
                <div class="single-widget-area">
                    <div class="profit-icon border-r-10 bg-green">
                        <img src="assets/img/svg-icon/active-employees.svg" width="25" />
                    </div>

                    <div class="total-profit  d-flex align-items-center justify-content-between">
                        <h6 class="mb-0">Active Employees</h6>
                        <div class="counter font-30 font-weight-bold" data-comma-separated="true">{{ statics.active }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-4 height-card box-margin">
        <div class="card border-r-20">
            <div class="card-body">
                <!-- Single Widget -->
                <div class="single-widget-area">
                    <div class="profit-icon border-r-10 bg-red">
                        <img src="assets/img/svg-icon/inactive-employees.svg" width="25" />
                    </div>

                    <div class="total-profit  d-flex align-items-center justify-content-between">
                        <h6 class="mb-0">Inactive Employees</h6>
                        <div class="counter font-30 font-weight-bold" data-comma-separated="true">{{ statics.inactive }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Employees</h6>
                <ul class="nav nav-tabs mb-0" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link {{ (activeTab == 1)?'active':'' }}" id="active-01-tab" data-toggle="tab"
                            href="javascript:void(0)" role="tab" aria-controls="active-01" aria-selected="true"
                            (click)="getActiveInactiveEmployee(1)">Active</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0 {{ (activeTab == 0)?'active':'' }}" id="inactive-02-tab"
                            data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="inactive-02"
                            aria-selected="false" (click)="getActiveInactiveEmployee(0)">Inactive</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0 {{ (activeTab == 2)?'active':'' }}" id="dnr-02-tab" data-toggle="tab"
                            href="javascript:void(0)" role="tab" aria-controls="dnr-02" aria-selected="false"
                            (click)="getDnrEmployee()">DNR</a>
                    </li>

                </ul>
                <div class="btn-group">

                    <button class="action-btn bg-dark-green mr-2" (click)="addEmployee(employeeFormModal)"><img
                            src="assets/img/svg-icon/add-white.svg" width="20" /></button>
                    <!-- <button class="action-btn bg-dark-blue"><img src="assets/img/svg-icon/filter-white.svg"
                            width="20" /></button> -->
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content list_table" id="ticketList">
                    <div class="tab-pane active show fade" id="active-01" role="tabpanel"
                        aria-labelledby="active-01-tab">
                        <div class="table-responsive list_table">
                            <table class="table  table-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th class="pt-0">First Name</th>
                                        <th class="pt-0">Last Name</th>
                                        <th class="pt-0">Specialty</th>
                                        <th class="pt-0">Email</th>
                                        <th class="pt-0">Phone</th>
                                        <th class="pt-0">Status</th>
                                        <th class="pt-0 text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="employees.length > 0">
                                        <tr *ngFor="let employee of employees | paginate: { id: 'employee_pagination', itemsPerPage: 15, currentPage: page, totalItems: totalRecords }; let i=index">
                                            <td class="text-black"> <a class="text-black" [routerLink]="['/dashboard/employee-profile/'+employee.id]"> {{ employee.first_name }}</a></td>
                                            <td class="text-gray"><a class="text-black" [routerLink]="['/dashboard/employee-profile/'+employee.id]">{{ employee.last_name }}</a></td>
                                            <td class="text-black">{{ employee.specialty?.name }}</td>
                                            <td class="text-gray">{{ employee.email }}</td>
                                            <td class="text-black">{{ employee.phone }}</td>
                                            <td
                                                class="text-{{ (activeTab != '2')?((employee.active == '1')?'green':'danger'):'info' }}">
                                                {{ (activeTab != '2')?((employee.active == '1')?'Active':'Inactive'):'DNR' }}</td>
                                            <td class="text-right">
                                                <button href="javascript;void(0)" (click)="openNote(addNote,employee)"  class="mr-2 action-btn bg-edit  md-trigger md-setperspective" data-modal="modal-19"><img src="assets/img/svg-icon/copy.svg" width="20" /></button>
                                                <button class="action-btn bg-red md-trigger md-setperspective" (click)="deleteModal(deleteModalBox,employee.id)" data-modal="modal-18"><img src="assets/img/svg-icon/delete.svg" width="20" /></button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr *ngIf="employees.length == 0">
                                        <td class="text-gray text-center" colspan="5">No Employees Found</td>
                                    </tr>
                                </tbody>
                            </table>
                            <pagination-controls class="pagination-controls-theme" id="employee_pagination"
                                (pageChange)="getEmployees(page = $event)" maxSize="9" directionLinks="true"
                                autoHide="true" responsive="true">
                            </pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #employeeFormModal let-modal>
    <div class="justify-content-right">
        <button type="button" class="close action-btn modal-dismiss mr-10 p-1" (click)="modal.dismiss('Cross click')"
            aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <form [formGroup]="employeeForm" (ngSubmit)="submitData(employeeForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Create New Employee</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">First
                                        Name<span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="first_name">
                                    <span class="text-danger"
                                        *ngIf="employeeForm.get('first_name').errors?.required || employeeForm.get('first_name').errors?.minlength && employeeForm.get('first_name').errors">
                                        {{formErrors.first_name}}</span>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Last
                                        Name<span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="last_name">
                                    <span class="text-danger"
                                        *ngIf="employeeForm.get('last_name').errors?.required || employeeForm.get('last_name').errors?.minlength && employeeForm.get('last_name').errors">
                                        {{formErrors.last_name}}</span>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Email<span
                                            class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="email">
                                    <span class="text-danger"
                                        *ngIf="employeeForm.get('email').errors?.required || employeeForm.get('email').errors?.email  && employeeForm.get('email').errors">
                                        {{formErrors.email}}</span>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState"
                                        class="col-form-label text-light-black font-16">Specialty</label>

                                    <ng-select formControlName="stf_specialty_id" placeholder="Select"
                                        clearAllText="Clear" class="form-control-ng rounded-0">
                                        <ng-option *ngFor="let speciality of specialities" [value]="speciality.id">
                                            {{speciality.name}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState"
                                        class="col-form-label text-light-black font-16">Phone</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="phone">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState" class="col-form-label text-light-black font-16">SSN</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="ssn">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState"
                                        class="col-form-label text-light-black font-16">Birthdate</label>
                                    <input class="form-control rounded-0 form-control-md" type="date"
                                        formControlName="birthdate">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Hire
                                        Date</label>
                                    <input class="form-control rounded-0 form-control-md" type="date"
                                        formControlName="hire_date">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Termination
                                        Date</label>
                                    <input class="form-control rounded-0 form-control-md" type="date"
                                        formControlName="termination_date">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState"
                                        class="col-form-label text-light-black font-16">Assignee(s)</label>
                                    <ng-select formControlName="assignee" [multiple]="true" placeholder="Select"
                                        clearAllText="Clear" class="form-control-ng rounded-0">
                                        <ng-option *ngFor="let assignee of assignees" [value]="assignee.id">
                                            {{assignee.name}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-3">
                                    <div class="checkbox d-inline">
                                        <label for="checkbox-recurit"
                                            class="col-form-label text-light-black font-16">Recruiting</label>
                                        <div class="new-checkbox toggle-check-box-t mt-15">
                                            <label>No</label>

                                            <!-- Rounded switch -->
                                            <label class="switch ml-15 mr-15">
                                                <input type="checkbox" formControlName="new_recruit" [checked]="isTrue"
                                                    id="checkbox-recurit">
                                                <span class="slider round"></span>
                                            </label>
                                            <label>Yes</label>
                                        </div>
                                        <!-- <input type="checkbox" formControlName="new_recruit" [checked]="isTrue" id="checkbox-recurit"> -->
                                    </div>
                                </div>
                                <div class="form-group col-md-4">
                                    <div class="checkbox d-inline">
                                        <label for="checkbox-active"
                                            class="col-form-label text-light-black font-16">Activate Employee
                                            Checklist</label>
                                        <div class="new-checkbox toggle-check-box-t">
                                            <label>No</label>

                                            <!-- Rounded switch -->
                                            <label class="switch ml-15 mr-15">
                                                <input type="checkbox" formControlName="active" [checked]="isTrue"
                                                    id="checkbox-active">
                                                <span class="slider round"></span>
                                            </label>
                                            <label>Yes</label>
                                        </div>
                                        <!-- <input type="checkbox" formControlName="active" [checked]="isTrue" id="checkbox-active"> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Location Information</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Address Line
                                        1</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="address_line_1">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Address Line
                                        2</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="address_line_2">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Apartment
                                        #</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="apartment_number">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">City</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="city">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState"
                                        class="col-form-label text-light-black font-16">State</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="state">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Zip</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="zip">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Pay Details</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="text-help">
                                    Use this section to provide additional pay and hiring information for the employee.
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Pay
                                        Rate</label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="pay_rate">
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="inputState" class="col-form-label text-light-black font-14">Pay
                                        Description</label>
                                    <editor formControlName="pay_description" [init]="{
                                        base_url: '/tinymce',
                                        suffix: '.min',
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                    }"></editor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Additional Information</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="text-help">Use this section to publish any additional details about the employee.
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="inputState" class="col-form-label text-light-black font-14">Additional
                                        Information</label>
                                    <editor formControlName="static_html_block" [init]="{
                                        base_url: '/tinymce',
                                        suffix: '.min',
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                    }"></editor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-modal-group text-center">
                <button type="submit" [disabled]="!employeeForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 w-30 md-close">SUBMIT</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #deleteModalBox let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>
    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteUser()" class="btn btn-success  border-r-10">Yes, delete it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>
</ng-template>


<!-- Add Note -->

<ng-template #addNote let-modal>
  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
      <h6 class="card-title mb-0"> Note</h6>
      <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross click')"
          aria-label="Close">
          <span aria-hidden="true">×</span>
      </button>
  </div>
  <div class="modal-body md-b-body">
      <form [formGroup]="noteForm" (ngSubmit)="submitNote(noteForm.value)">
          <div class="form-row">
              <div class="form-group col-md-12">
                  <input type="hidden" formControlName="id" value="">
                  <label class="col-form-label text-light-black font-16">Note <span class="text-danger">*</span></label>
                  <textarea required="" class="form-control rounded-0 form-control-md" id="exampleTextarea" rows="4" formControlName="note"></textarea>
              </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-12">
               <button type="submit" [disabled]="!noteForm.valid" class="action-btn bg-dark-green mr-2">Submit</button>
            </div>
          </div>
      </form>
  </div>
</ng-template>
