<form [formGroup]="employeeUploadNewFileForm"
(ngSubmit)="submitEmployeeUploadNewFile(employeeUploadNewFileForm.value)">
<div class="form-row">
    <div class="form-group col-md-12">
        <div class="card card-shadow-hide">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0"> Upload New File</h6>
            </div>
            <div class="card-body">
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label for="inputState" class="col-form-label text-light-black font-16">Select a
                            Field <span class="text-danger">*</span></label>

                        <ng-select formControlName="custom_field" placeholder="Select" clearAllText="Clear"
                            class="form-control-ng rounded-0">
                            <ng-option *ngFor="let custom_files of allStfCustomFiles"
                                [value]="custom_files.id">
                                {{custom_files.field_name}}
                            </ng-option>
                        </ng-select>
                        <!-- <span class="text-danger"
                            *ngIf="employeeUploadNewFileForm.get('custom_field').errors?.required && employeeUploadNewFileForm.get('custom_field').errors">
                            {{formErrors.custom_field}}</span> -->
                    </div>
                    <div class="form-group col-md-4">
                        <label for="inputState" class="col-form-label text-light-black font-16">
                            Expiration</label>
                        <input class="form-control rounded-0 form-control-md" type="date"
                            formControlName="expiration">
                        <input type="hidden" formControlName="id" id="id">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label for="inputState" class="col-form-label text-light-black font-16">
                            File Upload</label>
                        <div class="file-upload-3">
                            <div class="el-upload__text">
                                <p class="mb-0"><i class="fa fa-cloud-upload" aria-hidden="true"></i></p>
                                Drop file here or <em>click to upload</em>
                            </div>
                            <input class="form-control rounded-0 form-control-md" type="file"
                                formControlName="employee_files"  (change)="convertImageInBase64($event)" accept="image/*,application/pdf"
                               >

                        </div>
                        <p class="mt-4"> {{fileName}} </p>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="btn-modal-group text-center">
    <button type="submit" [disabled]="!employeeUploadNewFileForm.valid" id="submit-btn"
        class="btn btn-success border-r-10 mr-3 w-30 md-close">SUBMIT</button>
</div>
</form>