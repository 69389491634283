import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Console } from 'console';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-training-tests-builder',
  templateUrl: './training-tests-builder.component.html',
  styleUrls: ['./training-tests-builder.component.css']
})
export class TrainingTestsBuilderComponent implements OnInit {
  optionTypeModel:any =[];
  testDetailForm:FormGroup;
  questionForm:FormGroup;
  optionForm:FormGroup;
  currentTestId:any;
  passingPercentage:any=0;
  temp:any=0;
  isUpdate:boolean=false;
  tests:any=[];
  questions:any=[];
  response:any=[];
  singleTests:any=[];
  optionType:any=[{label:'Eassy',value:'Eassy',selected:false},{label:'Fill In',value:'Fill In',selected:false},{label:'Multiple Choice',value:'Multiple Choice',selected:false}];
  currentQuestionId:any;
  deleteId:any;


  formErrorsTestDetail = {
    'name':'',
    'question':'',
    'passing_percentage':'',
    'description':'',
  }

  validationMessagesTestDetail = {
    'name':{
      'required': 'Name is required.',
      'minlength': 'Name must be 3 characters long.'
    }
  }

  constructor(
    private route:ActivatedRoute,
    private api:ApiService,
    private modalService:NgbModal,
    private formBuilder:FormBuilder,
    private toastr:ToastrService
  ) { 
    this.currentTestId = this.route.snapshot.params['id'];
    if(this.currentTestId){
      this.selectTest(this.currentTestId);
    }else{
      this.allTest();
    }
  }

  ngOnInit(): void {
    this.init();
  }

  init(){

    this.questionForm = this.formBuilder.group({
      id:[''],
      content:[''],
      question_type:[''],
      needs_review:['']
    });

    this.testDetailForm = this.formBuilder.group({
      name:['', [Validators.required, Validators.minLength(3)]],
      question:[''],
      passing_percentage:[''],
      description:['']
    });
    this.testDetailForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairsTestDetail(this.testDetailForm);
    });

    this.optionForm = this.formBuilder.group({
      id:[''],
      value:[''],
      correct:['']
    });

  }

  validateKeyValuePairsTestDetail(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairsTestDetail(abstractControl);
      } else {
        this.formErrorsTestDetail[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessagesTestDetail[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrorsTestDetail[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  selectTest(value){
    this.currentTestId=value;
    this.isUpdate=true;
    this.getSingleTest(value);
    this.getTestQuestion(value);
  }

  async allTest(){
    this.api.get('admin/tests/tests_lists').subscribe((result)=>{
      this.tests = result.data;
    },(err)=>{
    });
    return true;
  }

  async getSingleTest(test_id){
    let data = {id:test_id};
    this.api.post('admin/tests/single_test',data).subscribe((result)=>{
      this.response = result;
      this.singleTests = this.response.data;
      let name = document.getElementById(`name`) as HTMLInputElement;
      name.value = this.singleTests.name;
      this.passingPercentage = (this.singleTests.passing_percentage)/1;
      this.temp=this.passingPercentage*10;
      let description = document.getElementById(`description`) as HTMLInputElement;
      description.value = this.singleTests.description;
    },(err)=>{
    });
    return true;
  }


  getCurrentQuestion(index){
      let currentQuestion = this.questions[index];
      const needsReview = document.getElementById(`needs_review${index}`) as HTMLInputElement;
      if(needsReview.value && Number(currentQuestion.needs_review) ===1){
       needsReview.click();
      }else{
        needsReview.value='0';
      }
       const updateButtons = [...Array.from(document.querySelectorAll('.updateButton'))]
       updateButtons.forEach(btn => {
        btn.classList.add('hidden');
       });
        const collapse = document.getElementById(`collapse${index}`) as HTMLElement;
        const editElementRef = document.getElementById(`edit${index}`) as HTMLElement;

        collapse.classList.contains('collapsed') ? editElementRef.classList.remove('hidden') : editElementRef.classList.add('hidden');
        collapse.classList.contains('collapsed') ? this.currentQuestionId=currentQuestion.id : this.currentQuestionId='';
      return true;
  }

  passPer(){
    if(this.passingPercentage>=0 && this.passingPercentage<=.9){
      this.temp = this.temp+1;
      this.passingPercentage = this.temp/10;
    }
  }

  passPerMinus(){
    if(this.passingPercentage>=.1 && this.passingPercentage<=1){
      this.temp = this.temp-1;
      this.passingPercentage = this.temp/10;
    }
  }

  getTestQuestion(test_id){
    this.optionTypeModel = [];
    let data = {id:test_id};
    this.api.post('admin/questions/test_question',data).subscribe((result)=>{
      this.response = result;
      this.questions = this.response.data;
      this.response.data.forEach((element) => {
        this.optionTypeModel.push(element?.question_type)
      });
      console.log(this.optionTypeModel);
    },(err)=>{
    });
    return true;
  }

  addTest(content){
    this.testDetailForm.reset();
    this.modalService.open(content, { size:'xl',centered:true });
    this.api.get('admin/questions/question_lists').subscribe((result)=>{
      this.questions = result.data;
      this.passingPercentage=0;
    },(err)=>{
    });
  }

  submitTestDetailData(data){
    data.passingPercentage=this.passingPercentage;
    this.api.post('admin/tests/create',data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

  addQuestion(content){
    this.questionForm.reset();
    this.modalService.open(content,{ size:'lg',centered:true });
  }

  submitQuestion(data){
    data.test_id = this.currentTestId;
    let endPoint=(data.id)?'update':'create';
    this.api.post('admin/questions/'+endPoint,data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.getTestQuestion(this.currentTestId);
      this.modalService.dismissAll();
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

  updateQuestionType(event,index){
    this.optionTypeModel[index]=event;
  }

  updateQuestion(index){
    let id =  document.getElementById(`id${index}`) as HTMLInputElement;
    let content = document.getElementById(`content${index}`) as HTMLInputElement;
    let question_type = this.optionTypeModel[index];
    let needs_review = document.getElementById(`needs_review${index}`) as HTMLInputElement;

    let data = {id:id.value,content:content.value,question_type:question_type,needs_review:needs_review.value};
    this.submitQuestion(data);
  }


  switchChange(event){
    var id = event.target.id;
    var value=(Number(event.target.value))?0:1;
    let newId =  document.getElementById(`${id}`) as HTMLInputElement;
    newId.value=value+'';
    console.log(value);
    return true;
  }

  deleteModal(content,current_row){
    this.deleteId = current_row.id;
    this.modalService.open(content,{ size:'sm',centered:true });
  }

  questionDelete(){
    let data = {id:this.deleteId};
    this.api.post('admin/questions/delete',data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.getTestQuestion(this.currentTestId);
      this.modalService.dismissAll();
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

  addOption(content){
    if(this.currentQuestionId){
      this.optionForm.reset();
      this.modalService.open(content,{ size:'lg',centered:true });
    }else{
      this.toastr.error('Drag this option to a question to get started.',"Error");
    }
  }

  submitOption(data){
    data.question_id = this.currentQuestionId;
    let endPoint = (data.id)?'update':'create';
    if(this.currentQuestionId){
        this.api.post('admin/question_options/'+endPoint,data).subscribe((result)=>{
          this.response = result;
          this.toastr.success(this.response.message,"Success");
          this.getTestQuestion(this.currentTestId);
          this.modalService.dismissAll();
        },(err)=>{
          this.toastr.error(err.error.message,"Error");
        });
    }else{
      this.toastr.error('Drag this option to a question to get started.',"Error");
    }
  }

  getCurrentOption(content,question_index,option_index){
    let ques = this.questions[question_index];
    let cur_opt = ques.question_options[option_index];
    this.optionForm.patchValue(cur_opt);
    this.modalService.open(content,{ size:'lg',centered:true });
  }

  deleteOption(){
    let data = {id:this.deleteId};
    this.api.post('admin/question_options/delete',data).subscribe((result)=>{
      this.response = result; 
      this.toastr.success(this.response.message,"Success");
      this.getTestQuestion(this.currentTestId);
      this.modalService.dismissAll();
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

  

}
