<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4">
                <div class="row">
                    <div class="col-xl-12 height-card box-margin" *ngIf="isClientdata && isEmployee">
                        <div class="card mb-0">
                            <div class="card-header bg-green">
                                <h6 class="card-title mb-0">Select A Type..</h6>
                            </div>
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <p>Contact Type </p>
                                        <ng-select [(ngModel)]='isSelecteContactType' placeholder="Select"
                                            (change)="selectaType($event)">
                                            <ng-option value="clients">Clients</ng-option>
                                            <ng-option value="employees">Employees</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Employee Box -->
                    <div class="col-xl-12 height-card box-margin" *ngIf=" !isBlank && !isTrue">
                        <div class="card mb-0">
                            <div class="card-header bg-green">
                                <h6 class="card-title mb-0">Employees Selection
                                    <span style="margin-left:175px;" *ngIf="!isEmployee "><a href="javascript:void(0);"
                                            (click)="RefreshPage()"><i class="fa fa-repeat" aria-hidden="true"
                                                style="color:black"></i></a></span>
                                </h6>
                            </div>
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <p>Employee </p>
                                        <ng-select placeholder="Select" (change)="selectEmployee($event)">
                                            <ng-option id="SelectEmployee" *ngFor="let employee of allEmployees"
                                                [value]="employee.id">
                                                {{employee.name}} ({{employee.specialty.name}})</ng-option>
                                        </ng-select>
                                        <div class="d-grid gap-2 d-md-block mt-3" *ngIf="!isEmployee ">
                                            <!-- <button class="btn btn-success" type="button"><i class="fa fa-search"
                                                    aria-hidden="true"></i> View Profile </button>&nbsp; -->
                                            <a class="btn btn-success"
                                                [routerLink]="['/dashboard/employee-profile/'+employeeId]"><i
                                                    class="fa fa-search" aria-hidden="true"></i> View Profile </a>&nbsp;
                                            <button class="btn btn-success"
                                                (click)="openEmailSchedule(emailScheduleModal)" type="button"><i
                                                    class="fa fa-calendar" aria-hidden="true"></i> Email
                                                Schedule</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Employee Box  End-->
                    <!-- Employee  Box 2 Start------->
                    <div class="col-xl-12 height-card box-margin" *ngIf="!isEmployee">
                        <div class="card mb-0">
                            <div class="card-header bg-green">
                                <h6 class="card-title mb-0">Filter Options</h6>
                            </div>
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label class="ml-3">Filter item</label>
                                        <div class="col-md-4">
                                            <div class="form-group  d-flex mb-20">
                                                <div class="form-check pl-0 mb-2" (click)="getUserType(1)">
                                                    <label class="form-check-label button-radio-btn">
                                                        <input type="radio" class="form-check-input radio-btn-button"
                                                            value="Schedule" name="filter_options" checked>
                                                        <div class="layer"></div>
                                                        <div class="button"><span>Schedule</span></div>
                                                    </label>
                                                </div>
                                                <div class="form-check pl-0 mb-2" (click)="getUserType(2)">
                                                    <label class="form-check-label button-radio-btn">
                                                        <input type="radio" class="form-check-input radio-btn-button"
                                                            value="Availability" name="filter_options">
                                                        <div class="layer"></div>
                                                        <div class="button"><span>Availability</span></div>
                                                    </label>

                                                </div>
                                                <div class="form-check pl-0 mb-2" (click)="getUserType(3)">
                                                    <label class="form-check-label button-radio-btn">
                                                        <input type="radio" class="form-check-input radio-btn-button"
                                                            value="All" name="filter_options">
                                                        <div class="layer"></div>
                                                        <div class="button"><span>All</span></div>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="button" *ngIf=" isAvailability">
                                    <button class="btn btn-success btn-sm" type="button"
                                        (click)="openEmailRequest(emailRequestModal)"><i class="fa fa-envelope-o"
                                            aria-hidden="true"></i> Email Request</button>&nbsp;
                                    <button class="btn btn-success btn-sm" type="button"
                                        (click)="openAvailabilityPDF(AvailabilityPdfModal)"><i
                                            class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                        Availability(PDF)</button>

                                    <a class="btn btn-success" style="margin:10px 0px 0px 50px ;"
                                        href="{{filePath}}client_schedule_images/client_image.jpg"><i
                                            class="fa fa-file-text-o" aria-hidden="true"></i> Availability(Docx)</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Employee  Box 2 End  -->
                    <!-- Employee Box 3 start-->
                    <div class="col-xl-12 height-card box-margin" *ngIf=" !isEmployee">
                        <div class="card mb-0">
                            <div class="card-header bg-green">
                                <h6 class="card-title mb-0">Attendance</h6>
                            </div>
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <p><b>Time Period:</b> {{lastMothDate | date:'MM/dd/yyyy' }} - {{toDayDate |
                                            date:'MM/dd/yyyy' }}</p>
                                        <p><b>Current Tally: {{totalAmount}} &nbsp;</b>
                                            <button class="btn btn-success btn-sm" type="button"
                                                (click)="openAdjustAmount(adjustAmountModal)"><i class="fa fa-pencil"
                                                    aria-hidden="true"></i></button>&nbsp;
                                            <button class="btn btn-success btn-sm" type="button"
                                                (click)="openCreateTally(createTallyModal,1)"><i class="fa fa-plus"
                                                    aria-hidden="true">1</i></button>&nbsp;
                                            <button class="btn btn-success btn-sm" type="button"
                                                (click)="openCreateTally(createTallyModal,2)"><i class="fa fa-plus"
                                                    aria-hidden="true"></i>2</button>&nbsp;
                                            <button class="btn btn-success btn-sm" type="button"
                                                (click)="openCreateTally(createTallyModal,4)"><i class="fa fa-plus"
                                                    aria-hidden="true"></i>4</button>&nbsp;

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Employee box 3 End -->
                    <!-- Client Box Start -->
                    <div class="col-xl-12 height-card box-margin" *ngIf="!isBlank && isDefault && isTrue">
                        <div class="card mb-0">
                            <div class="card-header bg-green">
                                <h6 class="card-title mb-0">Client Selection<span style="margin-left:215px;"
                                        *ngIf=" !isClientdata"><a href="javascript:void(0);" (click)="RefreshPage()"><i
                                                class="fa fa-repeat" aria-hidden="true"
                                                style="color:black"></i></a></span></h6>
                            </div>
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label>Filter By Client(s)</label>
                                        <div class="form-check pl-0 mb-2">
                                            <label class="form-check-label button-radio-btn">
                                                <input type="radio" class="form-check-input radio-btn-button"
                                                    value="Individual" name="client" [checked]="!isFilterClient">
                                                <div class="layer"></div>
                                                <div class="button" (click)="filterByClient(1)"><span>Individual</span>
                                                </div>
                                            </label>
                                            <label class="form-check-label button-radio-btn" *ngIf="isClientdata">
                                                <input type="radio" class="form-check-input radio-btn-button"
                                                    value="Multiple" name="client" [checked]="isFilterClient">
                                                <div class="layer"></div>
                                                <div class="button" (click)="filterByClient(2)"><span>Multiple
                                                    </span></div> 
                                            </label>
                                            <label class="form-check-label button-radio-btn" *ngIf="!isClientdata">
                                                <input type="radio" class="form-check-input radio-btn-button"
                                                    value="Multiple" name="client">
                                                <div class="layer"></div>
                                                <div class="button" (click)="filterByClient(3)"><span>Multiple
                                                    </span></div>
                                            </label>
                                        </div>
                                        <p>Client(s)</p>
                                        <ng-select placeholder="Select" *ngIf="isFilterClient"
                                            [multiple]="isFilterClient" (change)="selectMultipleClient($event)">
                                            <ng-option *ngFor="let client of allClients" [value]="client.id">
                                                {{client.company_name}}</ng-option>
                                        </ng-select>
                                        <ng-select placeholder="Select" *ngIf="!isFilterClient"
                                            (change)="selectClient($event)">
                                            <ng-option *ngFor="let client of allClients" [value]="client.id">
                                                {{client.company_name}}</ng-option>
                                        </ng-select>
                                        <div class="d-grid gap-2 d-md-block" style="margin:25px 50px 0px 100px;"
                                            *ngIf="!isClientdata">
                                            <a class="btn btn-success"
                                                [routerLink]="['/crm/customer-profile/'+clientId]"><i
                                                    class="fa fa-search" aria-hidden="true"></i> View Profile</a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Client Box End -->
                    <!-- Client Box 2 Start -->
                    <div class="col-xl-12 height-card box-margin" *ngIf="!isClientdata ">
                        <div class="card mb-0">
                            <div class="card-header bg-green">
                                <h6 class="card-title mb-0 mi-3 ">Filter Options</h6>
                            </div>
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label class="ml-4">Filter item</label>
                                        <div class="col-md-4">
                                            <div class="form-group  d-flex mb-20 ">
                                                <div class="form-check pl-0 mb-2" (click)="userData(3)">
                                                    <label class="form-check-label button-radio-btn">
                                                        <input type="radio" class="form-check-input radio-btn-button"
                                                            value="Schedule" name="btn1" checked>
                                                        <div class="layer"></div>
                                                        <div class="button"><span>All</span></div>
                                                    </label>
                                                </div>
                                                <div class="form-check pl-0 mb-2" (click)="userData(1)">
                                                    <label class="form-check-label button-radio-btn">
                                                        <input type="radio" class="form-check-input radio-btn-button"
                                                            value="Availability" name="btn1">
                                                        <div class="layer"></div>
                                                        <div class="button"><span>Individual</span></div>
                                                    </label>

                                                </div>
                                                <div class="form-check pl-0 mb-2" (click)="userData(0)">
                                                    <label class="form-check-label button-radio-btn">
                                                        <input type="radio" class="form-check-input radio-btn-button"
                                                            value="All" name="btn1">
                                                        <div class="layer"></div>
                                                        <div class="button"><span>Multiple</span></div>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="form-group col-md-12" *ngIf="isIndividual">
                                            <p>Employee</p>
                                            <ng-select placeholder="Select" (change)="selectEmployeeId($event)">
                                                <ng-option *ngFor="let employee of allEmployees" [value]="employee.id">
                                                    {{employee.name }} </ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="form-group col-md-12" *ngIf="isMultipel">
                                            <p>Employee(s)</p>
                                            <ng-select placeholder="Select" [multiple]="true" [(ngModel)]="employeeIds">
                                                <ng-option *ngFor="let employee of allEmployees" [value]="employee.id">
                                                    {{employee.name}}</ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="form-group col">
                                            <label for="" class="col-form-label text-light-black font-16">Start
                                                Date</label>
                                            <input class="form-control rounded-0 form-control-md"
                                                [(ngModel)]="start_date" type="date">
                                        </div>
                                        <div class="form-group col">
                                            <label for="" class="col-form-label text-light-black font-16">End
                                                Date</label>
                                            <input class="form-control rounded-0 form-control-md" [(ngModel)]="end_date"
                                                type="date">
                                        </div>
                                        <button class="btn btn-success btn-sm" (click)="downloadPDF(viewPdfModal)"
                                            type="button" style="margin:5px 0px 0px 100px ;"><i class="fa fa-calendar"
                                                aria-hidden="true"></i> Download PDF</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Client Box 2 End -->
                </div>
            </div>
            <div class="col-md-8 box-margin">
                <div class="card">
                    <div class="card-body text-center">
                        <div class="row text-center">
                            <div class="col-md-4">
                                <div class="btn-group">
                                    <div class="btn btn-primary" mwlCalendarPreviousView [view]="view"
                                        [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                                        Previous
                                    </div>
                                    <div class="btn btn-outline-secondary pointer" mwlCalendarToday
                                        [(viewDate)]="viewDate">
                                        Today
                                    </div>
                                    <div class="btn btn-primary" mwlCalendarNextView [view]="view"
                                        [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                                        Next
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
                            </div>
                            <div class="col-md-4">
                                <div class="btn-group">
                                    <div class="btn btn-primary" (click)="AddEvent()"> Add Evenet </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div [ngSwitch]="view">
                            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate"
                                [events]="events" [refresh]="refreshCalendar"
                                [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
                                (eventClicked)="handleEvent('Clicked', $event.event)">
                            </mwl-calendar-month-view>
                            <!-- <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate"
                                [refresh]="refresh" (eventTimesChanged)="eventTimesChanged($event)">
                            </mwl-calendar-week-view>
                            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate"
                                [refresh]="refresh" (eventTimesChanged)="eventTimesChanged($event)">
                            </mwl-calendar-day-view> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Email Schedule Modal -->
<ng-template #emailScheduleModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Co-nfirm</h6>
    </div>

    <div class="modal-body md-b-body">
        <p> Are you sure you would like to send this schedule?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="openEmailScheduleBox(openEmailModal)" class="btn btn-success  border-r-10">
                Yes </button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>

</ng-template>

<!-- open Email Modal -->
<ng-template #openEmailModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0">Email Form</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">

        <form [formGroup]="EmailScheduleForm" (ngSubmit)="sendEmailSchedule(EmailScheduleForm.value)">

            <div class="row">
                <div class="col-md-12">
                    <label> Would you like to add a message to this email? </label>
                    <div class="form-group">
                        <editor id="message" formControlName="message" [init]="{
                            base_url: '/tinymce',
                            suffix: '.min',
                            height: 350,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                        }"></editor>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="box-footer">
                        <div class="pull-right">
                            <button type="submit" class="btn bg-dark-blue btn-sm mb-2"><i class="fa fa-envelope-o"></i>
                                Yes</button>
                            &nbsp;
                            <button type="reset" class="btn bg-dark-blue btn-sm mb-2"><i class="fa fa-times"></i>
                                No</button>
                        </div>
                    </div>
                </div>


            </div>
        </form>

    </div>

</ng-template>

<!-- open adjust Amount Modal -->
<ng-template #adjustAmountModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0">Adjust Amount</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">

        <form [formGroup]="adjustAmountModalForm" (ngSubmit)="submitAdjustAmountForm(adjustAmountModalForm.value)">

            <div class="row">
                <div class="col-md-12">
                    <label> Please enter a new tally amount for this employee. </label>
                    <div class="form-group">
                        <input type="text" class="form-control" [(ngModel)]="amount" formControlName="amount" value="0">
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="box-footer">
                        <div class="pull-right">
                            <button type="submit" class="btn btn-success btn-sm mb-2"> Yes</button>
                            &nbsp;
                            <button type="reset" class="btn btn-danger btn-sm mb-2"
                                (click)="modal.dismiss('Cross click')"> No</button>
                        </div>
                    </div>
                </div>


            </div>
        </form>

    </div>
</ng-template>

<!-- open create Tally Modal -->
<ng-template #createTallyModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0">Create Tally</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">

        <form [formGroup]="createTallyModalForm" (ngSubmit)="submitcreateTallyForm(createTallyModalForm.value)">

            <div class="row">
                <div class="col-md-12">
                    <label> Please enter a note for this entry. </label>
                    <div class="form-group">
                        <textarea required="" class="form-control rounded-0 form-control-md" id="exampleTextarea"
                            rows="4" formControlName="note"></textarea>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="box-footer">
                        <div class="pull-right">
                            <button type="submit" class="btn btn-success btn-sm mb-2"> Yes</button>
                            &nbsp;
                            <button type="reset" class="btn btn-danger btn-sm mb-2"
                                (click)="modal.dismiss('Cross click')"> No</button>
                        </div>
                    </div>
                </div>


            </div>
        </form>

    </div>
</ng-template>


<!-- open email Request Modal -->
<ng-template #emailRequestModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0">Availability Request</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <form [formGroup]="emailRequestForm" (ngSubmit)="submitFmailRequestForm(emailRequestForm.value)">
            <div class="row">
                <div class="col-md-12">
                    <label> What is the last scheduled date of this employee? <span class="text-danger">*</span>
                    </label>
                    <div class="form-group">
                        <input type="date" class="form-control" formControlName="start_date">
                    </div>
                </div>
                <div class="col-md-12">
                    <label>What date should this schedule end at? <span class="text-danger">*</span></label>
                    <div class="form-group">
                        <input type="date" class="form-control" formControlName="end_date">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="box-footer">
                        <div class="pull-left">
                            <button type="submit" [disabled]="!emailRequestForm.valid"
                                class="btn btn-success btn-sm mb-2"> Send</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<!-- view Pdf Modal -->
<ng-template #viewPdfModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <!-- <h6 class="card-title mb-0">Email Form</h6> -->
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <div class="row">
            <div class="col-md-12 box-margin">
                <div class="card">
                    <div class="card-body">
                        <iframe id="iframeid" [src]="getPdfPath(pdfPath)" width="100%" height="500px"></iframe>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="box-footer">
                    <div class="pull-right">
                        <a target="_blank" [href]="getPdfPath(pdfPath)" class="link-font--larger"><i
                                class="fa fa-share mr-1"></i>View File
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Availability Pdf Modal -->
<ng-template #AvailabilityPdfModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <!-- <h6 class="card-title mb-0">Email Form</h6> -->
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <div class="row">
            <div class="col-md-12 box-margin">
                <div class="card">
                    <div class="card-body">
                        <iframe id="iframeid" [src]="getPdfPath(pdfPath)" width="100%" height="500px"></iframe>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="box-footer">
                    <div class="pull-right">
                        <a target="_blank" [href]="getPdfPath(pdfPath)" class="link-font--larger"><i
                                class="fa fa-share mr-1"></i>View File
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Show event information -->
<ng-template #modalContent let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">Event Details</h5>
        <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <div>
                <label for=""></label> <span> {{modalData?.event.title}}</span>
            </div>
            <div>
                <label for=""></label> <span> {{modalData?.event.start | date:"hh:mm a"}} - {{modalData?.event.end |
                    date:"hh:mm a"}}</span>
            </div>
            <div>
                <label *ngIf="modalData?.event.event_info.client_cancelled == 1"><span>CC,&nbsp;</span></label>
                <label *ngIf="modalData?.event.event_info.employee_cancelled == 1"><span>ECO,&nbsp;</span></label>
                <label *ngIf="modalData?.event.event_info.employee_late == 1"><span>EL,&nbsp;</span></label>
                <label *ngIf="modalData?.event.event_info.employee_ncns == 1"><span>NC/NS,&nbsp;</span></label>
                <label *ngIf="modalData?.event.event_info.employee_left_early == 1"><span>LE,&nbsp;</span></label>
                <label *ngIf="modalData?.event.event_info.employee_nos == 1"><span>NOS,&nbsp;</span></label>
                <label *ngIf="modalData?.event.event_info.double_shift == 1"><span>DOUBLE</span></label>

            </div>
            <div>
                <label for=""></label> <span> {{modalData?.event?.event_info?.client?.company_name}}</span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="close()">
            OK
        </button>
    </div>
</ng-template>

<!-- New Calender Modal -->
<ng-template #calenderFormModal let-modal>
    <div class="justify-content-right">
        <button type="button" class="close action-btn modal-dismiss mr-10 p-1" (click)="modal.dismiss('Cross click')"
            aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <form [formGroup]="calenderForm" (ngSubmit)="submitCalender(calenderForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">New Calendar Item</h6>
                        </div>
                        <div class="card-body">

                            <div class="form-row">
                                <div class="col-md-12">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Client<span
                                            class="text-danger">*</span></label>
                                </div>
                                <div class="form-group col-md-4">
                                    <!-- <input class="form-control rounded-0 form-control-md" type="text"> -->
                                    <ng-select placeholder="Select" formControlName="client_id">
                                        <ng-option class="col-md-8" *ngFor="let client of allClients"
                                            [value]="client.id">
                                            {{client.company_name}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-4">
                                    <button class="btn btn-info btn-sm" type="button"
                                        (click)="selectShift(selectShiftFormModal)"><i class="fa fa-table"
                                            aria-hidden="true"></i> Set Shift</button>
                                </div>
                                
                            </div>

                            <div class="form-row" *ngIf="isCalendardate">
                                <div class="col-md-12">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Event Date </label>
                                </div>
                                <div class="form-group col-md-8">
                                    <input class="form-control rounded-0 form-control-md" type="date"
                                        formControlName="start_date" >
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-12">
                                    <label for="inputState" class="col-form-label text-light-black font-16">Start/End
                                        Time <span class="text-danger">*</span></label>
                                </div>
                                <div class="form-group col-md-4">
                                    <ng-select placeholder="Select" formControlName="shift_start_time"
                                        [(ngModel)]="shiftStartTime">
                                        <ng-option class="col-md-8" *ngFor="let time of timeframes"
                                            [value]="time.value.from">
                                            {{time.value.from }}</ng-option>
                                    </ng-select>
                                    <!-- <input class="form-control rounded-0 form-control-md" type="time"
                                        formControlName="start_date"> -->
                                </div>
                                <div class="form-group col-md-4">
                                    <ng-select placeholder="Select" formControlName="shift_end_time"
                                        [(ngModel)]="shiftEndTime">
                                        <ng-option class="col-md-8" *ngFor="let time of timeframes"
                                            [value]="time.value.to">
                                            {{time.value.to }} </ng-option>
                                    </ng-select>
                                    <!-- <input class="form-control rounded-0 form-control-md" type="time"
                                        formControlName="end_datetime"> -->
                                </div>
                                <div class="col-md-12">
                                    <label for="inputState"
                                        class="col-form-label text-light-black font-16">Notes</label>
                                </div>
                                <div class="form-group col-md-12">
                                    <textarea class="form-control" formControlName="notes" rows="5"></textarea>
                                </div>
                            </div>



                            <div class="form-group col-md-12">
                                <input type="checkbox" id="client_cancelled" value="0"
                                    formControlName="client_cancelled">
                                <label for="client_cancelled">&nbsp;Client Cancelled (CC)</label><br>
                                <input type="checkbox" id="employee_cancelled" value="0"
                                    formControlName="employee_cancelled">
                                <label for="employee_cancelled">&nbsp;Employee Called Off (ECO)</label><br>
                                <input type="checkbox" id="employee_late" value="0" formControlName="employee_late">
                                <label for="employee_late">&nbsp;Employee Late (EL)</label><br>
                                <input type="checkbox" id="employee_ncns" value="0" formControlName="employee_ncns">
                                <label for="employee_ncns">&nbsp;Employee NC/NS (NC/NS)</label><br>
                                <input type="checkbox" id="employee_left_early" value="0"
                                    formControlName="employee_left_early">
                                <label for="employee_left_early">&nbsp;Employee Left Early (LE)</label><br>
                                <input type="checkbox" id="double_shift" value="0" formControlName="double_shift">
                                <label for="double_shift">&nbsp;Double Shift (DOUBLE)</label><br>
                                <input type="checkbox" id="employee_nos" value="0" formControlName="employee_nos">
                                <label for="employee_nos">&nbsp;Not On Schedule (NOS)</label><br>
                            </div>
                            <div class="form-group text-center">
                                <button class="btn btn-success border-r-10 mr-3 w-30 md-colse"
                                    type="submit">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<!-- calender event Delete -->
<ng-template #calenderEventDeleteModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>

    <div class="modal-body md-b-body">
        <p>Are you sure you would like to delete the following shifts?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="calenderEventDelete()" class="btn btn-success  border-r-10">Yes, delete
                it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>

</ng-template>
<!-- calender event Delete -->
<ng-template #calenderClientEventDeleteModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>

    <div class="modal-body md-b-body">
        <p>Are you sure you would like to delete the following shifts?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="calenderClientEventDelete()" class="btn btn-success  border-r-10">Yes, delete
                it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>

</ng-template>

<ng-template #selectShiftFormModal let-modal>
    <div class="justify-content-right">
        <button type="button" class="close action-btn modal-dismiss mr-10 p-1" (click)="modal.dismiss('Cross click')"
            aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <form>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Shift Selection</h6>
                        </div>
                        <div class="card-body shift ">

                            <div class="selectDate1" style="float: left;">
                                <ul class="shift-selection">
                                    <li *ngFor="let times of timeframes;let i= index">
                                        <label for="readio-btn-{{i}}" role="radio">
                                            <input type="radio" name="btn" id="readio-btn-{{i}}" value="{{times.value}}"
                                                (change)="onChange(times.value)" />&nbsp;
                                            {{times.label}}
                                        </label>
                                    </li>
                                </ul>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>