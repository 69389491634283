<div class="row">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Application Settings</h6>
            </div>

            <div class="card-body">
                
                <form [formGroup]="theamForm" (ngSubmit)="submitForm(theamForm.value)">
                    <input type="hidden" formControlName="id">
                    <div class="row">
                        <div class="col-sm-12">
                            <label for="">Application Name</label>
                            <input class="form-control" formControlName="theme_application_name">
                        </div>
    
                        <div class="col-sm-12 mt-2">
                            <label for="">Custom Css</label>
                            <div class="editor" #codecss></div>
                        </div>
    
                        <div class="col-sm-12 mt-2 mb-4">
                            <label for="">Custom Script</label>
                            <div class="editor" #codejs></div>
                        </div>
    
                    </div>
    
                    <div class="row">
                        <div class="col-sm-12">
                            <button class="action-btn bg-dark-blue">Submit</button>
                        </div>
                    </div> 
                </form>

            </div>

        </div>
    </div>
</div>