<div class="row">
  <div class="col-xl-12 box-margin">
      <div class="card">
          <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
              <h6 class="card-title mb-0">Call Scripts</h6>
              <!-- <ul class="nav nav-tabs mb-0" role="tablist">
                  <li class="nav-item">
                      <a class="nav-link show active" id="active-01-tab" data-toggle="tab" href="#active-01" role="tab" aria-controls="active-01" aria-selected="true">Active</a>
                  </li>
                  <li class="nav-item">
                      <a class="nav-link mr-0" id="inactive-02-tab" data-toggle="tab" href="#inactive-02" role="tab" aria-controls="inactive-02" aria-selected="false">Inactive</a>
                  </li>

              </ul> -->
              <div class="btn-group">

                  <button class="action-btn bg-dark-green mr-2" (click)="addCallScript(callScriptFormModal)"><img src="assets/img/svg-icon/add-white.svg" width="20" /></button>
                 <!--  <button class="action-btn bg-dark-blue" data-toggle="modal" data-target="#exampleModalRight"><img src="assets/img/svg-icon/filter-white.svg" width="20" /></button> -->
              </div>
          </div>
          <div class="card-body">
              <div class="tab-content list_table" id="ticketList">
                  <div class="table-responsive list_table">
                      <table class="table  table-nowrap mb-0">
                          <thead>
                              <tr>
                                  <th class="pt-2">Name</th>
                                  <th class="pt-2">Content</th>
                                  <th class="pt-2">Active</th>
                                  <th class="pt-2">Create At</th>
                                  <th class="pt-2">Updated At</th>
                                  <th class="pt-2 text-center">Actions</th>
                              </tr>
                          </thead>
                          <tbody>
                              <ng-container *ngIf="callscripts.length > 0">
                                  <tr *ngFor="let script of callscripts | paginate: { id: 'call_script_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalRecords }; let i=index">
                                      <td class="text-black">{{script.name}}</td>
                                      <td class="text-gray">{{script.content}}</td>
                                      <td class="text-gray" *ngIf="script.active==1">Yes</td>
                                      <td class="text-gray" *ngIf="script.active==0">No</td>
                                      <td class="text-black">{{script.created_at | date : 'MM/dd/yyyy' }}</td>
                                      <td class="text-black">{{script.updated_at | date : 'MM/dd/yyyy' }}</td>
                                      <td class="text-right">
                                        <a href="javascript:void(0)" class="mr-2 action-btn bg-edit" title="Update Call Script" (click)="editCallScript(callScriptFormModal,i)"><img src="assets/img/svg-icon/copy.svg" width="20" /></a>
                                        <a href="javascript:void(0)" class="action-btn bg-red" title="Delete" (click)="deleteModal(callScriptDeleteModal,script)"><img src="assets/img/svg-icon/delete.svg" width="20" /></a>
                                      </td>
                                  </tr>
                              </ng-container>
                          </tbody>
                      </table>
                      <pagination-controls class="pagination-controls-theme" id="call_script_pagination" (pageChange)="getCallScript(page = $event)" maxSize="9" directionLinks="true"
                      autoHide="true" responsive="true">
                      </pagination-controls>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- Add Call Script  -->

<ng-template #callScriptFormModal let-modal>

<div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
  <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross  click')"
      aria-label="Close">
      <span aria-hidden="true">×</span>
  </button>
</div>

<div class="modal-body md-b-body">
  <form [formGroup]="callScriptForm" (ngSubmit)="submitCallScriptData(callScriptForm.value)">
    <div class="form-row">

      <div class="form-group col-md-12">

        <div class="card card-shadow-hide">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Script Details</h6>
            </div>
            <div class="card-body">

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="name" class="col-form-label text-light-black font-16">Name<span class="text-danger">*</span></label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="name">
                  <span class="text-danger"
                      *ngIf="callScriptForm.get('name').errors?.required || callScriptForm.get('name').errors?.minlength && callScriptForm.get('name').errors">
                      {{formErrors.name}}</span>
                  <input type="hidden" formControlName="id" value="">
                </div>

                  <div class="form-group col-md-12">
                      <label for="content" class="col-form-label text-light-black font-14">Content</label>
                      <editor formControlName="content" [init]="{
                          base_url: '/tinymce',
                          suffix: '.min',
                          height: 350,
                          menubar: false,
                          plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                      }"></editor>
                  </div>

              </div>

            </div>
        </div>
      </div>



    </div>
    <div class="btn-modal-group">
        <button type="submit" [disabled]="!callScriptForm.valid" id="submit-btn" class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
    </div>
  </form>
</div>

</ng-template>

<!-- Delete  -->
<ng-template #callScriptDeleteModal let-modal>

  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
      <h6 class="card-title mb-0 text-center">Delete</h6>
  </div>

  <div class="modal-body md-b-body">
      <p>Are you sure you want to delete?</p>
      <div class="btn-modal-group d-flex justify-content-between">
          <button type="button" (click)="deleteCallScript()" class="btn btn-success  border-r-10">Yes, delete it</button>
          <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
      </div>
  </div>

</ng-template>

<!-- Right Alight Modal -->
<div class="modal fade drawer right-align" id="exampleModalRight" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Right Align Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
