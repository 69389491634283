<div class="row">
  <div class="col-xl-12 box-margin">
      <div class="card">
          <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
              <h6 class="card-title mb-0">Certifications</h6>
              <div class="btn-group">

                  <button class="action-btn bg-dark-green mr-2" (click)="addCertification(certificationFormModal)"><img src="assets/img/svg-icon/add-white.svg" width="20" /></button>
                  <!-- <button class="action-btn bg-dark-blue" data-toggle="modal" data-target="#exampleModalRight"><img src="assets/img/svg-icon/filter-white.svg" width="20" /></button> -->
              </div>
          </div>
          <div class="card-body">
              <div class="tab-content list_table">
                  <div class="table-responsive list_table">
                      <table class="table  table-nowrap mb-0">
                          <thead>
                              <tr>
                                  <th class="pt-2">Name</th>
                                  <th class="pt-2">Description</th>
                                  <th class="pt-2">Active</th>
                                  <th class="pt-2">Create At</th>
                                  <th class="pt-2">Updated At</th>
                                  <th class="pt-2 text-center">Actions</th>
                              </tr>
                          </thead>
                          <tbody>
                              <ng-container *ngIf="certifications.length > 0">
                                  <tr *ngFor="let certification of certifications | paginate: { id: 'certification_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalRecords }; let i=index">
                                      <td class="text-black">{{certification.name}}</td>
                                      <td class="text-gray">{{certification.description}}</td>
                                      <td class="text-gray" *ngIf="certification.active==1">Yes</td>
                                      <td class="text-gray" *ngIf="certification.active==0">No</td>
                                      <td class="text-black">{{certification.created_at | date : 'MM/dd/yyyy' }}</td>
                                      <td class="text-black">{{certification.updated_at | date : 'MM/dd/yyyy' }}</td>
                                      <td class="text-right">
                                        <a href="javascript:void(0)" class="mr-2 action-btn bg-edit" title="Update Topic" (click)="editCertification(certificationFormModal,i)"><img src="assets/img/svg-icon/edit.svg" width="20" /></a>
                                        <a href="javascript:void(0)" class="action-btn bg-red" title="Delete" (click)="deleteModal(certificationDeleteModal,certification)"><img src="assets/img/svg-icon/delete.svg" width="20" /></a>
                                      </td>
                                  </tr>
                              </ng-container>
                          </tbody>
                      </table>
                      <pagination-controls class="pagination-controls-theme" id="certification_pagination" (pageChange)="getCertification(page = $event)" maxSize="9" directionLinks="true"
                      autoHide="true" responsive="true">
                      </pagination-controls>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- Add Call Script  -->

<ng-template #certificationFormModal let-modal>

  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
    <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross  click')"
        aria-label="Close">
        <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="modal-body md-b-body">
    <form [formGroup]="certificationForm" (ngSubmit)="submitCertificationData(certificationForm.value)">
      <div class="form-row">

        <div class="form-group col-md-12">

          <div class="card card-shadow-hide">
              <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                  <h6 class="card-title mb-0">Certification Details</h6>
              </div>
              <div class="card-body">

                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="name" class="col-form-label text-light-black font-16">Name<span class="text-danger">*</span></label>
                    <input class="form-control rounded-0 form-control-md" type="text" formControlName="name">
                    <span class="text-danger"
                        *ngIf="certificationForm.get('name').errors?.required || certificationForm.get('name').errors?.minlength && certificationForm.get('name').errors">
                        {{formErrors.name}}</span>
                    <input type="hidden" formControlName="id" value="">
                  </div>

                  <div class="form-group col-md-12">
                    <label for="description" class="col-form-label text-light-black font-16">Description</label>
                    <textarea class="form-control rounded-0 form-control-md" type="text" formControlName="description" rows="5"></textarea>

                  </div>
                </div>
              </div>
          </div>
        </div>

      </div>
      <div class="btn-modal-group">
          <button type="submit" [disabled]="!certificationForm.valid" id="submit-btn" class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
      </div>
    </form>
  </div>

  </ng-template>

  <!-- Delete  -->
<ng-template #certificationDeleteModal let-modal>

  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
      <h6 class="card-title mb-0 text-center">Delete</h6>
  </div>

  <div class="modal-body md-b-body">
      <p>Are you sure you want to delete?</p>
      <div class="btn-modal-group d-flex justify-content-between">
          <button type="button" (click)="deleteCertification()" class="btn btn-success  border-r-10">Yes, delete it</button>
          <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
      </div>
  </div>

</ng-template>
