<div class="row">
  <div class="col-xl-12 box-margin">
      <div class="card">
          <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
              <h6 class="card-title mb-0">File Fields</h6>

              <div class="btn-group">

                  <button class="action-btn bg-dark-green mr-2" (click)="addCustomFiles(customFilesFormModal)"><img src="assets/img/svg-icon/add-white.svg" width="20" /></button>
                  <!-- <button class="action-btn bg-dark-blue" data-toggle="modal" data-target="#exampleModalRight"><img src="assets/img/svg-icon/filter-white.svg" width="20" /></button> -->
              </div>
          </div>
          <div class="card-body">
              <div class="tab-content list_table" id="ticketList">
                  <div class="table-responsive list_table">
                      <table class="table  table-nowrap mb-0">
                          <thead>
                              <tr>
                                  <th class="pt-0">Model</th>
                                  <th class="pt-0">Field Name</th>
                                  <th class="pt-0">Field Category</th>
                                  <th class="pt-0">Require Expiration</th>
                                  <th class="pt-0">Action</th>
                                  <!-- <th class="pt-0">Action</th> -->
                              </tr>
                          </thead>
                          <tbody>
                              <ng-container *ngIf="totalRecords > 0">
                                  <tr *ngFor="let customfile of customfiles | paginate: { id: 'custome_files_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalRecords }; let i=index">
                                      <td class="text-black">{{customfile.model_type}}</td>
                                      <td class="text-black">{{customfile.field_name }}</td>
                                      <td class="text-black">{{customfile.category }}</td>
                                      <td class="text-gray" *ngIf="customfile.require_expiration==1">Yes</td>
                                      <td class="text-gray" *ngIf="customfile.require_expiration==0 || customfile.require_expiration==null">No</td>
                                      <td class="text-right">
                                        <!-- <a href="javascript:void(0)" class="mr-2 action-btn bg-edit" title="Update Rates" (click)="editRates(ratesFormModal,i)"><img src="assets/img/svg-icon/copy.svg" width="20" /></a> -->
                                        <a href="javascript:void(0)" class="action-btn bg-red" title="Delete" (click)="deleteModal(customFilesDeleteModal,customfile)"><img src="assets/img/svg-icon/delete.svg" width="20" /></a>
                                      </td>
                                  </tr>
                              </ng-container>
                          </tbody>
                      </table>
                      <pagination-controls class="pagination-controls-theme" id="custome_files_pagination" (pageChange)="getCustomFiles(page = $event)" maxSize="9" directionLinks="true"
                      autoHide="true" responsive="true">
                      </pagination-controls>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- Add Client  -->

<ng-template #customFilesFormModal let-modal>

<div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
  <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross  click')"
      aria-label="Close">
      <span aria-hidden="true">×</span>
  </button>
</div>

<div class="modal-body md-b-body">
  <form [formGroup]="customFilesForm" (ngSubmit)="submitCustomFilesData(customFilesForm.value)">
    <div class="form-row">

      <div class="form-group col-md-12">

        <div class="card card-shadow-hide">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Create New File Field</h6>
            </div>
            <div class="card-body">

              <div class="form-row">

                <div class="form-group col-sm-12">
                  <label for="model_type">Type <span class="text-danger">*</span></label>
                  <select   class="form-control rounded-0 form-control-md" formControlName="model_type">
                    <option value="">Select</option>
                    <option value="App\Models\Staffing\Client">Client</option>
                    <option value="App\Models\Staffing\Employee">Employee</option>
                  </select>
                  <span class="text-danger" *ngIf="customFilesForm.get('model_type').errors?.required || customFilesForm.get('model_type').errors?.minlength && customFilesForm.get('model_type').errors">
                    {{formErrors.model_type}}</span>
                  <input type="hidden" formControlName="id" value="">
                </div>

                <div class="form-group col-sm-12">
                  <label for="field_name">Field Name <span class="text-danger">*</span></label>
                  <input type="text"  class="form-control rounded-0 form-control-md" formControlName="field_name">
                  <span class="text-danger" *ngIf="customFilesForm.get('field_name').errors?.required || customFilesForm.get('field_name').errors?.minlength && customFilesForm.get('field_name').errors">
                        {{formErrors.field_name}}</span>

                </div>

                <div class="form-group col-sm-12">
                  <label for="category">Field Category </label>
                  <input type="text"  class="form-control rounded-0 form-control-md" formControlName="category">

                </div>


                <div class="form-group col-dm-12">
                  <div class="checkbox d-inline">
                      <label for="checkbox-recurit"
                          class="col-form-label text-light-black font-16">Require Expiration?</label>
                      <div class="new-checkbox toggle-check-box-t mt-15">
                          <label>No</label>

                          <!-- Rounded switch -->
                          <label class="switch ml-15 mr-15">
                              <input type="checkbox" formControlName="require_expiration"  id="checkbox-marketing">
                              <span class="slider round"></span>
                          </label>
                          <label>Yes</label>
                      </div>
                  </div>
                </div>

              </div>

            </div>
        </div>
      </div>



    </div>
    <div class="btn-modal-group">
        <button type="submit" [disabled]="!customFilesForm.valid" id="submit-btn" class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
    </div>
  </form>
</div>

</ng-template>

<!-- Delete Marketing -->
<ng-template #customFilesDeleteModal let-modal>

  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
      <h6 class="card-title mb-0 text-center">Delete</h6>
  </div>

  <div class="modal-body md-b-body">
      <p>Are you sure you want to delete?</p>
      <div class="btn-modal-group d-flex justify-content-between">
          <button type="button" (click)="deleteCustomFilesField()" class="btn btn-success  border-r-10">Yes, delete it</button>
          <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
      </div>
  </div>

</ng-template>

<!-- Right Alight Modal -->
<div class="modal fade drawer right-align" id="exampleModalRight" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Right Align Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
