<div class="row mb-10">
    <div class="col-md-12">
        <h3 class="user_name">Hi {{user.name}},</h3>
        <p class="user_decr">Welcome to your dashboard</p>
    </div>
</div>
<div class="row">
      <!-- Support Ticket -->
      <div class="col-xl-6 height-card box-margin">
        <div class="card">
            <div class="card-header bg-green d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Recent Notifications</h6>
                <!-- <div class="dashboard-dropdown">
                    <div class="dropdown">
                        <button class="btn dropdown-toggle" type="button" id="dashboardDropdown50" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="assets/img/svg-icon/elps.svg"/>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dashboardDropdown50" x-placement="bottom-end">
                            <a class="dropdown-item" href="#"><i class="ti-pencil-alt"></i> Edit</a>
                            <a class="dropdown-item" href="#"><i class="ti-settings"></i> Settings</a>
                            <a class="dropdown-item" href="#"><i class="ti-eraser"></i> Remove</a>
                            <a class="dropdown-item" href="#"><i class="ti-trash"></i> Delete</a>
                        </div>
                    </div>
                </div> -->
            </div>

            <!-- Tab -->
            <div class="card-body  user-area ">
                <div class=" d-flex align-items-center  justify-content-between">
                    <ul class="nav nav-tabs mb-0" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link" id="sell-01-tab" data-toggle="tab" href="#sell-01" role="tab" aria-controls="sell-01" aria-selected="true">Read</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link mr-0" id="rent-02-tab" data-toggle="tab" href="#rent-02" role="tab" aria-controls="rent-02" aria-selected="false">All</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link show active mr-0" id="rent-03-tab" data-toggle="tab" href="#rent-03" role="tab" aria-controls="rent-03" aria-selected="false">Unread</a>
                        </li>
                    </ul>
                    <!-- <div class="dashboard-dropdown">
                        <div class="dropdown">
                            <button class="btn bg-dark-gray dropdown-toggle" type="button" id="dashboardDropdown50" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img src="assets/img/svg-icon/fileter.svg"/>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dashboardDropdown50" x-placement="bottom-end">
                                <a class="dropdown-item" href="#">Submitted Availability</a>
                                <a class="dropdown-item" href="#">Attendance</a>
                                <a class="dropdown-item" href="#">Expiring Credentials</a>
                                <a class="dropdown-item" href="#">New Activation</a>
                            </div>
                        </div>
                    </div> -->
                </div>

                <div class="tab-content list_table card-scrol" id="ticketList">
                    <div class="tab-pane fade" id="sell-01" role="tabpanel" aria-labelledby="sell-01-tab">
                        <div class="table-responsive order-stats">
                            <table class="table table-nowrap avatar-area">

                                <tbody>

                                   <ng-container *ngIf="totalRecordsReadNotification>0">
                                    <tr *ngFor="let notificationread of readnotification | paginate: { id: 'notification_read_pagination', itemsPerPage: 20, currentPage: pagereadnotification, totalItems: totalRecordsReadNotification }; let i=index">
                                        <td class="">
                                          <img src="assets/img/core-img/logo.png" alt="user" class="thumb-xs mb-2 rounded-circle">
                                        </td>
                                        <td>
                                          <h3 class="td_h3" *ngIf="notificationread.model_id!=''" ><a class="text-black" [routerLink]="['/dashboard/employee-profile/'+notificationread.model_id]">{{notificationread.message}}</a></h3>
                                          <h3 class="td_h3" *ngIf="notificationread.model_id==''" >{{notificationread.message}}</h3>
                                          <p class="td_p"><img src="assets/img/svg-icon/watch.svg" class="mr-2">{{notificationread.created_at | dateAgo}}</p>
                                        </td>
                                        <!-- <td class="text-right">
                                            <a href="javascript;void()" class="mr-2 action-btn"><img src="assets/img/svg-icon/email-gray.svg" width="20" /></a>
                                            <a href="javascript;void()" class="action-btn bg-red"><img src="assets/img/svg-icon/delete.svg" width="20" /></a>
                                        </td> -->

                                    </tr>
                                  </ng-container>

                                </tbody>
                            </table>
                            <pagination-controls class="pagination-controls-theme" id="notification_read_pagination" (pageChange)="getReadNotification(pagereadnotification = $event)" maxSize="9" directionLinks="true" autoHide="true" responsive="true">
                            </pagination-controls>
                        </div>
                    </div>

                    <!-- Tab 2 -->
                    <div class="tab-pane fade" id="rent-02" role="tabpanel" aria-labelledby="rent-02-tab">
                        <div class="table-responsive order-stats">
                            <table class="table table-nowrap avatar-area">

                                <tbody>
                                  <ng-container *ngIf="totalRecordsAllNotification>0">
                                    <tr *ngFor="let notificationall of allnotification | paginate: { id: 'notification_all_pagination', itemsPerPage: 20, currentPage: pageallnotification, totalItems: totalRecordsAllNotification }; let i=index">
                                        <td class="">
                                          <img src="assets/img/core-img/logo.png" alt="user" class="thumb-xs mb-2 rounded-circle">
                                        </td>
                                        <td>
                                          <h3 class="td_h3" *ngIf="notificationall.model_id!=''" ><a class="text-black" [routerLink]="['/dashboard/employee-profile/'+notificationall.model_id]">{{notificationall.message}}</a></h3>
                                          <h3 class="td_h3" *ngIf="notificationall.model_id==''" >{{notificationall.message}}</h3>
                                          <p class="td_p"><img src="assets/img/svg-icon/watch.svg" class="mr-2">{{notificationall.created_at | dateAgo}}</p>
                                        </td>
                                        <!-- <td class="text-right">
                                            <a href="#" class="mr-2 action-btn"><img src="assets/img/svg-icon/email-gray.svg" width="20" /></a>
                                            <a href="#" class="action-btn bg-red"><img src="assets/img/svg-icon/delete.svg" width="20" /></a>
                                        </td> -->

                                    </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                            <pagination-controls class="pagination-controls-theme" id="notification_all_pagination" (pageChange)="getAllNotification(pageallnotification = $event)" maxSize="9" directionLinks="true" autoHide="true" responsive="true">
                            </pagination-controls>
                        </div>
                    </div>
                     <!-- Tab 3 -->
                     <div class="tab-pane fade active show" id="rent-03" role="tabpanel" aria-labelledby="rent-03-tab">
                        <div class="table-responsive order-stats">
                            <table class="table table-nowrap avatar-area">

                                <tbody>
                                  <ng-container *ngIf="totalRecordsUnreadNotification>0">
                                    <tr *ngFor="let notificationunread of unreadnotification | paginate: { id: 'notification_unread_pagination', itemsPerPage: 20, currentPage: pageunreadnotification, totalItems: totalRecordsUnreadNotification }; let i=index">
                                        <td class="">
                                          <img src="assets/img/core-img/logo.png" alt="user" class="thumb-xs mb-2 rounded-circle">
                                        </td>
                                        <td>
                                          <h3 class="td_h3" *ngIf="notificationunread.model_id!=''" ><a class="text-black" [routerLink]="['/dashboard/employee-profile/'+notificationunread.model_id]">{{notificationunread.message}}</a></h3>
                                          <h3 class="td_h3" *ngIf="notificationunread.model_id==''" >{{notificationunread.message}}</h3>
                                          <p class="td_p"><img src="assets/img/svg-icon/watch.svg" class="mr-2">{{notificationunread.created_at | dateAgo}}</p>
                                        </td>
                                        <!-- <td class="text-right">
                                            <a href="#" class="mr-2 action-btn"><img src="assets/img/svg-icon/email-gray.svg" width="20" /></a>
                                            <a href="#" class="action-btn bg-red"><img src="assets/img/svg-icon/delete.svg" width="20" /></a>
                                        </td> -->

                                    </tr>
                                    </ng-container>

                                </tbody>
                            </table>
                            <pagination-controls class="pagination-controls-theme" id="notification_unread_pagination" (pageChange)="getUnreadNotification(pageunreadnotification = $event)" maxSize="9" directionLinks="true" autoHide="true" responsive="true">
                          </pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <!-- Projects of the Month -->
      <div class="col-xl-6 height-card box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Reminders</h6>
                <button class="text-green btn btn-default btn-sm" (click)="addReminders(addReminderModel)">Create</button>
            </div>
            <div class="card-body">

                <div class="table-responsive list_table">
                    <table class="table  table-nowrap mb-0">
                        <thead>
                            <tr>
                                <th class="pt-0">Title</th>
                                <th class="pt-0">Type</th>
                                <th class="pt-0">Date</th>
                                <th class="pt-0">Time</th>
                                <th class="pt-0">Status</th>
                                <th class="pt-0 text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngIf="totalRecords > 0">
                            <tr *ngFor="let reminder of reminders | paginate: { id: 'reminders_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalRecords }; let i=index">
                                <td class="text-black">{{reminder.title}}</td>
                                <td class="text-gray">{{reminder.type}}</td>
                                <td class="text-black">{{reminder.date | date : 'MM/dd/yyyy' }}</td>
                                <td class="text-gray">{{reminder.time}} <img src="assets/img/svg-icon/time.svg" width="30" /></td>
                                <td class="text-danger" *ngIf="latest_date > reminder.date ">Past Due</td>
                                <td class="text-success" *ngIf="latest_date <= reminder.date ">On Track</td>
                                <td class="text-right">
                                    <a href="javascript:void(0)" class="mr-2 action-btn bg-edit" (click)="editReminders(addReminderModel,i)"><img src="assets/img/svg-icon/edit.svg" width="20" /></a>
                                    <a href="javascript:void(0)" class="action-btn bg-red" (click)="reminderDelete(reminderDeleteModal,reminder)"><img src="assets/img/svg-icon/delete.svg" width="20" /></a>
                                </td>
                            </tr>
                          </ng-container>

                        </tbody>
                    </table>
                    <pagination-controls class="pagination-controls-theme" id="reminders_pagination" (pageChange)="getReminder(page = $event)" maxSize="9" directionLinks="true"
                      autoHide="true" responsive="true">
                      </pagination-controls>
                </div>
            </div>
        </div>
      </div>
</div>

<ng-template #addReminderModel let-modal>
  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
    <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15" (click)="modal.dismiss('Cross  click')"
        aria-label="Close">
        <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="modal-body md-b-body">
    <form [formGroup]="reminderForm" (ngSubmit)="submitReminderData(reminderForm.value)">

      <div class="form-row">

        <div class="form-group col-md-12">
          <div class="card card-shadow-hide">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Reminder Details</h6>
            </div>

            <div class="card-body">

              <div class="form-row">

                <div class="form-group col-md-4">
                  <label for="title" class="col-form-label text-light-black font-16">Title<span class="text-danger">*</span></label>
                  <input class="form-control rounded-0 form-control-md" type="text" formControlName="title">
                  <span class="text-danger"
                      *ngIf="reminderForm.get('title').errors?.required || reminderForm.get('title').errors?.minlength && reminderForm.get('title').errors">
                      {{formErrors.title}}</span>
                  <input type="hidden" formControlName="id" value="">
                </div>

                <div class="form-group col-md-2">
                  <label for="date" class="col-form-label text-light-black font-16">Date <span class="text-danger">*</span></label>
                  <input class="form-control rounded-0 form-control-md" type="date" formControlName="date">
                  <span class="text-danger"
                      *ngIf="reminderForm.get('date').errors?.required || reminderForm.get('date').errors?.minlength && reminderForm.get('date').errors">
                      {{formErrors.date}}</span>
                </div>

                <div class="form-group col-md-2">
                  <label for="time" class="col-form-label text-light-black font-16">Time <span class="text-danger">*</span></label>
                  <input class="form-control rounded-0 form-control-md" type="time" formControlName="time">
                  <span class="text-danger"
                      *ngIf="reminderForm.get('time').errors?.required || reminderForm.get('time').errors?.minlength && reminderForm.get('time').errors">
                      {{formErrors.time}}</span>
                </div>

                <div class="form-group col-md-4">
                  <label for="type" class="col-form-label text-light-black font-16">Type <span class="text-danger">*</span></label>

                  <div class="el-radio-group">
                    <label tabindex="-1" class="el-radio-button">
                      <input type="radio" formControlName="type" tabindex="-1" class="el-radio-button__orig-radio" value="Meeting">
                        <span class="el-radio-button__inner">Meeting</span>
                      </label>
                      <label tabindex="0" class="el-radio-button is-active" aria-checked="true">
                        <input type="radio" formControlName="type" checked tabindex="0" class="el-radio-button__orig-radio" value="Task">
                        <span class="el-radio-button__inner">Task</span>
                      </label>
                    </div>
                </div>


                <div class="form-group col-md-12">
                  <label for="notes" class="col-form-label text-light-black font-16">Notes</label>
                  <textarea class="form-control rounded-0 form-control-md" type="notes" formControlName="notes" rows="7"></textarea>
                </div>

              </div>

              <div class="btn-modal-group">
                <button type="submit" [disabled]="!reminderForm.valid" id="submit-btn"class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
              </div>

            </div>



          </div>
        </div>

      </div>

    </form>
  </div>

</ng-template>

<!-- Delete Marketing -->
<ng-template #reminderDeleteModal let-modal>

  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
      <h6 class="card-title mb-0 text-center">Delete</h6>
  </div>

  <div class="modal-body md-b-body">
      <p>Are you sure you want to delete this record?</p>
      <div class="btn-modal-group d-flex justify-content-between">
          <button type="button" (click)="deleteReminder()" class="btn btn-success  border-r-10">Yes, delete it</button>
          <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
      </div>
  </div>

</ng-template>

<ng-template #deleteNotificationModal let-modal>
  <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
    <h6 class="card-title mb-0 text-center">Delete</h6>
  </div>

  <div class="modal-body md-b-body">
      <p>Are you sure you want to delete this notification?</p>
      <div class="btn-modal-group d-flex justify-content-between">
          <button type="button" (click)="deleteNotification()" class="btn btn-success  border-r-10">Yes, delete it</button>
          <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
      </div>
  </div>
</ng-template>
