<div class="main-content">
    <div class="container-fluid">

        <div class="row">

            <div class="col-xl-8 box-margin">

                <div class="card">
                    <div class="card-header bg-green">
                        <span class="h6">My Test</span>
                    </div>

                    <div class="card-body" *ngIf="isUpdate">
                        <div id="accordion-" >
                            <div class="card boxshadow-hide" *ngFor="let question of questions let i=index">
                                <div class="card-header  mt-3 bg-green collapsed text-white justify-content-between d-flex align-items-center" *ngIf="question">
                                    <a class="flex-1 card-title mb-0 font-18 text-dark collapsed" id="collapse{{i}}"
                                        (click)="getCurrentQuestion(i)" data-toggle="collapse" href="#accordion-{{i}}"
                                        aria-expanded="false">
                                        <span class="quest_content">{{ question.content }}</span>
                                        <span class="quest_option">{{ question.question_type }}</span>
                                    </a>
                                    <button type="button" id="edit{{i}}"
                                        class="action-btn hidden btn-outline-success bordered mr-2 updateButton" (click)="updateQuestion(i)"> <i class="fa fa-floppy-o"></i> </button>
                                    <button type="button" class="action-btn btn-outline-danger bordered mr-2"  (click)="deleteModal(deleteQuestionModal,question)"> <i class="fa fa-times"></i>
                                    </button>
                                </div>

                                <div id="accordion-{{i}}" class="collapse" data-parent="#accordion-" *ngIf="question">
                                    <div class="card-body">
                                        <div class="row">
                                            <input type="hidden" id="id{{i}}" [value]="question.id">
                                            <div class="col-sm-12 box-margin">
                                                <label for="content">Content</label>
                                                <input type="text" id="content{{i}}" class="form-control" [value]="question.content">
                                            </div>

                                            <div class="col-sm-12">
                                                <label for="">Question Type</label>
                                                <ng-select [(ngModel)]="optionTypeModel[i]" (change)="updateQuestionType($event,i)" class="custom-mutiple-select" id="question_type{{i}}" placeholder="Select" clearAllText="Clear">
                                                    <ng-option *ngFor="let option of optionType;let z = index" [value]="option.value"> {{ option.value }}</ng-option>
                                                </ng-select>
                                                <!-- <ng-select [items]="(option_type)" class="custom-mutiple-select" id="question_type{{i}}"></ng-select> -->
                                            </div>
                                            <div class="col-sm-12">
                                                <label for="">Needs Review</label>
                                                <div class="new-checkbox toggle-check-box-t mt-15">
                                                    <label>No</label>
                                                    <label class="switch ml-15 mr-15">
                                                        <input type="checkbox"  (change)="switchChange($event)" id="needs_review{{i}}">
                                                        <span class="slider round"></span>
                                                    </label>
                                                    <label>Yes</label>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row"  *ngIf="question.question_options.length">
                                            <div class="col-sm-12 mt-2 mb-2">Options</div>

                                            <div class="col-md-4 height-card w-100" *ngFor="let options of question.question_options; let j=index">
                                                <div class="element-card box-margin">
                                                    <div class="element-card-header d-flex align-items-center justify-content-between">
                                                        <div class="element-card-title text-light-black font-16">
                                                            {{ options.value }}
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <button type="button" class="action-outline-c btn btn-outline-success bordered mr-2" (click)="getCurrentOption(optionModal,i,j)"> <i class="fa fa-floppy-o"></i> </button>
                                                            <button type="button" class="action-outline-c btn btn-outline-danger bordered mr-2" (click)="deleteModal(deleteOptionModal,options)"> <i class="fa fa-times"></i> </button>
                                                        </div>
                                                    </div>
                                                    <div class="element-card-body p-10 text-center">
                                                        <i class="fa fa-circle-o fa-2x" *ngIf="!options.correct"></i>
                                                        <i class="fa fa-check-circle-o fa-2x" *ngIf="options.correct"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="add-btn-option-sec">
                                            <button id="add-btn-option" (click)="addOption(optionModal)">ADD Option</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="!isUpdate">
                        <p class="m-2">Please select a test to get started.</p>
                    </div>

                </div>

            </div>
            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card mb-30">
                            <div class="card-header bg-green">
                                <h6 class="card-title mb-0">Test Details</h6>
                            </div>
                            <div class="card-body">
                                <div class="form-row" *ngIf="isUpdate">

                                    <div class="form-group col-md-12">
                                        <label for="" class="">Name</label>
                                        <input type="text" id="name" class="form-control">

                                    </div>
                                    <div class="form-group col-md-12">
                                        <label class="text-light-black">Passing Percentage</label>
                                        <div class="input-group inc_dec">
                                            <div class="input-group-prepend">
                                                <button type="button" class="input-group-text" (click)="passPerMinus()">-</button>
                                            </div>
                                            <div class="custom-file"> <!--   formControlName="passing_percentage" -->
                                                <input type="text"  class="form-control text-center" [value]="passingPercentage">
                                            </div>
                                            <div class="input-group-append">
                                                <button type="button" class="input-group-text" (click)="passPer()">+</button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group col-md-12">
                                        <label for="example-range">Description</label>
                                        <textarea  id="description" cols="30" rows="7" class="form-control"></textarea>
                                    </div>
                                   
                                </div>

                                <div class="row" *ngIf="!isUpdate">
                                    <div class="col-sm-12">
                                        <label for="test_id">Select a test...</label>
                                        <ng-select class="custom-mutiple-select" id="test_id" (change)="selectTest($event)" placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let test of tests" [value]="test.id"> {{ test.name }}</ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="col-sm-12 mt-2">
                                        <button class="action-btn bg-dark-blue"  (click)="addTest(addTestDetailModal)">Create New</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" *ngIf="isUpdate">
                        <div class="card mb-30">
                            <div class="card-header bg-green">
                                <h6 class="card-title mb-0">Tools</h6>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4" (click)="addQuestion(addQuestionModal)">
                                        <div class="action-card">
                                            <div class="action-card-header">
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                                <span class="font-12">Add Question</span>
                                            </div>
                                            <div class="action-card-body">
                                                <i class="fa fa-question-circle" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4" (click)="addOption(optionModal)">
                                        <div class="action-card">
                                            <div class="action-card-header">
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                                <span class="font-12">Add Option</span>
                                            </div>
                                            <div class="action-card-body">
                                                <i class="fa fa-file-image-o" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Test Details -->
<ng-template #addTestDetailModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0"> Test Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <form [formGroup]="testDetailForm" (ngSubmit)="submitTestDetailData(testDetailForm.value)">
            <div class="row">
                <div class="col-sm-4">
                    <label for="">Name <span class="text-danger">*</span></label>
                    <input type="text" formControlName="name" class="form-control">
                    <span class="text-danger"
                    *ngIf="testDetailForm.get('name').errors?.required || testDetailForm.get('name').errors?.minlength && testDetailForm.get('name').errors">
                    {{formErrorsTestDetail.name}}</span>
                </div>
                <div class="col-sm-4">
                    <label for="">Question(s)</label>
                    <ng-select [multiple]="true" class="custom-mutiple-select" formControlName="question" placeholder="Select" clearAllText="Clear">
                        <ng-option *ngFor="let question of questions" [value]="question.id"> {{ question.content }}</ng-option>
                    </ng-select>
                </div>
                <div class="form-group col-md-4">
                    <label class="text-light-black">Passing Percentage</label>
                    <div class="input-group inc_dec">
                        <div class="input-group-prepend">
                            <button type="button" class="input-group-text" (click)="passPerMinus()">-</button>
                        </div>
                        <div class="custom-file">
                            <input type="text"  class="form-control text-center" [value]="passingPercentage"  formControlName="passing_percentage">
                        </div>
                        <div class="input-group-append">
                            <button type="button" class="input-group-text" (click)="passPer()">+</button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <label for="">Description</label>
                    <textarea formControlName="description" class="form-control" id="" cols="30" rows="5"></textarea>
                </div>
                <div class="col-sm-12 mt-2">
                    <div class="btn-modal-group">
                        <button type="submit" [disabled]="!testDetailForm.valid" id="submit-btn"
                            class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>


<!-- Test Details -->
<ng-template #addQuestionModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0"> Question Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <form [formGroup]="questionForm" (ngSubmit)="submitQuestion(questionForm.value)">
            <input type="hidden" formControlName="id">
            <div class="row">
                <div class="col-sm-6">
                    <label for="">Content</label>
                    <input type="text" formControlName="content" placeholder="Enter the question here" class="form-control">
                </div>
                <div class="col-sm-6">
                    <label for="">Question Type</label>
                    <ng-select class="custom-mutiple-select" formControlName="question_type" placeholder="Select" clearAllText="Clear">
                        <ng-option *ngFor="let option of optionType" [value]="option.value"> {{ option.value }}</ng-option>
                    </ng-select>
                </div>
                <div class="col-sm-12 hidden">
                    <label for="">Needs Review</label>
                    <div class="new-checkbox toggle-check-box-t mt-15">
                        <label>No</label>
                        <label class="switch ml-15 mr-15">
                            <input type="checkbox" formControlName="needs_review" id="">
                            <span class="slider round"></span>
                        </label>
                        <label>Yes</label>
                    </div>
                </div>
                <div class="col-sm-12 mt-2">
                    <div class="btn-modal-group">
                        <button type="submit" id="submit-btn" class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<!-- delete question  -->
<ng-template #deleteQuestionModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Question</h6>
    </div>
  
    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete this question?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="questionDelete()" class="btn btn-success btn-sm  border-r-10">Yes</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger btn-sm border-r-10">Cancel</button>
        </div>
    </div>
  
  </ng-template>
<!-- Delete Option  -->
  <ng-template #deleteOptionModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Option</h6>
    </div>
  
    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete this option?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteOption()" class="btn btn-success btn-sm  border-r-10">Yes</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger btn-sm border-r-10">Cancel</button>
        </div>
    </div>
  
  </ng-template>

  <!-- Add Option -->

  <ng-template #optionModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Option Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
  
    <div class="modal-body md-b-body">
        <form [formGroup]="optionForm" (ngSubmit)="submitOption(optionForm.value)">
            <div class="row">
                <div class="col-sm-4 form-group">
                    <label for="">Label</label>
                    <input type="text" class="form-control" formControlName="value">
                </div>
                
                <div class="col-sm-4 form-group">
                    <label for="">Is this option correct?</label>
                    <div class="new-checkbox toggle-check-box-t mt-15">
                        <label>No</label>
                        <label class="switch ml-15 mr-15">
                            <input type="checkbox"  formControlName="correct">
                            <span class="slider round"></span>
                        </label>
                        <label>Yes</label>
                    </div>
                </div>

                <div class="col-sm-12">
                    <button class="action-btn bg-dark-blue">Submit</button>
                </div>
            </div>
        </form>
    </div>
  
  </ng-template>