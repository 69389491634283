import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-recruiting',
  templateUrl: './recruiting.component.html',
  styleUrls: ['./recruiting.component.css']
})
export class RecruitingComponent implements OnInit {

  recruterForm:FormGroup;
  response:any=[];
  specialities:any=[];
  assignees:any=[];
  isTrue:boolean=true;
  isTrueRecruit:boolean=true;
  isTruePipeline:boolean=true;
  active:boolean=true;
  priorityusers:any=[];
  noteForm: FormGroup;
  employee_id:any;
  user:any=[];
  totalRecords:any=[];
  page:any=1;
  activeTab:any=1;
  recruitsNewUsers:any=[];
  recruitsPendingUsers:any[];
  totalPendingRecords:any=[];


  formErrors = {
    'first_name': '',
    'last_name': '',
    'email': '',
    'stf_specialty_id': '',
    'phone': '',
    'ssn': '',
    'birthdate': '',
    'hire_date': '',
    'termination_date': '',
    'assignee': '',
    'new_recruit': '',
    'pipeline':'',
    'active': '',
    'address_line_1': '',
    'address_line_2': '',
    'apartment_number': '',
    'city': '',
    'state': '',
    'zip': '',
    'pay_rate': '',
    'pay_description': '',
    'static_html_block': ''
  };
  validationMessages = {
    'first_name': {
      'required': 'First name is required.',
      'minlength': 'Name must be 3 characters long.'
    },
    'last_name': {
      'required': 'Last name is required.',
      'minlength': 'Name must be 3 characters long.'
    },
    'email': {
      'required': 'Email is required.',
      'email': 'Email is invalid'
    },
    'phone': {
      'minlength': 'Phone number must be of 10 digit.'
    }
  };

  constructor(
    private api:ApiService,
    private modalService:NgbModal,
    private formBuilder:FormBuilder,
    private toastr:ToastrService,
    private router : Router,
  ) { 
    this.user =  JSON.parse(localStorage.getItem('reliant_user'));
    this.noteForm = this.formBuilder.group({
      note: [''],
      id:['']
    });
  }

  ngOnInit(): void {
    this.init();
    this.getSpecialties();
    this.getAssignee();
    this.getUsers(this.page);
    this.getNewUsers(this.page);
    this.getPendingUsers(this.page);
  }

  init(): void {
    this.recruterForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.minLength(3)]],
      last_name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      stf_specialty_id: [''],
      phone: [''],
      ssn: [''],
      birthdate: [''],
      hire_date: [''],
      termination_date: [''],
      assignee: [''],
      new_recruit: [true],
      pipeline: [true],
      active: [true],
      address_line_1: [''],
      address_line_2: [''],
      apartment_number: [''],
      city: [''],
      state: [''],
      zip: [''],
      pay_rate: [''],
      pay_description: [''],
      static_html_block: ['']
    });
    this.recruterForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.recruterForm);
    });
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  async getSpecialties() {
    this.api.get('admin/specialities/lists').subscribe((result) => {
      this.specialities = result.data;
    }, (err) => {
    });
  }

  async getAssignee(){
    
    this.api.get('admin/recruter/assignees').subscribe((result)=>{
      this.response = result;
      this.assignees = this.response.data;
    },(err)=>{

    });
  }


  addRecruter(content){
    this.recruterForm.reset();
    this.modalService.open(content,{ size:'xl',centered:true });
  }


  submitData(data):void{
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    this.api.post('admin/recruter/create', data).subscribe((result) => {
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      //this.getRecruter(this.page);
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.modalService.dismissAll();
    }, (err) => {
      this.toastr.error(err.error.message,"Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.modalService.dismissAll();
    });
    //console.log(data);
  }

  async getUsers(page){
      this.api.get('admin/recruter/lists?page='+page).subscribe((result)=>{  
      this.totalRecords = result.data.total;
      this.priorityusers = result.data.data;
    },(err)=>{

    });
  }

  async getNewUsers(page){
    this.api.get('admin/recruter/new_recruits?page='+page).subscribe((result)=>{  
    this.totalRecords = result.data.total;
    this.recruitsNewUsers = result.data.data;
    },(err)=>{

    });
  }
  async getPendingUsers(page){
    this.api.get('admin/recruter/pending_recruits?page='+page).subscribe((result)=>{  
    this.totalPendingRecords = result.data.total;
    this.recruitsPendingUsers = result.data.data;
  },(err)=>{

  });
  }
  addFavoritesUser(user_id,key){
     //let data = { id:id,creds:status}
     let data = { model_id:user_id,user_id:this.user.id}
     this.api.post('admin/recruter/add_favorites_user', data).subscribe((result) => {
        this.response=result;
        this.toastr.success(this.response.message,"Success");
        this.modalService.dismissAll();
        (key=='pending')?this.getPendingUsers(1):'';
        (key=='new')?this.getNewUsers(1):'';
      }, (err) => {
        this.toastr.error(err.error.message,'Error');
      });
    console.log(data);
  }
  recruitsUpdate(id,key,value){
    let data = { id:id,key:key,value:value}
    this.api.post('admin/recruter/update', data).subscribe((result) => {
        this.response=result;
        this.toastr.success(this.response.message,"Success");
        this.modalService.dismissAll();
        //this.getUsers();
     }, (err) => {
       this.toastr.error(err.error.message,'Error');
     });
  }
  strikeEmail(id,key,value){
    let data = { id:id,key:key,value:value}
    this.api.post('admin/recruter/email_update', data).subscribe((result) => {
        this.response=result;
        this.toastr.success(this.response.message,"Success");
        this.modalService.dismissAll();
        //this.getUsers();
     }, (err) => {
       this.toastr.error(err.error.message,'Error');
     });
     console.log(data);
  }

  recruitsModelUpdate(user_id) {
    let data = { id:user_id}
    this.api.post('admin/recruter/delete_favorites', data).subscribe((result) => {
        this.response=result;
        this.toastr.success(this.response.message,"Success");
        this.modalService.dismissAll();
        this.getUsers(1);
     }, (err) => {
       this.toastr.error(err.error.message,'Error');
     });
    this.getUsers(1);
    
  }
  openNote(addNote,employee_id){
    this.noteForm.reset();
    this.employee_id=employee_id
    this.modalService.open(addNote, { size: 'xl', centered: true });
  }
  submitNote(data): void {
    let endPoint = (data.id)?'update':'create';
    data.model_type = 'Employee';
    data.model_id = this.employee_id;
     data.user_id = this.user.id;
    this.api.post('admin/note/'+endPoint, data).subscribe((result) => {
       console.log(result);
       this.response=result;
       this.toastr.success(this.response.message,"Success");
       this.modalService.dismissAll();
       this.router.navigate(['/dashboard/employee-profile/'+this.employee_id]);
    }, (err) => {
      this.toastr.error(err.error.message,'Error');
    });
  }
}
