<div class="ecaps-page-wrapper">
    <!-- Sidemenu Area -->
    <div class="ecaps-sidemenu-area">
        <!-- Desktop Logo -->
        <div class="ecaps-logo">
            <a href="javascript:void(0)"><img class="desktop-logo" src="assets/img/core-img/logo.png"
                    alt="Desktop Logo"> <img class="small-logo" src="assets/img/core-img/small-logo.png"
                    alt="Mobile Logo"></a>
        </div>

        <!-- Side Nav -->
        <div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 100%;">
            <div class="ecaps-sidenav" id="ecapsSideNav" style="overflow: hidden; width: auto; height: 100%;">
                <!-- Side Menu Area -->
                <div class="side-menu-area">
                    <!-- Sidebar Menu -->
                    <nav>
                        <ul class="sidebar-menu" data-widget="tree">
                            <li routerLinkActive="active">
                                <a routerLink="/administration/manage-calls-dashboard">
                                    <i class='fa fa-home'></i>
                                    <span>Dashboard</span>
                                </a>
                            </li>
                            <li routerLinkActive="active">
                                <a routerLink="/administration/calls">
                                    <i class='fa fa-user-md'></i>
                                    <span>Call Logs </span>
                                </a>
                            </li>
                            <li routerLinkActive="active">
                                <a routerLink="/administration/call-schedule">
                                    <i class='fa fa-user'></i>
                                    <span>Schedule</span>
                                </a>
                            </li>

                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0"> Calls</h6>
                <div class="btn-group">
                    <button (click)="addCalls(callsFormModal)" class="action-btn bg-dark-green mr-2"><img
                            src="assets/img/svg-icon/add-white.svg" width="20" /></button>
                    <!-- <button class="action-btn bg-dark-blue" data-toggle="modal" data-target="#exampleModalRight"><img src="assets/img/svg-icon/filter-white.svg" width="20" /></button> -->
                </div>

            </div>
            <div class="card-body">
                <div class="tab-content list_table" id="ticketList">
                    <div class="tab-pane active show fade" id="active-01" role="tabpanel"
                        aria-labelledby="active-01-tab">
                        <div class="table-responsive list_table">
                            <table class="table mb-0">
                                <thead>
                                    <tr>
                                        <th class="pt-2"> Date/Time </th>
                                        <th class="pt-2"> Direction </th>
                                        <th class="pt-2"> Phone (From) </th>
                                        <th class="pt-2"> Phone (To) </th>
                                        <th class="pt-2"> Duration (s) </th>
                                        <th class="pt-2"> Status </th>
                                        <th class="pt-2"> Employee </th>
                                        <th class="pt-2"> Client </th>
                                        <th class="pt-2">Action </th>
                                        <th class="pt-2">SID </th>
                                        <th class="pt-2">Image </th>
                                        <th class="pt-2">Active </th>
                                        <th class="pt-2">Updated At </th>
                                        <th class="pt-2">Actions </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="calls.length > 0">
                                        <tr
                                            *ngFor="let call of calls | paginate: { id: 'calls_pagination', itemsPerPage: 25, currentPage: page, totalItems: totalRecords }; let i=index">
                                            <td class="text-black"> {{call.created_at | date:'MM/dd/yyyy, h:mm a'}}</td>
                                            <td> {{call.direction }} </td>
                                            <td><a href="tel:{{call.phone_from}}"> {{call.phone_from }}</a></td>
                                            <td><a href="tel:{{call.phone_to}}"> {{call.phone_to }}</a></td>
                                            <td> {{call.duration}}</td>
                                            <td> {{call.status}}</td>
                                            <td> <span *ngIf="call.employee">{{call.employee.name}}</span></td>
                                            <td> <a *ngIf="call.client"
                                                    [routerLink]="['/crm/customer-profile/'+call.client.id]">
                                                    {{call.client.company_name}} <i class='fa fa-share'></i></a></td>
                                            <td> {{call.action}}</td>
                                            <td> {{call.sid}}</td>
                                            <td> image</td>
                                            <td> <span *ngIf="call.active==1"> Yes</span>
                                                <span *ngIf="call.active!=1">NO </span>
                                            </td>
                                            <td> {{call.updated_at | date:'MM/dd/yyyy, h:mm:ss'}}</td>
                                            <td class="text-right">
                                                <a href="javascript:void(0)" (click)="openDeletePopUp(deleteCalls,call)" class="action-btn bg-red"
                                                    title="Delete"><img src="assets/img/svg-icon/delete.svg"
                                                        width="20" /></a>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr *ngIf="calls.length == 0">
                                        <td class="text-gray text-center" colspan="5">No Calls Found</td>
                                    </tr>
                                </tbody>
                            </table>
                            <pagination-controls class="pagination-controls-theme" id="calls_pagination"
                                (pageChange)="getCalls(page = $event)" maxSize="9" directionLinks="true" autoHide="true"
                                responsive="true">
                            </pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Add Calls  -->

<ng-template #callsFormModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <form [formGroup]="callsForm" (ngSubmit)="submitCallsData(callsForm.value)">
            <div class="form-row">

                <div class="form-group col-md-12">

                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Calls Details</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <!-- <div class="form-group col-md-4">
                                    <label for="company_name" class="col-form-label text-light-black font-16">Name<span
                                            class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" type="text"
                                        formControlName="name">
                                </div> -->
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div class="btn-modal-group">
                <button type="submit" [disabled]="!callsForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
            </div>
        </form>
    </div>

</ng-template>


<!-- Delete Marketing -->
<ng-template #deleteCalls let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>
    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteCallsData()" class="btn btn-success  border-r-10">Yes, delete it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>
  </ng-template>

<!-- Right Alight Modal -->
<div class="modal fade drawer right-align" id="exampleModalRight" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Right Align Modal title</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</div>