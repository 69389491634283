<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div>
                <ul class="nav nav-tabs mb-0" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="active-01-tab" data-toggle="tab" href="javascript:void(0)"
                            role="tab" aria-controls="overview" aria-selected="true">Details</a>
                    </li>
                </ul>
            </div>
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Call Log Details</h6>
                <div class="btn-group tab_list">
                    <a href="javascript:void(0)" (click)="editCollLogsModal(callLogsregistrationFormModal)"
                        class="mr-2 action-btn bg-edit" title="Update follow Ups">
                        <img src="assets/img/svg-icon/edit.svg" width="20" /></a>
                    <a href="javascript:void(0)" class="action-btn bg-red" (click)="deleteModal(callLogsDeleteModal)"
                        title="Delete"><img src="assets/img/svg-icon/delete.svg" width="20" /></a>
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content list_table" id="ticketList">
                    <div class="tab-pane active show fade" id="overview" role="tabpanel"
                        aria-labelledby="active-01-tab">
                        <div class="row">

                            <div class="col-md-6">
                                <h6 class="text-black font-14 sm-font-14 md-font-14"> Date Received </h6>
                                <h6 class="text-black font-18 sm-font-16 md-font-16"> {{callLogsData.received_date | date : 'dd/MM/yyyy' }}</h6>

                            </div>
                            <div class="col-md-6">
                                <h6 class="text-black font-14 sm-font-14 md-font-14"> Call Notes </h6>
                                <h6 class="text-black font-18 sm-font-16 md-font-16" > <span>{{ notes }}</span> </h6>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Delete  -->
<ng-template #callLogsDeleteModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>

    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteCallLogs()" class="btn btn-success  border-r-10">Yes, delete
                it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>

</ng-template>

<!--  Call Logs Registration -->
<ng-template #callLogsregistrationFormModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <form [formGroup]="callLogsForm" (ngSubmit)="submitCallLogsData(callLogsForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Call Details</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-3">
                                    <label for="received_date" class="col-form-label text-light-black font-16">Received
                                        Date
                                        <span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md"
                                        formControlName="received_date" [(ngModel)]="receivedDate" type="date">
                                    <!-- <span class="text-danger">Hello</span> -->
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="time" class="col-form-label text-light-black font-16">Time <span
                                            class="text-danger">*</span></label>
                                    <ng-select class="custom-mutiple-select" (change)="selectStatus($event)"
                                        formControlName="time" placeholder="Select" clearAllText="Clear">
                                        <ng-option *ngFor="let time of times;let i = index" value="{{time.value}}">
                                            {{time.label}}</ng-option>
                                    </ng-select>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="contact_id"
                                        class="col-form-label text-light-black font-16">Contact</label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select formControlName="contact_id" class="custom-mutiple-select"
                                            placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let contact of allContacts;" value="{{contact.id}}">
                                                {{contact.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" (click)="openContactModal(addContactModal)"
                                            class="btn btn-default"><i class="fa fa-plus"></i> Add
                                            New</button>
                                    </div>
                                </div>
                                <div class="form-group col-sm-12">
                                    <label for="notes">Notes <span class="text-danger">*</span> </label>
                                    <textarea formControlName="notes" class="form-control" cols="30"
                                        rows="7"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Call Scripts</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-12">
                                    <p>Which script would you like to use for this follow up?</p>
                                    <p>No scripts available.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-modal-group">
                <button type="submit" [disabled]="!callLogsForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
            </div>
        </form>
    </div>

    <div class="modal-footer">
        <!-- <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button> -->
    </div>

</ng-template>

<!-- Status Modal  -->
<ng-template #addStatusModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Status Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <app-modal-status [details]="statusDetails" [statusModelRef]="statusModelRef" [modelName]="modelName">
        </app-modal-status>
    </div>
</ng-template>

<!-- Flag Modal  -->
<ng-template #addFlagModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Flag Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <app-modal-flag [details]="flagDetails" [flagModelRef]="flagModelRef" [modelName]="modelName"></app-modal-flag>
    </div>

</ng-template>

<!-- Contact Modal  -->
<ng-template #addContactModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <form [formGroup]="ContactForm" (ngSubmit)="submitContactData(ContactForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Contact Details</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="first_name" class="col-form-label text-light-black font-16">First Name
                                        <span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="first_name"
                                        type="text">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="last_name" class="col-form-label text-light-black font-16"> Last Name
                                        <span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="last_name"
                                        type="text">
                                </div>
                                <div class="form-group col-md-8">
                                    <label for="email" class="col-form-label text-light-black font-16">Email
                                        <span class="text-danger">*</span></label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="email"
                                        type="text">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="title" class="col-form-label text-light-black font-16">Title </label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="title"
                                        type="text">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="phone" class="col-form-label text-light-black font-16">Phone (Work)
                                    </label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="phone"
                                        type="text">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="phone_mobile" class="col-form-label text-light-black font-16">Phone
                                        (Home)</label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="phone_mobile"
                                        type="text">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="phone_mobile" class="col-form-label text-light-black font-16">Phone
                                        (Mobile)</label>
                                    <input class="form-control rounded-0 form-control-md" formControlName="phone_mobile"
                                        type="text">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-12">
                    <div class="card card-shadow-hide">
                        <div class="card-body">
                            <div class="form-row">
                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Flag </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select democlass" Id="Flag"
                                            (change)="selectFlag($event)" formControlName="flag_id" placeholder="Select"
                                            clearAllText="Clear">

                                            <ng-option *ngFor="let flag of flags; let i = index" value="{{flag.id}}">
                                                {{flag.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" *ngIf="!isSelectedFlag" class="btn btn-default"
                                            (click)="openFlagModal(addFlagModal)"><i class="fa fa-plus"></i> Add
                                            New</button>
                                        <button type="button" *ngIf="isSelectedFlag" class="btn btn-default"
                                            (click)="openFlagModal(addFlagModal)"><i class="fa fa-eye"></i>
                                            View/Edit</button>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Status </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select" (change)="selectStatus($event)"
                                            formControlName="status_id" placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let status of statuses;let i = index"
                                                value="{{status.id}}"> {{status.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" *ngIf="!isSelectedStatus" class="btn btn-default"
                                            (click)="openStatusModal(addStatusModal)"><i class="fa fa-plus"></i> Add
                                            New</button>
                                        <button type="button" *ngIf="isSelectedStatus" class="btn btn-default"
                                            (click)="openStatusModal(addStatusModal)"><i class="fa fa-eye"></i>
                                            View/Edit </button>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Type </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select" formControlName="type_id"
                                            placeholder="Select" clearAllText="Clear">
                                            <ng-option *ngFor="let type of allTypes;let i = index" value="{{type.id}}">
                                                {{type.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" class="btn btn-default"
                                            (click)="flagManagementType('/flag-management/types')"><i
                                                class="fa fa-cog"></i> Manage </button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-modal-group">
                <button type="submit" [disabled]="!ContactForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
            </div>
        </form>
    </div>

</ng-template>
