import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-administration-message-templates',
  templateUrl: './administration-message-templates.component.html',
  styleUrls: ['./administration-message-templates.component.css']
})
export class AdministrationMessageTemplatesComponent implements OnInit {

  messagetemplateForm:FormGroup;
  page=1;
  totalRecords:any;
  messagetemplate:any=[];
  editData:any=[];


  response:any=[];
  delete_id:any;

  formErrors = {
    'title':'',
    'subject':'',
    'body':''
  }

  validationMessages = {
    'title':{
      'required':"Enter Title",
      'minlength':"must be 3 character long"
    }
  }


  constructor(
    private api:ApiService ,
    private modalService:NgbModal,
    private formBuilder:FormBuilder,
    private toastr:ToastrService
  ) {
    this.messagetemplateForm = this.formBuilder.group({
      messagetemplate:[''],
    });

    this.getMessageTemplate(this.page);

   }

   ngOnInit(): void {
    this.init();
  }

  init() :void{
    this.messagetemplateForm = this.formBuilder.group({
      id: [''],
      title: ['', [Validators.required, Validators.minLength(3)]],
      subject:[''],
      body:['']
    });
    this.messagetemplateForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.messagetemplateForm);
    });
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  async getMessageTemplate(page){

    this.api.get("admin/message_template/lists?page="+page).subscribe((result)=>{
      this.totalRecords = result.data.total;
      this.messagetemplate = result.data.data;
    },(err)=>{

    });

  }

  editMessageTemplate(content,index){
    this.messagetemplateForm.reset();
    this.editData = this.messagetemplate[index];
    this.messagetemplateForm.patchValue(this.editData);
    this.modalService.open(content,{ size:'xm' , centered:true});
  }

  addMessageTemplate(content){
    this.messagetemplateForm.reset();
    this.modalService.open(content , { size:"xl" , centered:true });
  }

  submitRatesData(data):void{

    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    let endPoint = (data.id) ? 'update' : 'create';
    this.api.post("admin/message_template/"+endPoint,data).subscribe((result)=>{
      this.response = result;
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.getMessageTemplate(this.page);
    },(err)=>{
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
      this.toastr.error(err.error.message,"Error");
    });

  }

  deleteModal(content,current_row){
    this.delete_id=current_row.id;
    this.modalService.open(content,{size:'xl',centered:true});
  }

  deleteMessageTemplate():void{
    let data = {id:this.delete_id};
    console.log(data);
    this.api.post("admin/message_template/delete",data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.getMessageTemplate(this.page);
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
    });
  }

}
