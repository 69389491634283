import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-administration-custom-files',
  templateUrl: './administration-custom-files.component.html',
  styleUrls: ['./administration-custom-files.component.css']
})
export class AdministrationCustomFilesComponent implements OnInit {

  customFilesForm:FormGroup;
  totalRecords:any;
  customfiles:any;
  page:any=1;
  delete_id:any;

  response:any=[];


  formErrors = {
    'model_type':'',
    'field_name':'',
    'category':'',
    'require_expiration':''
  }

  validationMessages = {
    'model_type':{
      'required' : 'Select Type'
    },
    'field_name':{
      'required':"Enter Field Name",
      'minlength':"Field name must be 3 character long."
    }

  }

  constructor(
    private api:ApiService,
    private modalService:NgbModal,
    private formBuilder:FormBuilder,
    private toastr:ToastrService
  ) {
    this.customFilesForm = this.formBuilder.group({
      customfiels:[''],
    });
    this.getCustomFiles(this.page);
   }

  ngOnInit(): void {
    this.init();
  }

  init() :void{
    this.customFilesForm = this.formBuilder.group({
      id: [''],
      field_name: ['', [Validators.required, Validators.minLength(3)]],
      model_type:['',[Validators.required]],
      category:[''],
      require_expiration:['']
    });
    this.customFilesForm.valueChanges.subscribe(() => {
      this.validateKeyValuePairs(this.customFilesForm);
    });
  }

  validateKeyValuePairs(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string, index) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.validateKeyValuePairs(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid && (abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }


  async getCustomFiles(page){
    this.api.get("admin/custom_files/lists?page="+page).subscribe((result)=>{
      this.totalRecords=result.data.total;
      this.customfiles = result.data.data;
    },(err)=>{

    });
  }

  addCustomFiles(content){
    this.modalService.open(content,{size:'md',centered:true});
  }

  submitCustomFilesData(data):void{
    (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = true;
    let endPoint = (data.id) ? 'update' : 'create';

    this.api.post("admin/custom_files/"+endPoint,data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.getCustomFiles(this.page);
      this.modalService.dismissAll();
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
      (<HTMLButtonElement>document.getElementById("submit-btn")).disabled = false;
    });

  }

  deleteModal(content,current_row){
    this.delete_id=current_row.id;
    this.modalService.open(content,{size:"sm",centered:true});
  }

  deleteCustomFilesField():void{
    let data ={id:this.delete_id};
    this.api.post("admin/custom_files/delete",data).subscribe((result)=>{
      this.response = result;
      this.toastr.success(this.response.message,"Success");
      this.modalService.dismissAll();
      this.getCustomFiles(this.page);
    },(err)=>{
      this.toastr.error(err.error.message,"Error");
      this.modalService.dismissAll();
    });
  }

}
