<div class="row">
    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Follow Ups</h6>
                <ul class="nav nav-tabs mb-0" role="tablist" *ngIf="!modelDetailsTemp && !activeTabTodayTemp ">
                    <li class="nav-item">
                        <a class="nav-link {{ (activeTab == 1)?'active':'' }}" id="active-01-tab" data-toggle="tab"
                            href="javascript:void(0)" role="tab" aria-controls="active-01" aria-selected="true"
                            (click)="crmDashboardDetail(1)">Today </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0 {{ (activeTab == 2)?'active':'' }}" id="inactive-02-tab"
                            data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="inactive-02"
                            aria-selected="false" (click)="crmDashboardDetail(2)">Past</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0 {{ (activeTab == 3)?'active':'' }}" id="dnr-02-tab" data-toggle="tab"
                            href="javascript:void(0)" role="tab" aria-controls="dnr-02" aria-selected="false"
                            (click)="crmDashboardDetail(3)">Future </a>
                    </li>
                </ul>

                <ul class="nav nav-tabs mb-0" role="tablist" *ngIf="activeTabTodayTemp">
                    <li class="nav-item">
                        <a class="nav-link {{ (activeTab == 1)?'active':'' }}" id="active-01-tab" data-toggle="tab"
                            href="javascript:void(0)" role="tab" aria-controls="active-01" aria-selected="true"
                            (click)="getAllFollowUps(1)">Today </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0 {{ (activeTab == 2)?'active':'' }}" id="inactive-02-tab"
                            data-toggle="tab" href="javascript:void(0)" role="tab" aria-controls="inactive-02"
                            aria-selected="false" (click)="getAllFollowUps(2)">Past </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link mr-0 {{ (activeTab == 3)?'active':'' }}" id="dnr-02-tab" data-toggle="tab"
                            href="javascript:void(0)" role="tab" aria-controls="dnr-02" aria-selected="false"
                            (click)="getAllFollowUps(3)">Future </a>
                    </li>
                </ul>
                <div class="btn-group" *ngIf="modelDetailsTemp || modelNameTemp">
                    <button class="action-btn bg-dark-green mr-2" (click)="addfollowUps(followUpsFormModal)"><img
                            src="assets/img/svg-icon/add-white.svg" width="20" /></button>
                    <!-- <button class="action-btn bg-dark-blue" data-toggle="modal" data-target="#exampleModalRight"><img src="assets/img/svg-icon/filter-white.svg" width="20" /></button> -->
                </div>
                <!-- <div class="btn-group" *ngIf="modelDetailsTemp">
                    <button class="action-btn bg-dark-green mr-2" (click)="addfollowUps(followUpsFormModal)"><img src="assets/img/svg-icon/add-white.svg" width="20" /></button> next
                   
                </div> -->
            </div>
            <div class="card-body" *ngIf="!isTrueTeam">
                <div class="tab-content list_table" id="ticketList">
                    <div class="tab-pane active show fade" id="active-01" role="tabpanel"
                        aria-labelledby="active-01-tab">
                        <div class="table-responsive list_table">
                            <table class="table mb-0">
                                <thead>
                                    <tr>
                                        <th class="pt-2 text-center"> </th>
                                        <th class="pt-2 text-center"> Open Follow Up </th>
                                        <th class="pt-2"> Client</th>
                                        <th class="pt-2"> Type</th>
                                        <th class="pt-2"> Due Date</th>
                                        <th class="pt-2"> Assignee</th>
                                        <th class="pt-2"> Completed </th>
                                        <th class="pt-2 text-center"> Actions </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <ng-container *ngIf="totalRecords > 0">
                                        <tr
                                            *ngFor="let follow_ups of followUps | paginate: { id: 'active_followups_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalRecords }; let i=index">
                                            <td class="text-center"><span
                                                    *ngIf="follow_ups.model_flags && follow_ups.model_flags.core_flags"
                                                    class="mr-2 action-btn"> <i
                                                        title="{{follow_ups.model_flags.core_flags.name}}"
                                                        class='fa {{follow_ups.model_flags.core_flags.icon}}'></i></span>
                                            </td>
                                            <td class="text-black text-center">
                                                <a *ngIf="follow_ups.type=='Phone'"
                                                    (click)="followUpDetailsModal(followUpDetailsFormModal,i,'modal')"
                                                    href="javascript:void(0)"><span class="mr-2 action-btn bg-edit"><i
                                                            class='fa fa-phone'></i></span></a>
                                                <a *ngIf="follow_ups.type=='Text'"
                                                    (click)="followUpDetailsModal(followUpDetailsFormModal,i,'modal')"
                                                    href="javascript:void(0)"><span class="mr-2 action-btn bg-edit"><i
                                                            class='fa fa-comments-o'></i></span></a>
                                                <a *ngIf="follow_ups.type=='Email'"
                                                    (click)="followUpDetailsModal(followUpDetailsFormModal,i,'modal')"
                                                    href="javascript:void(0)"><span class="mr-2 action-btn bg-edit"><i
                                                            class='fa fa-envelope'></i></span></a>
                                            </td>
                                            <td class="text-black"> <a
                                                    [routerLink]="['/crm/customer-profile/'+follow_ups.customer_id ]">
                                                    {{follow_ups.customer.company_name}} <i class='fa fa-share'></i></a>
                                            </td>
                                            <td class="text-black">{{follow_ups.type}}</td>
                                            <td class="text-black"> {{follow_ups.due_date | date : 'yyyy-MM-dd' }}</td>
                                            <td class="text-black">{{follow_ups.assignee.name}}</td>
                                            <td class="text-black">No</td>
                                            <td class="text-black">
                                                <a href="javascript:void(0)"
                                                    (click)="editfollowUpsModal(followUpsFormModal,i)"
                                                    class="mr-2 action-btn bg-edit" title="Update follow Ups"><img
                                                        src="assets/img/svg-icon/edit.svg" width="20" /></a>
                                                <a href="javascript:void(0)" class="action-btn bg-red"
                                                    (click)="deleteModal(follow_upsDeleteModal,follow_ups)"
                                                    title="Delete"><img src="assets/img/svg-icon/delete.svg"
                                                        width="20" /></a>
                                            </td>

                                        </tr>
                                    </ng-container>

                                    <tr *ngIf="totalRecords==0">
                                        <td colspan="7" class="text-center">Data not found.</td>
                                    </tr>

                                </tbody>
                            </table>
                            <pagination-controls class="pagination-controls-theme" id="active_followups_pagination"
                                (pageChange)="getFollowUps(page = $event)" maxSize="9" directionLinks="true"
                                autoHide="true" responsive="true">
                            </pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="isTrueTeam">
                <div class="tab-content list_table" id="ticketList">
                    <div class="tab-pane active show fade" id="active-01" role="tabpanel"
                        aria-labelledby="active-01-tab">
                        <div class="table-responsive list_table">
                            <p>You have {{ allTodatadata.length }} follow up tasks to complete today.</p>
                            <h4 *ngIf="allTodatadata.length == 0">Great work so far!</h4>
                            <div class="tab-content list_table" id="ticketList" *ngIf="allTodatadata.length > 0">
                                <div class="tab-pane active show fade" id="workspace" role="tabpanel"
                                    aria-labelledby="active-0-tab">
                                    <!-- Show All Data -->
                                    <div id="accordion-">
                                        <div class="card boxshadow-hide mt-3">
                                            <div
                                                class="card-header bg-green collapsed text-white justify-content-between d-flex align-items-center">
                                                <a class="flex-1 card-title mb-0 font-18 text-dark collapsed"
                                                    id="collapse99" data-toggle="collapse" href="#accordion-99"
                                                    aria-expanded="false">
                                                    Follow Ups ({{ allTodatadata.length }})
                                                </a>
                                            </div>

                                            <div id="accordion-99" class="collapse" data-parent="#accordion-">
                                                <div class="card-body">
                                                    <div class="row mt-3" followUps
                                                        *ngFor="let follow_ups of allTodatadata | paginate: { id: 'active_followups_pagination', itemsPerPage: 20, currentPage: page, totalItems: totalRecords }; let i=index">
                                                        <div class="col-sm-6 ">
                                                            <a
                                                                routerLink="/crm/view-follow-ups">{{follow_ups.title}}</a>
                                                            <span class="text-capitalize"> {{follow_ups.type }} </span>
                                                        </div>
                                                        <div class="col-sm-6 text-right ">
                                                            <button type="button"
                                                                class="action-outline-c btn btn-outline-danger bordered mr-2"
                                                                (click)="followUpDetailsModal(followUpDetailsFormModal,i,'dashboard')">
                                                                <i class="fa fa-envelope-o"></i> </button>
                                                            <button type="button"
                                                                class="action-outline-c btn btn-outline-danger bordered mr-2"
                                                                (click)="editfollowUpsModalDashBord(followUpsFormModal,i)">
                                                                <i class="fa fa-pencil"></i> </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- Delete  -->
<ng-template #follow_upsDeleteModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Delete</h6>
    </div>

    <div class="modal-body md-b-body">
        <p>Are you sure you want to delete?</p>
        <div class="btn-modal-group d-flex justify-content-between">
            <button type="button" (click)="deleteFollowUps()" class="btn btn-success  border-r-10">Yes, delete
                it</button>
            <button type="button" (click)="modal.dismiss()" class="btn btn-danger  border-r-10">Cancel</button>
        </div>
    </div>

</ng-template>
<!-- Add Call Script  -->

<ng-template #followUpsFormModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body md-b-body">
        <form [formGroup]="followUpsForm" (ngSubmit)="submitFollowUpsData(followUpsForm.value)">
            <div class="form-row">
                <div class="form-group col-md-12">

                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Follow Up Details</h6>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">
                                        Client <span class="text-danger">*</span></label>
                                    <ng-select class="custom-mutiple-select" formControlName="customer_id"
                                        placeholder="Select" clearAllText="Clear">
                                        <ng-option *ngFor="let client of allClients;" value="{{client.id}}">
                                            {{client.company_name}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState" class="col-form-label text-light-black font-16">
                                        Contact </label>
                                    <ng-select class="custom-mutiple-select" formControlName="contact_id"
                                        placeholder="Select" clearAllText="Clear">
                                        <ng-option *ngFor="let contact of allContacts;" value="{{contact.id}}">
                                            {{contact.name}}</ng-option>
                                    </ng-select>
                                </div>

                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Type</label>
                                    <div class="form-group  d-flex mb-20">
                                        <div class="form-check pl-0 mb-2">
                                            <label class="form-check-label button-radio-btn">
                                                <input type="radio" class="form-check-input radio-btn-button"
                                                    formControlName="type" value="Phone">
                                                <div class="layer"></div>
                                                <div class="button"><span>Phone</span></div>
                                            </label>
                                        </div>
                                        <div class="form-check pl-0 mb-2">
                                            <label class="form-check-label button-radio-btn">
                                                <input type="radio" class="form-check-input radio-btn-button"
                                                    formControlName="type" value="Email">
                                                <div class="layer"></div>
                                                <div class="button"><span>Email</span></div>
                                            </label>
                                        </div>
                                        <div class="form-check pl-0 mb-2">
                                            <label class="form-check-label button-radio-btn">
                                                <input type="radio" class="form-check-input radio-btn-button"
                                                    formControlName="type" value="Text">
                                                <div class="layer"></div>
                                                <div class="button"><span>Text</span></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">

                                <div class="form-group col-sm-4">
                                    <label for="" class="col-form-label text-light-black font-16">Assignee <span
                                            class="text-danger">*</span></label>
                                    <ng-select class="custom-mutiple-select" formControlName="assignee_id"
                                        placeholder="Select" clearAllText="Clear">
                                        <ng-option *ngFor="let assignees of allAssignees;" value="{{assignees.id}}">
                                            {{assignees.name}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="inputState"
                                        class="col-form-label text-light-black font-16">Opportunity</label>
                                    <ng-select class="custom-mutiple-select" formControlName="opportunity_id"
                                        placeholder="Select" clearAllText="Clear">
                                        <ng-option *ngFor="let opportunity of allOpportunities;"
                                            value="{{opportunity.id}}"> {{opportunity.title}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-sm-4">
                                    <label for="" class="col-form-label text-light-black font-16">Due Date <span
                                            class="text-danger">*</span></label>
                                    <input type="date" class="form-control" formControlName="due_date"
                                        [(ngModel)]="dueDate">
                                </div>
                                <div class="col-sm-12">
                                    <label for="">Notes</label>
                                    <textarea formControlName="notes" class="form-control" cols="30"
                                        rows="10"></textarea>
                                </div>

                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Flag </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select democlass" Id="Flag"
                                            (change)="selectFlag($event)" formControlName="flags_selection"
                                            placeholder="Select" clearAllText="Clear">

                                            <ng-option *ngFor="let flag of flags; let i = index" value="{{flag.id}}">
                                                {{flag.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" *ngIf="!isSelectedFlag" class="btn btn-default"
                                            (click)="openFlagModal(addFlagModal)"><i class="fa fa-plus"></i> Add
                                            New</button>
                                        <button type="button" *ngIf="isSelectedFlag" class="btn btn-default"
                                            (click)="openFlagModal(addFlagModal)"><i class="fa fa-eye"></i>
                                            View/Edit</button>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <label class="col-form-label text-light-black font-16">Status </label>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <ng-select class="custom-mutiple-select" (change)="selectStatus($event)"
                                            formControlName="status_selection" placeholder="Select"
                                            clearAllText="Clear">
                                            <ng-option *ngFor="let status of statuses;let i = index"
                                                value="{{status.id}}"> {{status.name}}</ng-option>
                                        </ng-select>
                                        <button type="button" *ngIf="!isSelectedStatus" class="btn btn-default"
                                            (click)="openStatusModal(addStatusModal)"><i class="fa fa-plus"></i> Add
                                            New</button>
                                        <button type="button" *ngIf="isSelectedStatus" class="btn btn-default"
                                            (click)="openStatusModal(addStatusModal)"><i class="fa fa-eye"></i>
                                            View/Edit </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-modal-group">
                <button type="submit" [disabled]="!followUpsForm.valid" id="submit-btn"
                    class="btn btn-success border-r-10 mr-3 md-close">SUBMIT</button>
            </div>
        </form>
    </div>
</ng-template>

<!-- Right Alight Modal -->
<div class="modal fade drawer right-align" id="exampleModalRight" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Right Align Modal title</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                ...
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div>
        </div>
    </div>
</div>

<!-- Flag Modal  -->
<ng-template #addFlagModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Flag Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <app-modal-flag [details]="flagDetails" [flagModelRef]="flagModelRef" [modelName]="modelName"></app-modal-flag>
    </div>

</ng-template>

<!-- Status Modal  -->
<ng-template #addStatusModal let-modal>
    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <h6 class="card-title mb-0 text-center">Status Details</h6>
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <!-- <app-modal-status [details]="statusDetails" [statusModelRef]="statusModelRef" [modelName]="modelName"></app-modal-status> 
            -->
        <app-modal-status></app-modal-status>
    </div>
</ng-template>


<ng-template #followUpDetailsFormModal let-modal>

    <div class="modal-header mod-h3 bg-green custom_padding d-flex align-items-center justify-content-between">
        <button type="button" class="close action-btn modal-dismiss bg-red text-red mr-15"
            (click)="modal.dismiss('Cross  click')" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body md-b-body">
        <form [formGroup]="followUpsDetailsForm" (ngSubmit)="submitFollowUpsFinishData(followUpsDetailsForm.value)">
            <div class="form-row">

                <div class="form-group col-md-12">

                    <div class="card card-shadow-hide">
                        <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                            <h6 class="card-title mb-0">Follow Up Details </h6>
                            <div class="btn-group">
                                <!-- <button class="mr-2 action-btn bg-edit"
                                    (click)="followUpDetailsUpdate(followUpsFormModal)"><img
                                        src="assets/img/svg-icon/edit.svg" width="20" /></button> -->
                                <a class="nav-link mr-0 " data-toggle="tab" href="javascript:void(0)" role="tab"
                                    aria-controls="inactive-02" aria-selected="false"
                                    (click)="followUpDetailsUpdate(followUpsFormModal)"><img
                                        src="assets/img/svg-icon/edit.svg" width="20" /></a>
                                <!-- <button class="mr-2 action-btn bg-red" (click)="followUpDetailsDelete()"><img
                                        src="assets/img/svg-icon/edit.svg" width="20" /></button> -->
                                <a class="nav-link mr-0 " data-toggle="tab" href="javascript:void(0)" role="tab"
                                    aria-controls="inactive-02" aria-selected="false"
                                    (click)="deleteModal(follow_upsDeleteModal,editData)"><img
                                        src="assets/img/svg-icon/delete.svg" width="20" /></a>
                            </div>
                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div class="form-group col-md-12 bg-success">
                                    <label for="inputState" *ngIf="editData.model_flags.core_flags"
                                        class="col-form-label text-light-black font-16">{{editData.model_flags.core_flags.message}}
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="col-form-label font-16">Due Date</label> <br />
                                    <span class="col-form-label text-light-black font-16"> {{editData.due_date | date :
                                        'yyyy-MM-dd' }}</span>

                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputState" class="col-form-label font-16"> Type </label> <br />
                                    <span class="col-form-label text-light-black font-16"> {{editData.type}}</span>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputState" *ngIf="editData.customer.administrator_name"
                                        class="col-form-label font-16 text-light-black">
                                        {{editData.customer.administrator_name}} (Administrator) </label>
                                    <label for="inputState" *ngIf="!editData.customer.administrator_name"
                                        class="col-form-label font-16"> N/A (Administrator) </label>
                                    <br />
                                    <span class="col-form-label text-light-black font-16"
                                        *ngIf="editData.customer.administrator_phone"> <a
                                            href="tel:{{editData.customer.administrator_phone}}">
                                            {{editData.customer.administrator_phone}} </a></span>
                                    &nbsp;
                                    <span class="col-form-label text-light-black font-16"
                                        *ngIf="editData.customer.administrator_alt_phone"> <a
                                            href="tel:{{editData.customer.administrator_alt_phone}}">
                                            {{editData.customer.administrator_alt_phone}} </a></span>&nbsp;
                                    <span class="col-form-label text-light-black font-16"
                                        *ngIf="editData.customer.administrator_email"> <a
                                            href="mailto:{{editData.customer.administrator_email}}">
                                            {{editData.customer.administrator_email}} </a></span>

                                    <span class="col-form-label text-light-black font-16"
                                        *ngIf="!editData.customer.administrator_phone && !editData.customer.administrator_alt_phone && !editData.customer.administrator_email">
                                        (No contact
                                        information)</span>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="col-form-label font-16" *ngIf="editData.customer.scheduler_name"><span
                                            class="text-light-black"></span>{{editData.customer.scheduler_name}}
                                        (Scheduler) </label> <br />
                                    <label class="col-form-label font-16"
                                        *ngIf="!editData.customer.scheduler_name"><span
                                            class="text-light-black"></span>N/A (Scheduler) </label> <br />

                                    <span class="col-form-label text-light-black font-16"
                                        *ngIf="editData.customer.scheduler_phone"> <a
                                            href="tel:{{editData.customer.scheduler_phone}}">
                                            {{editData.customer.scheduler_phone}}</a></span> &nbsp;
                                    <span class="col-form-label text-light-black font-16"
                                        *ngIf="editData.customer.scheduler_alt_phone"> <a
                                            href="tel:{{editData.customer.scheduler_alt_phone}}">
                                            {{editData.customer.scheduler_alt_phone}}</a></span> &nbsp;
                                    <span class="col-form-label text-light-black font-16"
                                        *ngIf="editData.customer.scheduler_email"> <a
                                            href="tel:{{editData.customer.scheduler_email}}">
                                            {{editData.customer.scheduler_email}}</a></span> &nbsp;

                                    <span class="col-form-label text-light-black font-16"
                                        *ngIf="!editData.customer.scheduler_phone && !editData.customer.scheduler_alt_phone && !editData.customer.scheduler_email">
                                        (No contact
                                        information)</span>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="inputState" class="col-form-label font-16"> Comment </label> <br />
                                    <span class="col-form-label text-light-black font-16"> No comments have been
                                        configured.</span>
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="col-form-label font-16">Call Cycle </label>
                                    <ng-select formControlName="call_cycle" (change)="selectCallCycle($event)" class="custom-mutiple-select" placeholder="Select" clearAllText="Clear">
                                        <ng-option *ngFor="let callcycle of callCycle;" value="{{ callcycle.value }}_{{ callcycle.days }}"> {{callcycle.value }}</ng-option>
                                    </ng-select>
                                    <input type="hidden" formControlName="days" value="">
                                </div>

                                <div class="col-md-12">
                                    <label class="col-form-label text-light-black font-16">Status</label>
                                    <div class="d-flex align-items-center justify-content-between">

                                        <ng-select class="custom-mutiple-select" (change)="selectStatus($event)"
                                            formControlName="status_selection" placeholder="Select"
                                            clearAllText="Clear">
                                            <ng-option *ngFor="let status of statuses;let i = index"
                                                value="{{status.id}}"> {{status.name}}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <label for="">Notes </label>
                                    <textarea formControlName="notes" class="form-control" cols="30" rows="10"
                                        value="Hello"></textarea>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div class="btn-modal-group">
                <button type="submit" id="submit-btn" class="btn btn-success border-r-10 mr-3 md-close">Finish</button>
            </div>
        </form>
    </div>

</ng-template>