<div class="row">
    <div class="col-xl-12 box-margin">
        <div class="card">
            <div class="card-header bg-green user-area d-flex align-items-center justify-content-between">
                <h6 class="card-title mb-0">Manage Availability</h6>
                

                <div class="btn-group">
                    <button class="action-btn bg-dark-blue" (click)="sendRequest()">Send Request</button>
                </div>
            </div>
            <div class="card-body">

                <div class="row">
                    <div class="col-sm-4">
                        <button class="action-btn bg-dark-blue" (click)="toggle()">Select All</button>
                    </div>

                    <div class="col-sm-8">
                        <div class="form-inline pull-right">
                            <div class="form-group mb-2">
                              <label for=""><b>Start : </b> </label>&nbsp;
                              <input type="date" id="fromDate" class="form-control">
                            </div>
                            <div class="form-group mx-sm-3 mb-2">
                              <label for=""><b>End : </b> </label>&nbsp;
                              <input type="date" id="toDate" class="form-control">
                            </div>
                            <button type="button" (click)="setDates()" class="action-btn bg-dark-blue">Update All</button>
                        </div>
                    </div>

                </div>

                <div class="tab-content list_table" id="ticketList">
                   <div class="table table-responsive">
                       <table class="table  table-nowrap mb-0">
                           <thead>
                               <tr>
                                   <th></th>
                                   <th>Employee</th>
                                   <th>Status</th>
                                   <th>Last Date Of Availability</th>
                                   <th>Set Start Date*</th>
                                   <th>Set End Date*</th>
                               </tr>
                           </thead>
                           <tbody>
                               <ng-container *ngIf="users.length>0">
                                 <tr *ngFor="let user of users; let i=index">
                                     <td>
                                         <input type="checkbox" (change)="selectedUser(i)" [(ngModel)]="user.isSelected" value="{{user.id}}">
                                     </td>
                                     <td>{{ user.last_name+', '+user.first_name }}</td>
                                     <td><span class="text-info" *ngIf="user.availability_locked==1">Received</span><span class="text-danger" *ngIf="user.availability_locked==0">Send</span> </td>
                                     <td>{{ user.last_availability_date }}</td>
                                     <td>
                                         <input type="date" (change)="getStartDate($event,i,1)" value="{{ user.availability_date }}" class="form-control">
                                     </td>
                                     <td>
                                         <input type="date" (change)="getStartDate($event,i,0)" value="{{ user.availability_date_to }}" class="form-control">
                                     </td>

                                 </tr>
                               </ng-container>
                           </tbody>
                       </table>
                   </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  
  
  <!-- Right Alight Modal -->
  <div class="modal fade drawer right-align" id="exampleModalRight" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Right Align Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          ...
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
  